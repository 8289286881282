import { FormEvent, memo, useEffect, useRef, useState } from "react";
import { TextInput } from "../../../TextInput";
import { Updater } from "use-immer";
import { CimpressDocument, TextArea, TextAreaWithContent, TextField } from "@mcp-artwork/cimdoc-types-v2";
import { getDocumentPanels } from "../../../selectors/document";
import { InteractionEventEmitter } from "../../InteractionStateHandler";
import { FontStyles } from "./FontStyles";
import CSS from "./TextAreaEditor.module.css";

type TextAreaEditorProps = {
  onSetCimDoc: Updater<CimpressDocument>;
  textArea: TextArea;
  interactionEventEmitter: InteractionEventEmitter;
};

const getText = (textArea: TextArea): string => {
  try {
    return ((textArea as TextAreaWithContent).content[0] as TextField).content;
  } catch (e) {
    console.error("textArea detected that is not TextAreaWithContent with TextField. This is not supported in the demo.");
    throw e;
  }
};

export const TextAreaEditor = memo(({ onSetCimDoc, textArea, interactionEventEmitter }: TextAreaEditorProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [showFontStyle, setShowFontStyles] = useState<boolean>(false);

  const onTextChange = (e: FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    onSetCimDoc((draft) => {
      const surfaces = getDocumentPanels(draft);
      const textAreas = surfaces.map((surface) => surface.textAreas).flat();
      const draftTextArea = textAreas.find((t) => t?.id === textArea.id);
      ((draftTextArea as TextAreaWithContent).content[0] as TextField).content = newValue;
      // TODO: support TextAreaWithFields?
    });
  };

  useEffect(() => {
    const onClick = (id: string) => {
      if (id === textArea.id) {
        inputRef.current?.focus();
      }
    };

    interactionEventEmitter.addEventListener("click", onClick);

    return () => {
      interactionEventEmitter.removeEventListener("click", onClick);
    };
  }, [interactionEventEmitter]);

  return (
    <div className={CSS.TextEditor}>
      <TextInput
        className={CSS.Input}
        onInput={onTextChange}
        value={getText(textArea)}
        onFocus={() => interactionEventEmitter.triggerEvent("focus", textArea.id)}
        ref={inputRef}
      />
      <button className={CSS.Button} onClick={() => setShowFontStyles(!showFontStyle)}>
        💅🏽
      </button>
      {showFontStyle && <FontStyles onSetCimDoc={onSetCimDoc} textArea={textArea} />}
    </div>
  );
});
