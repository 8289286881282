import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { JsonEditor } from "jsoneditor-react";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";
import "jsoneditor-react/es/editor.min.css";
import "./JSONViewer.module.css";

type DocumentEditorProps = {
  document: CimpressDocument;
  onDocumentChange: (document: CimpressDocument) => void;
};

export function DocumentEditor({ document, onDocumentChange }: DocumentEditorProps) {
  const onChange = (document: CimpressDocument | null) => {
    if (document) {
      onDocumentChange(document);
    }
  };

  return <JsonEditor value={document} ace={ace} theme="ace/theme/github" mode="code" onChange={onChange} />;
}

type JSONViewerProps = {
  object: any;
};

export function JSONReadOnlyViewer({ object }: JSONViewerProps) {
  return <JsonEditor value={object} ace={ace} theme="ace/theme/github" mode="code" />;
}
