import { useState } from "react";
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { FusionCanvas } from "../FusionCanvas";
import { findOverprintsInDocument } from "../utils/overprint";

type OutputProps = {
  selectedId: string;
  document: CimpressDocument;
  pixelSize: string;
};

export function ItemOutput({ selectedId, document, pixelSize }: OutputProps) {
  const [layoutError, setLayoutError] = useState<any>();

  return (
    <div>
      {layoutError && (
        <div style={{ color: "red" }}>
          <strong>layout error</strong>
          <pre>{layoutError.message}</pre>
        </div>
      )}
      <FusionCanvas
        input={{
          pixelSize,
          document,
          overprints: findOverprintsInDocument(document),
          selector: { id: selectedId, type: "item" },
          referrer: "fusion-demo",
        }}
        onLayout={({ layoutError }) => setLayoutError(layoutError)}
      />
    </div>
  );
}
