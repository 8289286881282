import { fetchWithNetworkCache } from "../../cache/network";

/**
 * {@link https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/298844816/Panel+Projections Panel Projections Specification}
 *
 * Projection involves two important concepts: a source document and a target document.
 * The target document will consist of panels, which are described in the {@link panels} array here.
 * Each target panel can be composed of zero or more source panels.
 * The source panels here each (1) refer to the content in a specific panel in the source doucment and (2) describe the transforms and clipping to be applied to that source content.
 */
export interface ProjectionSpecification {
  version: "v2";
  /** List of target panels. The size will be the number of panels in the projected document  */
  panels: TargetPanel[];
}

export interface TargetPanel {
  name: string;
  location: string;
  sources: SourcePanel[];
}

export interface SourcePanel {
  /** The ID of the panel in the source document that should be put here */
  location: string;
  /**
   * Only relevant for the first {@link SourcePanel} of a {@link TargetPanel}.
   * Expressed as a percentage.
   * The {@link TargetPanel}'s height will be the height of the panel being inserted here from the source document, divided by this percentage.
   * */
  height: string;
  /**
   * @see height
   * */
  width: string;
  /**
   * Whether or not the source document's panel should be clipped. Default value is false
   * */
  clip?: boolean;
  /**
   * A list of transforms to be applied to the source document's panel.
   */
  transforms?: SourcePanelTransform[];
}

export interface TranslateTransform {
  transformType: "translation";
  /**
   * Horizontal translation to be applied. Expressed as a percentage of the target panel's width. @default 0%
   */
  x?: string;
  /**
   * Vertical translation to be applied. Expressed as a percentage of the target panel's height. @default 0%
   */
  y?: string;
}

export interface RotationTransform {
  transformType: "rotation";
  /** X-coordinate of center of rotation to be applied. Expressed as a percentage of the target panel's width. @default 0% */
  x?: string;
  /** Y-coordinate of center of rotation to be applied. Expressed as a percentage of the target panel's height. @default 0% */
  y?: string;
  /** Clockwise angle to rotate in degrees */
  angle: number;
}

export interface ScaleTransform {
  transformType: "scale";
  /** Amount to scale in the horizontal direction, expressed as a percentage. For historical reasons, scaleX and scaleY must be equal. */
  scaleX: string;
  /** Amount to scale in the horizontal direction, expressed as a percentage. For historical reasons, scaleX and scaleY must be equal. */
  scaleY: string;
  /** X-coordinate of center of scale to be applied. Expressed as a percentage of the target panel's width. @default 0% */
  x?: string;
  /** Y-coordinate of center of scale to be applied. Expressed as a percentage of the target panel's height. @default 0% */
  y?: string;
}

export type AlignmentEdge = "top" | "left" | "right" | "bottom";
/** The align transform causes the transformed source panel to be aligned to the appropriate edge(s) of the target panel. */

export interface AlignTransform {
  transformType: "align";
  edges: AlignmentEdge[];
}

export type SourcePanelTransform = TranslateTransform | RotationTransform | ScaleTransform | AlignTransform;

// Some of the CimDocs refer to projections that don't work with CORS.
// For this reason, we use a proxy server to get at them instead.
function transformProjectionUrl(url: string): string {
  return `https://proxy.fusion.documents.cimpress.io/proxy?sourceUrl=${encodeURIComponent(url)}`;
}

export async function getProjectionSpecification(projectionId: string): Promise<ProjectionSpecification> {
  const url = transformProjectionUrl(projectionId);
  return fetchWithNetworkCache<ProjectionSpecification>({
    url,
    responseResolver: async (response) => {
      return (await (response as Response).json()) as ProjectionSpecification;
    },
  });
}
