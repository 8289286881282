import { CimpressDocument, TextArea, TextAreaWithContent, TextField } from "@mcp-artwork/cimdoc-types-v2";
import { Updater } from "use-immer";
import { getDocumentPanels } from "../../../selectors/document";
import { FormEvent, useEffect } from "react";
import CSS from "./FontStyles.module.css";
import { useRef } from "react";

type FontStylesProps = {
  onSetCimDoc: Updater<CimpressDocument>;
  textArea: TextArea;
};

export const FontStyles = ({ onSetCimDoc, textArea }: FontStylesProps) => {
  const onFontSizeChange = (e: FormEvent<HTMLSelectElement>) => {
    const newValue = e.currentTarget.value;
    onSetCimDoc((draft) => {
      const surfaces = getDocumentPanels(draft);
      const textAreas = surfaces.map((surface) => surface.textAreas).flat();
      const draftTextArea = textAreas.find((t) => t?.id === textArea.id);
      ((draftTextArea as TextAreaWithContent).content[0] as TextField).fontSize = newValue;
      // TODO: support TextAreaWithFields?
    });
  };

  const onFontStyleToggle = (fontStyle: "bold" | "strikeout" | "italic" | "underline") => {
    onSetCimDoc((draft) => {
      const surfaces = getDocumentPanels(draft);
      const textAreas = surfaces.map((surface) => surface.textAreas).flat();
      const draftTextArea = textAreas.find((t) => t?.id === textArea.id);

      const currentFontStyle = ((draftTextArea as TextAreaWithContent).content[0] as TextField).fontStyle.toLowerCase();

      const styles = currentFontStyle.split(",");

      if (styles.find((s) => s.trim() === fontStyle)) {
        const removedStyle = styles.filter((s) => s.trim() !== fontStyle).join(",");
        ((draftTextArea as TextAreaWithContent).content[0] as TextField).fontStyle = removedStyle ? removedStyle : "normal";
      } else {
        styles.push(fontStyle);
        ((draftTextArea as TextAreaWithContent).content[0] as TextField).fontStyle = styles.join(",");
      }

      // TODO: support TextAreaWithFields?
    });
  };

  const currentFontStyle = ((textArea as TextAreaWithContent).content[0] as TextField).fontStyle.toLowerCase();
  // Also add initial value
  const value = ((textArea as TextAreaWithContent).content[0] as TextField).fontSize;
  const fontSizeOptions = new Set([value, "8pt", "10pt", "12pt", "14pt", "16pt"]);

  return (
    <div className={CSS.Container}>
      <select onInput={onFontSizeChange} value={value}>
        {[...fontSizeOptions].map((fontSize) => (
          <option key={fontSize} value={fontSize}>
            {fontSize}
          </option>
        ))}
      </select>
      <button className={currentFontStyle.includes("bold") ? CSS.FontStyleActive : ""} onClick={() => onFontStyleToggle("bold")}>
        B
      </button>
      <button className={currentFontStyle.includes("italic") ? CSS.FontStyleActive : ""} onClick={() => onFontStyleToggle("italic")}>
        I
      </button>
      <button className={currentFontStyle.includes("strikeout") ? CSS.FontStyleActive : ""} onClick={() => onFontStyleToggle("strikeout")}>
        S
      </button>
      <button className={currentFontStyle.includes("underline") ? CSS.FontStyleActive : ""} onClick={() => onFontStyleToggle("underline")}>
        U
      </button>
    </div>
  );
};
