import { CimpressDocument, DesignDocumentWithPanels, DesignDocumentWithSurfaces, DesignSurface } from "@mcp-artwork/cimdoc-types-v2";
import { selectItemFromSurface } from "./selectItemFromSurface";

// Should we export this since we need it a lot?
// This should eventually be in a CimDoc SDK.
function getDesignSurfaces(document: CimpressDocument): DesignSurface[] {
  return (document.document as DesignDocumentWithSurfaces).surfaces ?? (document.document as DesignDocumentWithPanels).panels ?? [];
}

export function selectPanel({ document, id }: { document: CimpressDocument; id: string }): DesignSurface {
  const panels: DesignSurface[] = getDesignSurfaces(document);

  const surface: DesignSurface | undefined = panels.find((panel) => panel.id === id);

  if (surface === undefined) {
    throw Error(`No panel found for id ${id}`);
  }

  return surface;
}

export function selectPanelByPage({ document, page }: { document: CimpressDocument; page: number }): DesignSurface {
  const panels: DesignSurface[] = getDesignSurfaces(document);

  if (page > panels.length) {
    throw Error(`No panel found for page ${page}`);
  }

  return panels[page - 1];
}

export function selectPanelByNameOrLocation({ document, nameOrLocation }: { document: CimpressDocument; nameOrLocation: string }): DesignSurface {
  const panels: DesignSurface[] = getDesignSurfaces(document);

  const surface: DesignSurface | undefined = panels.find((panel) => panel.name.toLowerCase() === nameOrLocation.toLowerCase());
  if (surface !== undefined) {
    return surface;
  }

  const surface2: DesignSurface | undefined = panels.find((panel) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const location = (panel as any).location;
    return location.length > 0 && location.toLowerCase() === nameOrLocation.toLowerCase();
  });
  if (surface2 !== undefined) {
    return surface2;
  }

  throw Error(`No panel found for name or location ${nameOrLocation}`);
}

export function selectPanelByItemId({ document, itemId }: { document: CimpressDocument; itemId: string }): DesignSurface {
  const panels: DesignSurface[] = getDesignSurfaces(document);

  const surface = panels.find((panel) => {
    try {
      selectItemFromSurface({ id: itemId, surface: panel });
      return true;
    } catch (e) {
      return false;
    }
  });

  if (surface === undefined) {
    throw Error(`No panel found with an item of id ${itemId}`);
  }

  return surface;
}
