import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { useDocument } from "../hooks/useDocument";
import { DebugOptions, layout } from "@rendering/plasma";
import CSS from "./RichMediaDocumentPreview.module.css";
import { DocumentEditor } from "../JSONViewer";
import { GlobalOptionsContext } from "../Context/GlobalOptionsContext";
import { useSelectors } from "../DocumentPreview/useSelectors";
import { createRichMediaEngine } from "@rendering/neon";
import { FusionCanvas } from "@rendering/fusion-react";
import { getPixelSize } from "@rendering/fusion-react/dist/esm/utils/getPixelSize";

type DocumentPreviewProps = {
  url: string;
  both: boolean;
};

export function RichMediaDocumentPreview({ url }: DocumentPreviewProps) {
  const { setDocument, document: cimDoc, error, onResetDocument, projectionSpecification } = useDocument({ url });
  const [layoutError, setLayoutError] = useState<any>(null);

  const dimension = useMemo(() => ({ width: (Math.min(600, window?.innerWidth) / 2) * Math.ceil(window.devicePixelRatio) }), []);

  const { options } = useContext(GlobalOptionsContext);
  const debugOptions: DebugOptions = useMemo(() => ({ log: options.log, timers: options.timers }), [options.log, options.timers]);

  const parentRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const player = useRef<Awaited<ReturnType<typeof createRichMediaEngine>> | null>(null);

  const onError = useCallback((e: unknown) => {
    setLayoutError(e);
    console.error(e);
  }, []);

  const onChangeDocument = (cimDoc: CimpressDocument) => setDocument(cimDoc);

  const { selectors, selectedSelector, onSetSelectedSelector } = useSelectors({ cimDoc, projectionSpecification });

  useEffect(() => {
    async function renderDocument() {
      if (cimDoc && parentRef.current && selectedSelector && canvasRef.current) {
        const pixelSize = await getPixelSize({ cimDoc, dimension, selector: selectedSelector });
        const layoutResult = await layout({
          document: cimDoc,
          pixelSize,
          selector: selectedSelector,
          textOptions: options.textOptions,
          debugOptions: debugOptions,
          experimentalOptions: options.experimentalOptions,
          videoOptions: { enableVideo: true, mode: "video" },
          referrer: "fusion-demo",
        });
        parentRef.current.replaceChildren();
        const canvasContext = canvasRef.current.getContext("2d")!;
        player.current = await createRichMediaEngine({ videoParent: parentRef.current, layoutResult, pixelSize, canvasContext });
        // richMediaPaint({
        //   parent: parentRef.current,
        //   layoutResult,
        //   pixelSize,
        // });
      }
    }
    renderDocument();
  }, [cimDoc, parentRef.current, selectedSelector]);

  function play() {
    console.log(player.current);
    player.current?.play();
    // if (parentRef.current) {
    //   for (const child of parentRef.current.children) {
    //     if (child.nodeName === "VIDEO") {
    //       (child as HTMLVideoElement).play();
    //     }
    //   }
    // }
  }

  if (error) {
    return (
      <div>
        <h1>Error loading document</h1>
        <pre>{error.message}</pre>
      </div>
    );
  }

  if (!cimDoc) {
    return <div>Loading...</div>;
  }

  return (
    <div className={CSS.Container}>
      <div className={CSS.Preview}>
        {layoutError && (
          <div className={CSS.Error}>
            <strong>layout error</strong>
            <pre>{layoutError.message}</pre>
          </div>
        )}
        {selectedSelector && (
          <FusionCanvas
            cimDoc={cimDoc}
            dimension={dimension}
            onError={onError}
            selector={selectedSelector}
            textOptions={options.textOptions}
            debugOptions={debugOptions}
            experimentalOptions={options.experimentalOptions}
            videoOptions={{ enableVideo: true }}
            className={CSS.Canvas}
            onPaint={() => {}}
            referrer="fusion-demo"
          />
        )}
        <canvas ref={canvasRef} />
        <div style={{ visibility: "collapse" }} ref={parentRef}></div>
      </div>

      <div className={CSS.Editor}>
        <DocumentEditor document={cimDoc} onDocumentChange={onChangeDocument} />
        <button onClick={play}>Play</button>
      </div>
    </div>
  );
}
