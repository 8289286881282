import { Dimension } from "@rendering/fusion-react/dist/esm/utils/getPixelSize";
import { ReactNode, useEffect, useRef, useState } from "react";
import { getDevicePixelRatio } from "./utils/devicePixelRatio";
import { debounce } from "lodash";

type DimensionCalculatorProps = {
  children: ({ dimension }: { dimension: Dimension }) => ReactNode;
};

export function DimensionCalculator({ children }: DimensionCalculatorProps) {
  const [dimension, setDimension] = useState<Dimension>();
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      if (divRef.current?.parentElement) {
        const width = Math.floor(divRef.current.parentElement.clientWidth) * getDevicePixelRatio();
        if ((dimension as any)?.width !== width) {
          setDimension({ width });
        }
      }
    }, 300);

    const resizeObserver = new ResizeObserver(debouncedResizeHandler);

    if (divRef.current?.parentElement) {
      setDimension({ width: Math.floor(divRef.current.parentElement.clientWidth) * getDevicePixelRatio() });

      resizeObserver.observe(divRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      debouncedResizeHandler.cancel();
    };
  }, []);

  return <div ref={divRef}>{dimension && children({ dimension })}</div>;
}
