import { CimpressDocument, Image, ItemReference, Shape, TextArea } from "@mcp-artwork/cimdoc-types-v2";
import { BoundingBox, LayoutResult, getTransformedRotatedBoundingBox, transformBoundingBox } from "@rendering/plasma";
import { selectSurfaces } from "../selectors/surfaces";

type SelectCimDocElementsByPositionArgs = {
  cimDoc: CimpressDocument;
  layoutResults: LayoutResult[];
  position: { x: number; y: number };
};

type CimDocElement = TextArea | Image | Shape | ItemReference;
type SelectCimDocElementsByPositionReturn = CimDocElement[];

type ElementInfo = {
  id: string;
  boundingBox: BoundingBox;
};

const collision = (position: { x: number; y: number }, box: BoundingBox): boolean =>
  position.x >= box.left && position.x <= box.left + box.width && position.y >= box.top && position.y <= box.top + box.height;

export const mapToTransformedBoundingBox = (layoutResult: LayoutResult): { id: string; transformedBoundingBox: BoundingBox }[] =>
  layoutResult.elements
    .map((element) => {
      // Use the transform of the group
      if (element.renderingOperation.type === "group") {
        const transform = element.renderingOperation.transform;
        return element.renderingOperation.contents.map((content) => {
          return {
            id: content.id,
            transformedBoundingBox: transformBoundingBox(getTransformedRotatedBoundingBox(element.measurementData.previewBox), transform),
          };
        });
      }
      return { id: element.id, transformedBoundingBox: getTransformedRotatedBoundingBox(element.measurementData.previewBox) };
    })
    .flat();

export const selectCimDocElementsByPosition = ({
  cimDoc,
  layoutResults,
  position,
}: SelectCimDocElementsByPositionArgs): SelectCimDocElementsByPositionReturn => {
  const surfaces = selectSurfaces({ cimDoc });
  const cimDocElements: CimDocElement[] = surfaces
    .map((surface) =>
      ([] as CimDocElement[])
        .concat(surface.textAreas ?? [])
        .concat(surface.itemReferences ?? [])
        .concat(surface.images ?? [])
        .concat(surface.shapes ?? []),
    )
    .flat();

  return layoutResults
    .map(mapToTransformedBoundingBox)
    .flat()
    .filter(({ transformedBoundingBox }) => collision(position, transformedBoundingBox))
    .map(({ id }) => cimDocElements.find((cimDocElement) => cimDocElement?.id === id))
    .filter((element): element is CimDocElement => !!element) // Filter elements with missing ids, element should have an id
    .sort((a, b) => (b.zIndex ?? 0) - (a.zIndex ?? 0));
};

// Finds all elements in a document that intersect with the provided position
export const findElementsByPosition = (layoutResults: LayoutResult[], position: { x: number; y: number }): ElementInfo[] => {
  return layoutResults
    .map(mapToTransformedBoundingBox)
    .flat()
    .filter(({ transformedBoundingBox }) => collision(position, transformedBoundingBox))
    .map(({ id, transformedBoundingBox }) => ({
      id,
      boundingBox: transformedBoundingBox,
    }));
};

export const hasCimDocElementOnPosition = ({ cimDoc, layoutResults, position }: SelectCimDocElementsByPositionArgs): boolean => {
  const surfaces = selectSurfaces({ cimDoc });
  const cimDocElements: CimDocElement[] = surfaces
    .map((surface) =>
      ([] as CimDocElement[])
        .concat(surface.textAreas ?? [])
        .concat(surface.itemReferences ?? [])
        .concat(surface.images ?? [])
        .concat(surface.shapes ?? []),
    )
    .flat();

  return layoutResults
    .map(mapToTransformedBoundingBox)
    .flat()
    .filter(({ transformedBoundingBox }) => collision(position, transformedBoundingBox))
    .some(({ id }) => cimDocElements.some((cimDocElement) => cimDocElement?.id === id));
};
