// @ts-nocheck
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const imageDocument: CimpressDocument = {
  version: "2",
  document: {
    panels: [
      {
        id: "e8e3e476-afcd-4536-8bc5-5734a61cbff9",
        name: "image",
        width: "91.898611mm",
        height: "53.798611mm",
        images: [
          {
            id: "f01d4cf6-a9c2-40a3-8f9b-7c76775ad79f",
            printUrl: "https://uploads.documents.cimpress.io/v1/uploads/f9deffa2-3a7d-4c11-8ada-674db81abc48~110?variant=original&tenant=doc-platform",
            previewUrl: "https://uploads.documents.cimpress.io/v1/uploads/f9deffa2-3a7d-4c11-8ada-674db81abc48~110?variant=original&tenant=doc-platform",
            position: {
              x: "5.473587437455189mm",
              y: "11.219506564141167mm",
              width: "38.73830244852338mm",
              height: "11.35957197488044mm",
            },
            rotationAngle: "0",
            mirrorDirection: "horizontal",
            effects: [
              {
                type: "colorMatrix",
                values: [
                  [3, 0, 0, 0, 0],
                  [0, 1, 0, 0, 0],
                  [0, 0, 1, 0, 0],
                  [0, 0, 0, 1, 0],
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
