export type RequestNumberGenerator = {
  next: () => number;
  lastCompletedRequest: () => number;
  setLastCompletedRequest: (request: number) => void;
};

export const createRequestNumberGenerator = (): RequestNumberGenerator => {
  let number = 0;
  let lastCompleted = 0;

  return {
    next() {
      number++;
      return number;
    },
    lastCompletedRequest() {
      return lastCompleted;
    },
    setLastCompletedRequest(request: number) {
      lastCompleted = request;
    },
  };
};
