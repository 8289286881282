import Pixelmatch from "pixelmatch";
import { useEffect, useRef, useState } from "react";

type DiffCanvasProps = {
  img1: ImageData;
  img2: ImageData;
  onDiff: (pixels: number) => void;
  className?: string;
};

export function DiffCanvas({ img1, img2, onDiff, className }: DiffCanvasProps) {
  const [pixels, setPixel] = useState<number>(0);
  const diffCanvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (diffCanvas.current) {
      if (img1.width !== img2.width || img1.height !== img2.height) {
        return;
      }

      const { width, height } = img1;

      diffCanvas.current.width = width;
      diffCanvas.current.height = height;

      diffCanvas.current.style.width = `${width / Math.ceil(window.devicePixelRatio)}px`;
      diffCanvas.current.style.height = `${height / Math.ceil(window.devicePixelRatio)}px`;

      const diff = diffCanvas.current.getContext("2d")?.getImageData(0, 0, width, height) as ImageData;

      const pixels = Pixelmatch(img1.data, img2.data, diff.data, width, height);
      diffCanvas.current.getContext("2d")?.clearRect(0, 0, width, height);
      diffCanvas.current.getContext("2d")?.putImageData(diff, 0, 0);
      setPixel(pixels);
    }
  }, [img1, img2]);

  useEffect(() => {
    onDiff(pixels);
  }, [pixels]);

  if (img1.width !== img2.width || img1.height !== img2.height) {
    return <p className={className}>Width and height are not the same value. Can't performance diff.</p>;
  }

  return <canvas className={className} width="0" height="0" ref={diffCanvas} />;
}
