import CSS from "./CSRvsSSR.module.css";
import { DiffBlock } from "./DiffBlock";
import { useState } from "react";
import { AddDocumentURL } from "../AddDocumentURL";

export function CSRvsSSR() {
  const [urls, setUrls] = useState<string[]>([
    // Some Text
    "https://storage.documents.cimpress.io/v3/documents/84d837d2-fa71-470e-8198-3c75bfed174d",
    // Hello World
    "https://storage.documents.cimpress.io/v3/documents/transient?document=tZRNb9swDIb%2FyqBdNiB2LMuy5dwKFAUGDCuwdqdiB33QiVDZMiSlbRr0v492ki3tdho2X2zxJfmQtKQ9eYAQrR%2FIipRkQQw4SGDIqpMuAq693vYwJLLak1EO4CJZ3e2JRQ8CAhhUTZ3JTpus4qzOhNI84w2rZE216roWUw6yB%2FTugsc0C%2FJoTdrguqW5aEVNad%2BjdQN2vUEK4SxvfpkTPKWLAPKMyipVyFpVmVGKZRUVKhNFLTNTIbVqlGHCYOToo01zX3vyhGGU57QqKWtaxquy5WU9A3ZT3yIvWkrbtqxqXjRH5VQnb3JelE3TiPrwvK6XFnkhhEDjC1p9sM%2FYpnQXzq6Hw%2BCIxhcEDMJRJ6tfi701xgGKehse4EwpFkQ5r%2B%2BvnH%2B8tAH0oZszRpbUcUTXwWKMPHoMMm2DdKg9fxoMPM25NIbMeXGOP7%2Bxjckt7cbpD9nB2WEqpUP9SvbWTdP54pN%2FdyOHeBRu0s5N3oMP%2FQyZjfZ5slE2Tr9Ye%2BcDLsNafXhfMqE69hHHcw7%2B%2FLdY5Z1ZxBTsPfhteo0v6e943nUlb97ir%2F9l13%2FCtmXJK%2FoW%2B%2BiDM%2F974LSgoiwQ%2FX1BbIL%2B9gA7HaXZbkD7MO%2BXW9CbAcPXE9p3XQTcTZd2bXGDoevLAfcV5vPkw%2B5bcNO%2BSWmMq%2BVy0mJ%2BuiRirm0%2FBogxt375QJfhFGYhLgvJFO2UzEQn66xiQDOhGWQS65UclKC8WI5b5Wzc4A308gM%3D",
    // Linear Gradient Text
    "https://storage.documents.cimpress.io/v3/documents/transient?document=nVTbjts2EP2VVEWBFLBkUnf5qUFTp4tc291tii36QJFDmw1FKiTtjb3Yf%2B9QshsHfQnCJ3EuZ2bOGeohEZbvBjDhSiSrhLBSSNnIVDTQp2WVs7QXZZ8ykJySikBOZbJI9uC8sgYTcrwJ0BAA0yXTHhaJg72a3WSR2HsDDgPpb3V7V7yGn9viSkh48fadvzpe37T1h9vN3fX%2B5U9cK%2BzCI14Aw0zAHMa53Zmw2uS%2FHKs%2FmL17bz%2Fe5vmfH9%2F9c%2FPqNlY%2B9Z6sHpKRGdA%2BWf31kKg4CrRQQNnUKZNc4ChFnbY9r9KqKUpWU95L2SGEYQNgtHQWYRbJvRJhi%2FeOZm3X1pQOA1q3oDbb2FFVZM1nc4BP4ZkDdlFVdGUna0qQNoIEguzSFpoq7fu%2B6%2Fuck5zUmDlar8JE0UPyKbKTZ1VDu6rsiqqqSZ2XU4FDdBUZaUtC6qbNad1UNJ9c50arJqtI3jRNW8%2Fny4brLC%2BrCPaIRuvUEcdk%2BplWGzMTl2iQYZY0KP6lK9gRPXzn9nBhRlF7bfmHtbb3z5UDPg9yAZ%2BG%2FsTOWxdFZacIw8LOMY2%2B45URgINThMeUCRcp%2FO87%2BRW0tosn763T4rsIdhijTMpoZQDvEiPXbFA6UvTGBvvkmhl%2FclyHg47RxrphKjcZ1REmOsc4LrfaxrUcmTLh6XB44ZiIrdIfk8e%2FF4kKMNzMJc8iT3YB3LppnBvgW4Mgm9iAldIDDvtcbRTOfwqVykwi%2B3k9Vdxu%2FLooFq8%2BMBe%2BdQ9QVTDia7K7rDgleJR13ti57fjsf7igxG36p9%2Bv1wQPkrG4CKPk%2F4GErPHMrPkReRKvIWytmLgVn5WLujF3njx5xDPr8jtMj8G6w63TcY1CGP1quYw%2Bn51fuM%2B4GhDe%2B0zZ5Z4u3TlNgV8SVvRU9ixtJavTsgCatryAlBHasgr6llZkOe56rfwWf1SP%2FwI%3D",
    // List
    "https://storage.documents.cimpress.io/v3/documents/transient?document=1ZZNb9swDIb%2FyqBdNsByLMvyR24FigI9bAPW7jT0INt0IlS2DElpmwb576PysSaHoVuzDBjggyWKfMlHtKUVeQDrlBnIlKQkIi1o8NCSaSe1AxybZtHD4Ml0RUY5gHZk%2Bn1FFK4gUAKHrMip7JqWZoLntKwbQUXBM5mzpu66CkMOsgdc3VmDYSLyqFo%2Fx3HF4rIqc8b6HmfnoGZzVCGCx8XLtIcnf2FBHqh2XZ2zvMxoWpSCZlXW0FrWgubQcpYVXEAC6Dkap%2FymrhV5QjdWxVWCflXCuUjSIhMbgSWaspglIhWMZ2mas5LxrWmfKBPVcYq8iLMizXFyjbPGqmesTOoLrWbDlhXR0IVaka1XzbHJmxEtzcI%2BwMF0EpFam%2Bb%2BSpvHS2Wh2eZ%2BEJ76egfki1XoI3crBukXVmq0PV8PLWCtDMOjyyYuUjt818r5T9Leg73xSx22xdgWLG74L3yIGQJNvxzDYjVotRl3aL%2BSvdKB32fjzbsbObidYR96MLaXOlpgVvbA70Y9Byvjo9%2BoamNxaGf1h1SIKImSj2R9FzpxDPjZT%2FGQ%2B7WH%2Fs%2FkXxV9zxJWpkE0egsf%2F2hO4%2FNqgkgkYsV%2FR4XKs3NhZRKlx2DSE8FEzlt1D2bhf2tfsGNPQnRuRilPoiw8R5D4ubvn5UN%2BM5r6zGReGvzvNc%2FZv6q5hRP%2Fxm%2Fi8g%2F%2FNndHUueUUVjG7VZqf8vY1dwYuzlcb6GZD%2Bg%2BC9Km6xzg0XupZgpPY1y63sp9hc1Vw9jlN6vDie396KaTSbC5eH9%2FcnGj%2BtGCc7Eykwc2sXs3BW6SSF6zrpa07GROMw6Mlg0HKjFfKaAumUgm46JGJnPsj%2FUP",
    // Long paragraph
    "https://storage.documents.cimpress.io/v3/documents/5b76aa52-571a-41b1-bd8d-79e1f28a1ab6/docref",
    // Image
    "https://storage.documents.cimpress.io/v3/documents/transient?document=zVI9r9wgEPwvFKkePmPAgKUoemWaFJFSRSmwWe6QMFiYu%2Fdxuvz2rB2dkivSR1TL7Ozs7O6VXKCsIScykI48EQcRKjgyeBtXwDhP5xlSJcOVLDZBXMnw%2FUoCZhDQwEGonlo%2FOSok76keJ0ml4sL2bBq9N1gy2RkwO8z2CBi%2BBFdPGBvWaKN7xuYZf08QjidUIZI36s%2F3TvpL0rfMicmjpJk6KlrLqfZmpGpSvVLSOmU80pYSUv1WIjJOtS7rcDicl5itW5u7obWZwrwUWNcm5MOF3RMO3jjw3naUW4WuJsaots7SXgk3ambHSeifjLWfLrYEm%2BrHXMIxJBs%2FVEhbjAp0ibb6XOa9F7gEePlPunn%2FnBy8kqHFxvIa6r75K3ndJt8IxaVWgishJdNm38AbIow1HTOy7WUvmGCsVzt03yTXjeKat50QWnac68eNIptLIxUzSmjdCoHwDXHs9D2nauNzDMf0%2B8hIBF%2BRjEdZw%2FQI1bxs48SD%2BHKeRyi7iZKr3Uw8p2PcroxpcvuB74l4rP0VdpO5vD2Of8P%2BNfylZEduvwA%3D",
    // Shape
    "https://storage.documents.cimpress.io/v3/documents/transient?document=pVFBTsMwEPzLcqFSlMapkrS5piD1hITghDiYZBNbdWzLdqEl6t9Zp1DBGZ%2B8O7Mzs%2FYEnWkPI%2Bqw66CGDkdzp5S0HrfffUjgHZ2XRhOeU9WhwoDE7rnymFwFoJ7Aco3KQ%2F0ygYx6%2FuB63iKjMc1HpM4u4EjVh%2ByCoLIs0qrMq2zD2HrNqpyNERUoB0GCsCpTxooyL7LN5RQz7gW3%2BNsm1tEknGw0wcsKkRmc2WOM1hplHGFueLu9YVlWbu4XcULIdq%2FRkxpkable2QDnBD53usMj1FkC1ngZ5vUnOEbWHOF0vf1nFXL6k6tqmqZsFnB%2Bje%2FcGsej8xO2QhNtiKam7z2GB7eVgwxcEZVEeqPDI85JjTs9O0VEEYL19XIZMZ%2F%2B%2FJJPWzlaRwun0iytMx2cvwA%3D",
    // Curve shape
    "https://instructions.documents.cimpress.io/v3/instructions:preview?documentUri=nVLBbtswDP2VgKcNUBw5sYfCx26XDhswDO1p2EGR6IiILRmS4jYz%2Bu%2BjlDZArgUMyXyPenoktYDx%2BjSiSw8GuhxIEDBjiOQdA1uODA6YkOleDREFBJzpQktxPQ7dApNyOETo%2FixAWa3ef9GybZu13Jt%2B3Rhe9ltVr1u9RdzvjN7pnvWdGpGz%2B%2BBZRsAzmWQ5bnfV3TgyYJEONmUzd1VTkGjVhJeL0nnKh%2FUpzJiZFPwRsxntBx8yM56Pn6RY1ZKX%2FH3mtGRJHx3GmF0WyYEcflUTx8GfnHlDvntyV%2BhVwL8HZ%2FAFukbA5COl0oUFGAFZZM7Xv%2FcyanlbRIlZqnSoFFIzfeP2avXN7Uz4fO9fPnpTnA%2B%2FVLLfVFKM%2FqylqOXqx7YVq%2Fay1byvmtIjuEl%2FcpSFiqwefMR7tGqm4jSnUsLx8TKAUgm8%2Fs3PRfugcmseUVvHhR2yV0MHSmrgFLY0YlKm%2BFk46nnwv7E01IfzUxg43aY0dZtNpmL1%2FshipWmcAs%2BtIr%2BZgueh%2FAc%3D",
    // Vertical Text
    "https://rendering.documents.cimpress.io/v1/cse/preview?width=1000&height=1000&format=png&instructions_uri=https%3A%2F%2Finstructions.documents.cimpress.io%2Fv3%2Finstructions%3Apreview%3FdocumentUri%3DvVRBb9MwFP4rlU8gNamdNI3T28Q0wQUkNk6Ig%252BO%252BtGZOHNnutq7aZYCAaRIX7uwIByQ4DiHtzzDUnvgLvKTrVI5IiOTi9z6%252F9773%252BdlzcgDWKVORIYlIl4xAg4cRGRZCO0DbyGkJlSfDOalFBdqR4dM5UbiDpDEkgrM44DKKgj5lUZBzOQgSPshYMohYzgtMWYkScHdhDabpkkM18hO0GaVlifYE1HjiGwdfOTwc%252BS0LYqMS5HFSZDEP4gySoM9FFuRCsCBOU5nkKWRRlGBkbZzybS9zctSWCFPazxK6%252FvptgdkK4hllfZZGLIuRbIusufEkpHEWp5SmPMXGktpvMo0HPEw2NjCETxA3Vh1jk0JvaTWuVrIRDUUTjDJ7Jf%252BEvKkRkVN7ABtu2iW5NnJ%252FR5vDbWVBrlq6zRBYfaPSI6swQtzglfBTKxrs%252BEE1AhSAdYlWFdy%252FVThsjlgixbYQqnu7JtcfLn9eXKraTcvOyGhjO3hsfvHmfHnxeXl5tTh9%252B%252BPb%252B8XZV1z8%252Bn7eEJjVzbGqqimBdoGpdkSpdCPvQ%252BNNZ1dUrrN77wbb9TPdBFTGli3L1qmOG18UtQLLpi6aspzt36Hd5scpuYvabjJ9LrSooTKd6y%252FvFq%252BukPg%252FZtOnf8EGRVucflq%252B%252BLh8fb54eYby%252FGdtnnWJ8lDurUquL0%252FrH4E0th2PPZCTCjOMGwKmKBzg8GyrscJhxa0nq4qPob1Bxs6e4IwNycT72g17vQZz4fopcKFUZW3BuVCZ3gHr2XWYAtejIs5ZkYuAF2IQ9GNg%252BDzEEAjKuEgg5yyhvXqaa%252BUm%252BM6c%252FAY%253D",
    // Vertical Text 2
    "https://storage.documents.cimpress.io/v3/documents/transient?document=XVJNb9swDP0rhXbpgCi246%2FEtwBFsV02YO3Qw7CDLFM2MVkyJCVtEuS%2Fj7aTLqtO4nskHx%2BlE9uD82gNq9iKLVgDGgI0rFJCe6DYyl0PJrDqxAZhQHtW%2FToxpAwGa0ghKwsulGx4lqcFX9cy53mZZqJIZK3Uhloa0QNlK2epzYK9YhM6ipM47nuKO8C2CzdAgLewdSBulERWJOsyBw51HfMMVorXZV5wmaiiLLO1SrOaKgfrMUxeTuyNyvK54eHf9Sr%2BUTqn%2BEyAdXikMYXeamzNbJxpUOPgtKiA8n8q2IEYuXN7uIHjBau1lX8etX19QAdyHuq9A3f64vO7Q6oQF96IsHNi5Jydwa1p9bi8mLDjV9MA2UpIkGaclGhB73f2BbS2dy%2FW6WZsfxjGSjQaDVCsKO9R9KjHdXyzwd49CeMvxFM4TDrGun4aYALxOGLJahjtS6uto9C19f2neDqf2fn3gmGA%2FnkWuz7dhDcgrZtcPIPsDJW3o7RVygMZf8AWadGUep7lfsD0ftYdftJ%2BKtaFMPgqikbOL68f0S8l9oMD75doo30SuWsZgo%2BEUkneFDmHJFY826waXq9BcKmaLM%2FkJpWpioZdrdF39MvPfwE%3D",
    // Line
    "https://instructions.documents.cimpress.io/v3/instructions:preview?documentUri=RVHLbsMgEPyVilMrEQcndhX52lN6rNpTlQOGdUDhJSBp3Cj%2F3oWkrWQhz87OMLtciPTiaMHlrSRDAYxQcoKYtHdYWCGSYCAD0hM3CSiJcNI3mtE%2FORkuJHAHJpHh80J0cWvHZ8H6vluwUU6LTuIxrni76MUKYFxLsRYT%2BjtuAbun6NGGki8ts0Lcr5uNtVhQoPcqlzCbpquVpHiA20V5DkVstINC5OgPULIIb3xEQtj58MjoQ8vwKN8TtmWlxcFBSiVkdSz6Fx4QR3908l559dr9la6UfG%2BdhDMZOnobsOZo67081hWciyOrlnOZAf9QByi%2Fk90%2F2Vb2uisbFj7yjDt9B6EcRt%2BXBqn3OnNDrjv0sJC55JmjEaIJd%2FUGwSedfZw%2FosF2lXMYlstCpeb3XVIjtA0RZ220X4bocZAf",
    // Subpanel
    "https://instructions.documents.cimpress.io/v3/instructions:preview?documentUri=xZVNb9QwEIb%2Fi7lQaZ16%2FDneW0VbCS5ICE6oB8dxdqNmk5CkQKn63xkv3XaXqlCQEKckM5P5eOZ1csM%2Bp3Fq%2Bo4tmWQLVqU2zaliyzq0U6LnPl5tUjez5Q0bQpfaiS0%2F3rCGIljCpJJ2loc6VlwbZTmW0XDjlA4WYlnXnlJ2YZMouh57SrNgX5pqXtOzhwI9WoDNhqzr1KzWVIUZVbgH83RV7lX99rqr0le2FAs29FMzb9u%2BYWRhYht%2BnRPQ3e3i2R1irrIOQ%2FpRYr4ecrNjinPoVm3K3nnsL1MuFPu2H7N3Vb58AQJQiiMKmNdNvOzSNOU%2BCotqyHO2TZdehYFs06erMKY705u%2Byaw3zUwm6nM3k348k1OFEMZaMMobY525nxELYdAAWKMUaAFu69mRVYX11hovtRTSCOfcIWJVaEonrVBopRBW2D1kXkEpRSV5qnzgWjjgPqrAY1CxtiCdqvJSD1D4sxN5Io8ox38EaB4DRP2PiY39HHLBk%2B2kS6YN22G01qKJikMtBNegIkcIiUN0laqx1GUpnoExtW0zTL%2BAeH5uzsSTEPfwqMd4rC%2BUF2CVRtQIGu9pqcJb4bUDb7REB%2BqAFrjCOks4BekSSBOHsMjtvKPtWg0S0Kr9A1m6hJWMPJisLkDDvfDITVLoyugTovkZy%2FnZ6WkecQ9L1sGfM3Hmb3RlH68ZxG7NdDrLukLP6Ypcx4TcV1Wk0yKr4ERZk3nbaBjnp471g0jBFg6skF46YQAV3MksddW9pGk1iiRsEZVTUtnfvHx7cXuRv%2BqxH7cjvE9x3RGsVX6lr%2BspzW%2FH02bVzKFlFLxgNX2m36WtVvrx%2BsPYUuB6nodpeXycfVOx%2BydMRWw2w0h4i6Y%2FHsa%2BYrffAQ%3D%3D",
    // Line that was being clipped
    "https://instructions.documents.cimpress.io/v3/instructions:preview?documentUri=lVRLc9MwEP4v4gIzcWpZtmXnxNACE6DTQhNgyvQgS%2BtExLZcSekrk%2F%2FOys2DA4dy3JV3v8fuekOUkesWOj9VZEIgzdNSJRDlvCqjtGJxVCYJi6iQjIoy4yrOyIjcgXXadFiQYKSgAQ9YXovGwYhYuNPPz3REzH0HFj%2BkX%2FPimp3DacGmqoaPF5du%2BnQ1K%2FLVfHF9dff5rWw0snDYz0MnOo81Qkqz7vxkkbx%2Fyr4Lc%2F3D3M6T5Oft5e%2FZl3lA3nEnkw3pRQeNI5NfG6IHKQUwSHkeiVqqKM1YHhWVzKKMs1TkVFZ1XWKLTrSAX9fWYJsRudfKLzEu6bgoi5zStsXsEvRiGRhlbMyPabcUPTxD%2Bsc%2BtGl0B%2BHBW7OCwEqaxgT5dlG9fkVjWiTxmyBxqeWqA%2BeChX0ADpWnosfY3a6FhV3qk9HB5lZ7TG1H5GnaKXgYnB1UpjnnKLOMuExFlLI66I3rCIeWUCopK7gYCAk7uPQQRpGOOc05LXiaURbjgAc1j4ELHSNFylPkyVjOSnxBVOjUvjiLxwVLWMqKXfGhlsb%2FrNUe2tmzO4NfZHsTVkYaKzwuyQzkskOTFqGFqWsH%2FsKe6YX2osHy3TBpSrNCFSIqRZbgXsYsqnidRlwxXsVJVedxehxmJeTqf2f5UkY3KKkFL5TwIniiZHN%2BCPe7h5L7Rng42%2B3nN6jBQidhbv8S9cLZeSs6VxvbuoBnjUeoqkGd3q7x2pwUzTHeBnf38EdCL4RqjFwNKKC0P9zzgcAhs9%2FCXWjwf2C1Cqew2W4P%2Bj4MN3UI34WpDP7VJljSG6e9sY%2FBkwlZet%2B7yclJeHPj%2FWG7sdRtb%2FFQxtqc9NYosv0D",
    // Unclosed shape that was being clipped
    "https://instructions.documents.cimpress.io/v3/instructions:preview?documentUri=nVLBbtswDP2VgKcNUBw5iYfCx26XDi0wDO1p2EGR6IiILXmS4jY1%2Bu%2BllDZArgMMwXwknx4fNYPx%2BjigS3cG2hxIEDBhiOQdA2uODPaYkNOd6iMKCDjROS3FpR3aGUblsI%2FQ%2FpmBMlu9%2B6Zl02yXcme65dbwsVuretnoNeJuY%2FRGd8zv1IBc3QXPNAKeySTLcbOpboaBAYu0tymLuam2BYlWjXi%2BKJ3G3KyPYcKcScEfMIvRvvchZ4bT4YsUi1rykb%2BvXJYs6YPDGLPKQtmTw%2B9q5Dj%2BO6qAH9BPT9mG4I%2FOwJuA1ztn8AXarYDRR0rFhhkYAVl4Tpe%2FzzlqeT1FiZmqWFQmqTl9Jfei9UPuRPh861%2F%2B96Y47X%2BpZH%2BopBh9qKWo5eJ%2B3YhFs5g2cFXw5Ci3FiLd%2B4i3aNVERVvWSQmHx7PnRTu8%2Fc0vRPugshmPqK3jUfZZnaE9JdVzCYsYMClTFMwcdbzr31gs9OH0FHoutymN7WqVU7H6fFex0jSMgVdVkV%2BNwfMa3gE%3D",
  ]);

  return (
    <div>
      <h1>Client side rendering vs server side rendering</h1>
      <p>Compare the output given by the client side rendering libary (Fusion, on the left) with the output given by the server side rendering application.</p>
      <AddDocumentURL
        onDocumentURLAdded={(url) => {
          if (urls.includes(url)) {
            window.alert("url already added");
          } else {
            setUrls([url, ...urls]);
          }
        }}
      />
      <div className={CSS.ClearButton}>
        <button onClick={() => setUrls([])}>Clear all examples</button>
      </div>
      <div className={CSS.Container}>
        {urls.map((url) => (
          <DiffBlock key={url} url={url} />
        ))}
      </div>
    </div>
  );
}
