import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import React, { useCallback } from "react";
import { getDocumentPanels } from "../../selectors/document";
import { InteractionEventEmitter } from "../InteractionStateHandler";
import CSS from "./CimDocEditor.module.css";
import { ImageEditor } from "./ImageEditor";
import { ShapeEditor } from "./ShapeEditor";
import { Updater } from "use-immer";
import { TextAreaEditor } from "./TextAreaEditor";
import { ItemReferenceEditor } from "./ItemReferenceEditor";

type CimDocEditorProps = {
  cimDoc: CimpressDocument;
  onSetCimDoc: Updater<CimpressDocument>;
  interactionEventEmitter: InteractionEventEmitter;
  moveIntoViewEnabled: boolean;
};

export function CimDocEditor({ cimDoc, onSetCimDoc, interactionEventEmitter, moveIntoViewEnabled }: CimDocEditorProps) {
  const surfaces = getDocumentPanels(cimDoc);
  const multipleSurfaces = surfaces.length > 1;

  return (
    <div className={CSS.Container}>
      <h2 className={CSS.Header}>Editor</h2>
      {surfaces
        .filter((surface) => surface.images || surface.textAreas || surface.itemReferences || surface.shapes)
        .map((surface) => (
          <React.Fragment key={surface.id}>
            {multipleSurfaces && <h3 className={CSS.SurfaceName}>{surface.name}</h3>}
            {surface.textAreas?.map((textArea, index) => (
              <TextAreaEditor key={`${textArea.id}-${index}`} textArea={textArea} interactionEventEmitter={interactionEventEmitter} onSetCimDoc={onSetCimDoc} />
            ))}
            {surface.shapes?.map((shape, index) => (
              <ShapeEditor
                key={`${shape.id}-${index}`}
                moveIntoViewEnabled={moveIntoViewEnabled}
                shape={shape}
                onSetCimDoc={onSetCimDoc}
                interactionEventEmitter={interactionEventEmitter}
              />
            ))}
            {surface.itemReferences?.map((itemReference, index) => (
              <ItemReferenceEditor
                key={`${itemReference.id}-${index}`}
                itemReference={itemReference}
                onSetCimDoc={onSetCimDoc}
                interactionEventEmitter={interactionEventEmitter}
              />
            ))}
            {surface.images?.map((image, index) => (
              <ImageEditor
                key={`${image.id}-${index}`}
                image={image}
                onSetCimDoc={onSetCimDoc}
                interactionEventEmitter={interactionEventEmitter}
                moveIntoViewEnabled={moveIntoViewEnabled}
              />
            ))}
          </React.Fragment>
        ))}
      <p className={CSS.Note}>Note: images are replaced client side, they are not uploaded.</p>
    </div>
  );
}
