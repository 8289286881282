import { HslFilter, TriangleBlurFilter } from "@rendering/plasma";
import { Updater } from "use-immer";

export function BlurFilterControls({
  filter,
  onChange,
}: {
  filter: [TriangleBlurFilter, TriangleBlurFilter];
  onChange: Updater<[HslFilter, TriangleBlurFilter, TriangleBlurFilter]>;
}) {
  return (
    <>
      <label>Blur:</label>
      <input
        type="range"
        value={filter[0].deltaX}
        min="0"
        max="0.05"
        step="0.0025"
        onInput={(e) => {
          const value = e.currentTarget.value;
          onChange((draft) => {
            draft[1].deltaX = Number(value);
            draft[2].deltaY = Number(value);
          });
        }}
      />
    </>
  );
}
