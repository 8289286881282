export const fetchHTMLImageElement = async ({ url }: { url: string }): Promise<HTMLImageElement> => {
  const img = await new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = url;
  });

  return img;
};
