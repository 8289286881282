import memoize from "lodash.memoize";

// Only 2 time units are supported in cimdoc: s and ms

// Get number value and unit value
// Example: "12.5" ms returns 12.5 and ms
// eslint-disable-next-line
const regex = /(^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?)\s?(ms|s)/;

export function getTimeNumberAndUnitValue(value: string): [number, string] {
  const regExResult = value.match(regex);
  if (regExResult && regExResult[1] && regExResult[2]) {
    return [Number(regExResult[1]), regExResult[2]];
  } else {
    throw Error(`Failed to parse measurement ${value}!`);
  }
}

export const parseMS = memoize(
  (input: string) => {
    const [numberValue, unitValue] = getTimeNumberAndUnitValue(input);

    return toMilliseconds(numberValue, unitValue);
  },
  (input) => input,
);

export function toMilliseconds(value: number, unit: string): number {
  switch (unit) {
    case "s":
      return value * 1000;
    case "ms":
      return value;
    default:
      throw Error(`Unsupported unit: ${unit}!`);
  }
}
