import {
  CimpressDocument,
  DesignSurface,
  ListContent,
  Subpanel,
  TextAreaWithContent,
  TextAreaWithFields,
  TextContent,
  TextElement,
} from "@mcp-artwork/cimdoc-types-v2";
import { getDocumentPanels } from "../selectors/document";

const SPOT_REGEX = /(^spot\((.*)\))$/;

export function findOverprintsInDocument(document: CimpressDocument): string[] {
  const overprints = new Set<string>();
  for (const panel of getDocumentPanels(document)) {
    findOverprints(panel, overprints);
  }

  const channels: string[] = [];

  overprints.forEach((o) => {
    const parsedOverprint = parseOverprint(o);

    if (parsedOverprint) {
      channels.push(parsedOverprint);
    }
  });

  return channels;
}

export function parseOverprint(overprint: string): string | undefined {
  const match = overprint.match(SPOT_REGEX);

  if (match && match.length == 3) {
    return match[2];
  }
  return undefined;
}

export function findOverprints(panel: DesignSurface | Subpanel, overprints: Set<string>): void {
  for (const image of panel.images ?? []) {
    for (const overlay of image.overlays ?? []) {
      if (overlay.color !== undefined) {
        overprints.add(overlay.color);
      }
    }
  }
  for (const shape of panel.shapes ?? []) {
    for (const overprint of shape.overprints ?? []) {
      if (overprint !== undefined) {
        overprints.add(overprint);
      }
    }
  }
  for (const t of panel.textAreas ?? []) {
    for (const textField of (t as TextAreaWithFields).textFields ?? []) {
      for (const overprint of textField.overprints ?? []) {
        overprints.add(overprint);
      }
    }
    for (const textContent of (t as TextAreaWithContent).content ?? []) {
      findOverprintsInTextContent(textContent, overprints);
    }
  }
  for (const subpanel of panel.subpanels ?? []) {
    findOverprints(subpanel, overprints);
  }
}

function findOverprintsInTextContent(content: TextElement, overprints: Set<string>): void {
  for (const overprint of content.overprints ?? []) {
    overprints.add(overprint);
  }
  for (const listContent of (content as TextContent).content ?? []) {
    for (const overprint of (listContent as ListContent).overprints ?? []) {
      overprints.add(overprint);
    }
    for (const listItem of (listContent as ListContent).content ?? []) {
      findOverprintsInTextContent(listItem, overprints);
    }
  }
}
