import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const opacityDocument: CimpressDocument = {
  version: "2",
  deleted: false,
  document: {
    panels: [
      {
        id: "e8e3e476-afcd-4536-8bc5-5734a61cbff9",
        name: "front",
        width: "91.898611mm",
        height: "53.798611mm",
        subpanels: [
          {
            position: {
              x: "0mm",
              y: "0mm",
            },
            id: "subpanel1",
            subpanels: [
              {
                zIndex: 0,
                position: {
                  x: "0mm",
                  y: "25mm",
                },
                id: "subpanel2",
                opacityMultiplier: 0.5,
                textAreas: [
                  {
                    id: "text1a",
                    position: {
                      x: "50mm",
                      y: "10mm",
                      width: "40mm",
                      height: "10mm",
                    },
                    zIndex: 4,
                    content: [
                      {
                        content: "50%",
                        fontFamily: "Noto Sans",
                        fontStyle: "normal",
                        fontSize: "13pt",
                        color: "rgb(#238bf3)",
                      },
                    ],
                  },
                  {
                    id: "text2a",
                    position: {
                      x: "50mm",
                      y: "15mm",
                      width: "40mm",
                      height: "10mm",
                    },
                    zIndex: 4,
                    content: [
                      {
                        content: "25%",
                        fontFamily: "Noto Sans",
                        fontStyle: "normal",
                        fontSize: "13pt",
                        color: "rgb(#238bf3)",
                      },
                    ],
                    opacityMultiplier: 0.5,
                  },
                  {
                    id: "text3a",
                    position: {
                      x: "50mm",
                      y: "20mm",
                      width: "40mm",
                      height: "10mm",
                    },
                    zIndex: 4,
                    content: [
                      {
                        content: "50%",
                        fontFamily: "Noto Sans",
                        fontStyle: "normal",
                        fontSize: "13pt",
                        color: "rgb(#238bf3)",
                      },
                    ],
                  },
                ],
              },
            ],
            textAreas: [
              {
                id: "text1",
                position: {
                  x: "50mm",
                  y: "10mm",
                  width: "40mm",
                  height: "10mm",
                },
                zIndex: 4,
                content: [
                  {
                    content: "100%",
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontSize: "13pt",
                    color: "rgb(#238bf3)",
                  },
                ],
              },
              {
                id: "text2",
                position: {
                  x: "50mm",
                  y: "15mm",
                  width: "40mm",
                  height: "10mm",
                },
                zIndex: 4,
                content: [
                  {
                    content: "50%",
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontSize: "13pt",
                    color: "rgb(#238bf3)",
                  },
                ],
                opacityMultiplier: 0.5,
              },
              {
                id: "text3",
                position: {
                  x: "50mm",
                  y: "20mm",
                  width: "40mm",
                  height: "10mm",
                },
                zIndex: 4,
                content: [
                  {
                    content: "100%",
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontSize: "13pt",
                    color: "rgb(#238bf3)",
                  },
                ],
              },
            ],
            images: [
              {
                id: "TransparentImg",
                printUrl: "https://uploads.documents.cimpress.io/v1/uploads/ac40603a-474f-477c-9649-9131f0e8d814~110?tenant=doc-platform",
                previewUrl: "https://uploads.documents.cimpress.io/v1/uploads/ac40603a-474f-477c-9649-9131f0e8d814~110?tenant=doc-platform",
                zIndex: 2,
                position: {
                  x: "2.82203mm",
                  y: "2.82224mm",
                  width: "44.742295mm",
                  height: "36.115625095mm",
                },
                horizontalAlignment: "left",
                verticalAlignment: "top",
                pageNumber: 1,
                opacityMultiplier: 0.5,
              },
            ],
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
