import classNames from "classnames";
import { useState, useRef } from "react";
import { useCloseOnOutside } from "../../hooks/useCloseOnOutside";
import CSS from "./OpacityInput.module.css";

type OpacityInputProps = {
  value: number | undefined;
  onChange: (value: number) => void;
  className?: string;
};

export function OpacityInput({ onChange, value, className }: OpacityInputProps) {
  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useCloseOnOutside({ getElement: () => containerRef.current, callback: () => setOpen(false) });

  return (
    <div className={classNames(CSS.Container, className)} ref={containerRef}>
      <button className={CSS.Button} onClick={() => setOpen(!open)}>
        Opacity
      </button>
      {open && (
        <div className={CSS.RangeContainer}>
          <input
            defaultValue={value ?? 1}
            min={0}
            max={1}
            step={0.1}
            className={CSS.Range}
            type="range"
            onInput={(e) => onChange(parseFloat(e.currentTarget.value))}
          />
          {value ?? 1}
        </div>
      )}
    </div>
  );
}
