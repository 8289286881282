import { VortexCanvas, VortexContainer, VortexContainerProps } from "@rendering/vortex-react";
import { useMemo } from "react";
import { FusionVortex } from "../../../VortexDemo/FusionVortex";
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { DelayedLoading } from "../../../Components/DelayedLoading";
import { getDocumentPanels, selectFirstSurface } from "../../../selectors/document";
import { parseMM } from "@rendering/plasma";

type VortexDesignPreviewProps = {
  cimDoc: CimpressDocument;
};

const getModel = (cimDoc: CimpressDocument): string => {
  const surface = selectFirstSurface(cimDoc);
  const width = parseMM(surface.width);
  const height = parseMM(surface.height);
  const weddingCard =
    "https://cdn.previews.cimpress.io/v2/modelConfig?sku=PRD-MOIKO8CZ&skuAttributes=%7B%22Size%22%3A%224.6%5C%22%20x%207.2%5C%22%22%2C%22Fold%22%3A%22Flat%22%2C%22Trim%22%3A%22Standard%22%2C%22Paper%20Stock%22%3A%22Standard%20Matte%20-%20271gsm%20%2814pt%29%20Uncoated%22%2C%22Foil%20Color%22%3A%22None%22%2C%22Backside%22%3A%22Color%22%2C%22Product%20Orientation%22%3A%22Vertical%22%7D&tenantId=vistaprint-prod&tenantType=merchants&version=20";

  const bussinessCard =
    "https://cdn.previews.cimpress.io/v2/modelConfig?sku=PRD-IYXT1T3V&skuAttributes=%7B%22Finish%22%3A%22None%22%2C%22Backsides%22%3A%22Blank%22%2C%22Shape%22%3A%22Standard%22%2C%22Corners%22%3A%22Standard%20Corners%22%2C%22Thickness%22%3A%22Standard%22%2C%22Substrate%22%3A%22Glossy%22%2C%22Connected%20Cards%22%3A%22None%22%2C%22Product%20Orientation%22%3A%22Horizontal%22%7D&tenantId=vistaprint-prod&tenantType=merchants&version=22";
  const twoSidedCard =
    "https://cdn.previews.cimpress.io/v2/modelConfig?sku=CIM-RNW04KGR&skuAttributes=%7B%22Backsides%22%3A%22Blank%22%2C%22Orientation%22%3A%22Horizontal%22%2C%22Paper%20Thickness%22%3A%22Flexible%20-%200.02%5C%22%22%2C%22Material%22%3A%22Frosted%22%7D&tenantType=merchants&tenantId=vistaprint-prod";

  if (width > height) {
    if (getDocumentPanels(cimDoc).length > 1 && getDocumentPanels(cimDoc)[1].colorMode !== "blank") {
      return twoSidedCard;
    }
    return bussinessCard;
  }

  return weddingCard;
};

export function VortexDesignPreview({ cimDoc }: VortexDesignPreviewProps) {
  const tenant = useMemo<VortexContainerProps["user"]["tenant"]>(() => ({ id: "crs-vortex", type: "crs" }), []);
  const user = useMemo<VortexContainerProps["user"]>(() => ({ referer: "fusion-demo", tenant }), [tenant]);
  const productConfig = getModel(cimDoc);

  return (
    <div style={{ display: "block", width: "100%", maxHeight: 400, position: "relative", margin: "auto" }}>
      <FusionVortex cimDoc={cimDoc}>
        {({ fusionVortexAdapter }) => (
          <VortexContainer
            fallback={<div>Error in Vortex (fallback scenario)</div>}
            onError={console.error}
            user={user}
            productConfig={productConfig}
            vortexDocumentAdapter={fusionVortexAdapter}
          >
            {({ loadingModel, updatingCanvas }) => (
              <>
                <DelayedLoading loading={loadingModel || updatingCanvas} />
                <VortexCanvas />
              </>
            )}
          </VortexContainer>
        )}
      </FusionVortex>
    </div>
  );
}
