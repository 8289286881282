import { useCallback } from "react";
import { Image, ItemReference, TextArea, Shape } from "@mcp-artwork/cimdoc-types-v2";
import { CimDocUpdater, ImageProgressUpdater, Item, SherbertFilesUpdater } from "../models";
import { TextAreaEditor } from "../ItemEditors/TextArea";
import { ItemReferenceEditor } from "../ItemEditors/ItemReference";
import { ImageEditor } from "../ItemEditors/Image";
import { RotateInput } from "../ItemEditors/Inputs/RotateInput";
import { findElement } from "../utils";
import { OpacityInput } from "../ItemEditors/Inputs/OpacityInput";
import { ShapeEditor } from "../ItemEditors/Shape";
import { SherbertFile } from "../../sherbert/api";
import CSS from "./Toolbar.module.css";

type ToolbarProps = {
  item: Item;
  setCimDoc: CimDocUpdater;
  setImageUploadingProgress: ImageProgressUpdater;
  fetchSherbertFiles: () => void;
  sherbertFiles: SherbertFile[];
  setSherbertFiles: SherbertFilesUpdater;
};

const isTextArea = (item: any): boolean => item.textFields || item.content;
const isItemReference = (item: any): boolean => item.data;
const isImage = (item: any): boolean => item.previewUrl;
const isShape = (item: any): boolean => item.type === "rectangle" || item.type === "ellipse" || item.type === "line" || item.type === "curve";

export function Toolbar({ item, setCimDoc, setImageUploadingProgress, fetchSherbertFiles, sherbertFiles, setSherbertFiles }: ToolbarProps) {
  const onRotationAngleChange = useCallback((value: string) => {
    setCimDoc((draft) => {
      if (draft) {
        const draftTextarea = findElement(draft, item.id);
        draftTextarea.rotationAngle = value;
      }
    });
  }, []);

  const onOpacityChange = useCallback((value: number) => {
    setCimDoc((draft) => {
      if (draft) {
        const draftTextarea = findElement(draft, item.id);
        draftTextarea.opacityMultiplier = value;
      }
    });
  }, []);

  return (
    <div className={CSS.Toolbar}>
      {isTextArea(item) && <TextAreaEditor textArea={item as TextArea} setCimDoc={setCimDoc} />}
      {isItemReference(item) && <ItemReferenceEditor itemReference={item as ItemReference} setCimDoc={setCimDoc} />}
      {isImage(item) && (
        <ImageEditor
          image={item as Image}
          setCimDoc={setCimDoc}
          setImageUploadingProgress={setImageUploadingProgress}
          fetchSherbertFiles={fetchSherbertFiles}
          sherbertFiles={sherbertFiles}
          setSherbertFiles={setSherbertFiles}
        />
      )}
      {isShape(item) && <ShapeEditor shape={item as Shape} setCimDoc={setCimDoc} />}
      <RotateInput className={CSS.Wrapper} value={item.rotationAngle ? parseInt(item.rotationAngle) : 0} onChange={onRotationAngleChange} />
      <OpacityInput className={CSS.Wrapper} value={item.opacityMultiplier} onChange={onOpacityChange} />
    </div>
  );
}
