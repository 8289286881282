import { CimpressDocument, DesignSurface, DesignDocumentWithPanels, DesignDocumentWithSurfaces } from "@mcp-artwork/cimdoc-types-v2";
import { parseMM, Selector, getProjector, getPageSize, ProjectionSpecification } from "@rendering/plasma";
import { Item, ItemType } from "../Models/Item";

const selectFirstItemFromSurface = ({ surface }: { surface: DesignSurface }): { item: Item; itemType: ItemType } => {
  if (surface.shapes?.[0]) {
    return {
      item: surface.shapes?.[0],
      itemType: "shape",
    };
  }

  if (surface.images?.[0]) {
    return {
      item: surface.images?.[0],
      itemType: "image",
    };
  }

  if (surface.textAreas?.[0]) {
    return {
      item: surface.textAreas?.[0],
      itemType: "textArea",
    };
  }

  if (surface.itemReferences?.[0]) {
    return {
      item: surface.itemReferences?.[0],
      itemType: "itemReference",
    };
  }

  if (surface.subpanels?.[0]) {
    return {
      item: surface.subpanels?.[0],
      itemType: "subpanel",
    };
  }

  throw new Error("No item found in document");
};

export const selectItemWithId = ({ surface, id }: { surface: DesignSurface; id: string }): { item: Item; itemType: ItemType } => {
  const shape = surface.shapes?.find((shape) => shape.id === id);
  if (shape) {
    return {
      item: shape,
      itemType: "shape",
    };
  }

  const image = surface.images?.find((shape) => shape.id === id);
  if (image) {
    return {
      item: image,
      itemType: "image",
    };
  }

  const itemreference = surface.itemReferences?.find((shape) => shape.id === id);
  if (itemreference) {
    return {
      item: itemreference,
      itemType: "itemReference",
    };
  }

  const textArea = surface.textAreas?.find((shape) => shape.id === id);
  if (textArea) {
    return {
      item: textArea,
      itemType: "textArea",
    };
  }

  const subpanel = surface.subpanels?.find((subpanel) => subpanel.id === id);
  if (subpanel) {
    return {
      item: subpanel,
      itemType: "subpanel",
    };
  }

  throw new Error(`Item id ${id} not found in surface`);
};

export const selectFirstItemFromDocument = ({ document }: { document: CimpressDocument }): { item: Item; itemType: ItemType } => {
  const surface: DesignSurface = getPrimarySurface({ document });
  const firstItem = selectFirstItemFromSurface({ surface });

  // Force the first item to have an id
  if (!firstItem.item.id) {
    firstItem.item.id = "csr-test";
  }

  return firstItem;
};

export const getPrimarySurface = ({ document }: { document: CimpressDocument }): DesignSurface => {
  const surface: DesignSurface | undefined =
    (document.document as DesignDocumentWithPanels).panels?.[0] ?? (document.document as DesignDocumentWithSurfaces).surfaces?.[0];

  if (!surface) {
    throw new Error("No surface found in document");
  }
  return surface;
};

export const selectAllItemIds = ({ document }: { document: CimpressDocument }): { id: string; type: ItemType }[] => {
  const surface = getPrimarySurface({ document });
  return [
    ...(surface.shapes ?? []).map<{ id: string; type: ItemType }>((shape) => ({ id: shape.id, type: "shape" })),
    ...(surface.images ?? []).map<{ id: string; type: ItemType }>((shape) => ({ id: shape.id, type: "image" })),
    ...(surface.itemReferences ?? []).map<{ id: string; type: ItemType }>((shape) => ({ id: shape.id, type: "itemReference" })),
    ...(surface.textAreas ?? []).map<{ id: string; type: ItemType }>((shape) => ({ id: shape.id, type: "textArea" })),
  ];
};

export function selectFirstSurface(document: CimpressDocument): DesignSurface {
  const panels: DesignSurface[] | undefined =
    (document.document as DesignDocumentWithSurfaces).surfaces ?? (document.document as DesignDocumentWithPanels).panels;

  if (panels) {
    return panels[0];
  }
  throw new Error("No design surface in document");
}

export function createSelectors(document: CimpressDocument, projectionSpecification?: ProjectionSpecification): (Selector & { name: string })[] {
  const panels = getDocumentPanels(document);

  const selectors = panels.map<Selector & { name: string }>((panel) => ({ type: "panel", id: panel.id, name: panel.name }));

  projectionSpecification?.panels.forEach((panel, index) => {
    selectors.push({ type: "page", number: index + 1, name: `projection: ${panel.name}` });
  });

  // static projection
  // todo: more pages?
  if (!projectionSpecification && document.projectionId) {
    selectors.push({ type: "page", number: 1, name: `projection: page 1` });
  }

  return selectors;
}

export function getDocumentPanels(document: CimpressDocument): DesignSurface[] {
  return (document.document as DesignDocumentWithSurfaces).surfaces ?? (document.document as DesignDocumentWithPanels).panels ?? [];
}

export async function getPageDimensions(page: number, document: CimpressDocument): Promise<{ width: number; height: number }> {
  if (document.projectionId) {
    const projector = await getProjector(document.projectionId, page);

    if (projector === undefined) {
      throw Error("No projection found");
    }

    return getPageSize(projector, document);
  }

  const panels = getDocumentPanels(document);

  if (page > panels.length) {
    throw Error(`Page ${page} does not exist in the document`);
  }

  const panel = getDocumentPanels(document)[page - 1];

  return { width: parseMM(panel.width), height: parseMM(panel.height) };
}

export function selectSurface({ document, id }: { document: CimpressDocument; id: string }) {
  const surfaces = getDocumentPanels(document);
  return surfaces.find((surface) => surface.id === id);
}
