// @ts-nocheck
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const clippingDocument: CimpressDocument = {
  version: "2",
  document: {
    panels: [
      {
        id: "73e5a813-8c22-4012-b8c6-586915621b8f",
        name: "front",
        width: "91mm",
        height: "61mm",
        images: [
          {
            id: "932b0097-7f40-41cd-916b-e93c289eec7e",
            printUrl: "https://uploads.documents.cimpress.io/v1/uploads/0e6d5bbf-c741-47cf-b243-4ddf48d3918d~110?tenant=doc-platform",
            previewUrl: "https://uploads.documents.cimpress.io/v1/uploads/0e6d5bbf-c741-47cf-b243-4ddf48d3918d~110?tenant=doc-platform",
            originalSourceUrl: "https://uploads.documents.cimpress.io/v1/uploads/0e6d5bbf-c741-47cf-b243-4ddf48d3918d~110?tenant=doc-platform",
            zIndex: 1,
            position: {
              x: "-7.105427357601002e-15mm",
              y: "4.913833333333329mm",
              width: "91.00000000000001mm",
              height: "51.17233333333334mm",
            },
            clipping: {
              position: {
                x: "33.1mm",
                y: "6.5mm",
                scaleX: 1,
                scaleY: 1,
              },
              specification: {
                type: "svgPathData",
                data: "M 13.6 0 L 27.1 27.1 L 0 27.1",
                unit: "mm",
              },
            },
            horizontalAlignment: "left",
            verticalAlignment: "top",
            pageNumber: 1,
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/0a3b1fba-8fa6-43e1-8c3e-a018a5eb8150/published",
};
