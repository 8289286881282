// @ts-nocheck
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const list: CimpressDocument = {
  version: "2",
  deleted: false,
  document: {
    panels: [
      {
        id: "e8e3e476-afcd-4536-8bc5-5734a61cbff9",
        name: "front",
        width: "91.898611mm",
        height: "53.798611mm",
        textAreas: [
          {
            id: "ffb61684-2785-494c-bab5-6ed314735e0e",
            position: {
              x: "19.901689033502745mm",
              y: "4.1052513422618135mm",
              width: "159mm",
              height: "37.4726mm",
            },
            horizontalAlignment: "left",
            verticalAlignment: "top",
            curveAlignment: 0,
            blockFlowDirection: "horizontal-tb",
            textOrientation: "natural",
            zIndex: 1,
            content: [
              {
                content: [
                  {
                    listMarkerStyle: "ordered",
                    content: [
                      {
                        content: "one",
                        type: "inline",
                        fontFamily: "Noto Sans",
                        fontStyle: "normal,underline",
                        fontSize: "13pt",
                        color: "rgb(255,0,0)",
                      },
                    ],
                    depth: 1,
                    type: "listItem",
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontSize: "13pt",
                    color: "rgb(#101820)",
                  },
                  {
                    listMarkerStyle: "ordered",
                    content: [
                      {
                        content: "two",
                        type: "inline",
                        fontFamily: "Noto Sans",
                        fontStyle: "normal",
                        fontSize: "13pt",
                        color: "rgb(0,0,170)",
                      },
                    ],
                    depth: 1,
                    type: "listItem",
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontSize: "13pt",
                    color: "rgb(#101820)",
                  },
                  {
                    listMarkerStyle: "ordered",
                    content: [
                      {
                        content: "two-a",
                        type: "inline",
                        fontFamily: "Noto Sans",
                        fontStyle: "normal",
                        fontSize: "13pt",
                        color: "rgb(0,180,20)",
                      },
                    ],
                    depth: 2,
                    type: "listItem",
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontSize: "13pt",
                    color: "rgb(0,0,255)",
                  },
                  {
                    listMarkerStyle: "ordered",
                    content: [
                      {
                        content: "two-aa",
                        type: "inline",
                        fontFamily: "Noto Sans",
                        fontStyle: "normal,strikeout",
                        fontSize: "13pt",
                        color: "rgb(230,40,40)",
                      },
                    ],
                    depth: 3,
                    type: "listItem",
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontSize: "13pt",
                    color: "rgb(255,0,0)",
                  },
                  {
                    listMarkerStyle: "ordered",
                    content: [
                      {
                        content: "two-b",
                        type: "inline",
                        fontFamily: "Noto Sans",
                        fontStyle: "normal",
                        fontSize: "13pt",
                        color: "rgb(#101820)",
                      },
                    ],
                    depth: 2,
                    type: "listItem",
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontSize: "13pt",
                    color: "rgb(#101820)",
                  },
                  {
                    listMarkerStyle: "ordered",
                    content: [
                      {
                        content: "three",
                        type: "inline",
                        fontFamily: "Noto Sans",
                        fontStyle: "normal",
                        fontSize: "13pt",
                        color: "rgb(#101820)",
                      },
                    ],
                    depth: 1,
                    type: "listItem",
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontSize: "13pt",
                    color: "rgb(#101820)",
                  },
                ],
                type: "list",
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                fontSize: "13pt",
                color: "rgb(#101820)",
              },
            ],
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
