import { BlockFlowDirection, GlyphRun, Position, TextArea, TextDecoration, TextField } from "@mcp-artwork/cimdoc-types-v2";
import {
  CalculationResult,
  FontMetrics,
  Glyph,
  GlyphRun as RTextGlyphRun,
  ResultFont,
  TextBlock,
  TextOrientation,
  LineInfo as RtextCaretLine,
  CaretPosition as RtextCaret,
  LineSpan as RtextCaretSpan,
  TextBoundaries,
  TextBoundaryRange,
} from "@mcp-artwork/rtext";
import { TextOptions } from "../../models/Layout";
import { BoundingBox } from "../../utils/boundingBox";
import { mmToString, parseMM } from "../../utils/unitHelper";
import { TextPathData } from "../../utils/text/textUtil";

export type TextMeasurements = {
  actual?: {
    width: number;
    height: number;
  };

  /**
   * @deprecated The preview box can be found on the LayoutMeasurement object
   */
  previewBox: BoundingBox;
  snapBox?: BoundingBox;

  baselines?: number[];
  caretLines?: CaretLine[];
  textBoundaries?: TextBoundaries[];
  glyphRunOverrides?: GlyphRun[];
  rtextResult?: CalculationResult;
  resizeFactor?: number;
};

export type CaretLine = {
  baseline: number;
  spans: Span[];
};

export type Span = {
  ascent: number;
  descent: number;
  carets: Caret[];
};

export type Caret = {
  position: {
    x: number;
    y: number;
  };
  rotation: number;
  // If the caret is a list ordinal not present in the CimDoc content
  isOrdinal: boolean;
  // Index of the respective text block
  contentIndex: number;
  // Character index with respect to the entire text area
  absoluteCharacterIndex: number;
  // Character index relative to the respective text block
  relativeCharacterIndex: number;
};

export interface RtextCaretWithRotation extends RtextCaret {
  rotation?: number;
}

export interface RtextCaretSpanWithRotation extends Omit<RtextCaretSpan, "caretPositions"> {
  caretPositions: RtextCaretWithRotation[];
}

export interface RtextCaretLineWithRotation extends Omit<RtextCaretLine, "spans" | "textBounds"> {
  spans?: RtextCaretSpanWithRotation[];
}

export function getMeasurements({
  textFields,
  textBlocks,
  listOrdinals,
  isNewParagraphTextBlock,
  textArea,
  result,
  fonts,
  textOptions,
  textOrientation,
  textPathData,
}: {
  textFields: TextField[];
  textBlocks: TextBlock[];
  listOrdinals: boolean[];
  isNewParagraphTextBlock: boolean[];
  textArea: TextArea;
  result: CalculationResult;
  fonts: ResultFont[];
  textOptions?: TextOptions;
  textOrientation: TextOrientation;
  textPathData?: TextPathData;
}): TextMeasurements {
  if (result.textBounds === null) {
    throw Error("No text bounds from the text engine!");
  }

  if (result.blackBoxBounds === null) {
    throw Error("No black box results from the text engine!");
  }
  const textBoundsX: number = result.textBounds?.x ?? 0;
  const textBoundsY: number = result.textBounds?.y ?? 0;

  // Build the initial black box from the engine
  const blackBox: BoundingBox = {
    left: result.blackBoxBounds?.x ?? 0,
    top: result.blackBoxBounds?.y ?? 0,
    width: result.blackBoxBounds?.width ?? 0,
    height: result.blackBoxBounds?.height ?? 0,
  };

  const { actualWidth, actualHeight } = (() => {
    if (textOrientation === "vertical") {
      return {
        actualWidth: result.textBounds?.width ?? 0,
        actualHeight: parseMM(textArea.position.height),
      };
    }

    return {
      actualWidth: parseMM(textArea.position.width),
      actualHeight: result.textBounds?.height ?? 0,
    };
  })();

  return {
    actual: {
      width: actualWidth,
      height: actualHeight,
    },
    previewBox: blackBox,
    snapBox: {
      left: blackBox.left - textBoundsX,
      top: blackBox.top - textBoundsY,
      width: result.textBounds?.width ?? 0,
      height: result.textBounds?.height ?? 0,
    },
    baselines: result.lineInfos?.map((li) => li.baseline) ?? [],
    caretLines: textOptions?.caretLines ? getCaretLines(result.lineInfos ?? [], textBlocks, listOrdinals, isNewParagraphTextBlock) : undefined,
    textBoundaries: textOptions?.requestTextBoundaries ? getTextBoundaries(result.textBoundaries ?? [], textBlocks, listOrdinals) : undefined,
    glyphRunOverrides: textOptions?.glyphRunOverrides ? getGlyphRunOverrides(result, textFields, fonts, textPathData) : undefined,
    rtextResult: textOptions?.rtextResult ? result : undefined,
    resizeFactor: result.resizeFactor,
  };
}

export function getTextBoundaries(textBoundaries: TextBoundaries[], textBlocks: TextBlock[], listOrdinals: boolean[]): TextBoundaries[] {
  let contentLength = 0;
  // stores information about the ordinals and their lengths.
  const ordinalInfo: { index: number; length: number }[] = [];
  for (let i = 0; i < textBlocks.length; i++) {
    if (listOrdinals[i] && textBlocks[i].content && textBlocks[i].content?.length) {
      ordinalInfo.push({
        index: contentLength,
        length: (textBlocks[i].content ?? "").length,
      });
    }
    contentLength += textBlocks[i].content?.length ?? 0;
  }

  const outputTextBoundaries: TextBoundaries[] = [];
  for (let i = 0; i < textBoundaries.length; i++) {
    const ranges = textBoundaries[i].ranges;
    const outputRanges: TextBoundaryRange[] = [];
    let currentOrdinalIndex = 0;
    let ordinalLength = 0;
    for (let j = 0; j < ranges.length; j++) {
      const range = ranges[j];
      let start: number, end: number;
      // The boundaries after the last ordinal.
      if (!ordinalInfo[currentOrdinalIndex]) {
        start = range.start - ordinalLength;
        end = range.end - ordinalLength;
        outputRanges.push({ start, end });
      }
      // The boundaries within the ordinal - should be ignored.
      else if (
        range.start >= ordinalInfo[currentOrdinalIndex].index &&
        range.end <= ordinalInfo[currentOrdinalIndex].index + ordinalInfo[currentOrdinalIndex].length
      ) {
        continue;
      }
      // Here we handle all cases, as boundaries could span multiple ordinals(probably?)
      else {
        if (range.start < ordinalInfo[currentOrdinalIndex].index) {
          start = range.start - ordinalLength;
        } else if (range.start < ordinalInfo[currentOrdinalIndex].index + ordinalInfo[currentOrdinalIndex].length) {
          start = ordinalInfo[currentOrdinalIndex].index - ordinalLength;
        } else {
          start = range.start - ordinalLength - ordinalInfo[currentOrdinalIndex].length;
        }
        while (ordinalInfo[currentOrdinalIndex] && range.end > ordinalInfo[currentOrdinalIndex].index + ordinalInfo[currentOrdinalIndex].length) {
          ordinalLength += ordinalInfo[currentOrdinalIndex].length;
          currentOrdinalIndex++;
        }
        if (!ordinalInfo[currentOrdinalIndex] || range.end < ordinalInfo[currentOrdinalIndex].index) {
          end = range.end - ordinalLength;
        } else {
          end = ordinalInfo[currentOrdinalIndex].index - ordinalLength;
        }
        outputRanges.push({ start, end });
      }
    }
    outputTextBoundaries.push({
      type: textBoundaries[i].type,
      ranges: outputRanges,
    });
  }
  return outputTextBoundaries;
}

export function getCaretLines(
  caretPositions: RtextCaretLineWithRotation[],
  textBlocks: TextBlock[],
  listOrdinals: boolean[],
  isNewParagraphTextBlock: boolean[],
  blockFlowDirection?: BlockFlowDirection,
): CaretLine[] {
  const caretLines: CaretLine[] = [];

  // Quick check if no text blocks exists
  if (textBlocks.length === 0) return caretLines;

  // Find first non-empty textBlock index
  let blockIndex = 0;
  let currentIndex = 0;
  let blockTextLength = textBlocks[blockIndex].content?.length ?? 0;

  // Track ordinals
  let ordinalBlockCount = 0;
  let paragraphTextBlockCount = 0;
  let ordinalCount = 0;
  let paragraphLength = 0;
  // Carets from rtext are given per line
  for (const line of caretPositions) {
    const spans: Span[] = [];

    for (const span of line.spans ?? []) {
      const carets: Caret[] = [];
      for (const caret of span.caretPositions) {
        const isLastCaretInTextBlock = caret.index - currentIndex - paragraphLength === blockTextLength;
        const isEmptyTextBlock = blockTextLength === 0;
        const isFirstCaret = caret.index === 0;
        const isLastTextBlock = blockIndex + 1 >= textBlocks.length;

        // Check if the caret is in the next text block
        if ((isLastCaretInTextBlock || isEmptyTextBlock) && !isFirstCaret && !isLastTextBlock) {
          if (listOrdinals[blockIndex]) {
            ordinalBlockCount++;
          }
          if (isNewParagraphTextBlock[blockIndex + 1]) {
            paragraphTextBlockCount++;
            paragraphLength += blockTextLength;
          } else if (isNewParagraphTextBlock[blockIndex]) {
            currentIndex += paragraphLength + blockTextLength;
            paragraphLength = 0;
          } else {
            currentIndex += blockTextLength;
          }
          blockIndex++;
          blockTextLength = textBlocks[blockIndex].content?.length ?? 0;
          if (listOrdinals[blockIndex] && listOrdinals[blockIndex - 1]) {
            while (blockIndex + 1 < textBlocks.length && listOrdinals[blockIndex]) {
              ordinalBlockCount++;
              currentIndex += blockTextLength;
              blockIndex++;
              blockTextLength = textBlocks[blockIndex].content?.length ?? 0;
            }
          } else if (isEmptyTextBlock) {
            while (blockIndex + 1 < textBlocks.length && blockTextLength === 0) {
              if (isNewParagraphTextBlock[blockIndex + 1]) {
                paragraphTextBlockCount++;
              }
              blockIndex++;
              blockTextLength = textBlocks[blockIndex].content?.length ?? 0;
            }
            if (blockIndex + 1 < textBlocks.length && caret.index - currentIndex - paragraphLength === blockTextLength) {
              if (isNewParagraphTextBlock[blockIndex + 1]) {
                paragraphTextBlockCount++;
                paragraphLength += blockTextLength;
              } else if (isNewParagraphTextBlock[blockIndex]) {
                currentIndex += paragraphLength + blockTextLength;
                paragraphLength = 0;
              } else {
                currentIndex += blockTextLength;
              }
              blockIndex++;
              blockTextLength = textBlocks[blockIndex].content?.length ?? 0;
            }
          }
        }

        // Assume last textBlock of ordinal is empty white space and return it as a normal caret to start the line
        const isOrdinal = listOrdinals[blockIndex] && blockTextLength !== 0;

        carets.push({
          position: { x: caret.x, y: caret.y },
          contentIndex: blockIndex - ordinalBlockCount - paragraphTextBlockCount,
          isOrdinal,
          relativeCharacterIndex: caret.index - currentIndex,
          absoluteCharacterIndex: caret.index - ordinalCount,
          rotation: caret.rotation ?? (blockFlowDirection && blockFlowDirection !== "horizontal-tb" ? 0 : 270),
        });

        if (isOrdinal) {
          ordinalCount++;
        }
      }

      spans.push({
        ascent: span.ascent,
        descent: span.descent,
        carets: carets,
      });
    }

    caretLines.push({
      baseline: line.baseline,
      spans,
    });
  }

  return caretLines;
}

function getGlyphRunOverrides(result: CalculationResult, textFields: TextField[], fonts: ResultFont[], textPathData?: TextPathData): GlyphRun[] {
  const sourceGlyphRuns: RTextGlyphRun[] | undefined = textPathData === undefined ? result.glyphRuns : textPathData.glyphRuns;
  const glyphRuns: GlyphRun[] = [];

  for (const glyphRun of sourceGlyphRuns ?? []) {
    const textField: TextField = getTextFieldByBlockIndex(textFields, glyphRun.textBlockIndex ?? 0);
    const font: ResultFont = fonts[glyphRun.fontIndex ?? 0];

    glyphRuns.push({
      baseline: mmToString(glyphRun.baseline),
      color: textField.color,
      fontFamily: textField.fontFamily ?? font.fontReference.url,
      fontSize: mmToString(glyphRun.fontSize),
      fontStyle: textField.fontStyle,
      isSideways: glyphRun.orientation === "vertical",
      overprints: textField.overprints,
      rotationAngle: `${glyphRun.glyphPivoting}`, // TODO change to number
      stroke: textField.stroke,
      fontVersion: font.fontReference.fontVersion,
      effects: textField.effects,
      decorations: textField.decorations,
      glyphs: glyphRun.glyphs.map((g: Glyph) => {
        return {
          index: g.index,
          xOffset: mmToString(g.xOffset ?? 0),
          yOffset: mmToString(g.yOffset ?? 0),
          width: mmToString(g.xAdvance ?? 0),
        };
      }),
    });
  }

  return glyphRuns;
}

export function getDecorationBounds({
  type,
  metrics,
  glyphRun,
  textAreaPosition,
}: {
  type: "underline" | "strikeout";
  metrics: FontMetrics;
  glyphRun: RTextGlyphRun;
  textAreaPosition: Position;
}): BoundingBox {
  const baseline: number = glyphRun.baseline;
  const fontSize = glyphRun.fontSize;
  const lastGlyph = glyphRun.glyphs[glyphRun.glyphs.length - 1];
  const firstGlyphX = glyphRun.glyphs[0].xOffset ?? 0;
  const lastGlyphX = (lastGlyph.xOffset ?? 0) + (lastGlyph.xAdvance ?? 0);
  const firstGlyphY = glyphRun.glyphs[0].yOffset ?? 0;
  const glyphRunLength = lastGlyphX - firstGlyphX;
  const positionX = parseMM(textAreaPosition.x);
  const positionY = parseMM(textAreaPosition.y);

  if (type === "underline") {
    const thickness = getUnderlineThickness(metrics) * fontSize;
    const decorationStartY = -getUnderlineHeight(metrics) * fontSize + baseline - firstGlyphY;

    return {
      left: positionX + firstGlyphX - thickness / 2,
      top: positionY + decorationStartY - thickness / 2,
      width: glyphRunLength + thickness,
      height: thickness,
    };
  } else {
    const thickness = (metrics.strikeoutThickness ?? 0) * fontSize;
    const decorationStartY = -(metrics.strikeoutPosition ?? 0) * fontSize + baseline - firstGlyphY;

    return {
      left: positionX + firstGlyphX - thickness / 2,
      top: positionY + decorationStartY - thickness / 2,
      width: glyphRunLength + thickness,
      height: thickness,
    };
  }
}

// Fonts can be missing underline data and need a default
export function getUnderlineHeight(metrics: FontMetrics): number {
  if (metrics.underlinePosition && metrics.underlinePosition !== 0) {
    return metrics.underlinePosition;
  }

  return -0.1;
}

// Fonts can be missing underline data and need a default
export function getUnderlineThickness(metrics: FontMetrics): number {
  if (metrics.underlineThickness && metrics.underlineThickness !== 0) {
    return metrics.underlineThickness;
  }

  return 0.1;
}

export function getTextFieldByBlockIndex(textFields: TextField[], blockIndex: number): TextField {
  if (blockIndex < textFields.length) {
    return textFields[blockIndex];
  }

  throw Error(`Text area does not contain block index: ${blockIndex}!`);
}

// Gets the extra bounding boxes from font metrics like strikeout or underline.
// Also contains augmented bounds for glyphs which have a stroke
export function computeExtraBounds({
  textFields,
  result,
  fonts,
  textAreaPosition,
}: {
  textFields: TextField[];
  result: CalculationResult;
  fonts: ResultFont[];
  textAreaPosition: Position;
}) {
  const extraBounds: BoundingBox[] = [];

  for (const glyphRun of result.glyphRuns ?? []) {
    const textField: TextField = getTextFieldByBlockIndex(textFields, glyphRun.textBlockIndex ?? 0);
    const font: ResultFont = fonts[glyphRun.fontIndex ?? 0];

    // Possible if requestOutlines = false (not sure why requestOutlines would ever be false since we always set it to true, unlike in the server)
    if (font.glyphs === undefined || glyphRun.glyphs.length === 0 || font.unitsPerEm === undefined) continue;

    // If the font contains metrics compute additional bounds information
    if (font.fontMetrics) {
      const metrics: FontMetrics = font.fontMetrics;

      let decorations: TextDecoration[] = [];

      if (textField.decorations !== undefined && textField.decorations.length > 0) {
        decorations = textField.decorations.slice();
      }
      // Treat legacy underline/strikeout as new definition
      else {
        if (textField.fontStyle?.indexOf("strikeout") >= 0 && metrics.strikeoutPosition !== undefined && metrics.strikeoutThickness !== undefined) {
          decorations.push({ type: "strikeout" });
        }

        // Add underline
        if (textField.fontStyle?.indexOf("underline") >= 0) {
          decorations.push({ type: "underline" });
        }
      }

      // Newer method of specifying decorations
      decorations.forEach((decoration: TextDecoration) => {
        extraBounds.push(getDecorationBounds({ type: decoration.type, metrics, glyphRun, textAreaPosition }));
      });
    }
  }

  return extraBounds;
}
