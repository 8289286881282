import { DesignSurface, Subpanel } from "@mcp-artwork/cimdoc-types-v2";
import { ItemInfo } from "../models/Layout";

type SelectItemFromSurfaceArguments = {
  id: string;
  surface: DesignSurface | Subpanel;
};
export const selectItemFromSurface = ({ id, surface }: SelectItemFromSurfaceArguments): ItemInfo => {
  const itemReference = surface.itemReferences?.find((item) => item.id === id);
  if (itemReference) {
    return { item: itemReference, itemType: "itemReference" };
  }

  const shape = surface.shapes?.find((item) => item.id === id);
  if (shape) {
    return { item: shape, itemType: "shape" };
  }

  const image = surface.images?.find((item) => item.id === id);
  if (image) {
    return { item: image, itemType: "image" };
  }

  const textArea = surface.textAreas?.find((item) => item.id === id);
  if (textArea) {
    return { item: textArea, itemType: "textArea" };
  }

  const subpanel = surface.subpanels?.find((item) => item.id === id);
  if (subpanel) {
    return { item: subpanel, itemType: "subpanel" };
  }

  throw Error(`No item was found for id: ${id}`);
};
