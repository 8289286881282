import { CimpressDocument, DesignSurface, Subpanel } from "@mcp-artwork/cimdoc-types-v2";
import { getProjectionSpecification } from "../api/projectionClient";
import { mmToString } from "../unitHelper";
import { ERROR_MESSAGE_NO_PANEL_IN_PROJECTION, buildJsonProjector } from "./jsonProjector";
import { Projector, Size } from "./projector";
import { buildStaticProjector } from "./staticProjector";

export async function getProjector(projectionId: string, pageNumber: number): Promise<Projector>;
export async function getProjector(projectionId: undefined, pageNumber: number): Promise<undefined>;
export async function getProjector(projectionId: string | undefined, pageNumber: number): Promise<Projector | undefined>;
export async function getProjector(projectionId: string | undefined, pageNumber: number): Promise<Projector | undefined> {
  switch (projectionId) {
    case undefined:
      return undefined;
    case "ROTATE_90":
    case "ROTATE_270":
    case "90_270_EVEN_ODD":
    case "90_270_ODD_EVEN":
    case "270_90_EVEN_ODD":
    case "270_90_ODD_EVEN":
      return buildStaticProjector(projectionId, pageNumber);
    default: {
      const specification = await getProjectionSpecification(projectionId);
      return buildJsonProjector(specification, pageNumber);
    }
  }
}

export const project = (projector: Projector, document: CimpressDocument): DesignSurface => {
  const matchingPanels = projector.getDesignSurfaces(document);
  if (matchingPanels.length === 0) {
    throw Error(ERROR_MESSAGE_NO_PANEL_IN_PROJECTION);
  }

  const targetSize = projector.determineTargetSize(matchingPanels);
  const name = projector.getTargetName(document);

  return {
    decorationTechnology: matchingPanels[0].decorationTechnology,
    id: name,
    name: name,
    width: mmToString(targetSize.width),
    height: mmToString(targetSize.height),
    subpanels: matchingPanels.map((info, i) => panelToSubpanel(projector, info, i, targetSize)),
  };
};

export const getPageSize = (projector: Projector, document: CimpressDocument): Size => {
  const matchingPanels = projector.getDesignSurfaces(document);
  if (matchingPanels.length === 0) {
    throw Error(ERROR_MESSAGE_NO_PANEL_IN_PROJECTION);
  }

  return projector.determineTargetSize(matchingPanels);
};

const panelToSubpanel = (projector: Projector, sourceSurface: DesignSurface, i: number, targetSize: Size): Subpanel => {
  if (sourceSurface.clips && sourceSurface.clips.length > 0) {
    throw Error("Clips are not supported");
  }
  if (sourceSurface.simpleTextFields && sourceSurface.simpleTextFields.length > 0) {
    throw Error("SimpleTextFields are not supported");
  }

  const transforms = projector.getTransform(sourceSurface, i, targetSize);

  return {
    id: sourceSurface.id,
    position: { x: "0mm", y: "0mm" },
    images: sourceSurface.images,
    itemReferences: sourceSurface.itemReferences,
    name: sourceSurface.name,
    shapes: sourceSurface.shapes,
    textAreas: sourceSurface.textAreas,
    subpanels: sourceSurface.subpanels,
    videos: sourceSurface.videos,
    zIndex: i,
    transforms,
    clipping: projector.getClip(sourceSurface, i, transforms),
  };
};
