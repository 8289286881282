import { parseMM } from "@rendering/plasma";
import { useEffect, useState } from "react";
import { getPageDimensions } from "../selectors/document";
import { fetchDocument } from "../utils/API/document";
import { ScenePreview } from "./ScenePreview";
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { NonScenePreview } from "./NonScenePreview";

export function Preview() {
  const [cimDoc, setCimDoc] = useState<CimpressDocument>();

  const params = new URLSearchParams(location.hash.substring("#/preview".length));
  const cimdocUrl = params.get("cimdocUrl");
  const pixel = params.get("pixel");
  const page = parseInt(params.get("page") ?? "1");
  const sceneUrl = params.get("sceneUrl");
  const rtextEnabled = params.get("rtextEnabled") === "true";
  let width: number | null = params.get("width") ? parseInt(params.get("width")!) : null;

  if (!pixel && !width) {
    throw new Error("A pixel or width is required!");
  }

  if (!cimdocUrl) {
    throw new Error("A cimdocUrl is required!");
  }

  useEffect(() => {
    const fn = async () => {
      const cimDoc = await fetchDocument(cimdocUrl);
      setCimDoc(cimDoc);
    };

    fn();
  }, [cimdocUrl]);

  if (!cimDoc) {
    return null;
  }

  if (sceneUrl && width) {
    return <ScenePreview cimDoc={cimDoc} width={width / 2} page={page} sceneUrl={sceneUrl} rtextEnabled={rtextEnabled} />;
  }

  if (!sceneUrl) {
    return <NonScenePreview cimDoc={cimDoc} width={width} pixel={pixel} page={page} rtextEnabled={rtextEnabled} />;
  }

  return null;
}
