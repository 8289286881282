import { CimpressDocument, ImageWithPrintUrl, ItemReference, Shape, TextArea } from "@mcp-artwork/cimdoc-types-v2";
import { selectFirstSurface } from "../selectors/document";
import { Item } from "./models";

export function findImage(cimDoc: CimpressDocument, elementId: string): ImageWithPrintUrl {
  const surface = selectFirstSurface(cimDoc);
  return surface.images?.find((i) => i.id === elementId) as ImageWithPrintUrl;
}

export function findTextArea(cimDoc: CimpressDocument, elementId: string): TextArea {
  const surface = selectFirstSurface(cimDoc);
  return surface.textAreas?.find((t) => t.id === elementId) as TextArea;
}

export function findItemReference(cimDoc: CimpressDocument, elementId: string): ItemReference {
  const surface = selectFirstSurface(cimDoc);
  return surface.itemReferences?.find((t) => t.id === elementId) as ItemReference;
}

export function findShape(cimDoc: CimpressDocument, elementId: string): Shape {
  const surface = selectFirstSurface(cimDoc);
  return surface.shapes?.find((t) => t.id === elementId) as Shape;
}

export function findElement(cimDoc: CimpressDocument, elementId: string): Item {
  return findTextArea(cimDoc, elementId) ?? findImage(cimDoc, elementId) ?? findItemReference(cimDoc, elementId) ?? findShape(cimDoc, elementId);
}

export function removeElement(cimDoc: CimpressDocument, elementId: string): CimpressDocument {
  const surface = selectFirstSurface(cimDoc);
  surface.textAreas = surface.textAreas?.filter((textArea) => textArea.id !== elementId);
  surface.shapes = surface.shapes?.filter((shape) => shape.id !== elementId);
  surface.images = surface.images?.filter((image) => image.id !== elementId);
  surface.itemReferences = surface.itemReferences?.filter((itemReference) => itemReference.id !== elementId);
  return cimDoc;
}

export function removeAllOtherElements(cimDoc: CimpressDocument, elementId: string): CimpressDocument {
  const surface = selectFirstSurface(cimDoc);
  surface.textAreas = surface.textAreas?.filter((textArea) => textArea.id === elementId);
  surface.shapes = surface.shapes?.filter((shape) => shape.id === elementId);
  surface.images = surface.images?.filter((image) => image.id === elementId);
  surface.itemReferences = surface.itemReferences?.filter((itemReference) => itemReference.id === elementId);
  return cimDoc;
}
