// @ts-nocheck
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const subpanelTransformedLineDocument: CimpressDocument = {
  documentId: "doc0",
  version: "2",
  deleted: false,
  revision: 0,
  document: {
    panels: [
      {
        id: "1b6c0554-0bdf-4ddf-b2a1-5c2eeb3dc3cf",
        name: "front",
        width: "53.8mm",
        height: "28.4mm",
        subpanels: [
          {
            position: {
              x: "0mm",
              y: "0mm",
            },
            id: "deb3c88e-cf11-423d-83d2-e6d868d56f58",
            shapes: [
              {
                type: "line",
                stroke: {
                  color: "cmyk(0, 100, 0, 0)",
                  thickness: "1mm",
                  lineCap: "round",
                  lineJoin: "round",
                },
                zIndex: 4,
                id: "shape1",
                start: {
                  x: "10mm",
                  y: "5mm",
                },
                end: {
                  x: "40mm",
                  y: "15mm",
                },
                itemType: "shape",
                transforms: [
                  {
                    type: "rotate",
                    degreesClockwise: 60,
                  },
                ],
              },
            ],
          },
        ],
        decorationTechnology: "digital",
      },
    ],
  },
  metadata: {},
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
