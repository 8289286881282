import { CSSProperties, useRef, useState } from "react";
import { useCloseOnOutside } from "../hooks/useCloseOnOutside";
import { FusionDebug } from "../FusionDebug";
import { CimDocUpdater, Item } from "../models";
import { OnPaintArgs } from "@rendering/fusion-react";
import { removeAllOtherElements, removeElement } from "../utils";
import CSS from "./ContextMenu.module.css";

type ContextMenuProps = {
  style: CSSProperties;
  onClose: () => void;
  item: Item;
  fusionResults: OnPaintArgs | undefined;
  setCimDoc: CimDocUpdater;
};

export function ContextMenu({ style, onClose, fusionResults, item, setCimDoc }: ContextMenuProps) {
  const [showDebug, setShowDebug] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  useCloseOnOutside({ getElement: () => containerRef.current, callback: onClose });

  const onDelete = () => {
    setCimDoc((draft) => {
      if (draft) {
        removeElement(draft, item.id);
      }
    });
    onClose();
  };

  const onDeleteOthers = () => {
    setCimDoc((draft) => {
      if (draft) {
        removeAllOtherElements(draft, item.id);
      }
    });
    onClose();
  };

  if (showDebug) {
    return <FusionDebug fusionResults={fusionResults} onClose={() => setShowDebug(false)} item={item} />;
  }

  return (
    <div style={style} className={CSS.ContextMenu} ref={containerRef}>
      <button className={CSS.Button} onClick={onDelete}>
        ❌ Delete
      </button>
      <button className={CSS.Button} onClick={onDeleteOthers}>
        🧹 Delete all others
      </button>
      <button className={CSS.Button} onClick={() => setShowDebug(true)}>
        👷‍♀️ Show debug
      </button>
    </div>
  );
}
