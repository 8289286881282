import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { ProjectionSpecification, Selector, selectSurface } from "@rendering/plasma";
import { useEffect, useMemo, useState } from "react";
import { createSelectors } from "../selectors/document";
import { findOverprints, parseOverprint } from "../utils/overprint";

type EnhancedSelector = Selector & { name: string } & { overprints?: string[] };

type UseSelectorsReturn = {
  selectors: EnhancedSelector[];
  selectedSelector?: EnhancedSelector;
  onSetSelectedSelector: (selector: EnhancedSelector) => void;
};

type UseSelectorsProps = {
  cimDoc?: CimpressDocument;
  projectionSpecification?: ProjectionSpecification;
};

export function useSelectors({ cimDoc, projectionSpecification }: UseSelectorsProps): UseSelectorsReturn {
  const [selectorsWithOverprints, setSelectorsWithOverprints] = useState<EnhancedSelector[]>([]);
  const [selectedSelector, setSelectedSelector] = useState<EnhancedSelector>();

  // panel and page selectors
  const { panelAndPageSelectors } = useMemo(() => {
    const panelAndPageSelectors = cimDoc ? createSelectors(cimDoc, projectionSpecification) : [];
    return { panelAndPageSelectors };
  }, [cimDoc, projectionSpecification]);

  // selectors with overprints
  useEffect(() => {
    const createSelectorswithOverprints = async () => {
      const tempSelectorsWithOverprints = await Promise.all(
        panelAndPageSelectors.map(async (selector) => {
          const overprints = new Set<string>();
          const surface = await selectSurface({ selector, document: cimDoc! });
          findOverprints(surface, overprints);
          return { selector, overprints: Array.from(overprints) };
        }),
      );
      const mappedSelectors = tempSelectorsWithOverprints
        .map(({ selector, overprints }) => {
          return overprints.map((overprint) => ({ ...selector, name: `${selector.name}-overprint:${overprint}`, overprints: [parseOverprint(overprint)!] }));
        })
        .flat();

      setSelectorsWithOverprints(mappedSelectors);
    };

    if (cimDoc) {
      createSelectorswithOverprints();
    }
  }, [panelAndPageSelectors]);

  const onSetSelectedSelector = (selector: EnhancedSelector) => {
    const foundSelector = [...panelAndPageSelectors, ...selectorsWithOverprints].find((s) => JSON.stringify(s) === JSON.stringify(selector));
    if (foundSelector) {
      setSelectedSelector(foundSelector);
    } else {
      console.error("Selected selector was not found.", { selector, panelAndPageSelectors, selectorsWithOverprints });
    }
  };

  const selectors = [...panelAndPageSelectors, ...selectorsWithOverprints];
  const selectedSelectorOrFirst = selectedSelector ?? selectors[0];

  return {
    onSetSelectedSelector,
    selectedSelector: selectedSelectorOrFirst,
    selectors,
  };
}
