import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { paint } from "@rendering/neon";
import { LayoutResult, determinePixelSizeByDimension, layout, selectSurface } from "@rendering/plasma";
import { VortexDocumentAdapter } from "@rendering/vortex-react";
import { ReactNode, useMemo, useRef, useState } from "react";

type FusionVortexProps = {
  cimDoc: CimpressDocument;
  children: (args: { fusionVortexAdapter: VortexDocumentAdapter; layoutResults?: LayoutResult[] }) => ReactNode;
};

type SelectorWithOverprints = { type: "page"; number: number; overprints?: string[] };

export function FusionVortex({ cimDoc, children }: FusionVortexProps) {
  const [layoutResultsState, setLayoutResultsState] = useState<LayoutResult[]>();
  const canvasPoolRef = useRef<{ [key: string]: HTMLCanvasElement }>({});
  const fusionVortexAdapter = useMemo<VortexDocumentAdapter>(() => {
    return {
      generateCanvases: async (surfaces) => {
        // Create selectors from surface
        const selectorsAndDimensions: { dimension: number; selector: SelectorWithOverprints }[] = [];
        surfaces.forEach(({ page, channel, textureSize }) => {
          let selectorAndDimension = selectorsAndDimensions.find(({ selector }) => selector.number === page);
          if (!selectorAndDimension) {
            selectorAndDimension = {
              selector: {
                number: page,
                type: "page",
                overprints: [],
              },
              dimension: Math.max(textureSize.width, textureSize.height),
            };

            selectorsAndDimensions.push(selectorAndDimension);
          }
          channel && selectorAndDimension.selector.overprints?.push(channel);
        });

        // Validate if selectors are present in given cimDoc.
        // Sometimes a vortex product surface is an empty page and it won't be present in the cimdoc, like return address label.
        const validation = await Promise.all(
          selectorsAndDimensions.map(({ selector }) =>
            selectSurface({ document: cimDoc as any, selector })
              .then(() => true)
              .catch((e: Error) => {
                if (e.message.startsWith("No panel found for page ") || e.message.startsWith("No matching panels in projection")) {
                  return false; // Use a blank page, selector is filtered out later.
                }

                throw e;
              }),
          ),
        );

        const validatedSelectorsAndDimensions = selectorsAndDimensions.filter((_, index) => validation[index]);

        // Map selectors to layout call
        const fusionResults = await Promise.all(
          validatedSelectorsAndDimensions.map(async ({ selector, dimension }) => {
            const pixelSize = await determinePixelSizeByDimension({ dimension, selector, cimDoc });
            const layoutResult = await layout({
              document: cimDoc,
              pixelSize,
              selector,
              overprints: selector.overprints,
              textOptions: { rtextEnabled: true },
              referrer: "fusion-demo",
            });

            const key = `${selector.number}`;
            if (!canvasPoolRef.current[key]) {
              canvasPoolRef.current[key] = document.createElement("canvas");
            }

            const canvas = canvasPoolRef.current[key];
            paint({
              canvasContext: canvas.getContext("2d") as CanvasRenderingContext2D,
              layoutResult,
              pixelSize,
            });

            const overprints = selector.overprints?.map((overprint) => {
              const key = `${selector.number}-${overprint}`;
              if (!canvasPoolRef.current[key]) {
                canvasPoolRef.current[key] = document.createElement("canvas");
              }
              const canvas = canvasPoolRef.current[key];
              paint({
                canvasContext: canvas.getContext("2d") as CanvasRenderingContext2D,
                layoutResult,
                pixelSize,
                overprint,
              });

              return {
                overprint,
                canvas,
              };
            });

            return { selector, canvas, overprints, layoutResult };
          }),
        );

        const layoutResults = fusionResults.map(({ layoutResult }) => layoutResult);

        setLayoutResultsState(layoutResults);

        return surfaces.map((surface) => {
          const fusionResult = fusionResults.find((fusionResult) => fusionResult.selector.number === surface.page);
          if (fusionResult && surface.channel) {
            const fusionOverprintResult = fusionResult.overprints?.find(({ overprint }) => overprint === surface.channel);
            return { surface, canvas: fusionOverprintResult!.canvas };
          }
          if (fusionResult) {
            return { surface, canvas: fusionResult.canvas };
          }
          const key = "blank";
          if (!canvasPoolRef.current[key]) {
            const canvas = document.createElement("canvas");
            canvas.width = 1;
            canvas.height = 1;
            canvasPoolRef.current[key] = canvas;
          }
          return { surface, canvas: canvasPoolRef.current[key] };
        });
      },
      dispose: () => {
        // Reset canvasses to release memory on umount
        // https://pqina.nl/blog/canvas-area-exceeds-the-maximum-limit/
        // Width and height has to be 1, not 0 for this to work
        Object.values(canvasPoolRef.current).forEach((canvas) => {
          canvas.width = 1;
          canvas.height = 1;
          const ctx = canvas.getContext("2d");
          ctx && ctx.clearRect(0, 0, 1, 1);
        });
        canvasPoolRef.current = {};
      },
    };
  }, [cimDoc]);

  return <>{children({ fusionVortexAdapter, layoutResults: layoutResultsState })}</>;
}
