import { Outlet, NavLink } from "react-router-dom";
import CSS from "./AppLayout.module.css";

const MenuLink = ({ children, to }: { children: any; to: string }) => (
  <NavLink className={({ isActive }) => `${CSS.Link} ${isActive ? CSS.LinkActive : ""}`} to={to}>
    {children}
  </NavLink>
);

export function AppLayout() {
  return (
    <>
      <nav className={CSS.Nav}>
        <MenuLink to="/">🖼 Item previews</MenuLink>
        <MenuLink to="/document-rendering">📑 Document rendering</MenuLink>
        <MenuLink to="/rich-media-document-rendering">📽️ Rich media document rendering</MenuLink>
        <MenuLink to="/text">📖 Text</MenuLink>
        <MenuLink to="/csr-vs-ssr">⚔️ CSR vs SSR</MenuLink>
        <MenuLink to="/vortex-demo">🌀 Vortex Demo</MenuLink>
      </nav>
      <main className={CSS.Main}>
        <Outlet />
      </main>
    </>
  );
}
