import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const textAlongAPathDocument: CimpressDocument = {
  documentId: "doc0",
  version: "2",
  deleted: false,
  revision: 0,
  document: {
    panels: [
      {
        id: "1b6c0554-0bdf-4ddf-b2a1-5c2eeb3dc3cf",
        name: "front",
        width: "53.8mm",
        height: "28.4mm",
        textAreas: [
          {
            id: "textArea1",
            position: {
              x: "10mm",
              y: "10mm",
              width: "30mm",
              height: "5mm",
            },
            horizontalAlignment: "left",
            verticalAlignment: "middle",
            blockFlowDirection: "horizontal-tb",
            textOrientation: "natural",
            zIndex: 4,
            resizingOptions: {
              bounds: "blackbox",
              fit: "exactFit",
              wrapping: "prevent",
            },
            textPath: {
              pathSpecification: {
                type: "svgPath",
                svgPathData: "M 0 20 C 30 12 40 28 50 20",
                svgPathDataUnit: "mm",
              },
            },
            content: [
              {
                content: "Text along a path",
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                fontSize: "18pt",
                color: "cmyk(100, 50, 0, 0)",
                stroke: {
                  color: "cmyk(0, 0, 0, 100)",
                  thickness: "0.5pt",
                },
              },
            ],
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  metadata: {},
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
