import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { DocumentEditor } from "../JSONViewer";

type InputProps = {
  document: CimpressDocument;
  options: string[];
  onChangeSelectedId: (id: string) => void;
  selectedId: string;
  onDocumentChange: (document: CimpressDocument) => void;
};

export function ItemInput({ document, onChangeSelectedId, options, selectedId, onDocumentChange }: InputProps) {
  return (
    <div>
      {options.length > 1 && (
        <label>
          Element id:{" "}
          <select value={selectedId} onChange={(e) => onChangeSelectedId(e.currentTarget.value)}>
            {options.map((id) => (
              <option key={id}>{id}</option>
            ))}
          </select>
          ({options.length})
        </label>
      )}
      <DocumentEditor document={document} onDocumentChange={onDocumentChange} />
    </div>
  );
}
