import classNames from "classnames";
import CSS from "./FontSizeInput.module.css";

type FontSizeInputProps = {
  fontSize: number;
  onChange: (value: number) => void;
  className?: string;
};

export function FontSizeInput({ fontSize, onChange, className }: FontSizeInputProps) {
  return (
    <div className={classNames(CSS.Container, className)}>
      <button className={CSS.Button} type="button" onClick={() => onChange(Math.max(fontSize - 1, 1))}>
        -
      </button>
      <span className={CSS.Value}>{fontSize}</span>
      <button className={CSS.Button} type="button" onClick={() => onChange(fontSize + 1)}>
        +
      </button>
    </div>
  );
}
