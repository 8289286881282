import { CimpressDocument, ItemReference } from "@mcp-artwork/cimdoc-types-v2";
import { FormEvent, memo, useEffect, useRef } from "react";
import { Updater } from "use-immer";
import { TextInput } from "../../../TextInput";
import { InteractionEventEmitter } from "../../InteractionStateHandler";
import { getDocumentPanels } from "../../../selectors/document";

type ItemReferenceEditorProps = {
  itemReference: ItemReference;
  onSetCimDoc: Updater<CimpressDocument>;
  interactionEventEmitter: InteractionEventEmitter;
};

const ItemReferenceEditorInner = memo(({ itemReference, onSetCimDoc, interactionEventEmitter }: ItemReferenceEditorProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onInput = (e: FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    onSetCimDoc((draft) => {
      getDocumentPanels(draft)
        .map((surface) => surface.itemReferences)
        .flat()
        .forEach((i) => {
          if (i?.id === itemReference.id) {
            i.data.content = newValue;
          }
        });
    });
  };

  useEffect(() => {
    const onClick = (id: string) => {
      if (id === itemReference.id) {
        inputRef.current?.focus();
      }
    };

    interactionEventEmitter.addEventListener("click", onClick);

    return () => {
      interactionEventEmitter.removeEventListener("click", onClick);
    };
  }, [interactionEventEmitter]);

  return (
    <TextInput
      onInput={onInput}
      value={itemReference.data.content}
      onFocus={() => interactionEventEmitter.triggerEvent("focus", itemReference.id)}
      ref={inputRef}
    />
  );
});

// Only support itemReference with data.content for now
export const ItemReferenceEditor = (props: ItemReferenceEditorProps) => {
  if (!props.itemReference.data.content) {
    return null;
  }
  return <ItemReferenceEditorInner {...props} />;
};
