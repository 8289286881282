import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const textJustificationDocument: CimpressDocument = {
  documentId: "b707f445-580f-4395-83b5-0254a1f94b0b",
  version: "2",
  deleted: false,
  revision: 1,
  owner: "1Q68Z3MeC83IdfeGOPsIzST86kUgZSvK@clients",
  tenant: "account:g2Ez5VaoZWoqU22XqPjTLU",
  document: {
    panels: [
      {
        id: "e8e3e476-afcd-4536-8bc5-5734a61cbff9",
        name: "front",
        width: "91.898611mm",
        height: "53.798611mm",
        textAreas: [
          {
            id: "e9823e38-acd2-4fb3-8a34-d0306b2be71a",
            position: {
              x: "2.7545609502577344mm",
              y: "3.0309327297452002mm",
              width: "86.27394225455326mm",
              height: "24.9818mm",
            },
            horizontalAlignment: "right",
            verticalAlignment: "top",
            curveAlignment: "baseline",
            blockFlowDirection: "horizontal-tb",
            textOrientation: "natural",
            zIndex: 1,
            content: [
              {
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                // @ts-ignore
                type: "inline",
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                fontSize: "13pt",
                color: "rgb(#101820)",
              },
            ],
            textJustification: "interWord",
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
