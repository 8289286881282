import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const resizedText: CimpressDocument = {
  version: "2",
  deleted: false,
  document: {
    panels: [
      {
        id: "e8e3e476-afcd-4536-8bc5-5734a61cbff9",
        name: "front",
        width: "91.898611mm",
        height: "53.798611mm",
        textAreas: [
          {
            id: "34b0a6b4-dbb3-418b-806a-d434a47bd38d",
            position: {
              x: "15.142137935429526mm",
              y: "28.09119924650726mm",
              width: "40mm",
              height: "10mm",
            },
            zIndex: 1,
            content: [
              {
                content: "this text will always fit",
                // @ts-ignore
                type: "inline",
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                fontSize: "13pt",
                color: "rgb(#238bf3)",
              },
            ],
            resizingOptions: {
              bounds: "text",
              fit: "shrinkToFit",
              wrapping: "prevent",
            },
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
