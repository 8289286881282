import { DesignSurface } from "@mcp-artwork/cimdoc-types-v2";
import { Filter } from "../../models/Filters";

export function getFilters(surface: DesignSurface): Filter[] | undefined {
  if (surface.colorMode && surface.colorMode.indexOf("grayscale") >= 0) {
    return [
      {
        type: "colorMatrix",
        matrix: [
          [0.21, 0.72, 0.07, 0],
          [0.21, 0.72, 0.07, 0],
          [0.21, 0.72, 0.07, 0],
          [0, 0, 0, 1],
          [0, 0, 0, 0],
        ],
      },
    ];
  }

  return undefined;
}
