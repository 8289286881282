import { applyClip } from "./Clip";
import {
  LayoutLinearGradient,
  LayoutRadialGradient,
  LayoutPaint,
  Matrix,
  PathLayout,
  PathOperation,
  LayoutStroke,
  BoundingBox,
  transformBoundingBox,
  LayoutPattern,
  DecompositionValues,
} from "@rendering/plasma";
import { LayoutColor } from "@rendering/plasma";
import { getOffscreenCanvas, paint, PaintInput } from "../PaintEngine";

/* I couldn't find documentation for the maximum/minimum values for the values of each of the (x, y, width, height) parameters
 * of the fillRect() function. But through manual testing, I found that powers of 2 exceeding the 25th power seem to break the function
 * completely. From some testing it looks like this value still doesn't change with the canvases of different sizes.
 */
const FILLRECT_MAX_DIMENSION = Math.pow(2, 25);

type PaintPathOptions = {
  context: CanvasRenderingContext2D;
  layout: PathLayout;
  pixelSize: string;
  overprint?: string;
};

function convertLineJoinToCanvas(join: "bevel" | "mitre" | "round"): "bevel" | "miter" | "round" {
  switch (join) {
    case "mitre":
      return "miter";
    default:
      return join;
  }
}

function createLinearGradientFill(linearGradient: LayoutLinearGradient, context: CanvasRenderingContext2D): { fill: CanvasGradient; transform: Matrix } {
  const gradientFill: CanvasGradient = context.createLinearGradient(linearGradient.start.x, linearGradient.start.y, linearGradient.end.x, linearGradient.end.y);

  linearGradient.stops.forEach((stop) => {
    if (stop.color.display) {
      gradientFill.addColorStop(stop.offset, stop.color.display);
    }
  });

  return { fill: gradientFill, transform: linearGradient.transform };
}

function createRadialGradientFill(radialGradient: LayoutRadialGradient, context: CanvasRenderingContext2D): { fill: CanvasGradient; transform: Matrix } {
  const gradientFill: CanvasGradient = context.createRadialGradient(
    radialGradient.start.x,
    radialGradient.start.y,
    radialGradient.startRadius,
    radialGradient.end.x,
    radialGradient.end.y,
    radialGradient.endRadius,
  );

  radialGradient.stops.forEach((stop) => {
    if (stop.color.display) {
      gradientFill.addColorStop(stop.offset, stop.color.display);
    }
  });

  return { fill: gradientFill, transform: radialGradient.transform };
}

function createPatternFill(
  pattern: LayoutPattern,
  context: CanvasRenderingContext2D,
  pixelSize: string,
): { fill: CanvasPattern; transform: Matrix; patternCanvasScalar: number } {
  const patternContext = getOffscreenCanvas(6502);

  const input: PaintInput = {
    canvasContext: patternContext,
    layoutResult: pattern.layout,
    // Modifying the pixel size here is equivalent to changing the resolution of the pattern. Here, we are rendering the pattern
    // at the actual dimensions in the final canvas. Normally, the canvasScalar is quite a big number, something like 14. We are doing
    // this because a pattern drawn at a small resolution then scaled up causes big quality issues.
    pixelSize,
  };

  const paintOutput = paint(input);

  const canvasPattern = context.createPattern(patternContext.canvas, pattern.repetition) as CanvasPattern;
  const patternTransform = pattern.transform;

  // We need to apply the canvas scalar to the absolute components of the matrix since the compositing canvas is drawn WITHOUT the scalar applied.
  // See how the compositing canvas is drawn in the paintPath() function when fillIsPattern === true.
  patternTransform.x *= paintOutput.scalar;
  patternTransform.y *= paintOutput.scalar;

  return { fill: canvasPattern, transform: patternTransform, patternCanvasScalar: paintOutput.scalar };
}

function setFilterContext(context: CanvasRenderingContext2D, path: PathOperation): void {
  if (path.effects != null) {
    path.effects.forEach((effect) => {
      if (effect.type === "blur") {
        addFilter(context, `blur(${effect.radius}mm)`);
      }
    });
  }
}

function addFilter(context: CanvasRenderingContext2D, command: string): void {
  if (context.filter === "none") {
    context.filter = command;
  } else {
    context.filter = `${context.filter} ${command}`;
  }
}

/**
 * Fills the base channel of the path (no overprints)
 * This returns undefined for anything other than plain colors
 **/
function getFillStyle(fill: LayoutPaint, overprint: string | undefined): CanvasGradient | string | undefined {
  if (fill.type === "color") {
    if (fill.display) {
      // For overprints either show or block the fill if it matches the name
      if (overprint) {
        return fill.name === overprint ? "#ffffff" : "#000000";
      }

      return fill.display;
    }
  }

  return undefined;
}

function getCompositingCanvas(
  context: CanvasRenderingContext2D,
  fill: LayoutPaint,
  pixelSize: string,
): { compositingCanvasContext: CanvasRenderingContext2D | undefined; patternCanvasScalar?: number } {
  if (fill.type === "radialGradient" || fill.type === "linearGradient" || fill.type === "pattern") {
    let externalFill: { fill: CanvasGradient | CanvasPattern; transform: Matrix; patternCanvasScalar?: number };

    if (fill.type === "radialGradient") {
      externalFill = createRadialGradientFill(fill, context);
    } else if (fill.type === "linearGradient") {
      externalFill = createLinearGradientFill(fill, context);
    } else {
      externalFill = createPatternFill(fill, context, pixelSize);
    }

    const oCtx: CanvasRenderingContext2D = getOffscreenCanvas(1057);
    const oCnv: HTMLCanvasElement = oCtx.canvas;

    let offscreenCanvasBoundingBox: BoundingBox = {
      left: 0,
      top: 0,
      width: context.canvas.width,
      height: context.canvas.height,
    };

    const currentTransformInverse = context.getTransform().inverse();
    const offscreenCanvasMatrixTransform: Matrix = new Matrix(
      currentTransformInverse.a,
      currentTransformInverse.b,
      currentTransformInverse.c,
      currentTransformInverse.d,
      currentTransformInverse.e,
      currentTransformInverse.f,
    );

    // Transform the current canvas dimensions with the inverse of the current transform to get the original dimenisions of the current canvas.
    // This is because the offscreen canvas is composited after the main canvas has been completely rendered.

    // This is needed because just setting the offscreen canvas width/height to the main canvas width/height doesn't work for when there is a nested
    // transform context, which is a scenario when "group" type rendering operations are used. In those cases, it may be possible that the final dimensions
    // of the main canvas is not necessarily what it is currently.

    // NOTE: Not all affine 2D transforms are invertible but for most practical cases it will be. Fix this code if that scenario arises.
    offscreenCanvasBoundingBox = transformBoundingBox(offscreenCanvasBoundingBox, offscreenCanvasMatrixTransform);

    // We seemingly can't just set width/height to an extremely high number like how we do below for fillRect(), this has to be the smallest it can be to avoid
    // performance issues.
    // For item previews there is a scale transform applied and the inverse actually scales it down, avoid that
    // There is probably a better way to do all of this but these changes seem to fix the immediate issue
    const width = Math.max(offscreenCanvasBoundingBox.width, context.canvas.width);
    const height = Math.max(offscreenCanvasBoundingBox.height, context.canvas.height);

    oCnv.width = width;
    oCnv.height = height;

    oCtx.clearRect(0, 0, width, height);
    oCtx.setTransform(externalFill.transform.toDOMMatrix());
    oCtx.fillStyle = externalFill.fill;
    // The intent here is to have the gradient fill the entire canvas, with fillRect(0, 0, width, height). But if a transform was applied,
    // this may not always fill the canvas, since the rectangle from fillRect is also transformed, and fillStyles do not extend beyond the dimensions.
    // This is a 'hack' to ensure that the gradient is always extended beyond the canvas even with transforms, by creating a massive rectangle extending
    // beyond all sides of the canvas. Interestingly, this seems to be very performant.
    oCtx.fillRect(-FILLRECT_MAX_DIMENSION / 2, -FILLRECT_MAX_DIMENSION / 2, FILLRECT_MAX_DIMENSION, FILLRECT_MAX_DIMENSION);
    return { compositingCanvasContext: oCtx, patternCanvasScalar: externalFill.patternCanvasScalar };
  }

  return { compositingCanvasContext: undefined, patternCanvasScalar: undefined };
}

function contextFill(context: CanvasRenderingContext2D, path: Path2D, fillStyle: string | CanvasGradient, fillRule?: CanvasFillRule): void {
  context.fillStyle = fillStyle;
  context.fill(path, fillRule);
}

function contextStroke(
  context: CanvasRenderingContext2D,
  path: Path2D,
  properties: { layoutStroke: LayoutStroke; strokeStyle: string | CanvasGradient },
): void {
  context.strokeStyle = properties.strokeStyle;
  context.lineWidth = properties.layoutStroke.width;
  context.lineCap = properties.layoutStroke.lineCap;
  context.lineJoin = convertLineJoinToCanvas(properties.layoutStroke.lineJoin);

  if (properties.layoutStroke.dashArray) {
    context.setLineDash(properties.layoutStroke.dashArray);
  }

  context.stroke(path);
}

// Paints the path and takes into account if a overprint channel is requested
function paintPath(context: CanvasRenderingContext2D, operation: PathOperation, pixelSize: string, overprint: string | undefined): void {
  const path = new Path2D(operation.path);
  const canvasWidth = context.canvas.width;
  const canvasHeight = context.canvas.height;

  let fillStyle: CanvasGradient | string | undefined = undefined;
  const fillIsPattern: boolean = operation.fill?.type === "pattern" ? true : false;
  let strokeStyle: CanvasGradient | string | undefined = undefined;

  // This is where the radial gradients are drawn
  let compositingFillCanvasCtx: CanvasRenderingContext2D | undefined;
  let compositingStrokeCanvasCtx: CanvasRenderingContext2D | undefined;
  // The radial gradients here are then masked by the fill/stroke
  let fillMaskCanvasCtx: CanvasRenderingContext2D | undefined;
  let strokeMaskCanvasCtx: CanvasRenderingContext2D | undefined;

  let patternCanvasScalar: number | undefined;

  // Determine the fill style
  if (operation.fill) {
    fillStyle = getFillStyle(operation.fill, overprint);
    const compositingCanvasResponse = getCompositingCanvas(context, operation.fill, pixelSize);
    compositingFillCanvasCtx = compositingCanvasResponse.compositingCanvasContext;
    patternCanvasScalar = compositingCanvasResponse.patternCanvasScalar;
  }

  // Since a separate canvas may be used to draw the fill and then composited into the main canvas, there needs to exist
  // some kind of fill for the composite operation to fill the shape. While in theory the fill color should not be a factor,
  // having a non-white color produces some artifacting issues, probably from the compositing operation.
  if (compositingFillCanvasCtx !== undefined) {
    fillStyle = "#FFFFFFFF";
    fillMaskCanvasCtx = getOffscreenCanvas(1055);
    fillMaskCanvasCtx.canvas.width = canvasWidth;
    fillMaskCanvasCtx.canvas.height = canvasHeight;
    fillMaskCanvasCtx.clearRect(0, 0, canvasWidth, canvasHeight);
    fillMaskCanvasCtx.setTransform(context.getTransform());
  }

  // Visible stroke overprints should override base colors
  if (operation.overprints) {
    operation.overprints.forEach((o: LayoutColor) => {
      if (overprint) {
        fillStyle = o.name === overprint ? "#ffffff" : "#000000";
      } else if (o.display) {
        fillStyle = o.display;
      }
    });
  }

  if (fillStyle) {
    let fillContext: CanvasRenderingContext2D;

    if (fillMaskCanvasCtx !== undefined) {
      fillContext = fillMaskCanvasCtx;
    } else {
      fillContext = context;
    }
    contextFill(fillContext, path, fillStyle, operation.fillRule);
  }

  if (compositingFillCanvasCtx !== undefined && fillMaskCanvasCtx !== undefined) {
    context.save();
    context.resetTransform();
    // needs to be source-over and not source-in, since there is the factor of transparency in gradient color stops
    context.globalCompositeOperation = "source-over";
    // mask the gradient
    fillMaskCanvasCtx.globalCompositeOperation = "source-in";

    if (fillIsPattern) {
      fillMaskCanvasCtx.save();
      // We need to get rid of the canvasScalar in the transform. This is because the item with the pattern may have some scale
      // translation, and that still needs to be applied to the compositing canvas.
      // On a side note, this is poor software design to have a single matrix transform containing possibly many unrelated transformations. Because the
      // current case is simple enough I'm able to extract the scale transform. At some point, we should rewrite transform handling in plasma/neon to behave
      // more like the instructions service: a stack-based and isolated context using PushState/PopState. This should also make it easier to develop features
      // between fusion and Rendering.
      fillMaskCanvasCtx.scale(1 / (patternCanvasScalar as number), 1 / (patternCanvasScalar as number));
      const decomposedValues: DecompositionValues = getCurrentContextDecomposedValues(fillMaskCanvasCtx);
      fillMaskCanvasCtx.resetTransform();

      // This essentially applies the item transforms (not pattern-local transforms) to the pattern.
      let patternTransform = Matrix.scale(decomposedValues.scale.x, decomposedValues.scale.y);
      patternTransform = Matrix.multiply(patternTransform, Matrix.rotation(decomposedValues.rotation));
      patternTransform = Matrix.multiply(patternTransform, Matrix.translate(decomposedValues.translation.x, decomposedValues.translation.y));

      fillMaskCanvasCtx.setTransform(
        new DOMMatrix([patternTransform.a, patternTransform.b, patternTransform.c, patternTransform.d, patternTransform.x, patternTransform.y]),
      );
    }

    fillMaskCanvasCtx.drawImage(compositingFillCanvasCtx.canvas, 0, 0);

    if (fillIsPattern) {
      fillMaskCanvasCtx.restore();
    }

    // draw the result
    context.drawImage(fillMaskCanvasCtx.canvas, 0, 0);
    context.restore();
  }

  // Draw strokes if they exist
  if (operation.stroke && operation.stroke.width > 0) {
    const stroke = operation.stroke;

    // Determine the fill style
    if (stroke.fill) {
      strokeStyle = getFillStyle(stroke.fill, overprint);
      compositingStrokeCanvasCtx = getCompositingCanvas(context, stroke.fill, pixelSize).compositingCanvasContext;
    }

    // Similar to the logic for fills above
    if (compositingStrokeCanvasCtx !== undefined) {
      strokeStyle = "white";
      strokeMaskCanvasCtx = getOffscreenCanvas(1056);
      strokeMaskCanvasCtx.canvas.width = canvasWidth;
      strokeMaskCanvasCtx.canvas.height = canvasHeight;
      strokeMaskCanvasCtx.clearRect(0, 0, canvasWidth, canvasHeight);
      strokeMaskCanvasCtx.setTransform(context.getTransform());
    }

    // Visible stroke overprints should override base colors
    if (stroke.overprints) {
      stroke.overprints.forEach((o: LayoutColor) => {
        if (overprint) {
          fillStyle = o.name === overprint ? "#ffffff" : "#000000";
        } else if (o.display) {
          fillStyle = o.display;
        }
      });
    }

    if (strokeStyle) {
      let strokeContext: CanvasRenderingContext2D;

      if (strokeMaskCanvasCtx !== undefined) {
        strokeContext = strokeMaskCanvasCtx;
      } else {
        strokeContext = context;
      }

      contextStroke(strokeContext, path, { layoutStroke: stroke, strokeStyle: strokeStyle });
    }

    if (compositingStrokeCanvasCtx !== undefined && strokeMaskCanvasCtx !== undefined) {
      context.save();
      context.resetTransform();
      context.globalCompositeOperation = "source-in";
      strokeMaskCanvasCtx.globalCompositeOperation = "source-in";
      strokeMaskCanvasCtx.drawImage(compositingStrokeCanvasCtx.canvas, 0, 0);
      context.drawImage(strokeMaskCanvasCtx.canvas, 0, 0);
      context.restore();
    }
  }
}

export function paintPaths({ context, layout, overprint, pixelSize }: PaintPathOptions): void {
  context.save();
  try {
    applyClip({ context, clip: layout.clip });
    context.globalAlpha = context.globalAlpha * layout.opacityMultiplier;

    for (const operation of layout.paths) {
      // Scale the operation matrix
      const transform: Matrix = operation.transform;

      context.save();

      try {
        setFilterContext(context, operation);

        // Apply matrix transform
        context.transform(transform.a, transform.b, transform.c, transform.d, transform.x, transform.y);

        paintPath(context, operation, pixelSize, overprint);
      } finally {
        context.restore();
      }
    }
  } finally {
    context.restore();
  }
}

function getCurrentContextDecomposedValues(context: CanvasRenderingContext2D): DecompositionValues {
  const transform = context.getTransform();
  const matrix: Matrix = new Matrix(transform.a, transform.b, transform.c, transform.d, transform.e, transform.f);

  return Matrix.decomposeAffineTransform(matrix);
}
