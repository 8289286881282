import classNames from "classnames";
import { ChangeEvent } from "react";
import CSS from "./ImageFileInput.module.css";

type ImageFileInputProps = {
  className?: string;
  onChange: (file: File) => void;
};

export function ImageFileInput({ className, onChange }: ImageFileInputProps) {
  const onInnerChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];
    if (file) {
      onChange(file);
    }

    e.currentTarget.value = "";
  };

  return (
    <label className={classNames(CSS.Button, className)}>
      <input className={CSS.Input} type="file" onChange={onInnerChange} />
      Upload new image
    </label>
  );
}
