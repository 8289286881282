import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { determinePixelSizeByDimension, determinePixelSizeByHeight, determinePixelSizeByWidth, Selector } from "@rendering/plasma";

type DimensionWidth = { width: number };
type DimensionHeight = { height: number };
type DimensionMax = { maxDimension: number };
export type Dimension = DimensionWidth | DimensionHeight | DimensionMax;

type GetPixelSizeArgs = {
  dimension: Dimension;
  selector: Selector;
  cimDoc: CimpressDocument;
};

export const getPixelSize = async ({ dimension, selector, cimDoc }: GetPixelSizeArgs): Promise<string> => {
  if ((dimension as DimensionWidth).width) {
    return determinePixelSizeByWidth({ width: (dimension as DimensionWidth).width, selector, cimDoc });
  }
  if ((dimension as DimensionHeight).height) {
    return determinePixelSizeByHeight({ height: (dimension as DimensionHeight).height, selector, cimDoc });
  }
  return determinePixelSizeByDimension({ dimension: (dimension as DimensionMax).maxDimension, selector, cimDoc });
};
