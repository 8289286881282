import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { ReactNode, createContext, useCallback, useMemo } from "react";
import { useImmer } from "use-immer";
import { CimDocUpdater, ImageProgressUpdater, ItemSelecterUpdater, ImageProgress, SherbertFilesUpdater } from "../models";
import { getAuth } from "../../auth";
import { DesignPreviewsType } from "../Previews";
import { SherbertFile, listSherbertAssets, presignSherbertAsset } from "../../sherbert/api";

type DesignerContext = {
  cimDoc: CimpressDocument | undefined;
  setCimDoc: CimDocUpdater;
  selectedItemId: string | undefined;
  setSelectedItemId: ItemSelecterUpdater;
  imageUploadingProgress: ImageProgress;
  setImageUploadingProgress: ImageProgressUpdater;
  sherbertFiles: SherbertFile[];
  setSherbertFiles: SherbertFilesUpdater;
  fetchSherbertFiles: () => void;
  designPreviewsType: DesignPreviewsType;
  setDesignPreviewsType: (designPreviewsType: DesignPreviewsType) => void;
};

const designerContext = createContext<DesignerContext>({
  cimDoc: undefined,
  // @ts-ignore
  setCimDoc: (cimDoc: CimpressDocument | undefined) => {},
  selectedItemId: undefined,
  // @ts-ignore
  setSelectedItemId: (selectedItemId: string | undefined) => {},
  imageUploadingProgress: {},
  // @ts-ignore
  setImageUploadingProgress: (imageUploadingProgress: ImageProgress) => {},
  designPreviewsType: undefined,
  setDesignPreviewsType: () => {},
});

export const DesignerContextProvider = ({ children }: { children: (state: DesignerContext) => ReactNode }) => {
  const [cimDoc, setCimDoc] = useImmer<CimpressDocument | undefined>(undefined);
  const [selectedItemId, setSelectedItemId] = useImmer<string | undefined>(undefined);
  const [imageUploadingProgress, setImageUploadingProgress] = useImmer<ImageProgress>({});
  const [sherbertFiles, setSherbertFiles] = useImmer<SherbertFile[]>([]);
  const [designPreviewsType, setDesignPreviewsType] = useImmer<DesignPreviewsType | undefined>(undefined);

  const fetchSherbertFiles = useCallback(() => {
    listSherbertAssets({ accessToken: getAuth().getAccessToken() }).then((ids) => {
      setSherbertFiles((draft) => {
        // Save ids
        ids.forEach(({ id }) => {
          if (!draft.some((file) => file.id === id)) {
            draft.push({ id });
          }
        });
      });

      // Fetch and save signatures
      ids.forEach(({ id }) => {
        presignSherbertAsset({ id, accessToken: getAuth().getAccessToken() }).then((signed) => {
          setSherbertFiles((draft) => {
            const draftSherbertFile = draft.find((item) => item.id === id);
            if (draftSherbertFile) {
              draftSherbertFile.signature = signed.signature;
            }
          });
        });
      });
    });
  }, []);

  const state = useMemo<DesignerContext>(
    () => ({
      cimDoc,
      setCimDoc,
      imageUploadingProgress,
      setImageUploadingProgress,
      selectedItemId,
      setSelectedItemId,
      sherbertFiles,
      setSherbertFiles,
      fetchSherbertFiles,
      designPreviewsType,
      setDesignPreviewsType,
    }),
    [
      cimDoc,
      setCimDoc,
      imageUploadingProgress,
      setImageUploadingProgress,
      selectedItemId,
      setSelectedItemId,
      sherbertFiles,
      setSherbertFiles,
      fetchSherbertFiles,
      designPreviewsType,
      setDesignPreviewsType,
    ],
  );

  return <designerContext.Provider value={state}>{children(state)}</designerContext.Provider>;
};
