import { useEffect, useState } from "react";
import CSS from "./DelayedLoading.module.css";

export function DelayedLoading({ loading }: { loading: boolean }) {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (loading) {
      timeout = setTimeout(() => {
        setShow(true);
      }, 300);
    } else {
      setShow(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [loading]);

  if (show) {
    return <div className={CSS.Loading}>🔄</div>;
  }

  return null;
}
