import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { usePhysicsToDocument } from "../Physics/usePhysicsToDocument";
import { useEffect, useRef, useState } from "react";

type GravityAnimationControlProps = {
  cimDoc: CimpressDocument;
  setCimDoc: (cimDoc: CimpressDocument) => void;
  onReset: () => void;
  onStart: () => void;
  onPause: () => void;
};

export function GravityAnimationControl({ cimDoc, setCimDoc, onReset, onStart, onPause }: GravityAnimationControlProps) {
  const [start, setStart] = useState<boolean>(false);
  const animationFrameRef = useRef<number>();
  const { resetAnimation, updateLoop } = usePhysicsToDocument();

  useEffect(() => {
    if (start) {
      animationFrameRef.current = window.requestAnimationFrame((timestamp) => {
        const updatedDocument = updateLoop({ timestamp, document: cimDoc });
        setCimDoc(updatedDocument);
      });
    }

    return () => {
      cancelAnimationFrame(animationFrameRef.current as number);
    };
  }, [start, cimDoc]);

  return (
    <>
      <button
        onClick={() => {
          setStart(!start);
          onStart();
        }}
      >
        {start ? "Stop" : "Start"} gravity animation
      </button>
      <button
        onClick={() => {
          setStart(false);
          onPause();
          cancelAnimationFrame(animationFrameRef.current as number);
          resetAnimation();
          onReset();
        }}
      >
        Reset document
      </button>
    </>
  );
}
