// @ts-nocheck
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const curveDocument: CimpressDocument = {
  documentId: "doc0",
  version: "2",
  deleted: false,
  revision: 0,
  document: {
    panels: [
      {
        id: "1b6c0554-0bdf-4ddf-b2a1-5c2eeb3dc3cf",
        name: "front",
        width: "53.8mm",
        height: "28.4mm",
        shapes: [
          {
            type: "curve",
            stroke: {
              color: "cmyk(0, 100, 0, 0)",
              thickness: "1mm",
              lineCap: "round",
              lineJoin: "round",
            },
            zIndex: 4,
            position: {
              x: "0mm",
              y: "0mm",
              width: "10mm",
              height: "10mm",
            },
            id: "shape1",
            color: "cmyk(100, 0, 0, 0)",
            viewBox: {
              x: "0mm",
              y: "0mm",
              width: "10mm",
              height: "10mm",
            },
            svgPathData: "M10,10 L25, 5 L25, 15 L 40, 10",
            svgPathDataUnit: "mm",
            closeBehavior: "0",
            itemType: "shape",
          },
        ],
        decorationTechnology: "digital",
      },
    ],
  },
  metadata: {},
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
