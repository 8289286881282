import { CimpressDocument, DesignSurface, DesignDocumentWithSurfaces, DesignDocumentWithPanels } from "@mcp-artwork/cimdoc-types-v2";
import { matrixToTransform } from "../../layout/helpers/Transform";
import { Matrix } from "../math/matrix";
import { parseMM } from "../unitHelper";
import { Projector } from "./projector";
import { ERROR_MESSAGE_NO_PANEL_IN_PROJECTION } from "./jsonProjector";

export const buildStaticProjector = (specification: string, pageNumber: number): Projector => {
  const rotationIsClockwise: boolean = shouldRotateClockwise(specification, pageNumber);

  const getDesignSurfaces = (document: CimpressDocument) => {
    const panels: DesignSurface[] = (document.document as DesignDocumentWithSurfaces).surfaces ?? (document.document as DesignDocumentWithPanels).panels ?? [];

    const targetPanel = panels[pageNumber - 1];

    if (!targetPanel) {
      throw new Error(ERROR_MESSAGE_NO_PANEL_IN_PROJECTION);
    }

    return [targetPanel];
  };

  return {
    getDesignSurfaces,
    determineTargetSize: (surfaces) => ({
      width: parseMM(surfaces[0].height),
      height: parseMM(surfaces[0].width),
    }),
    getTargetName: (document) => getDesignSurfaces(document)[0].name,
    getTransform: (sourceSurface, sourceIndex, targetSize) => {
      if (rotationIsClockwise) {
        return [matrixToTransform(new Matrix(0, 1, -1, 0, targetSize.width, 0))];
      }
      return [matrixToTransform(new Matrix(0, -1, 1, 0, 0, targetSize.height))];
    },
    getClip: () => undefined,
  };
};

function shouldRotateClockwise(specification: string, pageNumber: number): boolean {
  switch (specification) {
    case "ROTATE_90":
      return true;
    case "ROTATE_270":
      return false;
    case "270_90_ODD_EVEN":
    case "90_270_EVEN_ODD":
      return pageNumber % 2 === 0;
    case "270_90_EVEN_ODD":
    case "90_270_ODD_EVEN":
      return pageNumber % 2 !== 0;
    default:
      throw Error("Unknown static projection");
  }
}
