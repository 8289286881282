import { ItemReference } from "@mcp-artwork/cimdoc-types-v2";
import { ValidationResult } from "../document/Validation";
import { ItemReferenceType, getItemReferenceType } from "./Layout";

export function itemReferenceValidation({ itemReference }: { itemReference: ItemReference }): ValidationResult {
  const itemRefType: ItemReferenceType = getItemReferenceType(itemReference);

  if (itemRefType === "vector") {
    return { status: "pass" };
  }

  return { status: "fail", error: "This itemreference is rendered on the server" };
}
