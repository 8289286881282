import { paint, PaintResult } from "@rendering/neon";
import { Filter, LayoutResult } from "@rendering/plasma";
import { useEffect, useContext } from "react";
import { GlobalOptionsContext } from "../Context/GlobalOptionsContext";
import { logPaintDiagnostics } from "../utils/diagnostics";

export interface CanvasByOverprint {
  /** Undefined indicates that this canvas is for the regular channels (RGB), a value indicates that it is for a specific overprint */
  overprint: string | undefined;
  canvas: HTMLCanvasElement | null;
}

type UsePaintProps = {
  canvases: CanvasByOverprint[];
  pixelSize: string;
  layoutResult?: LayoutResult;
  filters?: Filter[];
  onPaint?: (args: { paintResults: PaintResult[]; canvas: HTMLCanvasElement; layoutResult: LayoutResult }) => void;
};

export const usePaint = ({ canvases, pixelSize, layoutResult, filters, onPaint }: UsePaintProps): void => {
  const globalOptions = useContext(GlobalOptionsContext);

  useEffect(() => {
    if (layoutResult && canvases.length > 0 && canvases[0].canvas !== null) {
      // Override optional filters
      if (filters) {
        layoutResult.filters = filters;
      }

      const contexts = canvases.map(({ overprint, canvas }) => ({ overprint, canvas, context: canvas?.getContext("2d") as CanvasRenderingContext2D }));

      const results: PaintResult[] = contexts.map(({ overprint, context }) =>
        paint({
          layoutResult,
          canvasContext: context,
          overprint,
          pixelSize,
          debugOptions: {
            timers: globalOptions.options.timers,
            log: globalOptions.options.log,
          },
        }),
      );

      canvases.forEach(({ canvas }) => {
        if (canvas !== null) {
          canvas.style.width = `${canvas.width / Math.ceil(window.devicePixelRatio)}px`;
          canvas.style.height = `${canvas.height / Math.ceil(window.devicePixelRatio)}px`;
        }
      });

      onPaint?.({ paintResults: results, canvas: canvases[0].canvas, layoutResult });

      logPaintDiagnostics(results);
    }
  }, [layoutResult, pixelSize, filters, globalOptions.options.log, globalOptions.options.timers]);
};
