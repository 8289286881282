import { ColorPalette, RectangleItem } from "@mcp-artwork/cimdoc-types-v2";
import svgpath from "svgpath";
import { ClipPath, PathLayout } from "../Models";
import { LayoutElement, PreviewType } from "../../models/Layout";
import { BoundingBox, computeBoundsFromPosition } from "../../utils/boundingBox";
import { parseMM } from "../../utils/unitHelper";
import { getClip } from "../helpers/Clip";
import { parseFill, parseOverprints } from "../helpers/Paint";
import { getLayoutStroke } from "../helpers/Stroke";
import { buildTransform } from "../helpers/Transform";
import CimDocDefinitionTreeNode from "../../utils/CimDocDefinitionTreeNode";
import { getMeasurementData } from "../measurements/measurementData";

const rectangleToPath = ({ x, y, width, height, cornerRadius }): string => {
  let basePath: string;
  if (cornerRadius) {
    basePath = `M${cornerRadius},0 L${width - cornerRadius}, 0 A${cornerRadius} ${cornerRadius} 0 0 1 ${width} ${cornerRadius} L${width},${
      height - cornerRadius
    } A${cornerRadius} ${cornerRadius} 0 0 1 ${width - cornerRadius} ${height} L${cornerRadius}, ${height} A${cornerRadius} ${cornerRadius} 0 0 1 0 ${
      height - cornerRadius
    } L0,${cornerRadius} A${cornerRadius} ${cornerRadius} 0 0 1 ${cornerRadius} 0 Z`;
  } else {
    basePath = `M0,0H${width}V${height}H0Z`;
  }

  const translatedPath = svgpath(basePath).translate(x, y);

  return translatedPath.toString();
};

export async function rectangleLayout({
  rectangle,
  parentBounds,
  options,
  previewType,
}: {
  rectangle: RectangleItem;
  parentBounds: BoundingBox;
  options: { definitionTreeNode?: CimDocDefinitionTreeNode; fontRepositoryUrl?: string; colorPalette: ColorPalette | undefined };
  previewType: PreviewType;
}): Promise<LayoutElement> {
  const strokePadding = parseMM(rectangle.stroke?.thickness ?? "0mm") / 2;

  const originalScaleTransform = rectangle.scale;
  const originalBoundingBox = computeBoundsFromPosition({ position: rectangle.position, strokePadding });

  const itemPreviewTransform = buildTransform({
    bounds: originalBoundingBox,
    skew: rectangle.skew,
    scale: rectangle.scale,
    rotationAngle: rectangle.rotationAngle,
    matrixTransform: rectangle.transform,
    itemTransforms: rectangle.transforms,
  });

  const bounds: BoundingBox = computeBoundsFromPosition({ position: rectangle.position, strokePadding });
  let transform = buildTransform({
    bounds: bounds,
    skew: rectangle.skew,
    scale: rectangle.scale,
    rotationAngle: rectangle.rotationAngle,
    matrixTransform: rectangle.transform,
    itemTransforms: rectangle.transforms,
  });

  const clipTransform = buildTransform({ bounds, rotationAngle: rectangle.rotationAngle, itemTransforms: rectangle.transforms, translateToBounds: true });

  const clipPath: ClipPath | undefined = await getClip(rectangle, parentBounds, clipTransform);

  const measurementDataResponse = getMeasurementData({
    boundingBox: bounds,
    tightBounds: bounds,
    transform: itemPreviewTransform,
    scaleTransform: originalScaleTransform,
    itemType: "shape",
  });

  if (previewType === "item") {
    transform = measurementDataResponse.itemPreviewTransform;
  }

  const renderingOperation: PathLayout = {
    type: "drawPaths",
    clip: clipPath,
    paths: [
      {
        path: rectangleToPath({
          x: parseMM(rectangle.position.x),
          y: parseMM(rectangle.position.y),
          width: parseMM(rectangle.position.width),
          height: parseMM(rectangle.position.height),
          cornerRadius: rectangle.cornerRadius ? parseMM(rectangle.cornerRadius) : 0,
        }),
        transform,
        fill: await parseFill(rectangle.color, {
          definitionTreeNode: options.definitionTreeNode,
          itemBounds: bounds,
          colorPalette: options.colorPalette,
          fontRepositoryUrl: options.fontRepositoryUrl,
        }),
        overprints: parseOverprints(rectangle.overprints, options.colorPalette),
        stroke: await getLayoutStroke(rectangle.stroke, {
          definitionTreeNode: options.definitionTreeNode,
          itemBounds: bounds,
          colorPalette: options.colorPalette,
        }),
      },
    ],
    opacityMultiplier: rectangle.opacityMultiplier ?? 1,
  };

  return {
    id: rectangle.id,
    status: { mode: "local" },
    measurementData: {
      boundingBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.boundingBox,
      previewBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.previewBox,
      layoutBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.layoutBox,
    },
    renderingOperation,
  };
}
