import { CimpressDocument, LineItem, Shape } from "@mcp-artwork/cimdoc-types-v2";
import { FormEvent, memo, useRef } from "react";
import { InteractionEventEmitter } from "../../InteractionStateHandler";
import { Updater } from "use-immer";
import { getDocumentPanels } from "../../../selectors/document";
import CSS from "./ShapeInput.module.css";

type ShapeEditorProps = {
  shape: Shape;
  onSetCimDoc: Updater<CimpressDocument>;
  interactionEventEmitter: InteractionEventEmitter;
  moveIntoViewEnabled: boolean;
};

const getShapeColor = (shape: Shape) => ({
  fill: (shape as Exclude<Shape, LineItem>).color,
  stroke: shape.stroke?.thickness && parseFloat(shape.stroke?.thickness) > 0 ? shape.stroke?.color : undefined,
});

export const ShapeEditor = memo(({ interactionEventEmitter, moveIntoViewEnabled, onSetCimDoc, shape }: ShapeEditorProps) => {
  const { fill, stroke } = getShapeColor(shape);
  const initialValue = useRef<Shape>(shape);

  const onFillChange = (e: FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    onSetCimDoc((draft) => {
      getDocumentPanels(draft)
        .map((surface) => surface.shapes)
        .flat()
        .forEach((shapeDraft) => {
          if (shapeDraft?.id === shape.id) {
            (shapeDraft as Exclude<Shape, LineItem>).color = newValue;
          }
        });
    });
  };

  const onStrokeChange = (e: FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    onSetCimDoc((draft) => {
      getDocumentPanels(draft)
        .map((surface) => surface.shapes)
        .flat()
        .forEach((shapeDraft) => {
          if (shapeDraft?.id === shape.id) {
            (shapeDraft as Exclude<Shape, LineItem>).stroke!.color = newValue;
          }
        });
    });
  };

  const onReset = () => {
    onSetCimDoc((draft) => {
      const surfaces = getDocumentPanels(draft);
      surfaces.forEach((surface) => {
        const index = surface.shapes?.findIndex((s) => s.id === shape.id) ?? -1;
        if (index !== -1) {
          surface.shapes![index] = initialValue.current;
        }
      });
    });
  };

  if (!fill && !stroke) {
    return null;
  }

  return (
    <div className={CSS.Container}>
      {fill && (
        <>
          <span>Fill</span>
          <input type="color" onInput={onFillChange} value={fill} onFocus={() => interactionEventEmitter.triggerEvent("focus", shape.id)} />
        </>
      )}
      {stroke && (
        <>
          <span>Stroke</span>
          <input type="color" onInput={onStrokeChange} value={stroke} onFocus={() => interactionEventEmitter.triggerEvent("focus", shape.id)} />
        </>
      )}
      <button className={CSS.Reset} onClick={onReset}>
        Reset
      </button>
      {moveIntoViewEnabled && (
        <button className={CSS.Eyes} title="Move into view" onClick={() => interactionEventEmitter.triggerEvent("focus", shape.id)}>
          👀
        </button>
      )}
    </div>
  );
});
