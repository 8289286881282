import { HorizontalAlignment } from "@mcp-artwork/cimdoc-types-v2";
import classNames from "classnames";
import CSS from "./HorizontalAlignmentInput.module.css";

type HorizontalAlignmentProps = {
  value: HorizontalAlignment | undefined;
  onChange: (value: HorizontalAlignment) => void;
  className?: string;
};

export function HorizontalAlignmentInput({ value, onChange, className }: HorizontalAlignmentProps) {
  return (
    <div className={classNames(className)}>
      {["left", "center", "right"].map((alignment) => (
        <button
          key={alignment}
          className={classNames(CSS.Button, { [CSS.Active]: value?.toLowerCase().trim() === alignment })}
          onClick={() => onChange(alignment as HorizontalAlignment)}
        >
          {alignment}
        </button>
      ))}
    </div>
  );
}
