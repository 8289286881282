import { CimpressDocument, DesignDocumentWithPanels, DesignSurface, Subpanel, TextArea } from "@mcp-artwork/cimdoc-types-v2";
import { fetchServerFallback } from "../api";
import { FallBackItemOptions } from "../Item";
import { BitmapData, HTMLImageElementData } from "../../utils/api/image";
import { FallbackPreviewMetadata, parsePreviewMetadata } from "./xRenderMetadataHeaderParser";

export async function getPreviewMetadataFromFallback(item: TextArea | Subpanel, options: FallBackItemOptions): Promise<FallbackPreviewMetadata> {
  // strip all transforms from the item, we need the preview box
  const itemClone = { ...item };

  itemClone.rotationAngle = undefined;
  itemClone.scale = undefined;
  itemClone.skew = undefined;
  itemClone.transform = undefined;
  itemClone.transforms = undefined;

  const panel: DesignSurface = {
    id: "preview-0",
    name: "preview-0",
    width: "1mm", // not used for item preview on SSR
    height: "1mm", // not used for item preview on SSR
    decorationTechnology: options.decoTech,
  };

  if (options.itemInfo.itemType === "textArea") {
    panel.textAreas = [itemClone as TextArea];
  } else if (options.itemInfo.itemType === "subpanel") {
    panel.subpanels = [itemClone as Subpanel];
  } else {
    throw new Error("Unsupported item type for preview box fallback");
  }

  const cimdoc: CimpressDocument = {
    version: "2.0",
    fontRepositoryUrl: options.fontRepositoryUrl,
    document: {
      definitions: options.definitionTreeNode?.definition,
      colorPalette: options.colorPalette,
      panels: [panel],
    },
  };

  (cimdoc.document as DesignDocumentWithPanels).panels = [panel];

  const fallback: BitmapData | HTMLImageElementData = await fetchServerFallback({
    document: cimdoc,
    pixelSize: options.pixelSize,
    instructionType: "item",
    overprint: undefined,
    referrer: options.referrer,
  });

  if (fallback.xRenderMetadataHeader === undefined) {
    throw Error("Missing x-render-metadata header");
  }

  return parsePreviewMetadata(fallback.xRenderMetadataHeader);
}
