import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import CSS from "./Button.module.css";
import classNames from "classnames";

type ButtonsProps = {
  children: ReactNode;
} & DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export function Button({ children, className, ...rest }: ButtonsProps) {
  return (
    <button className={classNames(CSS.Button, className)} {...rest}>
      {children}
    </button>
  );
}
