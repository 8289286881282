import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { PreviewType } from "../models/Layout";
import { fetchImage, ImageArgs, BitmapData, HTMLImageElementData } from "../utils/api/image";
import { buildInstructionsRequest } from "../utils/api/instructionsClient";
import { buildRenderingRequest, RenderingRequest } from "../utils/api/renderingClient";
import { isWebPSupported } from "../layout/image/webP";

export async function fetchServerFallback({
  document,
  pixelSize,
  instructionType,
  breakCache = false,
  overprint,
  referrer,
}: {
  document: CimpressDocument;
  pixelSize: string;
  instructionType: PreviewType;
  breakCache?: boolean;
  overprint?: string;
  referrer: string;
}): Promise<BitmapData | HTMLImageElementData> {
  // Build an instructions request from the document and preview type
  const instructionsRequest = buildInstructionsRequest({ document, previewType: instructionType });

  const renderingRequest: RenderingRequest = buildRenderingRequest({
    instructionsRequest,
    tenant: "fusion-fallback",
    pixelSize,
    format: (await isWebPSupported()) ? "webp" : "png",
    breakCache,
    overprint,
    referrer,
  });

  const imageInput: ImageArgs = {
    url: renderingRequest.previewUri,
  };

  if (renderingRequest.instructionsPostData) {
    const formData = new FormData();
    formData.append("instructionsPostData", renderingRequest.instructionsPostData);
    imageInput.formData = formData;
  }

  const response = await fetchImage(imageInput);

  if (response.type === "svg") {
    throw new Error("Server fallback failed. Unexpected SVG.");
  }

  return response;
}
