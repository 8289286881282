import { Shape } from "@mcp-artwork/cimdoc-types-v2";
import { ValidationResult } from "../document/Validation";
import { DecorationTechnology } from "../helpers/Technology";

export function shapeValidation({ shape, decoTech }: { shape: Shape; decoTech: DecorationTechnology }): ValidationResult {
  if (decoTech === "embroidery") {
    return { status: "fail", error: "Embroidery is not supported!" };
  }

  if (shape?.stroke?.position === "outer") {
    return { status: "fail", error: "Outer strokes are not supported." };
  }

  return { status: "pass" };
}
