// @ts-nocheck
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const subpanelTransformedCurveDocument: CimpressDocument = {
  version: "2",
  deleted: false,
  document: {
    panels: [
      {
        id: "e8e3e476-afcd-4536-8bc5-5734a61cbff9",
        name: "front",
        width: "91.898611mm",
        height: "53.798611mm",
        subpanels: [
          {
            position: {
              x: "0mm",
              y: "0mm",
            },
            id: "deb3c88e-cf11-423d-83d2-e6d868d56f58",
            shapes: [
              {
                type: "curve",
                stroke: {
                  color: "cmyk(0, 100, 0, 0)",
                  thickness: "1mm",
                  lineCap: "round",
                  lineJoin: "round",
                },
                zIndex: 4,
                position: {
                  x: "0mm",
                  y: "0mm",
                  width: "10mm",
                  height: "10mm",
                },
                id: "shape1",
                color: "cmyk(100, 0, 0, 0)",
                viewBox: {
                  x: "0mm",
                  y: "0mm",
                  width: "10mm",
                  height: "10mm",
                },
                svgPathData: "M10,10 L25, 5 L25, 15 L 40, 10",
                svgPathDataUnit: "mm",
                closeBehavior: "0",
                itemType: "shape",
                transforms: [
                  {
                    type: "rotate",
                    degreesClockwise: 60,
                  },
                ],
              },
            ],
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
