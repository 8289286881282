import { VortexContainer } from "@rendering/vortex-react";
import { CimDocEditor } from "./CimDocEditor";
import { DemoPicker } from "./DemoPicker";
import { CimDocStateHandler } from "./DocumentStateHandler";
import { VortexPreview } from "./VortexPreview";
import { VortexPreviewActions } from "./VortexPreviewActions";
import { InteractionStateHandler } from "./InteractionStateHandler";
import { Loading } from "./Loading";
import CSS from "./VortexDemo.module.css";
import { FusionVortex } from "./FusionVortex";

const VortexFallback = () => (
  <div>
    <h1>React Error</h1>
  </div>
);

export function VortexDemo() {
  return (
    <>
      <DemoPicker>
        {({ demoData }) => (
          <CimDocStateHandler url={demoData.renderingInstructionsUrl}>
            {({ cimDoc, onSetCimDoc }) => (
              <FusionVortex cimDoc={cimDoc}>
                {({ fusionVortexAdapter, layoutResults }) => (
                  <div className={CSS.Container}>
                    <VortexContainer
                      key={JSON.stringify(demoData.productConfig)}
                      fallback={<VortexFallback />}
                      onError={console.error}
                      productConfig={demoData.productConfig}
                      user={demoData.user}
                      cameraOptions={demoData.cameraOptions}
                      initializationOptions={demoData.initializationOptions}
                      vortexDocumentAdapter={fusionVortexAdapter}
                    >
                      {({ loadingModel, updatingCanvas, preview }) => (
                        <InteractionStateHandler
                          layoutResults={layoutResults}
                          preview={preview}
                          cimDoc={cimDoc}
                          focusInteractionEnabled={demoData.name === "Wraparound mug"}
                        >
                          {({ hoveringElement, interactionEventEmitter }) => (
                            <>
                              <div style={{ cursor: loadingModel ? "wait" : "auto" }}>
                                <div className={CSS.DesignContainer}>
                                  <div className={CSS.Design} style={{ cursor: hoveringElement ? "pointer" : "", opacity: loadingModel ? 0 : 1 }}>
                                    <VortexPreview />
                                  </div>
                                  <Loading loading={loadingModel || updatingCanvas} />
                                </div>
                                {!loadingModel && <VortexPreviewActions />}
                              </div>
                              <div className={CSS.Form} style={{ opacity: loadingModel ? 0 : 1 }}>
                                <CimDocEditor
                                  moveIntoViewEnabled={demoData.name === "Wraparound mug"}
                                  interactionEventEmitter={interactionEventEmitter}
                                  cimDoc={cimDoc}
                                  onSetCimDoc={onSetCimDoc}
                                />
                              </div>
                            </>
                          )}
                        </InteractionStateHandler>
                      )}
                    </VortexContainer>
                  </div>
                )}
              </FusionVortex>
            )}
          </CimDocStateHandler>
        )}
      </DemoPicker>
    </>
  );
}
