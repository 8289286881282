import { VortexActions } from "@rendering/vortex-react";
import CSS from "./VortexPreviewActions.module.css";

export function VortexPreviewActions() {
  return (
    <ul className={CSS.ActionsList}>
      <VortexActions animations cameraViews>
        {({ actions, actionRunning }) =>
          actions.map((action) => (
            <li key={action.id} className={CSS.ActionListItem}>
              <button className={CSS.ActionButton} onClick={() => action.onAnimate({ scaleToFitGeometry: true, centerGeometry: true })}>
                {action.name}
              </button>
            </li>
          ))
        }
      </VortexActions>
    </ul>
  );
}
