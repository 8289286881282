import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const demoEllipse: CimpressDocument = {
  documentId: "demoEllipseDocument",
  version: "2",
  deleted: false,
  document: {
    panels: [
      {
        id: "surface1",
        name: "Item",
        width: "65.76270911881721mm",
        height: "36.115625099999995mm",
        shapes: [
          {
            id: "shape1",
            type: "ellipse",
            stroke: { color: "rgb(#10069F)", thickness: "0.683pt" },
            zIndex: 0,
            position: {
              x: "0mm",
              y: "0mm",
              width: "65.76270911881721mm",
              height: "36.115625099999995mm",
            },
            color: "rgb(#7CCC6C)",
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
