import { LayoutElement } from "../../models/Layout";
import { GetAnimationFunction, Layout } from "../Models";
import { Easing, ItemAnimatable, ItemAnimation, TransformAnimationOperation } from "@mcp-artwork/cimdoc-types-v2/dist/Animating";
import { Matrix } from "../../utils/math/matrix";
import { BoundingBox, getTransformedRotatedBoundingBox } from "../../utils/boundingBox";
import { Easings } from "./Easings";
import { parseMM } from "../../utils/unitHelper";
import { Point } from "../../utils/math/geometry";
import { parseMS } from "../../utils/timeUnitHelper";

// TODO: It would be useful if the bounding box could be adjusted to support images showing the best resolution
// Maybe there will be other issues?

function applyToRange(easing: Easing, timePercent: number, startValue: number, endValue: number): number {
  const percent = Easings[easing](timePercent);
  return startValue + percent * (endValue - startValue);
}

function applyMatrixOp(matrix: Matrix, center: Point, timePercent: number, op: TransformAnimationOperation): { matrix: Matrix; center: Point } {
  const easing = op.easing;
  switch (op.type) {
    case "translateX": {
      const value = applyToRange(easing, timePercent, parseMM(op.startValue), parseMM(op.endValue));
      return { matrix: Matrix.multiply(matrix, Matrix.translate(value, 0)), center: { x: center.x + value, y: center.y } };
    }
    case "translateY": {
      const value = applyToRange(easing, timePercent, parseMM(op.startValue), parseMM(op.endValue));
      return { matrix: Matrix.multiply(matrix, Matrix.translate(0, value)), center: { x: center.x, y: center.y + value } };
    }
    case "rotate": {
      const value = applyToRange(easing, timePercent, op.startValue, op.endValue);
      return { matrix: Matrix.multiply(matrix, Matrix.rotateAboutPoint((value * Math.PI) / 180, center.x, center.y)), center };
    }
    case "scaleX": {
      const value = applyToRange(easing, timePercent, op.startValue, op.endValue);
      return { matrix: Matrix.multiply(matrix, Matrix.scaleAboutPoint(value, 1, center)), center };
    }
    case "scaleY": {
      const value = applyToRange(easing, timePercent, op.startValue, op.endValue);
      return { matrix: Matrix.multiply(matrix, Matrix.scaleAboutPoint(1, value, center)), center };
    }
    case "scale": {
      const value = applyToRange(easing, timePercent, op.startValue, op.endValue);
      return { matrix: Matrix.multiply(matrix, Matrix.scaleAboutPoint(value, value, center)), center };
    }
  }
}

function applyAnimation(timeInMs: number, layout: Layout, layoutElement: LayoutElement, animation: ItemAnimation): Layout {
  const offsetInMs = timeInMs - parseMS(animation.startTime ?? "0ms");
  let sequenceItem = animation.sequence?.find(
    (i) => offsetInMs >= parseMS(i.offsetToStartTime ?? "0ms") && offsetInMs <= parseMS(i.offsetToStartTime ?? "0ms") + parseMS(i.duration),
  );
  if (!sequenceItem) {
    sequenceItem = animation.sequence?.[0];
    if (sequenceItem && offsetInMs >= parseMS(sequenceItem.offsetToStartTime ?? "0ms")) {
      sequenceItem = animation.sequence[animation.sequence.length - 1];
    }
  }
  if (sequenceItem) {
    const timePercent = Math.max(Math.min((offsetInMs - parseMS(sequenceItem.offsetToStartTime ?? "0ms")) / parseMS(sequenceItem.duration), 1), 0);
    const opacityMultiplier = sequenceItem.operations.reduce(
      (opacity, op) => (op.type === "opacity" ? opacity * applyToRange(op.easing, timePercent, op.startValue, op.endValue) : opacity),
      1,
    );

    const transformedPreviewBox = getTransformedRotatedBoundingBox(layoutElement.measurementData.previewBox);
    const { matrix } = sequenceItem.operations.reduce(
      ({ matrix, center }, op) => (op.type !== "opacity" ? applyMatrixOp(matrix, center, timePercent, op) : { matrix, center }),
      {
        matrix: Matrix.identity(),
        center: {
          x: transformedPreviewBox.left + transformedPreviewBox.width / 2,
          y: transformedPreviewBox.top + transformedPreviewBox.height / 2,
        },
      },
    );

    return {
      type: "group",
      contents: [{ ...layoutElement, renderingOperation: layout }],
      opacityMultiplier: opacityMultiplier,
      transform: matrix,
    };
  }
  return layout;
}

export function generateAnimationFunction(item: ItemAnimatable, parentBounds: BoundingBox, layoutElement: LayoutElement): GetAnimationFunction | undefined {
  const animations = item.animations;
  if (animations?.length) {
    return (timeInMs: number) => {
      return animations.reduce((prev, curr) => applyAnimation(timeInMs, prev, layoutElement, curr), layoutElement.renderingOperation);
    };
  }
  return undefined;
}
