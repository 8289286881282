// @ts-nocheck
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const subpanelTransformedItemsDocument: CimpressDocument = {
  version: "2",
  deleted: false,
  document: {
    panels: [
      {
        id: "e8e3e476-afcd-4536-8bc5-5734a61cbff9",
        name: "front",
        width: "91.898611mm",
        height: "53.798611mm",
        subpanels: [
          {
            zIndex: 0,
            position: {
              x: "0mm",
              y: "5mm",
            },
            id: "e8e3e476-afcd-4536-8bc5-5734a61cbff8",
            shapes: [
              {
                type: "rectangle",
                stroke: {
                  color: "rgb(#101820)",
                  thickness: "0.683pt",
                  lineCap: "square",
                  lineJoin: "mitre",
                },
                zIndex: 4,
                position: {
                  x: "73.00566153955675mm",
                  y: "8.058511653314017mm",
                  width: "3.6966592420250777mm",
                  height: "3.4556260386200606mm",
                },
                id: "931b20d2-ed9a-4071-9c3a-ca3cf61273d9",
                color: "rgb(#9EA2A2)",
              },
              {
                type: "rectangle",
                stroke: {
                  color: "rgb(#101820)",
                  thickness: "0.683pt",
                  lineCap: "square",
                  lineJoin: "mitre",
                },
                zIndex: 5,
                position: {
                  x: "84mm",
                  y: "8.058511653314017mm",
                  width: "3.6966592420250777mm",
                  height: "3.4556260386200606mm",
                },
                rotationAngle: "45",
                id: "966685c3-1f00-413c-81ae-1c7d3f8b4bb0",
                color: "rgb(#9EA2A2)",
              },
              {
                type: "ellipse",
                stroke: {
                  color: "rgb(#FF5E00)",
                  thickness: "0.683pt",
                },
                zIndex: 3,
                position: {
                  x: "69.39016348848148mm",
                  y: "3.960947195428713mm",
                  width: "17.67658503951612mm",
                  height: "17.79710164121863mm",
                },
                id: "eb7e8d2c-a59a-4185-9098-5e387bc9e885",
                color: "rgb(#FEDD00)",
              },
              {
                type: "line",
                stroke: {
                  color: "rgb(#FF5E00)",
                  thickness: "0.75pt",
                  lineCap: "square",
                  lineJoin: "mitre",
                },
                zIndex: 6,
                rotationAngle: "10",
                id: "566bfd89-66b8-4ce8-9ddc-ca2da70bf6b8",
                start: {
                  x: "73.00566153955674mm",
                  y: "16.716029270518316mm",
                },
                end: {
                  x: "83.93331688373236mm",
                  y: "16.716029270518316mm",
                },
              },
            ],
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
