import { ListContent } from "@mcp-artwork/cimdoc-types-v2";
import { FontReference } from "@mcp-artwork/rtext";
import { parseMM } from "../../utils/unitHelper";
import { BASE_INDENT_MULTIPLIER, containsUrl, FontInformation, InterpretedTextContent, translateFontStyle } from "./RTextTranslation";

const ORDINAL_TYPE = {
  number: 0,
  letter: 1,
  romanNumeral: 2,
};

const ROMAN_MAP = [
  { "1000": "M" },
  { "900": "CM" },
  { "500": "D" },
  { "400": "CD" },
  { "100": "C" },
  { "90": "XC" },
  { "50": "L" },
  { "40": "XL" },
  { "10": "X" },
  { "9": "IX" },
  { "5": "V" },
  { "4": "IV" },
  { "1": "I" },
];

const BULLET_GLYPH = "\u2022";
const BULLET_FONT = "https://fonts.documents.cimpress.io/shared/bulletfont";

const WHITE_SPACE = "";
const WHITE_SPACE_MULTIPLIER = 0.3;
const FIXED_INDENT_MULTIPLIER = 1.2;

export function getOrdinalCharacter(indentDepth: number, listDepth: number, uppercase: boolean): string {
  // Determine the ordinal type from the depth of the current paragraph
  const ordinalType = (indentDepth - 1) % Object.entries(ORDINAL_TYPE).length;

  switch (ordinalType) {
    case ORDINAL_TYPE.number:
      return `${listDepth}.`;
    case ORDINAL_TYPE.letter:
      if (uppercase) {
        return `${String.fromCharCode(65 + ((listDepth % 26) - 1))}.`;
      } else {
        return `${String.fromCharCode(97 + ((listDepth % 26) - 1))}.`;
      }
    case ORDINAL_TYPE.romanNumeral:
      return `${getRomanNumeral(listDepth, uppercase)}.`;
  }

  throw Error("Unknown ordinal type");
}

export function getListOrdinal(listItem: ListContent, parentFont: FontInformation, ordinalsPerDepth: number[], depth: number): InterpretedTextContent[] {
  const font: FontInformation = createChildFontInformation(listItem, parentFont);

  if (listItem.listMarkerStyle === undefined) {
    throw Error("List contents must have a listMarkerStyle!");
  }

  let targetOrdinal: string;

  // Determine the ordinal character for numbered and bulleted list
  switch (listItem.listMarkerStyle) {
    case "unordered": {
      targetOrdinal = BULLET_GLYPH;
      break;
    }
    case "ordered": {
      // Increment the depth of the current sub-list
      ordinalsPerDepth[depth - 1]++;

      // TODO figure out uppercase / lowercase detection
      targetOrdinal = getOrdinalCharacter(depth, ordinalsPerDepth[depth - 1], false);
      break;
    }
    default:
      throw Error(`Unknown list marker style: ${listItem.listMarkerStyle}!`);
  }

  if (font.size === undefined) {
    throw Error("A size must be present in list elements!");
  }

  const fontSize: number = parseMM(font.size);
  const baseIndent = fontSize * BASE_INDENT_MULTIPLIER;

  // Resetting the depth counts for all sub-lists
  for (let i = depth; i < ordinalsPerDepth.length; i++) {
    ordinalsPerDepth[i] = 0;
  }

  // First content containing the ordinal
  const interpretedTextContexts: InterpretedTextContent[] = [
    {
      listOrdinal: true,
      isNewParagraphTextBlock: false,
      textField: {
        id: "list ordinal",
        content: targetOrdinal,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        fontFamily: listItem.listMarkerStyle === "unordered" ? BULLET_FONT : font.family!,
        fontStyle: listItem.listMarkerStyle === "unordered" ? "normal" : font.style ?? "normal",
        fontSize: font.size,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        color: font.color!,
        stroke: font.stroke,
      },
      textBlock: {
        content: targetOrdinal,
        fontSize: parseMM(font.size),
        fixed: {
          width: fontSize * FIXED_INDENT_MULTIPLIER + baseIndent * (depth - 1),
          textAlignment: "trailing",
        },
        paragraphCharacteristics: {
          indent: baseIndent + baseIndent * (depth - 1),
          paragraphIndent: -baseIndent - baseIndent * (depth - 1),
        },
        fontReferences: [buildOrdinalFontReference(listItem.listMarkerStyle, font)],
        whitespaceStripping: "none",
      },
    },
  ];

  // Add white space between ordinal and content
  interpretedTextContexts.push({
    listOrdinal: true,
    isNewParagraphTextBlock: false,
    textField: {
      id: "list whitespace",
      content: WHITE_SPACE,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      fontFamily: listItem.listMarkerStyle === "unordered" ? BULLET_FONT : font.family!,
      fontStyle: "normal",
      fontSize: font.size,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      color: font.color!,
    },
    textBlock: {
      content: WHITE_SPACE,
      fontSize: parseMM(font.size),
      fixed: {
        width: fontSize * WHITE_SPACE_MULTIPLIER,
        textAlignment: "leading",
      },
      fontReferences: [buildOrdinalFontReference(listItem.listMarkerStyle, font)],
      whitespaceStripping: "none",
    },
  });

  return interpretedTextContexts;
}

export function createChildFontInformation(listItem: ListContent, parentFont: FontInformation): FontInformation {
  return {
    color: listItem.color ?? parentFont.color,
    decorations: listItem.decorations ?? parentFont.decorations,
    family: listItem.fontFamily ?? parentFont.family,
    size: listItem.fontSize ?? parentFont.size,
    stroke: listItem.stroke ?? parentFont.stroke,
    style: listItem.fontStyle ?? parentFont.style,
  };
}

function buildOrdinalFontReference(listMarkerStyle: string, parentFont: FontInformation): FontReference {
  const isUrl: boolean = containsUrl(parentFont.family);

  switch (listMarkerStyle) {
    case "unordered":
      return {
        url: BULLET_FONT,
      };
    case "ordered":
      return {
        url: isUrl ? parentFont.family : undefined,
        fontFamily: !isUrl ? parentFont.family : undefined,
        fontStyle: !isUrl ? translateFontStyle(parentFont.style) : undefined,
      };
    default:
      throw Error(`Unknown list marker style: ${listMarkerStyle}!`);
  }
}

function getRomanNumeral(listDepth: number, uppercase: boolean): string {
  let result = "";

  while (listDepth > 0) {
    for (const numeral of ROMAN_MAP) {
      const numeralKey = Object.keys(numeral)[0];
      const numeralValue = parseInt(numeralKey);

      while (listDepth >= numeralValue) {
        result += numeral[numeralKey];
        listDepth -= numeralValue;
      }
    }
  }

  return uppercase ? result : result.toLocaleLowerCase();
}
