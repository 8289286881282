// @ts-nocheck
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const calendarDocument: CimpressDocument = {
  version: "2.0",
  document: {
    surfaces: [
      {
        name: "front",
        width: "50mm",
        height: "45mm",
        itemReferences: [
          {
            id: "calendar",
            url: "http://rendering.documents.cimpress.io/CalendarGrid",
            position: {
              x: "0mm",
              y: "0mm",
              width: "50mm",
              height: "45mm",
            },
            fontRepositoryUrl: "http://fonts.pdt.vpsvc.com/",
            data: {
              type: "wall",
              year: 2018,
              month: 11,
              cultureName: "en-US",
              orientation: 0,
              gridColor: "rgb(#202020)",
              gridThickness: "0.1651mm",
              useCapitalization: true,
              headerFontFace: "GoudyOlSt BT",
              headerFontSize: "3.92502777777778mm",
              headerColor: "rgb(#202020)",
              headerAllUppercase: false,
              dateFontFace: "GoudyOlSt BT",
              dateColor: "rgb(#202020)",
              dateFontSize: "6.92502777777778mm",
              dayOfWeekFontFace: "GoudyOlSt BT",
              dayOfWeekFontSize: "1.2mm",
              dayOfWeekColor: "rgb(#202020)",
              eventFontFace: "Arial",
              eventFontSize: "1.0mm",
              style: 2,
              events: [
                {
                  day: 11,
                  name: "Veterans Day",
                  wrap: true,
                  hAlign: "1",
                  vAlign: "1",
                },
                {
                  day: 22,
                  name: "Thanksgiving",
                  wrap: true,
                  hAlign: "1",
                  vAlign: "1",
                },
              ],
            },
          },
        ],
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/179f4b28-9005-4814-a527-7e32c6b27ec8/published",
};
