import { MatrixTransformV2, ScaleTransformV2, SkewTransformV2 } from "@mcp-artwork/cimdoc-types-v2";
import { Updater } from "use-immer";
import CSS from "./TransformControls.module.css";

type TransformControlsProps = {
  transform: MatrixTransformV2;
  setTransform: Updater<MatrixTransformV2>;
  rotationAngle: string;
  setRotationAngle: Updater<string>;
  skew: SkewTransformV2;
  setSkew: Updater<SkewTransformV2>;
  scale: ScaleTransformV2;
  setScale: Updater<ScaleTransformV2>;
};

export function TransformControls({ transform, setTransform, rotationAngle, setRotationAngle, skew, setSkew, scale, setScale }: TransformControlsProps) {
  return (
    <>
      <div>
        <label>
          Scale x
          <input
            className={CSS.Input}
            type="number"
            min={0.1}
            max={2}
            step={0.1}
            value={scale.x}
            onInput={(e) =>
              setScale((draft) => {
                draft.x = parseFloat(e.currentTarget.value);
              })
            }
          />
        </label>
        <label>
          y
          <input
            className={CSS.Input}
            type="number"
            min={0.1}
            max={2}
            step={0.1}
            value={scale.y}
            onInput={(e) =>
              setScale((draft) => {
                draft.y = parseFloat(e.currentTarget.value);
              })
            }
          />
        </label>
      </div>
      <div>
        <label>
          Skew x
          <input
            className={CSS.Input}
            type="number"
            min={-100}
            max={100}
            step={1}
            value={skew.x}
            onChange={(e) =>
              setSkew((draft) => {
                draft.x = parseFloat(e.currentTarget.value);
              })
            }
          />
        </label>
        <label>
          y
          <input
            className={CSS.Input}
            type="number"
            min={-100}
            max={100}
            step={1}
            value={skew.y}
            onChange={(e) =>
              setSkew((draft) => {
                draft.y = parseFloat(e.currentTarget.value);
              })
            }
          />
        </label>
      </div>
      <div>
        <label>
          Rotation
          <input
            className={CSS.Input}
            type="number"
            min={-360}
            max={360}
            step={10}
            value={rotationAngle}
            onChange={(e) => setRotationAngle(e.currentTarget.value)}
          />
        </label>
      </div>
      <div>
        <label>
          Transform {/* Horizontal scaling */}
          a
          <input
            className={CSS.Input}
            type="number"
            min={0.1}
            max={2}
            step={0.1}
            value={transform.a}
            onChange={(e) =>
              setTransform((draft) => {
                draft.a = parseFloat(e.currentTarget.value);
              })
            }
          />
          {/* Vertical skewing */}
          b
          <input
            className={CSS.Input}
            type="number"
            min={0.1}
            max={2}
            step={0.1}
            value={transform.b}
            onChange={(e) =>
              setTransform((draft) => {
                draft.b = parseFloat(e.currentTarget.value);
              })
            }
          />
          {/* Horizontal skewing */}
          c
          <input
            className={CSS.Input}
            type="number"
            min={-2}
            max={2}
            step={0.1}
            value={transform.c}
            onChange={(e) =>
              setTransform((draft) => {
                draft.c = parseFloat(e.currentTarget.value);
              })
            }
          />
          {/* Veritcal skewing */}
          d
          <input
            className={CSS.Input}
            type="number"
            min={-2}
            max={2}
            step={0.1}
            value={transform.d}
            onChange={(e) =>
              setTransform((draft) => {
                draft.d = parseFloat(e.currentTarget.value);
              })
            }
          />
          {/* Horizontal translation */}
          x
          <input
            className={CSS.Input}
            type="number"
            value={parseFloat(transform.x)}
            step="1"
            onChange={(e) =>
              setTransform((draft) => {
                draft.x = `${parseFloat(e.currentTarget.value)}mm`;
              })
            }
          />
          mm
          {/* Veritcal translation */} y
          <input
            className={CSS.Input}
            type="number"
            value={parseFloat(transform.y)}
            step="1"
            onChange={(e) =>
              setTransform((draft) => {
                draft.y = `${parseFloat(e.currentTarget.value)}mm`;
              })
            }
          />
          mm
        </label>
      </div>
    </>
  );
}
