import { useEffect, useMemo, useRef, useState } from "react";
import { parseColor, preFetch } from "@rendering/plasma";
import classNames from "classnames";
import { useCloseOnOutside } from "../../hooks/useCloseOnOutside";
import CSS from "./CMYKPickerInput.module.css";

type CMYKPickerInputProps = {
  color: string;
  onColorChange: (value: string) => void;
  className?: string;
};

const getCMYK = (color: string): [number, number, number, number] => {
  const result = color.match(/[0-9]{1,3}/g);
  if (result?.length !== 4) {
    return [0, 0, 0, 0];
  }
  return [parseInt(result[0]), parseInt(result[1]), parseInt(result[2]), parseInt(result[3])];
};

export function CMYKPickerInput({ color, onColorChange, className }: CMYKPickerInputProps) {
  const [ready, setReady] = useState<boolean>(() => {
    try {
      parseColor("cmyk(10,10,10,10)", undefined);
      return true;
    } catch (e) {
      return false;
    }
  });
  const rgb = useMemo<string>(() => {
    if (ready) {
      return parseColor(color, undefined).display!;
    }
    return "";
  }, [ready, color]);
  const [open, setOpen] = useState<boolean>();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ready) {
      preFetch({ skipRText: true }).then(() => {
        setReady(true);
      });
    }
  }, []);

  useCloseOnOutside({ callback: () => setOpen(false), getElement: () => containerRef.current });

  if (!ready) {
    return null;
  }

  const [c, m, y, k] = getCMYK(color);

  return (
    <div className={classNames(className, CSS.Container)} ref={containerRef}>
      <button className={CSS.Button} style={{ backgroundColor: rgb }} onClick={() => setOpen(!open)} />
      {open && (
        <div className={CSS.OpenContainer}>
          <label className={CSS.Label}>
            <span className={CSS.LabelValue} style={{ color: parseColor("cmyk(100,0,0,0)", undefined).display! }}>
              C
            </span>
            <input
              className={CSS.Range}
              min={0}
              max={100}
              step={1}
              value={c}
              type="range"
              style={{
                background: `linear-gradient(to right,  ${parseColor(`cmyk(0,${m},${y},${k})`, undefined).display!} 0%,${parseColor(
                  `cmyk(100,${m},${y},${k})`,
                  undefined,
                ).display!} 100%)`,
              }}
              onInput={(e) => {
                onColorChange(`cmyk(${e.currentTarget.value},${m},${y},${k})`);
              }}
            />
          </label>
          <label className={CSS.Label}>
            <span className={CSS.LabelValue} style={{ color: parseColor("cmyk(0,100,0,0)", undefined).display! }}>
              M
            </span>
            <input
              className={CSS.Range}
              min={0}
              max={100}
              step={1}
              value={m}
              type="range"
              style={{
                background: `linear-gradient(to right,  ${parseColor(`cmyk(${c},0,${y},${k})`, undefined).display!} 0%,${parseColor(
                  `cmyk(${c},100,${y},${k})`,
                  undefined,
                ).display!} 100%)`,
              }}
              onInput={(e) => {
                onColorChange(`cmyk(${c},${e.currentTarget.value},${y},${k})`);
              }}
            />
          </label>
          <label className={CSS.Label}>
            <span className={CSS.LabelValue} style={{ color: parseColor("cmyk(0,0,100,0)", undefined).display! }}>
              Y
            </span>
            <input
              className={CSS.Range}
              min={0}
              max={100}
              step={1}
              value={y}
              type="range"
              style={{
                background: `linear-gradient(to right,  ${parseColor(`cmyk(${c},${m},0,${k})`, undefined).display!} 0%,${parseColor(
                  `cmyk(${c},${m},100,${k})`,
                  undefined,
                ).display!} 100%)`,
              }}
              onInput={(e) => {
                onColorChange(`cmyk(${c},${m},${e.currentTarget.value},${k})`);
              }}
            />
          </label>
          <label className={CSS.Label}>
            <span className={CSS.LabelValue} style={{ color: parseColor("cmyk(0,0,0,100)", undefined).display! }}>
              K
            </span>
            <input
              className={CSS.Range}
              min={0}
              max={100}
              step={1}
              value={k}
              type="range"
              style={{
                background: `linear-gradient(to right,  ${parseColor(`cmyk(${c},${m},${y},0)`, undefined).display!} 0%,${parseColor(
                  `cmyk(${c},${m},${y},100)`,
                  undefined,
                ).display!} 100%)`,
              }}
              onInput={(e) => {
                onColorChange(`cmyk(${c},${m},${y},${e.currentTarget.value})`);
              }}
            />
          </label>
        </div>
      )}
    </div>
  );
}
