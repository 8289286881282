import { useRef, useState } from "react";
import { Image } from "@mcp-artwork/cimdoc-types-v2";
import { CimDocUpdater, ImageProgressUpdater, SherbertFilesUpdater } from "../../models";
import { findImage } from "../../utils";
import { getAuth } from "../../../auth";
import { ImageFileInput } from "../Inputs/ImageFileInput";
import { useCloseOnOutside } from "../../hooks/useCloseOnOutside";
import CSS from "./Image.module.css";
import { SherbertFile, createSherbertAsset, presignSherbertAsset } from "../../../sherbert/api";

type ImageEditorProps = {
  image: Image;
  setCimDoc: CimDocUpdater;
  setImageUploadingProgress: ImageProgressUpdater;
  fetchSherbertFiles: () => void;
  sherbertFiles: SherbertFile[];
  setSherbertFiles: SherbertFilesUpdater;
};

export function ImageEditor({ image, setCimDoc, setImageUploadingProgress, fetchSherbertFiles, sherbertFiles, setSherbertFiles }: ImageEditorProps) {
  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useCloseOnOutside({ getElement: () => containerRef.current, callback: () => setOpen(false) });

  const onFileChange = async (file: File) => {
    setOpen(false);

    const isImage = file.type.startsWith("image/");

    setImageUploadingProgress((draft) => {
      draft[image.id] = { progress: 0, localImage: isImage ? URL.createObjectURL(file) : undefined };
    });

    const uploadedFile = await createSherbertAsset({
      accessToken: getAuth().getAccessToken(),
      file,
      onProgress: (percentComplete) => {
        setImageUploadingProgress((draft) => {
          draft[image.id] = { progress: percentComplete, localImage: draft[image.id]?.localImage };
        });
      },
    });

    const id = uploadedFile.id;
    const signed = await presignSherbertAsset({ id, accessToken: getAuth().getAccessToken() });
    const signature = signed.signature;
    const previewUrl = `https://api.sherbert.cimpress.io/v2/assets/${id}/webPreview?signature=${signature}`;
    const printUrl = `https://api.sherbert.cimpress.io/v2/assets/${id}/print?signature=${signature}`;

    setSherbertFiles((draft) => {
      draft.unshift({ id: uploadedFile.id, signature });
    });

    setCimDoc((draft) => {
      if (draft) {
        const imageDraft = findImage(draft, image.id);
        if (imageDraft) {
          imageDraft.previewUrl = previewUrl;
          imageDraft.printUrl = printUrl;
        }
      }
    });

    // Uncomment to show uploaded image
    // setImageUploadingProgress((draft) => {
    //   delete draft[image.id];
    // });
  };

  const onToggleOpen = () => {
    if (!open) {
      fetchSherbertFiles();
    }
    setOpen(!open);
  };

  const onSelectImage = (file: SherbertFile) => {
    setCimDoc((draft) => {
      if (draft) {
        const imageDraft = findImage(draft, image.id);
        if (imageDraft) {
          const previewUrl = `https://api.sherbert.cimpress.io/v2/assets/${file.id}/webPreview?signature=${file.signature}`;
          const printUrl = `https://api.sherbert.cimpress.io/v2/assets/${file.id}/print?signature=${file.signature}`;
          imageDraft.previewUrl = previewUrl;
          imageDraft.printUrl = printUrl;
        }
      }
    });
    setOpen(false);
  };

  return (
    <div className={CSS.Container} ref={containerRef}>
      <button onClick={onToggleOpen} className={CSS.Button}>
        Change image
      </button>
      {open && (
        <div className={CSS.Open}>
          <ImageFileInput onChange={onFileChange} />
          <pre>Files are stored for 1 day.</pre>
          {sherbertFiles.map((file) => {
            if (file.signature) {
              return (
                <img
                  onClick={() => onSelectImage(file)}
                  className={CSS.Image}
                  key={file.id}
                  src={`https://api.sherbert.cimpress.io/v2/assets/${file.id}/thumb?signature=${file.signature}`}
                />
              );
            }
            return <div className={CSS.ImagePlaceholder} key={file.id} />;
          })}
        </div>
      )}
    </div>
  );
}
