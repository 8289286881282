import { ColorPalette, Shape } from "@mcp-artwork/cimdoc-types-v2";
import { curveLayout } from "./Curve";
import { ellipseLayout } from "./Ellipse";
import { lineLayout } from "./Line";
import { rectangleLayout } from "./Rectangle";
import { LayoutElement, PreviewType } from "../../models/Layout";
import { DecorationTechnology } from "../helpers/Technology";
import { BoundingBox } from "../../utils/boundingBox";
import CimDocDefinitionTreeNode from "../../utils/CimDocDefinitionTreeNode";

export async function shapeLayout({
  shape,
  parentBounds,
  options,
  previewType,
}: {
  shape: Shape;
  decoTech: DecorationTechnology;
  parentBounds: BoundingBox;
  options: { definitionTreeNode?: CimDocDefinitionTreeNode; fontRepositoryUrl?: string; colorPalette: ColorPalette | undefined };
  previewType: PreviewType;
}): Promise<LayoutElement> {
  switch (shape.type) {
    case "rectangle":
      return await rectangleLayout({ rectangle: shape, parentBounds, options, previewType });
    case "ellipse":
      return await ellipseLayout({ ellipse: shape, parentBounds, options, previewType });
    case "line":
      return await lineLayout({ line: shape, parentBounds, options, previewType });
    case "curve":
      return await curveLayout({ curve: shape, parentBounds, options, previewType });
  }
}
