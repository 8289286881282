import { CimpressDocument, DesignDocumentWithPanels, DesignDocumentWithSurfaces } from "@mcp-artwork/cimdoc-types-v2";
import { useState, CSSProperties } from "react";
import { ItemInput } from "./ItemInput";
import { ItemOutput } from "./ItemOutput";

type BlockProps = {
  title: string;
  initialDocument: CimpressDocument;
  pixelSize: string;
};

const findIds = (document?: CimpressDocument): string[] => {
  return [
    ...((document?.document as DesignDocumentWithSurfaces).surfaces?.map((surface) => [
      ...(surface.images ?? []).map((image) => image.id),
      ...(surface.shapes ?? []).map((shape) => shape.id),
      ...(surface.textAreas ?? []).map((textArea) => textArea.id),
      ...(surface.simpleTextFields ?? []).map((simpleTextFields) => simpleTextFields.id),
      ...(surface.itemReferences ?? []).map((itemReference) => itemReference.id),
      ...(surface.subpanels ?? []).map((subpanel) => subpanel.id),
    ]) ?? []),
    ...((document?.document as DesignDocumentWithPanels).panels?.map((panel) => [
      ...(panel.images ?? []).map((image) => image.id),
      ...(panel.shapes ?? []).map((shape) => shape.id),
      ...(panel.textAreas ?? []).map((textArea) => textArea.id),
      ...(panel.simpleTextFields ?? []).map((simpleTextFields) => simpleTextFields.id),
      ...(panel.itemReferences ?? []).map((itemReference) => itemReference.id),
      ...(panel.subpanels ?? []).map((subpanel) => subpanel.id),
    ]) ?? []),
  ].flat();
};

export function ItemBlock({ title, initialDocument, pixelSize }: BlockProps) {
  const [document, setDocument] = useState<CimpressDocument>(initialDocument);
  const ids = findIds(document);
  const [selectedId, setSelectedId] = useState(ids[0]);

  const onChangeDocument = (document: CimpressDocument) => {
    setDocument(document);
    setSelectedId(findIds(document)[0]);
  };

  const container: CSSProperties = {
    display: "flex",
    alignItems: "flex-start",
  };

  const input: CSSProperties = {
    flex: 1,
    maxWidth: "700px",
    marginRight: "2rem",
    marginBottom: "2rem",
    minWidth: "500px",
  };

  const output: CSSProperties = {
    flex: 1,
  };

  return (
    <>
      <h2>{title}</h2>
      <div style={container}>
        <div style={input}>
          <ItemInput document={document} onChangeSelectedId={setSelectedId} options={ids} selectedId={selectedId} onDocumentChange={onChangeDocument} />
        </div>
        <div style={output}>
          <ItemOutput document={document} pixelSize={pixelSize} selectedId={selectedId} />
        </div>
      </div>
    </>
  );
}
