export interface Point {
  x: number;
  y: number;
}

export class Line {
  private static EPSILON = 0.001;
  public start: Point;
  public end: Point;

  constructor(start: Point, end: Point) {
    this.start = start;
    this.end = end;
  }

  public isVertical(): boolean {
    return Math.abs(this.start.x - this.end.x) <= Line.EPSILON;
  }

  public length(): number {
    return Math.sqrt(Math.pow(this.end.x - this.start.x, 2) + Math.pow(this.end.y - this.start.y, 2));
  }

  public getPoint(t: number) {
    const differenceVector: Point = {
      x: this.end.x - this.start.x,
      y: this.end.y - this.start.y,
    };

    return {
      x: this.start.x + t * differenceVector.x,
      y: this.start.y + t * differenceVector.y,
    };
  }

  public truncate(amount: number, fromEnd: boolean): Line {
    const ratio = amount / this.length();

    if (ratio > 1) {
      throw new Error("Can't truncate this line");
    }

    if (fromEnd) {
      return new Line(this.start, this.getPoint(1 - ratio));
    }
    return new Line(this.getPoint(ratio), this.end);
  }
}

export function distance(pt0: Point, pt1: Point): number {
  return Math.sqrt((pt1.x - pt0.x) * (pt1.x - pt0.x) + (pt1.y - pt0.y) * (pt1.y - pt0.y));
}
