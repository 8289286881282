import { Stroke } from "@mcp-artwork/cimdoc-types-v2";
import { parseMM } from "../../utils/unitHelper";

export function translateLineJoin(stroke: Stroke): "mitre" | "round" | "bevel" {
  switch (stroke.lineJoin) {
    case "bevel":
      return "bevel";
    case "mitre":
      return "mitre";
    case "round":
      return "round";
    case undefined:
      return "round";
    default:
      throw Error(`Unknown stroke line join: ${stroke.lineJoin}`);
  }
}

export function translateLineCap(stroke: Stroke): "round" | "butt" | "square" {
  switch (stroke.lineCap) {
    case "round":
      return "round";
    case "butt":
      return "butt";
    case "square":
      return "square";
    case undefined:
      return "round";
    default:
      throw Error(`Unknown stroke line cap: ${stroke.lineCap}`);
  }
}

export function translateDashArray(stroke: Stroke): number[] {
  return stroke.dashPattern?.segments.map((dashSegment) => parseMM(dashSegment.length)) ?? [];
}
