import { useMemo } from "react";
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { Updater } from "use-immer";
import { selectFirstSurface } from "../../selectors/document";
import { PositionedItemPreview } from "../PositionedItemPreview";
import { CimDocUpdater } from "../models";
import { useSpaceCalculator } from "./useSpaceCalculator";
import { DesignPreviewsType } from "../Previews";
import CSS from "./DesignArea.module.css";

type DesignAreaProps = {
  cimDoc: CimpressDocument;
  setSelectedItemId: Updater<string | undefined>;
  setCimDoc: CimDocUpdater;
  selectedItemId: string | undefined;
  imageUploadingProgress: { [key: string]: { progress: number; localImage?: string } };
  designPreviewsType: DesignPreviewsType;
};

export function DesignArea({ cimDoc, setSelectedItemId, setCimDoc, selectedItemId, imageUploadingProgress, designPreviewsType }: DesignAreaProps) {
  const surface = selectFirstSurface(cimDoc);
  const { width, height, adjustX } = useSpaceCalculator({ surface, designPreviewsType });
  const documentWidth = useMemo<{ width: number }>(() => ({ width }), [width]);

  return (
    <div className={CSS.DesignArea} style={{ width, height, transform: `translate(calc(-50% - ${adjustX}px), -50%)` }}>
      {[...(surface.textAreas ?? []), ...(surface.images ?? []), ...(surface.itemReferences ?? []), ...(surface.shapes ?? [])].map((item, index) => (
        <PositionedItemPreview
          onClick={setSelectedItemId}
          key={index}
          cimDoc={cimDoc}
          documentWidth={documentWidth}
          item={item}
          setCimDoc={setCimDoc}
          selected={selectedItemId === item.id}
          imageInfo={imageUploadingProgress[item.id]}
        >
          {imageUploadingProgress[item.id] && imageUploadingProgress[item.id]?.progress !== 100 && (
            <div className={CSS.ImageUploading}>
              <div className={CSS.ImageUploadingProgress} style={{ width: `${imageUploadingProgress[item.id]?.progress}%` }} />
              {imageUploadingProgress[item.id]?.progress}
            </div>
          )}
        </PositionedItemPreview>
      ))}
    </div>
  );
}
