import { CimDocDefinitions, DesignSurface, FilterSpecification, Paint } from "@mcp-artwork/cimdoc-types-v2";

/**
 * Hierarchical CimDoc definitions abstracted into a tree node. Each node has a pointer to its parent definition (null if root)
 */
export default class CimDocDefinitionTreeNode {
  /**
   * Guid to identify this node
   */
  public readonly id: string;
  /**
   * CimDoc definition. May be undefined.
   */
  public definition: CimDocDefinitions | undefined;
  /**
   * Pointer to its parent definition node. If this is null, then this node is the root.
   */
  public parent: CimDocDefinitionTreeNode | undefined;

  constructor(definition: CimDocDefinitions | undefined, parent: CimDocDefinitionTreeNode | undefined) {
    this.id = `${Math.random()}`;
    this.definition = definition;
    this.parent = parent;
  }

  /**
   * Creates and returns a CimDoc definition tree node as a child to this node.
   * @param definition
   * @returns the new child node, with this node as its parent
   */
  createChildNode(definition: CimDocDefinitions): CimDocDefinitionTreeNode {
    return new CimDocDefinitionTreeNode(definition, this);
  }

  getFilterRecursive(filterName: string): FilterSpecification | undefined {
    if (this.definition?.filters?.[filterName] !== undefined) {
      return this.definition.filters[filterName];
    }
    if (this.parent !== undefined) {
      return this.parent.getFilterRecursive(filterName);
    }
    return undefined;
  }

  getPaintRecursive(paintName: string): Paint | undefined {
    if (this.definition?.paints?.[paintName] !== undefined) {
      return this.definition.paints[paintName];
    }
    if (this.parent !== undefined) {
      return this.parent.getPaintRecursive(paintName);
    }
    return undefined;
  }

  getPanelRecursive(panelName: string): DesignSurface | undefined {
    if (this.definition?.panels?.[panelName] !== undefined) {
      return this.definition.panels[panelName];
    }
    if (this.parent !== undefined) {
      return this.parent.getPanelRecursive(panelName);
    }
    return undefined;
  }
}
