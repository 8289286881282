import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { hasCimDocElementOnPosition, selectCimDocElementsByPosition } from "@rendering/fusion-react";
import { LayoutResult } from "@rendering/plasma";
import { getTransformedRotatedBoundingBox } from "@rendering/plasma";
import { VortexContext, VortexCursorEvent } from "@rendering/vortex-react";
import { ReactNode, useEffect, useMemo, useState } from "react";

function createFocusEventEmitter(): InteractionEventEmitter {
  const listeners: Record<InteractionEvent, Listener[]> = {
    click: [],
    focus: [],
  };

  return {
    addEventListener(type: InteractionEvent, fn: Listener) {
      listeners[type].push(fn);
    },
    removeEventListener(type: InteractionEvent, fn: Listener) {
      listeners[type] = listeners[type].filter((f) => f !== fn);
    },
    triggerEvent(type: InteractionEvent, id: string) {
      listeners[type].forEach((fn) => fn(id));
    },
  };
}

type Listener = (id: string) => void;
type InteractionEvent = "click" | "focus";

export type InteractionEventEmitter = {
  addEventListener: (type: InteractionEvent, fn: Listener) => void;
  removeEventListener: (type: InteractionEvent, fn: Listener) => void;
  triggerEvent: (type: InteractionEvent, id: string) => void;
};

type InteractionStateHandlerProps = {
  children: (args: { hoveringElement: boolean; interactionEventEmitter: InteractionEventEmitter }) => ReactNode;
  layoutResults?: LayoutResult[];
  preview?: VortexContext["preview"];
  cimDoc: CimpressDocument;
  focusInteractionEnabled: boolean;
};

export function InteractionStateHandler({ children, layoutResults, preview, cimDoc, focusInteractionEnabled }: InteractionStateHandlerProps) {
  const interactionEventEmitter = useMemo(() => createFocusEventEmitter(), []);
  const [hoveringElement, setHoveringElement] = useState<boolean>(false);

  // Tack clicking on element
  useEffect(() => {
    if (!preview || !layoutResults) {
      return;
    }

    const onClick = (e: VortexCursorEvent) => {
      const clickedElements = selectCimDocElementsByPosition({ cimDoc, layoutResults, position: e.position.surface });
      if (clickedElements.length > 0) {
        interactionEventEmitter.triggerEvent("click", clickedElements[0].id);
      }
    };

    preview.addEventListener("click", onClick);

    return () => {
      preview.removeEventListener("click", onClick);
    };
  }, [preview, layoutResults, cimDoc]);

  // Track hovering over element
  useEffect(() => {
    const onMouseMove = (e: VortexCursorEvent) => {
      if (layoutResults) {
        const hoveringOnElement = hasCimDocElementOnPosition({ cimDoc, layoutResults, position: e.position.surface });
        setHoveringElement(hoveringOnElement);
      }
    };

    const onMouseOut = () => setHoveringElement(false);

    preview?.addEventListener("mousemove", onMouseMove);
    preview?.addEventListener("mouseout", onMouseOut);

    return () => {
      preview?.removeEventListener("mousemove", onMouseMove);
      preview?.removeEventListener("mouseout", onMouseOut);
    };
  }, [preview, layoutResults, cimDoc]);

  // Position camera to focussed element
  useEffect(() => {
    if (!preview || !layoutResults || !focusInteractionEnabled) {
      return;
    }

    const onFocus = (focusId: string) => {
      const layoutElements = layoutResults?.map((layoutResult) => layoutResult.elements).flat();
      const layoutElement = layoutElements?.find((element) => element.id === focusId);
      if (layoutElement) {
        const transformedPreviewBox = getTransformedRotatedBoundingBox(layoutElement.measurementData.previewBox);
        const x = transformedPreviewBox.left + transformedPreviewBox.width / 2;
        const y = transformedPreviewBox.top + transformedPreviewBox.height / 2;
        preview.focusOnDocument({ page: 1, location: { x, y }, polarLock: true });
      }
    };

    interactionEventEmitter.addEventListener("focus", onFocus);
    interactionEventEmitter.addEventListener("click", onFocus);

    return () => {
      interactionEventEmitter.removeEventListener("focus", onFocus);
      interactionEventEmitter.removeEventListener("click", onFocus);
    };
  }, [preview, layoutResults, cimDoc, interactionEventEmitter, focusInteractionEnabled]);

  return <>{children({ interactionEventEmitter, hoveringElement })}</>;
}
