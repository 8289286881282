import { memo, useCallback, useContext, useMemo, useState } from "react";
import { CimpressDocument, Image } from "@mcp-artwork/cimdoc-types-v2";
import { Updater } from "use-immer";
import { produce } from "immer";
import { DebugOptions, FusionCanvas, ItemOrPanelSelector, OnPaintArgs, TextOptions, parseMM } from "@rendering/fusion-react";
import { CimDocUpdater, Item } from "../models";
import { GlobalOptionsContext } from "../../Context/GlobalOptionsContext";
import { findElement } from "../utils";
import classNames from "classnames";
import { ContextMenu } from "./ContextMenu";
import { DelayedLoading } from "../../Components/DelayedLoading";
import CSS from "./PositionedItemPreview.module.css";

type PositionedItemPreviewProps = {
  item: Item;
  documentWidth: { width: number };
  cimDoc: CimpressDocument;
  onClick: Updater<string | undefined>;
  setCimDoc: CimDocUpdater;
  selected: boolean;
  children: any;
  imageInfo: { progress: number; localImage?: string } | undefined;
};

const getInteractionsPosition = (fusionResults: OnPaintArgs | undefined, item: Item) => {
  if (!fusionResults) {
    return undefined;
  }

  const { boundingBox } = fusionResults[0]?.layoutResult.elements[0].measurementData;
  const { scalar } = fusionResults[0]?.paintResult;
  const width = parseMM((item as any).position.width) ?? boundingBox.width;
  const left = parseMM((item as any).position.x) ?? boundingBox.left;
  const top = parseMM((item as any).position.y) ?? boundingBox.top;

  return {
    top: top * scalar,
    left: left * scalar,
    height: boundingBox.height * scalar,
    width: width * scalar,
  };
};

const getCanvasPosition = (fusionResults: OnPaintArgs | undefined) => {
  if (!fusionResults) {
    return undefined;
  }

  const { boundingBox } = fusionResults[0]?.layoutResult.elements[0].measurementData;
  const { scalar } = fusionResults[0]?.paintResult;
  return {
    top: boundingBox.top * scalar,
    left: boundingBox.left * scalar,
    height: boundingBox.height * scalar,
    width: boundingBox.width * scalar,
  };
};

export const PositionedItemPreview = memo(
  ({ cimDoc, item, documentWidth, onClick, setCimDoc, selected, children, imageInfo }: PositionedItemPreviewProps) => {
    const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
    const [fusionResults, setFusionResults] = useState<OnPaintArgs>();
    const [loading, setLoading] = useState<boolean>(false);
    const { options } = useContext(GlobalOptionsContext);
    const canvasPosition = getCanvasPosition(fusionResults);
    const interactionPosition = getInteractionsPosition(fusionResults, item);

    const cimDocForFusion = useMemo<CimpressDocument>(() => {
      return produce(cimDoc, (draft) => {
        const draftItem = findElement(draft, item.id);
        draftItem.rotationAngle = "0";
        if (imageInfo?.localImage) {
          (draftItem as Image).previewUrl = imageInfo.localImage;
        }
      });
    }, [cimDoc, imageInfo]);

    const selector = useMemo<ItemOrPanelSelector>(
      () => ({
        type: "item",
        id: item.id,
      }),
      [item.id],
    );

    const textOptions = useMemo<TextOptions>(
      () => ({
        rtextEnabled: options.textOptions.rtextEnabled,
      }),
      [options.textOptions],
    );

    const debugOptions = useMemo<DebugOptions>(
      () => ({
        log: options.log,
        timers: options.timers,
      }),
      [options.log, options.timers],
    );

    const onError = useCallback((e: any) => {
      console.error(e);
    }, []);

    const onClickInner = useCallback(() => {
      onClick(item.id);
    }, [item]);

    const onPaint = useCallback((args: OnPaintArgs) => {
      setFusionResults(args);
    }, []);

    const onLoading = useCallback((loading: boolean) => {
      setLoading(loading);
    }, []);

    // Handle the mousedown event
    // that's triggered when user drags the element
    const mouseDownHandler = function (e: React.MouseEvent<HTMLDivElement>) {
      if (!fusionResults) {
        return;
      }

      let didMove = false;
      let startX = e.clientX;
      let startY = e.clientY;
      const div = e.currentTarget;

      const mouseMoveHandler = (e: MouseEvent) => {
        didMove = true;
        const deltaX = startX - e.clientX;
        const deltaY = startY - e.clientY;
        startX = e.clientX;
        startY = e.clientY;

        div.style.top = `${parseFloat(div.style.top) - deltaY}px`;
        div.style.left = `${parseFloat(div.style.left) - deltaX}px`;
      };

      // Start mouseMove after 200ms to prevent moving on click
      const mousemoveTimeout = setTimeout(() => {
        document.addEventListener("mousemove", mouseMoveHandler);
        div.style.cursor = "move";
      }, 200);

      const mouseUpHandler = function () {
        clearTimeout(mousemoveTimeout);

        // Remove the handlers of `mousemove` and `mouseup`
        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);
        div.style.cursor = "";

        if (!didMove) {
          return;
        }

        // Sync new position to cimDoc
        const top = parseFloat(div.style.top);
        const left = parseFloat(div.style.left);
        const deltaX = left - interactionPosition!.left;
        const deltaXinMM = deltaX / fusionResults[0]?.paintResult.scalar;
        const deltaY = top - interactionPosition!.top;
        const deltaYinMM = deltaY / fusionResults[0]?.paintResult.scalar;
        setCimDoc((draft) => {
          if (draft) {
            const item = findElement(draft, selector.id);
            const oldPositionX = parseMM((item as any).position.x);
            (item as any).position.x = `${oldPositionX + deltaXinMM}mm`;
            const oldPositionY = parseMM((item as any).position.y);
            (item as any).position.y = `${oldPositionY + deltaYinMM}mm`;
          }
        });
      };

      document.addEventListener("mouseup", mouseUpHandler);
    };

    const onContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      setShowContextMenu(true);
    };

    return (
      <>
        {showContextMenu && (
          <ContextMenu
            style={{
              top: `${interactionPosition?.top}px`,
              left: `${interactionPosition?.left}px`,
            }}
            onClose={() => setShowContextMenu(false)}
            fusionResults={fusionResults}
            item={item}
            setCimDoc={setCimDoc}
          />
        )}
        <div
          className={classNames(CSS.Interaction, { [CSS.Selected]: selected })}
          onContextMenu={onContextMenu}
          onMouseDown={mouseDownHandler}
          onClick={onClickInner}
          style={{
            position: "absolute",
            top: `${interactionPosition?.top}px`,
            left: `${interactionPosition?.left}px`,
            zIndex: item.zIndex,
            width: interactionPosition?.width,
            height: interactionPosition?.height,
            transform: `rotate(${item.rotationAngle}deg)`,
          }}
        >
          <DelayedLoading loading={loading} />
          {children}
          <FusionCanvas
            className={CSS.Canvas}
            cimDoc={cimDocForFusion}
            selector={selector}
            dimension={documentWidth}
            onError={onError}
            onPaint={onPaint}
            onLoading={onLoading}
            textOptions={textOptions}
            debugOptions={debugOptions}
            style={{ transform: `translate(${(canvasPosition?.left ?? 0) - (interactionPosition?.left ?? 0)}px)` }}
            referrer={"fusion-demo"}
          />
        </div>
      </>
    );
  },
  (prev, next) => {
    return prev.item === next.item && prev.documentWidth === next.documentWidth && prev.selected === next.selected && prev.children === next.children;
  },
);
