import { useEffect } from "react";

type useCloseOnOutsideProps = {
  getElement: () => HTMLElement | null;
  callback: () => void;
};
export function useCloseOnOutside({ getElement, callback }: useCloseOnOutsideProps) {
  useEffect(() => {
    const onClickOutside = (event: any) => {
      if (!getElement()?.contains(event.target)) {
        callback();
      }
    };

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        callback();
      }
    };

    document.addEventListener("click", onClickOutside, true);
    document.addEventListener("contextmenu", onClickOutside, true);
    document.addEventListener("keydown", onKeyDown, true);

    return () => {
      document.removeEventListener("click", onClickOutside, true);
      document.removeEventListener("keydown", onKeyDown, true);
      document.removeEventListener("contextmenu", onClickOutside, true);
    };
  }, [getElement, callback]);
}
