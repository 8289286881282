import { CimpressDocument, DesignSurface, Transform } from "@mcp-artwork/cimdoc-types-v2";
import { matrixToTransform, transformBoundingBox } from "../../layout/helpers/Transform";
import { selectPanelByNameOrLocation } from "../../selectors/selectPanelFromDocument";
import { ProjectionSpecification, SourcePanel } from "../api/projectionClient";
import { BoundingBox } from "../boundingBox";
import { Matrix } from "../math/matrix";
import { parseMM, parsePercentage, toRadians } from "../unitHelper";
import { Projector, Size } from "./projector";

export const ERROR_MESSAGE_NO_PANEL_IN_PROJECTION = "No matching panels in projection";

export const parseRelativeSize = (width: string | undefined = "", height: string | undefined = "", size: Size): Size => {
  let resultWidth: number | undefined = undefined;
  let resultHeight: number | undefined = undefined;
  {
    const splitWidthValue = width.split(":").map((v) => v.trim().toLowerCase());
    const widthMultiplier = parsePercentage(splitWidthValue[0]);
    if (splitWidthValue.length > 1 && splitWidthValue[1] === "height") {
      resultHeight = size.width / widthMultiplier;
    } else {
      resultWidth = size.width / widthMultiplier;
    }
  }
  {
    const splitHeightValue = height.split(":").map((v) => v.trim().toLowerCase());
    const heightMultiplier = parsePercentage(splitHeightValue[0]);
    if (splitHeightValue.length > 1 && splitHeightValue[1] === "width") {
      resultWidth = size.height / heightMultiplier;
    } else {
      resultHeight = size.height / heightMultiplier;
    }
  }
  if (resultWidth === undefined) {
    throw Error("Could not determine target width");
  }
  if (resultHeight === undefined) {
    throw Error("Could not determine target height");
  }
  return { width: resultWidth, height: resultHeight };
};

export const parseRelativeUnit = (value: string | undefined, size: Size, dimension: "width" | "height"): number => {
  if (value === undefined) {
    return 0;
  }
  const splitValue = value.split(":").map((v) => v.trim().toLowerCase());
  if (splitValue.length === 0) {
    return 0;
  }
  const multiplier = parsePercentage(splitValue[0]);
  if (splitValue.length > 1 && (splitValue[1] === "width" || splitValue[1] === "height")) {
    dimension = splitValue[1];
  }
  switch (dimension) {
    case "width":
      return multiplier * size.width;
    case "height":
      return multiplier * size.height;
  }
};

export const buildJsonProjector = (specification: ProjectionSpecification, pageNumber: number): Projector => {
  if (specification.version !== "v2") {
    throw Error("Only v2 of projections is supported");
  }
  const targetPanel = specification.panels[pageNumber - 1];

  if (!targetPanel) {
    throw new Error(ERROR_MESSAGE_NO_PANEL_IN_PROJECTION);
  }

  return {
    getDesignSurfaces: (document: CimpressDocument) => {
      const matchingPanels = targetPanel.sources.map((source) => {
        const result = selectPanelByNameOrLocation({ document, nameOrLocation: source.location });
        if (result === undefined) {
          throw Error(`No panel found for ${source.location}`);
        }
        return result;
      });
      if (matchingPanels.length === 0) {
        throw Error(ERROR_MESSAGE_NO_PANEL_IN_PROJECTION);
      }
      return matchingPanels;
    },
    determineTargetSize: (surfaces: DesignSurface[]) => {
      if (targetPanel.sources.length > 0 && surfaces.length > 0) {
        const sourceInfo = targetPanel.sources[0];
        const sourceSurface = surfaces[0];
        const sourceSurfaceSize = { width: parseMM(sourceSurface.width), height: parseMM(sourceSurface.height) };
        return parseRelativeSize(sourceInfo.width, sourceInfo.height, sourceSurfaceSize);
      }
      throw Error("No sources or surfaces");
    },
    getTargetName: () => targetPanel.name,
    getTransform: (sourceSurface: DesignSurface, sourceIndex: number, targetSize: Size) => {
      return projectionTransformToTransform(targetPanel.sources[sourceIndex], sourceSurface, targetSize);
    },
    getClip: (sourceSurface: DesignSurface, sourceIndex: number, transforms: Transform[]) => {
      if (!targetPanel.sources[sourceIndex].clip) {
        return undefined;
      }
      const width = parseMM(sourceSurface.width);
      const height = parseMM(sourceSurface.height);
      return {
        position: { x: "0mm", y: "0mm" },
        specification: {
          type: "svgPathData",
          data: `M0,0H${width}V${height}H0z`,
          unit: "mm",
          transforms,
        },
      };
    },
  };
};

const projectionTransformToTransform = (sourceInfo: SourcePanel, sourceSurface: DesignSurface, targetSize: Size): Transform[] => {
  if (!sourceInfo.transforms) {
    return [];
  }
  const completeMatrix = sourceInfo.transforms.reduce((overallMatrix, transform) => {
    switch (transform.transformType) {
      case "translation":
        return Matrix.multiply(
          overallMatrix,
          Matrix.translate(parseRelativeUnit(transform.x, targetSize, "width"), parseRelativeUnit(transform.y, targetSize, "height")),
        );
      case "rotation":
        return Matrix.multiply(
          overallMatrix,
          Matrix.rotateAboutPoint(
            toRadians(transform.angle),
            parseRelativeUnit(transform.x, targetSize, "width"),
            parseRelativeUnit(transform.y, targetSize, "height"),
          ),
        );
      case "align": {
        const sourcePanelRectangularBounds: BoundingBox = { left: 0, top: 0, width: parseMM(sourceSurface.width), height: parseMM(sourceSurface.height) };
        const transformedBoundingBox = transformBoundingBox(sourcePanelRectangularBounds, overallMatrix);
        for (const edge of transform.edges) {
          switch (edge) {
            case "top":
              overallMatrix = Matrix.multiply(overallMatrix, Matrix.translate(0, -transformedBoundingBox.top));
              break;
            case "bottom":
              overallMatrix = Matrix.multiply(
                overallMatrix,
                Matrix.translate(0, targetSize.height - (transformedBoundingBox.top + transformedBoundingBox.height)),
              );
              break;
            case "left":
              overallMatrix = Matrix.multiply(overallMatrix, Matrix.translate(-transformedBoundingBox.left, 0));
              break;
            case "right":
              overallMatrix = Matrix.multiply(
                overallMatrix,
                Matrix.translate(targetSize.width - (transformedBoundingBox.left + transformedBoundingBox.width), 0),
              );
              break;
            default:
              throw Error("Unknown align edge");
          }
        }
        return overallMatrix;
      }
      case "scale":
        throw new Error("scale transforms not supported");
      default:
        throw new Error("Unknown transform type");
    }
  }, Matrix.identity());

  return [matrixToTransform(completeMatrix)];
};
