import { useCallback } from "react";
import { HorizontalAlignment, TextArea, TextAreaWithContent, TextAreaWithFields, TextField } from "@mcp-artwork/cimdoc-types-v2";
import { CimDocUpdater } from "../../models";
import { findTextArea } from "../../utils";
import { FontSizeInput } from "../Inputs/FontSizeInput";
import { ColorPickerInput } from "../Inputs/ColorPickerInput";
import { FontStyleInput } from "../Inputs/FontStyleInput";
import { TextInput } from "../Inputs/TextInput";
import { HorizontalAlignmentInput } from "../Inputs/HorizontalAlignmentInput";
import CSS from "./TextArea.module.css";

type TextEditorProps = {
  textArea: TextArea;
  setCimDoc: CimDocUpdater;
};

const getText = (textArea: TextArea): string => {
  if ((textArea as TextAreaWithFields).textFields) {
    return (textArea as TextAreaWithFields).textFields[0].content;
  }

  return ((textArea as TextAreaWithContent).content[0] as TextField).content;
};

const setText = (textArea: TextArea, value: string) => {
  if ((textArea as TextAreaWithFields).textFields) {
    (textArea as TextAreaWithFields).textFields[0].content = value;
  } else {
    ((textArea as TextAreaWithContent).content[0] as TextField).content = value;
  }
};

const setColor = (textArea: TextArea, value: string) => {
  if ((textArea as TextAreaWithFields).textFields) {
    (textArea as TextAreaWithFields).textFields[0].color = value;
  } else {
    ((textArea as TextAreaWithContent).content[0] as TextField).color = value;
  }
};

const getColor = (textArea: TextArea) => {
  if ((textArea as TextAreaWithFields).textFields) {
    return (textArea as TextAreaWithFields).textFields[0].color;
  }
  return ((textArea as TextAreaWithContent).content[0] as TextField).color;
};

const getFontSize = (textArea: TextArea): number => {
  if ((textArea as TextAreaWithFields).textFields) {
    return parseInt((textArea as TextAreaWithFields).textFields[0].fontSize);
  }
  return parseInt(((textArea as TextAreaWithContent).content[0] as TextField).fontSize);
};

const setFontSize = (textArea: TextArea, value: number) => {
  if ((textArea as TextAreaWithFields).textFields) {
    (textArea as TextAreaWithFields).textFields[0].fontSize = `${value}pt`;
  } else {
    ((textArea as TextAreaWithContent).content[0] as TextField).fontSize = `${value}pt`;
  }
};

const getFontStyle = (textArea: TextArea): string => {
  if ((textArea as TextAreaWithFields).textFields) {
    return (textArea as TextAreaWithFields).textFields[0].fontStyle;
  }
  return ((textArea as TextAreaWithContent).content[0] as TextField).fontStyle;
};

const setFontStyle = (textArea: TextArea, value: string): void => {
  if ((textArea as TextAreaWithFields).textFields) {
    (textArea as TextAreaWithFields).textFields[0].fontStyle = value;
  } else {
    ((textArea as TextAreaWithContent).content[0] as TextField).fontStyle = value;
  }
};

export function TextAreaEditor({ textArea, setCimDoc }: TextEditorProps) {
  const onTextChange = (value: string) => {
    setCimDoc((draft) => {
      if (draft) {
        const draftTextarea = findTextArea(draft, textArea.id);
        draftTextarea && setText(draftTextarea, value);
      }
    });
  };

  const onColorChange = useCallback(
    (value: string) =>
      setCimDoc((draft) => {
        if (draft) {
          const draftTextarea = findTextArea(draft, textArea.id);
          draftTextarea && setColor(draftTextarea, value);
        }
      }),
    [],
  );

  const onFontSizeChange = (value: number) => {
    setCimDoc((draft) => {
      if (draft) {
        const draftTextarea = findTextArea(draft, textArea.id);
        draftTextarea && setFontSize(draftTextarea, value);
      }
    });
  };

  const onFontStyleChange = (value: string) => {
    setCimDoc((draft) => {
      if (draft) {
        const draftTextarea = findTextArea(draft, textArea.id);
        draftTextarea && setFontStyle(draftTextarea, value);
      }
    });
  };

  const onHorizontalAlignmentChange = (value: HorizontalAlignment) => {
    setCimDoc((draft) => {
      if (draft) {
        const draftTextarea = findTextArea(draft, textArea.id);
        if (draftTextarea) {
          draftTextarea.horizontalAlignment = value;
        }
      }
    });
  };

  return (
    <>
      <TextInput className={CSS.Wrapper} autoFocus value={getText(textArea)} onTextChange={onTextChange} />
      <ColorPickerInput className={CSS.Wrapper} color={getColor(textArea)} onColorChange={onColorChange} />
      <FontSizeInput className={CSS.Wrapper} fontSize={getFontSize(textArea)} onChange={onFontSizeChange} />
      <FontStyleInput className={CSS.Wrapper} fontStyle={getFontStyle(textArea)} onChange={onFontStyleChange} />
      <HorizontalAlignmentInput className={CSS.Wrapper} value={textArea.horizontalAlignment} onChange={onHorizontalAlignmentChange} />
    </>
  );
}
