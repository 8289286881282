import { produce } from "immer";
import { LayoutElement } from "../../models/Layout";
import { ColorPalette } from "@mcp-artwork/cimdoc-types-v2";
import { parseColor } from "../../utils/paint/Color";
import { LayoutColor } from "../Models";

type ColorOverrides = {
  [key: string]: string;
}[];

type ParsedColorOverrides = {
  [key: string]: LayoutColor;
};

export const replaceColors = (layoutElements: LayoutElement[], colorOverrides: ColorOverrides, colorPalette: ColorPalette | undefined): LayoutElement[] => {
  const parsedColorOverrides = colorOverrides.map<ParsedColorOverrides>((colorOverride) => {
    const keys = Object.keys(colorOverride);
    return keys.reduce((previous, currentKey) => {
      previous[currentKey] = parseColor(colorOverride[currentKey], colorPalette);

      return previous;
    }, {});
  });

  const returnV = produce(layoutElements, (draft) => {
    draft.forEach((layoutElement) => {
      if (layoutElement.renderingOperation.type === "drawPaths") {
        layoutElement.renderingOperation.paths.forEach((path) => {
          if (path.fill?.type === "color") {
            const originalValue = path.fill.originalValue;
            const foundOverride = parsedColorOverrides.find((parsedColorOverride) => parsedColorOverride[originalValue]);

            if (foundOverride) {
              path.fill = foundOverride[originalValue];
            }
          }

          if (path.fill?.type === "linearGradient" || path.fill?.type === "radialGradient") {
            for (let i = 0; i < path.fill.stops.length; i++) {
              const originalValue = path.fill.stops[i].color.originalValue;
              const foundOverride = parsedColorOverrides.find((parsedColorOverride) => parsedColorOverride[originalValue]);

              if (foundOverride) {
                path.fill.stops[i].color = foundOverride[originalValue];
              }
            }
          }

          if (path.stroke?.fill?.type === "color") {
            const originalValue = path.stroke.fill.originalValue;
            const foundOverride = parsedColorOverrides.find((parsedColorOverride) => parsedColorOverride[originalValue]);

            if (foundOverride) {
              path.stroke.fill = foundOverride[originalValue];
            }
          }

          if (path.stroke?.fill?.type === "linearGradient" || path.stroke?.fill?.type === "radialGradient") {
            for (let i = 0; i < path.stroke.fill.stops.length; i++) {
              const originalValue = path.stroke.fill.stops[i].color.originalValue;
              const foundOverride = parsedColorOverrides.find((parsedColorOverride) => parsedColorOverride[originalValue]);

              if (foundOverride) {
                path.stroke.fill.stops[i].color = foundOverride[originalValue];
              }
            }
          }
        });
      }
    });
  });

  return returnV;
};
