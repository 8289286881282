import { useEffect, useState } from "react";
import CSS from "./Loading.module.css";

export function Loading({ loading }: { loading: boolean }) {
  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(loading);
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [loading]);

  if (showLoader) {
    return (
      <div className={CSS.Fullscreen}>
        <svg
          className={CSS.SVG}
          width={100}
          height={100}
          version="1.1"
          id="L3"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enableBackground="new 0 0 0 0"
        >
          <circle fill="none" stroke="#fff" strokeWidth="4" cx="50" cy="50" r="44" />
          <circle fill="#fcdde4" stroke="##fcdde4" strokeWidth="3" cx="8" cy="54" r="6">
            <animateTransform attributeName="transform" dur="1.8s" type="rotate" from="0 50 48" to="360 50 52" repeatCount="indefinite" />
          </circle>
        </svg>
      </div>
    );
  }
  return null;
}
