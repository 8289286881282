import { BoundingBox, GroupLayout, LayoutElement, LayoutResult } from "@rendering/plasma";

const drawRect = ({
  boundingBox,
  context,
  scalar,
  rotation = 0,
  color,
}: {
  boundingBox: BoundingBox;
  context: CanvasRenderingContext2D;
  scalar: number;
  rotation?: number;
  color: string;
}): void => {
  context.save();
  const x = boundingBox.left * scalar;
  const y = boundingBox.top * scalar;
  const width = boundingBox.width * scalar;
  const height = boundingBox.height * scalar;
  // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/rotate#rotating_a_shape_around_its_center
  context.translate(x + width / 2, y + height / 2);
  context.rotate(((rotation ?? 0) * Math.PI) / 180);
  context.translate(-(x + width / 2), -(y + height / 2));
  context.strokeStyle = color;
  context.lineWidth = 1;
  context.rect(x, y, width, height);
  context.stroke();
  context.restore();
};

export const drawRotatedPreviewBoxes = ({
  scalar,
  layoutElements,
  context,
  color,
}: {
  scalar: number;
  layoutElements: LayoutElement[];
  context: CanvasRenderingContext2D;
  color: string;
}): void => {
  layoutElements.forEach((element) => {
    if (element.renderingOperation.type === "group") {
      element.renderingOperation.contents.forEach((content) => {
        const transform = (element.renderingOperation as GroupLayout).transform;
        context.transform(transform.a, transform.b, transform.c, transform.d, transform.x * scalar, transform.y * scalar);
        drawRect({ boundingBox: content.measurementData.previewBox, context, scalar, color });
        context.resetTransform();
      });
    } else {
      drawRect({ boundingBox: element.measurementData.previewBox, context, scalar, rotation: element.measurementData.previewBox.rotation, color });
    }
  });
};

export const drawRotatedBoundingBoxes = ({
  scalar,
  layoutElements,
  context,
  color,
}: {
  scalar: number;
  layoutElements: LayoutElement[];
  context: CanvasRenderingContext2D;
  color: string;
}): void => {
  layoutElements.forEach((element) => {
    if (element.renderingOperation.type === "group") {
      element.renderingOperation.contents.forEach((content) => {
        const transform = (element.renderingOperation as GroupLayout).transform;
        context.transform(transform.a, transform.b, transform.c, transform.d, transform.x * scalar, transform.y * scalar);
        drawRect({ boundingBox: content.measurementData.boundingBox, context, scalar, color });
        context.resetTransform();
      });
    } else {
      drawRect({ boundingBox: element.measurementData.boundingBox, context, scalar, rotation: element.measurementData.boundingBox.rotation, color });
    }
  });
};
