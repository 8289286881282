import { BoundingBox } from "../boundingBox";
import { toRadians } from "../unitHelper";
import { DecompositionValues } from "./decompositionValues";
import { Point } from "./geometry";

export class Matrix {
  // Horizontal scaling
  public a: number;

  // Vertical skewing
  public b: number;

  // Horizontal skewing
  public c: number;

  // Vertical scaling
  public d: number;

  // Horizontal translation
  public x: number;

  // Vertical translation
  public y: number;

  constructor(a: number, b: number, c: number, d: number, x: number, y: number) {
    this.a = a;
    this.b = b;
    this.c = c;
    this.d = d;
    this.x = x;
    this.y = y;
  }

  public isIdentity(): boolean {
    return this.a === 1 && this.b === 0 && this.c === 0 && this.d === 1 && this.x === 0 && this.y === 0;
  }

  public copy(): Matrix {
    return new Matrix(this.a, this.b, this.c, this.d, this.x, this.y);
  }

  static fromDOMMatrix(matrix: DOMMatrix) {
    return new Matrix(matrix.a, matrix.b, matrix.c, matrix.d, matrix.e, matrix.f);
  }

  static identity(): Matrix {
    return new Matrix(1, 0, 0, 1, 0, 0);
  }

  static scale(scaleX: number, scaleY: number) {
    return new Matrix(scaleX, 0, 0, scaleY, 0, 0);
  }

  static scaleAboutPoint(scaleX: number, scaleY: number, point: Point) {
    const translateToOrigin: Matrix = Matrix.translate(-point.x, -point.y);
    const scale: Matrix = Matrix.scale(scaleX, scaleY);
    const translateBack: Matrix = Matrix.translate(point.x, point.y);

    return this.multiply(translateToOrigin, this.multiply(scale, translateBack));
  }

  static rotation(angle: number): Matrix {
    return new Matrix(Math.cos(angle), Math.sin(angle), -Math.sin(angle), Math.cos(angle), 0, 0);
  }

  static translate(x: number, y: number): Matrix {
    return new Matrix(1, 0, 0, 1, x, y);
  }

  static mirror(direction: "horizontal" | "vertical"): Matrix {
    if (direction === "horizontal") {
      return new Matrix(-1, 0, 0, 1, 0, 0);
    }

    return new Matrix(1, 0, 0, -1, 0, 0);
  }

  static mirrorAboutPoint(direction: "horizontal" | "vertical", point: Point) {
    const translateToOrigin: Matrix = Matrix.translate(-point.x, -point.y);
    const mirror: Matrix = Matrix.mirror(direction);
    const translateBack: Matrix = Matrix.translate(point.x, point.y);

    return this.multiply(translateToOrigin, this.multiply(mirror, translateBack));
  }

  static multiply(m1: Matrix, m2: Matrix): Matrix {
    return new Matrix(
      m1.a * m2.a + m1.b * m2.c,
      m1.a * m2.b + m1.b * m2.d,
      m1.c * m2.a + m1.d * m2.c,
      m1.c * m2.b + m1.d * m2.d,
      m1.x * m2.a + m1.y * m2.c + m2.x,
      m1.x * m2.b + m1.y * m2.d + m2.y,
    );
  }

  static rotateAboutCenter(angle: number, bounds: BoundingBox): Matrix {
    const centerX: number = bounds.left + bounds.width / 2;
    const centerY: number = bounds.top + bounds.height / 2;

    return this.rotateAboutPoint(angle, centerX, centerY);
  }

  // https://math.stackexchange.com/questions/2093314/rotation-matrix-of-rotation-around-a-point-other-than-the-origin
  static rotateAboutPoint(angle: number, x: number, y: number): Matrix {
    const centerOrigin: Matrix = this.translate(-x, -y);
    const rotationMatrix: Matrix = this.rotation(angle);
    const translateOrigin: Matrix = this.translate(x, y);

    const centeredRotation: Matrix = this.multiply(centerOrigin, rotationMatrix);

    return this.multiply(centeredRotation, translateOrigin);
  }

  static skew(angle: number, axis: "x" | "y"): Matrix {
    const tan = Math.tan(angle);
    switch (axis) {
      case "y":
        return new Matrix(1, tan, 0, 1, 0, 0);
      case "x":
        return new Matrix(1, 0, tan, 1, 0, 0);
      default:
        throw Error("Invalid skew axis");
    }
  }

  static skewAboutPoint(x: number, y: number, point: Point): Matrix {
    const centerOrigin: Matrix = this.translate(-point.x, -point.y);
    const rotationMatrix: Matrix = new Matrix(1, Math.tan(toRadians(y)), Math.tan(toRadians(x)), 1, 0, 0);
    const translateOrigin: Matrix = this.translate(point.x, point.y);

    return this.multiply(centerOrigin, this.multiply(rotationMatrix, translateOrigin));
  }

  static decomposeAffineTransform(m: Matrix): DecompositionValues {
    const a = m.a;
    const b = m.b;
    const c = m.c;
    const d = m.d;
    const x = m.x;
    const y = m.y;

    const result = {
      rotation: 0,
      translation: {
        x: x,
        y: y,
      },
      scale: {
        x: 0,
        y: 0,
      },
      skew: {
        x: 0,
        y: 0,
      },
    };

    const delta = a * d - b * c;

    if (a != 0 || b != 0) {
      const r = Math.sqrt(a * a + b * b);
      result.rotation = b > 0 ? Math.acos(a / r) : -Math.acos(a / r);
      result.scale = { x: r, y: delta / r };
      result.skew = { x: Math.atan((a * c + b * d) / (r * r)), y: 0 };
    } else if (c != 0 || d != 0) {
      const s = Math.sqrt(c * c + d * d);
      result.rotation = Math.PI / 2 - (d > 0 ? Math.acos(-c / s) : -Math.acos(c / s));
      result.scale = { x: delta / s, y: s };
      result.skew = { x: 0, y: Math.atan((a * c + b * d) / (s * s)) };
    }

    return result;
  }

  toDOMMatrix() {
    return new DOMMatrix([this.a, this.b, this.c, this.d, this.x, this.y]);
  }
}

// TODO: Do we have matrix.b and matrix.c reversed?
export function transformPoint(matrix: Matrix, point: Point): Point {
  return {
    x: matrix.a * point.x + matrix.c * point.y + matrix.x,
    y: matrix.b * point.x + matrix.d * point.y + matrix.y,
  };
}
