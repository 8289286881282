import { ItemReference } from "@mcp-artwork/cimdoc-types-v2";
import { CimDocUpdater } from "../../models";
import { findItemReference } from "../../utils";
import { TextInput } from "../Inputs/TextInput";
import { ColorPickerInput } from "../Inputs/ColorPickerInput";
import { FontStyleInput } from "../Inputs/FontStyleInput";
import CSS from "./ItemReference.module.css";
import { useCallback } from "react";

type ItemReferenceEditorProps = {
  itemReference: ItemReference;
  setCimDoc: CimDocUpdater;
};

export function ItemReferenceEditor({ itemReference, setCimDoc }: ItemReferenceEditorProps) {
  const onTextChange = (value: string) => {
    setCimDoc((draft) => {
      if (draft) {
        const draftItemReference = findItemReference(draft, itemReference.id);
        if (draftItemReference) {
          draftItemReference.data.content = value;
        }
      }
    });
  };

  const onColorChange = useCallback((value: string) => {
    setCimDoc((draft) => {
      if (draft) {
        const draftItemReference = findItemReference(draft, itemReference.id);
        if (draftItemReference) {
          draftItemReference.data.color = value;
        }
      }
    });
  }, []);

  const onStrokeColorChange = useCallback((value: string) => {
    setCimDoc((draft) => {
      if (draft) {
        const draftItemReference = findItemReference(draft, itemReference.id);
        if (draftItemReference) {
          draftItemReference.data.stroke.color = value;
        }
      }
    });
  }, []);

  const onFontStyleChange = (value: string) => {
    setCimDoc((draft) => {
      if (draft) {
        const draftItemReference = findItemReference(draft, itemReference.id);
        if (draftItemReference) {
          draftItemReference.data.fontStyle = value;
        }
      }
    });
  };

  return (
    <>
      {itemReference.data.content !== undefined && <TextInput className={CSS.Wrapper} value={itemReference.data.content} onTextChange={onTextChange} />}
      {itemReference.data.color !== undefined && <ColorPickerInput className={CSS.Wrapper} color={itemReference.data.color} onColorChange={onColorChange} />}
      {itemReference.data.stroke?.color && itemReference.data.stroke?.thinkness !== 0 && (
        <ColorPickerInput className={CSS.Wrapper} color={itemReference.data.stroke.color} onColorChange={onStrokeColorChange} />
      )}
      {itemReference.data.fontStyle !== undefined && (
        <FontStyleInput className={CSS.Wrapper} fontStyle={itemReference.data.fontStyle} onChange={onFontStyleChange} />
      )}
    </>
  );
}
