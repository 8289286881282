import { SyntheticEvent, useEffect, useRef } from "react";
import { TextInput } from "../../TextInput";
import CSS from "./AddCustomDemoForm.module.css";

type AddCustomDemoFormProps = {
  onClose: () => void;
  onSubmit: (args: { renderingInstructionsUrl: string; productConfig: string }) => void;
};

export function AddCustomDemoForm({ onClose, onSubmit }: AddCustomDemoFormProps) {
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (formRef.current && !formRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const onInnerSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const target = e.currentTarget as typeof e.currentTarget & {
      renderingInstructionsUrl: { value: string };
      productConfig: { value: string };
    };
    const renderingInstructionsUrl = target.renderingInstructionsUrl.value;
    const productConfig = target.productConfig.value;

    try {
      new URL(renderingInstructionsUrl);
      new URL(productConfig);
    } catch (e) {
      alert("Inputs are not valid URLs");
      return;
    }

    onSubmit({ renderingInstructionsUrl, productConfig });
  };

  return (
    <dialog className={CSS.Dialog} open>
      <form onSubmit={onInnerSubmit} className={CSS.Form} ref={formRef}>
        <label className={CSS.Label}>
          renderingInstructionsUrl
          <TextInput name="renderingInstructionsUrl" autoFocus />
        </label>
        <label className={CSS.Label}>
          productConfig
          <TextInput name="productConfig" />
        </label>
        <button type="submit" className={CSS.Button}>
          Submit
        </button>
        <button type="button" onClick={onClose} className={CSS.Button}>
          Close
        </button>
      </form>
    </dialog>
  );
}
