export const resolveShortURL = async ({ shortURL }: { shortURL: string }): Promise<{ url: string }> => {
  const response = await fetch(shortURL, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.redirected) {
    return { url: response.url };
  }
  throw new Error("Expected redirect, but didn't happen");
};

export const fetchData = async <T>({ url }: { url: string }): Promise<T> => {
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const postData = async <T>({ url, data, authToken }: { url: string; data: object; authToken: string }): Promise<T> => {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(data),
  });
  return response.json();
};
