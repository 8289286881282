import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { paint } from "@rendering/neon";
import { DebugOptions, ExperimentalOptions, Filter, layout, TextOptions, VideoOptions } from "@rendering/plasma";
import memoize from "lodash.memoize";
import { OnPaintArgs } from "../types/OnPaintArgs";
import { SelectorWithOverprints } from "../types/SelectorWithOverprints";
import { GetCanvasArgs } from "../utils/getCanvas";
import { Dimension, getPixelSize } from "../utils/getPixelSize";
import { RequestNumberGenerator } from "../utils/requestNumberGenerator";

const warnOnce = memoize(console.warn);

export type FusionReturn = OnPaintArgs | null;

type FusionArgs = {
  selectors: SelectorWithOverprints[];
  cimDoc: CimpressDocument;
  dimension: Dimension;
  layoutRequestNumberGenerator: RequestNumberGenerator;
  textOptions: TextOptions;
  getCanvas: (args: GetCanvasArgs) => HTMLCanvasElement;
  filters?: Filter[];
  debugOptions?: DebugOptions;
  experimentalOptions?: ExperimentalOptions;
  videoOptions?: VideoOptions;
  referrer: string;
};

export const fusion = async ({
  selectors,
  cimDoc,
  dimension,
  layoutRequestNumberGenerator,
  textOptions,
  getCanvas,
  filters,
  debugOptions,
  experimentalOptions,
  videoOptions,
  referrer,
}: FusionArgs): Promise<FusionReturn> => {
  if (filters?.length) {
    warnOnce("Filters are only rendered client side for demo purposed at the moment.");
  }

  const layoutRequest = layoutRequestNumberGenerator.next();

  // Determine pixel size // TODO: get pixelsize and dimension per page?
  const pixelSize = await getPixelSize({ cimDoc, dimension, selector: selectors[0] });

  // Layout
  const layoutResults = await Promise.all(
    selectors.map((selector) =>
      layout({
        document: cimDoc,
        pixelSize,
        selector,
        textOptions,
        debugOptions,
        experimentalOptions,
        overprints: selector.overprints,
        videoOptions,
        referrer,
      }),
    ),
  );

  if (layoutRequest !== 0 && layoutRequest < layoutRequestNumberGenerator.lastCompletedRequest()) {
    return null; // Prevent an old layout tyot paint over a newer layout
  }
  layoutRequestNumberGenerator.setLastCompletedRequest(layoutRequest);

  // Paint
  return layoutResults.map((layoutResult, index) => {
    const layoutResultWithFilters = layoutResult;
    layoutResultWithFilters.filters = filters;
    const selector = selectors[index];
    const canvas = getCanvas({ cimDocId: cimDoc.documentId, selector });
    const paintResult = paint({
      layoutResult,
      canvasContext: canvas.getContext("2d") as CanvasRenderingContext2D,
      pixelSize,
      debugOptions,
    });

    const paintResultsOverprints = selector.overprints?.map((overprint) => {
      const canvas = getCanvas({ cimDocId: cimDoc.documentId, selector, overprint });
      return {
        paintResult: paint({
          layoutResult,
          canvasContext: canvas.getContext("2d") as CanvasRenderingContext2D,
          pixelSize,
          debugOptions,
          overprint,
        }),
        canvas,
        overprint,
      };
    });

    return { canvas, paintResult, layoutResult, selector, paintResultsOverprints };
  });
};
