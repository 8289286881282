import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { Templates } from "./Templates";
import { DesignArea } from "./DesignArea";
import { Toolbar } from "./Toolbar";
import { findElement } from "./utils";
import { DesignerContextProvider } from "./state/DesignerState";
import { Previews } from "./Previews";

export function Designer() {
  return (
    <DesignerContextProvider>
      {({
        cimDoc,
        imageUploadingProgress,
        selectedItemId,
        setCimDoc,
        setImageUploadingProgress,
        setSelectedItemId,
        fetchSherbertFiles,
        setSherbertFiles,
        sherbertFiles,
        designPreviewsType,
        setDesignPreviewsType,
      }) => (
        <div>
          <Templates setCimDoc={setCimDoc} />
          {cimDoc && (
            <div>
              {selectedItemId && findElement(cimDoc as CimpressDocument, selectedItemId) && (
                <Toolbar
                  key={selectedItemId}
                  item={findElement(cimDoc as CimpressDocument, selectedItemId)}
                  setCimDoc={setCimDoc}
                  setImageUploadingProgress={setImageUploadingProgress}
                  fetchSherbertFiles={fetchSherbertFiles}
                  sherbertFiles={sherbertFiles}
                  setSherbertFiles={setSherbertFiles}
                />
              )}
              <DesignArea
                designPreviewsType={designPreviewsType}
                cimDoc={cimDoc}
                setSelectedItemId={setSelectedItemId}
                selectedItemId={selectedItemId}
                setCimDoc={setCimDoc}
                imageUploadingProgress={imageUploadingProgress}
              />
              <Previews designPreviewsType={designPreviewsType} setDesignPreviewsType={setDesignPreviewsType} cimDoc={cimDoc} />
            </div>
          )}
        </div>
      )}
    </DesignerContextProvider>
  );
}
