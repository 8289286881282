import CSS from "./PerformanceResults.module.css";

type PerformanceResultsProps = {
  clientSide: number;
  serverSide: number;
};

export function PerformanceResults({ clientSide, serverSide }: PerformanceResultsProps) {
  return (
    <div>
      <div className={CSS.Label}>Client side</div>
      <div className={CSS.BarContainer} style={{ backgroundColor: "#E0E0E0" }}>
        <div className={CSS.Bar} style={{ width: clientSide, backgroundColor: "#03c03c" }} />
        <div className={CSS.BarText}>
          {clientSide.toFixed(2)}ms {clientSide < serverSide && `🏆 (${(serverSide / clientSide).toFixed(1)}x faster)`}
        </div>
      </div>
      <div className={CSS.Label}>Server side</div>
      <div className={CSS.BarContainer} style={{ backgroundColor: "#E0E0E0" }}>
        <div
          className={CSS.Bar}
          style={{
            width: serverSide,
            backgroundColor: "#c23b22",
          }}
        />
        <div className={CSS.BarText}>{serverSide.toFixed(2)}ms</div>
      </div>
    </div>
  );
}
