import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { ReactNode } from "react";
import { useDocument } from "../hooks/useDocument";
import { Updater } from "use-immer";

type CimDocStateHandlerProps = {
  url: string;
  children: ({ cimDoc, onSetCimDoc }: { cimDoc: CimpressDocument; onSetCimDoc: Updater<CimpressDocument> }) => ReactNode;
};

export function CimDocStateHandler({ url, children }: CimDocStateHandlerProps) {
  const { document, error, setDocument } = useDocument({ url });

  if (error) {
    return (
      <div>
        <h1>Error in handling CimDoc</h1>
        <pre>{error.message ? error.message : JSON.stringify(error)}</pre>
      </div>
    );
  }

  if (!document) {
    return null;
  }

  return <>{children({ cimDoc: document, onSetCimDoc: setDocument as Updater<CimpressDocument> })}</>;
}
