import memoize from "lodash.memoize";

export const isWebPSupported = memoize(async (): Promise<boolean> => {
  const testImages = [
    "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA", // lossy
    "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==", // lossless
    "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==", // alpha
    "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA", // animation
  ];

  const tests = testImages.map((dataUrl) => {
    return new Promise<true>((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        if (img.width > 0 && img.height > 0) {
          resolve(true);
        } else {
          reject();
        }
      };
      img.onerror = () => {
        reject();
      };
      img.src = `data:image/webp;base64,${dataUrl}`;
    });
  });

  try {
    await Promise.all(tests);
    return true;
  } catch (e) {
    return false;
  }
});
