import { ColorPalette, LineItem } from "@mcp-artwork/cimdoc-types-v2";
import { BoundingBox, boundingBoxFromLineItem } from "../../utils/boundingBox";
import { parseMM } from "../../utils/unitHelper";
import { ClipPath, PathLayout } from "../Models";
import { LayoutElement, PreviewType } from "../../models/Layout";
import { getLayoutStroke } from "../helpers/Stroke";
import { buildTransform } from "../helpers/Transform";
import { parseOverprints } from "../helpers/Paint";
import { getClip } from "../helpers/Clip";
import CimDocDefinitionTreeNode from "../../utils/CimDocDefinitionTreeNode";
import { getMeasurementData } from "../measurements/measurementData";

const lineToPath = ({ x1, y1, x2, y2 }: { x1: number; y1: number; x2: number; y2: number }): string => {
  return `M${x1},${y1}L${x2},${y2}`;
};

export async function lineLayout({
  line,
  parentBounds,
  options,
  previewType,
}: {
  line: LineItem;
  parentBounds: BoundingBox;
  options: { definitionTreeNode?: CimDocDefinitionTreeNode; fontRepositoryUrl?: string; colorPalette: ColorPalette | undefined };
  previewType: PreviewType;
}): Promise<LayoutElement> {
  const originalScaleTransform = line.scale;
  const originalBoundingBox = boundingBoxFromLineItem({ line });

  const itemPreviewTransform = buildTransform({
    bounds: originalBoundingBox,
    skew: line.skew,
    scale: line.scale,
    rotationAngle: line.rotationAngle,
    matrixTransform: line.transform,
    itemTransforms: line.transforms,
  });

  const bounds: BoundingBox = boundingBoxFromLineItem({ line });

  let transform = buildTransform({
    bounds: bounds,
    skew: line.skew,
    scale: line.scale,
    rotationAngle: line.rotationAngle,
    matrixTransform: line.transform,
    itemTransforms: line.transforms,
  });

  const measurementDataResponse = getMeasurementData({
    boundingBox: bounds,
    tightBounds: bounds,
    transform: itemPreviewTransform,
    scaleTransform: originalScaleTransform,
    itemType: "shape",
  });

  if (previewType === "item") {
    transform = measurementDataResponse.itemPreviewTransform;
  }

  const clipPath: ClipPath | undefined = await getClip(line, parentBounds);

  const x1 = parseMM(line.start.x);
  const y1 = parseMM(line.start.y);
  const x2 = parseMM(line.end.x);
  const y2 = parseMM(line.end.y);

  const renderingOperation: PathLayout = {
    type: "drawPaths",
    clip: clipPath,
    paths: [
      {
        path: lineToPath({ x1, y1, x2, y2 }),
        transform,
        overprints: parseOverprints(line.overprints, options.colorPalette),
        stroke: await getLayoutStroke(line.stroke, { definitionTreeNode: options.definitionTreeNode, itemBounds: bounds, colorPalette: options.colorPalette }),
      },
    ],
    opacityMultiplier: line.opacityMultiplier ?? 1,
  };

  return {
    id: line.id,
    status: { mode: "local" },
    measurementData: {
      boundingBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.boundingBox,
      previewBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.previewBox,
      layoutBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.layoutBox,
    },
    renderingOperation,
  };
}
