import { Subpanel } from "@mcp-artwork/cimdoc-types-v2";
import { LayoutElement, LayoutMeasurement, PreviewType } from "../../models/Layout";
import { BoundingBox, RotatedBoundingBox, maximum } from "../../utils/boundingBox";
import { Matrix } from "../../utils/math/matrix";
import { parseMM, toRadians } from "../../utils/unitHelper";
import { getClip } from "../helpers/Clip";
import { buildTransform, transformBoundingBox } from "../helpers/Transform";
import CimDocDefinitionTreeNode from "../../utils/CimDocDefinitionTreeNode";
import { getMeasurementData } from "../measurements/measurementData";
import { ClipPath } from "../Models";

export async function subpanelLayout({
  subpanel,
  layoutElements,
  parentBounds,
  previewType,
}: {
  subpanel: Subpanel;
  layoutElements: LayoutElement[];
  parentBounds: BoundingBox;
  options: { definitionTreeNode?: CimDocDefinitionTreeNode };
  previewType: PreviewType;
}): Promise<LayoutElement> {
  const originalScaleTransform = subpanel.scale;

  // Use parentbounds for empty subpanels
  const boundingBox = getBoundingBox(subpanel, layoutElements, parentBounds, true);
  const untranslatedBoundingBox = getBoundingBox(subpanel, layoutElements, parentBounds, false);

  let transform = Matrix.translate(parseMM(subpanel.position.x), parseMM(subpanel.position.y));
  transform = Matrix.multiply(
    transform,
    buildTransform({
      bounds: boundingBox,
      skew: subpanel.skew,
      scale: subpanel.scale,
      rotationAngle: subpanel.rotationAngle,
      matrixTransform: subpanel.transform,
      itemTransforms: subpanel.transforms,
    }),
  );

  const measurementDataResponse = getMeasurementData({
    boundingBox: untranslatedBoundingBox,
    tightBounds: untranslatedBoundingBox,
    transform: transform,
    scaleTransform: originalScaleTransform,
    itemType: "subpanel",
  });

  const clipPath: ClipPath | undefined = await getClip(subpanel, parentBounds, transform);

  if (previewType === "item") {
    transform = measurementDataResponse.itemPreviewTransform;
  }

  return {
    id: subpanel.id,
    measurementData: {
      layoutBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.layoutBox,
      previewBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.previewBox,
      boundingBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.boundingBox,
    },
    renderingOperation: {
      type: "group",
      contents: layoutElements,
      transform: transform,
      clip: clipPath,
      opacityMultiplier: subpanel.opacityMultiplier ?? 1,
    },
    status: {
      mode: "local",
    },
  };
}

function getTransformedPreviewBox(element: LayoutElement): BoundingBox {
  const measurementData = element.measurementData as LayoutMeasurement;
  const previewBox = measurementData.previewBox as RotatedBoundingBox;
  const transform = Matrix.rotateAboutCenter(toRadians(previewBox.rotation ?? "0"), previewBox);
  return transformBoundingBox(previewBox, transform);
}

/**
 * Gets the bounding box of the subpanel by aggregating subpanel's items.
 * @param subpanel
 * @param layoutElements
 * @param parentBounds
 * @param includePosition
 * @returns
 */
function getBoundingBox(subpanel: Subpanel, layoutElements: LayoutElement[], parentBounds: BoundingBox, includePosition: boolean): BoundingBox {
  const boundingBox = layoutElements.length > 0 ? maximum(layoutElements.map((element) => getTransformedPreviewBox(element))) : parentBounds;
  if (includePosition) {
    return { ...boundingBox, left: boundingBox.left + parseMM(subpanel.position.x), top: boundingBox.top + parseMM(subpanel.position.y) };
  } else {
    return boundingBox;
  }
}
