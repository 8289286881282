// Ported from https://gitlab.com/Cimpress-Technology/DocDesign/Document/doc.instructions/-/blob/master/src/Doc.Instructions.Domain/Text/TextAlongAPath/PiecewisePath.cs

import * as svgpath from "svgpath";
import { distance, Point } from "../../../utils/math/geometry";
import { binarySearch } from "./binarySearch";
import { BoundingBox, parseMM } from "../../..";
import { maximum } from "../../../utils/boundingBox";
import { Vector2 } from "../../../utils/math/Vector2";
import { CalculationResult, CalculationResultCollection, Rectangle, ResultFont } from "@mcp-artwork/rtext";
import { Position } from "@mcp-artwork/cimdoc-types-v2";

interface VectorsFromPathShapeArguments {
  path: Omit<typeof svgpath, "default">;
}

export class PiecewisePath {
  private positionedVectors: PositionedPathSegment[];

  public length: number;

  public getPoint(t: number): { point: Point; theta: number } {
    if (t < 0 || t > 1) {
      throw Error("invalid t value");
    }

    let index = binarySearch(this.positionedVectors, t, (i, v) => i - v.position);

    if (index < 0) {
      index = -index - 2;
    }

    const nextOffset = index + 1 === this.positionedVectors.length ? 1 : this.positionedVectors[index + 1].position;
    const tDifference = t - this.positionedVectors[index].position;

    const segment = this.positionedVectors[index];

    const point = getPointOnSegment(segment, tDifference / (nextOffset - this.positionedVectors[index].position));
    return { point, theta: vectorTheta(segment) };
  }

  public getPathInclusiveBounds(calculationResultCollection: CalculationResultCollection, position: Position): BoundingBox {
    const augmentedBounds: BoundingBox[] = [];

    const results = calculationResultCollection.results as CalculationResult[];

    let maxAscent = Number.MIN_VALUE;
    let minDescent = Number.MAX_VALUE;

    results.forEach((result) => {
      (result.lineInfos ?? []).forEach((lineInfo) => {
        const baseline = lineInfo.baseline;
        const textBounds = lineInfo.textBounds as Rectangle;

        maxAscent = Math.max(maxAscent, baseline - textBounds.y);
        minDescent = Math.min(minDescent, baseline - textBounds.height - textBounds.y);
      });
    });

    this.positionedVectors.forEach((vector) => {
      const theta = vectorTheta(vector) + Math.PI / 2;
      const unitVector: Vector2 = new Vector2(Math.cos(theta), Math.sin(theta));
      const start = vector.start;
      const end = vector.end;

      const startAscent: Point = {
        x: start.x - unitVector.a * maxAscent,
        y: start.y - unitVector.b * maxAscent,
      };

      const startDescent: Point = {
        x: start.x - unitVector.a * minDescent,
        y: start.y - unitVector.b * minDescent,
      };

      const endAscent: Point = {
        x: end.x - unitVector.a * maxAscent,
        y: end.y - unitVector.b * maxAscent,
      };

      const endDescent: Point = {
        x: end.x - unitVector.a * minDescent,
        y: end.y - unitVector.b * minDescent,
      };

      const points = [startAscent, startDescent, endAscent, endDescent];
      const left = Math.min(...points.map((p) => p.x));
      const top = Math.min(...points.map((p) => p.y));
      const right = Math.max(...points.map((p) => p.x));
      const bottom = Math.max(...points.map((p) => p.y));

      const boundingBox: BoundingBox = {
        left: left + parseMM(position.x),
        top: top + parseMM(position.y),
        width: right - left,
        height: bottom - top,
      };

      augmentedBounds.push(boundingBox);
    });

    return maximum(augmentedBounds);
  }

  constructor({ path }: VectorsFromPathShapeArguments) {
    const vectors: PathSegment[] = segmentCurves({ path });
    let totalLength = 0;
    const positionedVectors0 = vectors.map((vector) => {
      const result = { ...vector, position: totalLength };
      totalLength += vector.length;
      return result;
    });

    this.positionedVectors = positionedVectors0.map((vector) => ({ ...vector, position: vector.position / totalLength }));
    this.length = totalLength;
  }
}

export interface PositionedPathSegment extends PathSegment {
  position: number;
}

export interface PathSegment {
  start: Point;
  end: Point;
  length: number;
}

export interface PositionedPathSegment extends PathSegment {
  position: number;
}

const vectorTheta = (vector: PathSegment): number => {
  const dbl = Math.atan2(vector.end.y - vector.start.y, vector.end.x - vector.start.x);
  return modulo(dbl, Math.PI * 2);
};

const modulo = (a: number, b: number): number => a - b * Math.floor(a / b);

const getPointOnSegment = (segment: PathSegment, t: number): Point => {
  const x = segment.start.x * (1 - t) + segment.end.x * t;
  const y = segment.start.y * (1 - t) + segment.end.y * t;
  return { x, y };
};

const CURVE_SEGMENT_COUNT = 200;
const CURVE_SEGMENT_IDS = [...Array(CURVE_SEGMENT_COUNT).keys()].map((i) => i + 1);

const segmentCurves = ({ path }: VectorsFromPathShapeArguments): PathSegment[] => {
  let lastStartingPosition: Point = { x: 0, y: 0 };
  const output: PathSegment[] = [];
  path
    .abs() // Replace all relative (lower case) path commands with absolute (upper case) path commands
    .unarc() // Replace all arch path commands (A) with curves (C or Q)
    .unshort() // Replace all shortcut command (T and S) with curves (C or Q)
    .iterate((segments, _, x, y) => {
      switch (segments[0]) {
        case "M": {
          lastStartingPosition = { x, y };
          break;
        }
        case "Z": {
          const endPoint = lastStartingPosition;
          output.push(makePathSegment({ x, y }, endPoint));
          break;
        }
        case "L": {
          const endPoint = { x: segments[1], y: segments[2] };
          output.push(makePathSegment({ x, y }, endPoint));
          break;
        }
        case "V": {
          const endPoint = { x, y: segments[1] };
          output.push(makePathSegment({ x, y }, endPoint));
          break;
        }
        case "H": {
          const endPoint = { x: segments[1], y };
          output.push(makePathSegment({ x, y }, endPoint));
          break;
        }
        case "C": {
          const curveStartPoint = { x, y };
          let currentPoint = curveStartPoint;
          output.push(
            ...CURVE_SEGMENT_IDS.map((i) => {
              const t = i / CURVE_SEGMENT_COUNT;
              const x1 = getPositionOnCubicCurve(t, curveStartPoint.x, segments[1], segments[3], segments[5]);
              const y1 = getPositionOnCubicCurve(t, curveStartPoint.y, segments[2], segments[4], segments[6]);
              const nextPoint = { x: x1, y: y1 };
              const result = makePathSegment(currentPoint, nextPoint);
              currentPoint = nextPoint;
              return result;
            }),
          );
          break;
        }
        case "Q": {
          const curveStartPoint = { x, y };
          let currentPoint = curveStartPoint;
          output.push(
            ...CURVE_SEGMENT_IDS.map((i) => {
              const t = i / CURVE_SEGMENT_COUNT;
              const x1 = getPositionOnQuadraticCurve(t, curveStartPoint.x, segments[1], segments[3]);
              const y1 = getPositionOnQuadraticCurve(t, curveStartPoint.y, segments[2], segments[4]);
              const nextPoint = { x: x1, y: y1 };
              const result = makePathSegment(currentPoint, nextPoint);
              currentPoint = nextPoint;
              return result;
            }),
          );
          break;
        }
        default:
          throw Error("Invalid SVG path character");
      }
    });

  return output;
};

const getPositionOnCubicCurve = (t: number, start: number, control1: number, control2: number, end: number): number => {
  if (t < 0 || t > 1) {
    throw new Error("Invalid t for cubic curve");
  }

  const k = 1 - t;
  return start * k * k * k + 3.0 * control1 * k * k * t + 3.0 * control2 * k * t * t + end * t * t * t;
};

const getPositionOnQuadraticCurve = (t: number, start: number, control: number, end: number): number => {
  if (t < 0 || t > 1) {
    throw new Error("Invalid t for quadratic curve");
  }

  const k = 1 - t;
  return start * k * k + 2.0 * control * k * t + end * t * t;
};

const makePathSegment = (start: Point, end: Point): PathSegment => {
  const length = distance(end, start);
  return { start, end, length };
};
