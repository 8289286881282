// We are allowing non-null assertions because the legacy curve format is deprecated and
// the CimDoc types defined for it are inaccurate. It doesn't seem worth fixing it.
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Curve, CurveItem, CurveItemSvg } from "@mcp-artwork/cimdoc-types-v2";
import { getNumberAndUnitValue } from "./unitHelper";

const getNumericValue = (value: string, unitLength: number): string => value.substring(0, value.length - unitLength);

const mapCurveToSvgPath = (curve: Curve, N: (value: string) => string): string => {
  const operation = curve.operation.toLowerCase();

  if (operation === "curveto") {
    return `C ${N(curve.controlPoint1!.x)} ${N(curve.controlPoint1!.y)}, ${N(curve.controlPoint2!.x)} ${N(curve.controlPoint2!.y)}, ${N(curve.position!.x)} ${N(
      curve.position!.y,
    )}`;
  }
  if (operation === "lineto") {
    return `L ${N(curve.position!.x)} ${N(curve.position!.y)}`;
  }
  if (operation === "moveto") {
    return `M ${N(curve.position!.x)} ${N(curve.position!.y)}`;
  }
  if (operation === "quadto") {
    return `Q ${N(curve.controlPoint1!.x)} ${N(curve.controlPoint1!.y)}, ${N(curve.position!.x)} ${N(curve.position!.y)}`;
  }
  if (operation === "close") {
    return "Z";
  }

  return "";
};

type CurveLegacyToCurveArguments = {
  curve: CurveItem | CurveItemSvg;
};

export function curveLegacyToCurve({ curve }: CurveLegacyToCurveArguments): CurveItemSvg {
  if ((curve as CurveItemSvg).svgPathData) {
    return curve as CurveItemSvg;
  }

  const [, unitValue] = getNumberAndUnitValue((curve as CurveItem).curves[0].position!.x);
  const N = (v: string): string => getNumericValue(v, unitValue.length);

  const pathData: string = (curve as CurveItem).curves.map((c) => mapCurveToSvgPath(c, N)).join("");

  return {
    id: curve.id,
    position: curve.position,
    type: curve.type,
    closeBehavior: curve.closeBehavior,
    color: curve.color,
    clipping: curve.clipping,
    metadata: curve.metadata,
    overprints: curve.overprints,
    rotationAngle: curve.rotationAngle,
    viewBox: curve.viewBox,
    svgPathDataUnit: unitValue,
    windingType: curve.windingType,
    zIndex: curve.zIndex,
    svgPathData: pathData,
    stroke: curve.stroke,
  };
}
