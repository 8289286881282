import { ColorPalette, Ornament } from "@mcp-artwork/cimdoc-types-v2";
import { fetchWithNetworkCache } from "../../cache/network";
import { imageLayout } from "../image/Layout";
import { ImageOptions, LayoutElement, PreviewType } from "../../models/Layout";
import { parseMM } from "../../utils/unitHelper";
import { Matrix } from "../../utils/math/matrix";
import { getTransformedRotatedBoundingBox, maximum, BoundingBox } from "../../utils/boundingBox";

type OrnamentLayoutArgs = {
  ornament: Ornament;
  parentBounds: BoundingBox;
  referrer: string;
  previewType: PreviewType;
  imageOptions?: ImageOptions;
  colorPalette: ColorPalette | undefined;
};

type OrnamentConfig = {
  name: string;
  type: "Printed" | "Physical" | "Unavailable";
  heightCm: number;
  widthCm: number;
  overlayUrl: string;
};

export async function ornamentLayout({
  ornament,
  parentBounds,
  previewType,
  referrer,
  imageOptions,
  colorPalette,
}: OrnamentLayoutArgs): Promise<LayoutElement | undefined> {
  const ornamentConfig = await fetchWithNetworkCache<OrnamentConfig>({
    url: `https://cdn.previews.cimpress.io/v2/ornaments/configs/${ornament.type}`,
    responseResolver: async (response) => (await (response as Response).json()) as OrnamentConfig,
  });

  if (ornamentConfig.type === "Printed") {
    const imagesLayoutElements = await Promise.all(
      ornament.positions.map((position, index) =>
        imageLayout({
          image: {
            id: `ornament-${ornament.type}-${index}`,
            position: {
              x: `${parseMM(position.x) - (ornamentConfig.widthCm * 10) / 2}mm`, // correct with center of origin
              y: `${parseMM(position.y) - (ornamentConfig.heightCm * 10) / 2}mm`, // correct with center of origin
              height: `${ornamentConfig.heightCm * 10}mm`,
              width: `${ornamentConfig.widthCm * 10}mm`,
            },
            previewUrl: ornamentConfig.overlayUrl,
            url: ornamentConfig.overlayUrl,
          },
          parentBounds,
          previewType,
          referrer,
          imageOptions,
          colorPalette,
        }),
      ),
    );

    // Use the previewBox for all measurement data for now
    const previewBox = maximum(imagesLayoutElements.map((element) => getTransformedRotatedBoundingBox(element.measurementData.previewBox)));

    return {
      id: `ornaments-${ornament.type}`,
      renderingOperation: {
        type: "group",
        transform: Matrix.identity(),
        contents: imagesLayoutElements,
        opacityMultiplier: 1,
      },
      status: {
        mode: "local",
      },
      measurementData: {
        // Use the previewBox for all measurement data for now
        boundingBox: previewBox,
        previewBox,
        layoutBox: previewBox,
      },
    };
  }

  // Skip other types of ornaments
  return undefined;
}
