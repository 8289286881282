import { parseColor } from "../../utils/paint/Color";
import { parsePaint } from "../../utils/paint/Paint";
import { LayoutPaint, LayoutColor } from "../Models";
import { ColorPalette } from "@mcp-artwork/cimdoc-types-v2";
import { BoundingBox } from "../../utils/boundingBox";
import CimDocDefinitionTreeNode from "../../utils/CimDocDefinitionTreeNode";

export async function parseFill(
  fillPaint: string | undefined,
  options: { definitionTreeNode?: CimDocDefinitionTreeNode; itemBounds?: BoundingBox; colorPalette?: ColorPalette; fontRepositoryUrl?: string },
): Promise<LayoutPaint | undefined> {
  if (fillPaint === undefined || fillPaint.length === 0) {
    return undefined;
  }

  if (fillPaint.startsWith("paint")) {
    return await parsePaint(fillPaint, options);
  }

  return parseColor(fillPaint, options?.colorPalette);
}

export function parseOverprints(overprints: string[] | undefined, colorPalette?: ColorPalette): LayoutColor[] | undefined {
  if (overprints === undefined || overprints.length === 0) {
    return undefined;
  }
  return overprints.map((o) => parseColor(o, colorPalette));
}
