import { init, TextEngine } from "@mcp-artwork/rtext";
import once from "lodash.once";
import { log } from "../../utils/log";

export const initRText = once(async (logEnabled: boolean) => {
  log({ message: "fetching rtext wasm resource", enabled: logEnabled });
  // Loads the mjs rtext wasm
  const textEngine = await init("https://fusion.documents.cimpress.io/resources/rtext/8.0.0/rtext.wasm");
  log({ message: "done fetching rtext wasm resource", enabled: logEnabled });
  return textEngine;
}) as (logEnable: boolean) => Promise<TextEngine>;
