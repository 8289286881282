import { CimpressDocument, DesignSurface } from "@mcp-artwork/cimdoc-types-v2";
import { Selector } from "../models/Layout";
import { getProjector, project } from "../utils/projections/projectionHelper";
import { selectPanel, selectPanelByItemId, selectPanelByPage } from "./selectPanelFromDocument";

type SelectSurfaceArgs = {
  selector: Selector;
  document: CimpressDocument;
};

export async function selectSurface({ selector, document }: SelectSurfaceArgs): Promise<DesignSurface> {
  if (selector.type === "page") {
    if (document.projectionId) {
      const projector = await getProjector(document.projectionId, selector.number);

      return project(projector, document);
    }

    return selectPanelByPage({ document, page: selector.number });
  }

  if (selector.type === "item") {
    return selectPanelByItemId({ document, itemId: selector.id });
  }

  return selectPanel({ document, id: selector.id });
}
