import { BoundingBox } from "@rendering/plasma";
import type { ImageCrop } from "@rendering/plasma";

export type DestinationAndSourceDimensions = { sx: number; sy: number; sw: number; sh: number; dx: number; dy: number; dw: number; dh: number };

export function getDestinationAndSourceDimensions({
  bounds,
  crop,
  image,
}: {
  bounds: BoundingBox;
  crop?: ImageCrop;
  image: { width: number; height: number };
}): DestinationAndSourceDimensions {
  if (crop) {
    const cLeft: number = image.width * crop.left;
    const cTop: number = image.height * crop.top;
    const cRight: number = image.width * crop.right;
    const cBottom: number = image.height * crop.bottom;

    return {
      sx: cLeft,
      sy: cTop,
      sw: image.width - cRight - cLeft,
      sh: image.height - cBottom - cTop,
      dx: bounds.left,
      dy: bounds.top,
      dw: bounds.width,
      dh: bounds.height,
    };
  }

  return {
    sx: 0,
    sy: 0,
    sw: image.width,
    sh: image.height,
    dx: bounds.left,
    dy: bounds.top,
    dw: bounds.width,
    dh: bounds.height,
  };
}
