import classNames from "classnames";
import { Button } from "../../Components/Button";
import { VortexDesignPreview } from "./VortexDesignPreview";
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import CSS from "./Previews.module.css";

export type DesignPreviewsType = "panels" | "3d" | "scene" | undefined;

type PreviewsProps = {
  designPreviewsType: DesignPreviewsType;
  setDesignPreviewsType: (designPreviewsType: DesignPreviewsType) => void;
  cimDoc: CimpressDocument;
};

export function Previews({ designPreviewsType, setDesignPreviewsType, cimDoc }: PreviewsProps) {
  const isPanels = designPreviewsType === "panels";
  const isThreeD = designPreviewsType === "3d";
  const isScene = designPreviewsType === "scene";

  return (
    <>
      <div className={classNames(CSS.Previews, { [CSS.Panels]: isPanels, [CSS.ThreeD]: isThreeD, [CSS.Scene]: isScene })}>
        {isThreeD && <VortexDesignPreview cimDoc={cimDoc} />}
      </div>
      <div className={CSS.PreviewTypesContainer}>
        <Button className={CSS.Button} onClick={() => setDesignPreviewsType(designPreviewsType !== "3d" ? "3d" : undefined)}>
          3D
        </Button>
        <Button className={CSS.Button} onClick={() => setDesignPreviewsType(designPreviewsType !== "panels" ? "panels" : undefined)}>
          Panels
        </Button>
        <Button className={CSS.Button} onClick={() => setDesignPreviewsType(designPreviewsType !== "scene" ? "scene" : undefined)}>
          Scene
        </Button>
      </div>
    </>
  );
}
