import { useCallback, useContext, useMemo, useState } from "react";
import { DebugOptions, HslFilter, TriangleBlurFilter } from "@rendering/plasma";
import { HslFilterControls } from "./HslFilterControls";
import { useDocument } from "../hooks/useDocument";
import { createFPSCounter } from "../FPSCounter";
import { drawRotatedBoundingBoxes, drawRotatedPreviewBoxes } from "../utils/drawMeasurementData";
import { DocumentEditor } from "../JSONViewer";
import { FusionCanvas, OnPaintArgs } from "@rendering/fusion-react";
import { GlobalOptionsContext } from "../Context/GlobalOptionsContext";
import { logLayoutDiagnostics, logPaintDiagnostics } from "../utils/diagnostics";
import { useSelectors } from "./useSelectors";
import { getDevicePixelRatio } from "../utils/devicePixelRatio";
import { DimensionCalculator } from "../DimensionCalculator";
import { ErrorMessage } from "./ErrorMessage";
import { useImmer } from "use-immer";
import { GravityAnimationControl } from "./GravityAnimationControl";
import CSS from "./DocumentPreview.module.css";
import { BlurFilterControls } from "./BlurFilterControls";

type DocumentPreviewProps = {
  url: string;
};

const defaultHslFilter: HslFilter = {
  type: "hslFilter",
  hueMultiplier: 1,
  hueOffset: 1,
  saturationMultiplier: 1,
  saturationOffset: 0,
  lightnessMultiplier: 1,
  lightnessOffset: 0,
};

const defaultBlurFilters: TriangleBlurFilter[] = [
  {
    type: "triangleBlur",
    deltaX: 0,
    deltaY: 0,
  },
  {
    type: "triangleBlur",
    deltaX: 0,
    deltaY: 0,
  },
];

export function DocumentPreview({ url }: DocumentPreviewProps) {
  const [showFPSCounter, setShowFPSCounter] = useState<boolean>(false);
  const fpsCounter = useMemo(() => createFPSCounter(), []);

  const { projectionSpecification, setDocument, document: cimDoc, error: documentError, onResetDocument } = useDocument({ url });
  const [layoutError, setLayoutError] = useState<any>(null);

  const [showRotatedPreviewBoxes, setShowRotatedPreviewBoxes] = useState<boolean>(false);
  const [showRotatedBoundingBoxes, setShowRotatedBoundingBoxes] = useState<boolean>(false);

  const [filters, setFilters] = useImmer<[HslFilter, TriangleBlurFilter, TriangleBlurFilter]>([defaultHslFilter, defaultBlurFilters[0], defaultBlurFilters[1]]);

  const { selectors, selectedSelector, onSetSelectedSelector } = useSelectors({ cimDoc, projectionSpecification });
  const { options } = useContext(GlobalOptionsContext);
  const videoOptions = useMemo(() => ({ enableVideo: options.videoEnabled }), [options.videoEnabled]);
  const debugOptions = useMemo<DebugOptions>(() => ({ log: options.log, timers: options.timers }), [options.log, options.timers]);

  const onPaint = useCallback(
    (results: OnPaintArgs) => {
      const { canvas, layoutResult, paintResult } = results[0];
      setLayoutError(null);

      // Resize to adjust devicePixelRatio
      canvas.style.width = `${canvas.width / getDevicePixelRatio()}px`;
      canvas.style.height = `${canvas.height / getDevicePixelRatio()}px`;

      logLayoutDiagnostics(layoutResult);
      logPaintDiagnostics([paintResult]);

      const context = canvas.getContext("2d") as CanvasRenderingContext2D;
      if (showFPSCounter) {
        fpsCounter({ canvasContext: context });
      }
      if (showRotatedPreviewBoxes) {
        const { scalar } = paintResult;
        drawRotatedPreviewBoxes({
          scalar,
          layoutElements: layoutResult.elements,
          context,
          color: "#25C2A0",
        });
      }
      if (showRotatedBoundingBoxes) {
        const { scalar } = paintResult;
        drawRotatedBoundingBoxes({
          scalar,
          layoutElements: layoutResult.elements,
          context,
          color: "#1BABEB",
        });
      }
    },
    [showRotatedPreviewBoxes, showRotatedBoundingBoxes, fpsCounter, showFPSCounter],
  );

  const onError = useCallback((e: unknown) => {
    setLayoutError(e);
    console.error(e);
  }, []);

  const onReset = () => {
    onResetDocument();
    setFilters([defaultHslFilter, defaultBlurFilters[0], defaultBlurFilters[1]]);
  };

  const onSelectSelector = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const selector = JSON.parse(value);
    onSetSelectedSelector(selector);
  };

  if (documentError) {
    return <ErrorMessage error={documentError} title="Error loading document" />;
  }

  if (!cimDoc || !selectedSelector) {
    return <div>Loading...</div>;
  }

  return (
    <div className={CSS.Container}>
      <div className={CSS.Preview}>
        {layoutError && <ErrorMessage error={layoutError} title="Layout Error" />}
        <DimensionCalculator>
          {({ dimension }) =>
            dimension && (
              <FusionCanvas
                cimDoc={cimDoc}
                dimension={dimension}
                onError={onError}
                selector={selectedSelector}
                textOptions={options.textOptions}
                filters={filters}
                onPaint={onPaint}
                debugOptions={debugOptions}
                experimentalOptions={options.experimentalOptions}
                videoOptions={videoOptions}
                className={CSS.Canvas}
                referrer="fusion-demo"
              >
                Some text describing the canvas
              </FusionCanvas>
            )
          }
        </DimensionCalculator>
        <div className={CSS.Filters}>
          <HslFilterControls filter={filters[0]} onChange={setFilters} />
          <BlurFilterControls filter={[filters[1], filters[2]]} onChange={setFilters} />
        </div>
        <div className={CSS.ButtonsContainer}>
          <GravityAnimationControl
            cimDoc={cimDoc}
            setCimDoc={setDocument}
            onReset={onReset}
            onStart={() => setShowFPSCounter(true)}
            onPause={() => setShowFPSCounter(false)}
          />
          <button
            className={CSS.Button}
            onClick={() => {
              setShowRotatedPreviewBoxes(!showRotatedPreviewBoxes);
              // trigger rerender
              const newdoc = JSON.parse(JSON.stringify(cimDoc));
              newdoc.ignoreThis = Math.random();
              setDocument(newdoc);
            }}
          >
            {showRotatedPreviewBoxes ? "Hide " : "Show "} rotated preview boxes
          </button>
          <button
            className={CSS.Button}
            onClick={() => {
              setShowRotatedBoundingBoxes(!showRotatedBoundingBoxes);
              // trigger rerender
              const newdoc = JSON.parse(JSON.stringify(cimDoc));
              newdoc.ignoreThis = Math.random();
              setDocument(newdoc);
            }}
          >
            {showRotatedBoundingBoxes ? "Hide " : "Show "} rotated bounding boxes
          </button>
        </div>
      </div>
      <div className={CSS.Editor}>
        <DocumentEditor document={cimDoc} onDocumentChange={(newDocument) => setDocument(newDocument)} />
        <div>
          <h2>Panels / projection:</h2>
          <select value={JSON.stringify(selectedSelector)} onChange={onSelectSelector}>
            {selectors.map((selector) => (
              <option key={selector.name} value={JSON.stringify(selector)}>
                {selector.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
