import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const svgDocument: CimpressDocument = {
  version: "2",
  document: {
    panels: [
      {
        id: "e8e3e476-afcd-4536-8bc5-5734a61cbff9",
        name: "image",
        width: "91.898611mm",
        height: "53.798611mm",
        images: [
          {
            id: "CexvuhcapkyajGDw0POn8H8CVSll8dh1VG9PJfh5K3P48WgOZAkiCSmyceZHEEBV",
            printUrl: "https://c-cluster-100.uploads.documents.cimpress.io/v1/uploads/94d90613-41e8-41ad-b74d-382fdc445ce6~100?tenant=sherbert-uploads",
            previewUrl: "https://c-cluster-100.uploads.documents.cimpress.io/v1/uploads/94d90613-41e8-41ad-b74d-382fdc445ce6~100?tenant=sherbert-uploads",
            zIndex: 13,
            position: {
              x: "265.61mm",
              y: "559.37mm",
              width: "18.33mm",
              height: "18.33mm",
            },
            horizontalAlignment: "left",
            verticalAlignment: "top",
            pageNumber: 0,
            rotationAngle: "0.00",
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
