import classNames from "classnames";
import { FormEvent, InputHTMLAttributes } from "react";
import CSS from "./TextInput.module.css";

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  value: string;
  onTextChange: (value: string) => void;
  className?: string;
};

export function TextInput({ value, onTextChange, className, ...rest }: TextInputProps) {
  const onInput = (e: FormEvent<HTMLInputElement>) => {
    onTextChange(e.currentTarget.value);
  };

  return <input className={classNames(CSS.TextInput, className)} type="text" onInput={onInput} value={value} {...rest} />;
}
