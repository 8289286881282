// Doc on colors: https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/189628717/Colors+CDIF

import { ColorPalette } from "@mcp-artwork/cimdoc-types-v2";
import { LayoutColor } from "../../layout/Models";
import { parseColorForRender } from "@mcp-artwork/color-converter";

const RGBA_REGEX = /^rgba\(([0-9]{1,3}),\s*([0-9]{1,3}),\s*([0-9]{1,3}),\s*([0-9]{1,3})\)$/;
const PALETTE_REGEX = /(^palette\((.*)\))$/;

export const regExMatch = (value: string, matcher: RegExp): RegExpMatchArray => {
  const result = value.match(matcher);
  if (!result) {
    throw new Error(`Regex error for value ${value} with matcher ${matcher}`);
  }
  return result;
};

// Support for legacy premium finishes
export function sanitizeOverprint(overprint: string | undefined): string | undefined {
  if (!overprint) return undefined;

  if (overprint.toLowerCase() === "foil") {
    return "Metallic";
  }

  if (overprint.toLowerCase() === "gloss") {
    return "RaisedInk";
  }

  return overprint;
}

export function parseColor(rawValue: string, colorPalette: ColorPalette | undefined): LayoutColor {
  if (rawValue.match(PALETTE_REGEX)) {
    if (colorPalette) {
      return parsePaletteColor(rawValue, colorPalette);
    } else {
      throw new Error("colorPalette is undefined");
    }
  }

  const result = parseColorForRender(rawValue);

  if (result === undefined) {
    throw new TypeError(`Could not determine format for color: ${rawValue}`);
  }
  return result;
}

const parsePaletteColor = (rawValue: string, colorPalette: ColorPalette): LayoutColor => {
  const [, , paletteColorName] = regExMatch(rawValue, PALETTE_REGEX);

  const paletteColor = colorPalette.palette[paletteColorName];

  if (!paletteColor) {
    throw new Error(`Palette color not found for ${rawValue} in colorPalette`);
  }

  return {
    type: "color",
    name: paletteColorName,
    display: parseColor(paletteColor.color, undefined).display,
    originalValue: rawValue,
  };
};

export const parseRgbaColorValues = (rgbaColor: string): { r: number; g: number; b: number; a: number } | undefined => {
  if (RGBA_REGEX.test(rgbaColor)) {
    const [r, g, b, a] = regExMatch(rgbaColor, /[0-9]{1,4}/g);
    return { r: parseInt(r), g: parseInt(g), b: parseInt(b), a: parseFloat(a) };
  }
};
