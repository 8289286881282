import { Video } from "@mcp-artwork/cimdoc-types-v2";
import { ImageCrop, VideoLayout } from "../Models";
import { ExperimentalOptions, LayoutElement } from "../../models/Layout";
import { BoundingBox, boundingBoxToPreviewBox, computeBoundsFromPosition } from "../../utils/boundingBox";
import { parseMS } from "../../utils/timeUnitHelper";
import { getClip } from "../helpers/Clip";
import { buildTransform } from "../helpers/Transform";

function getCrop(image: Video): ImageCrop | undefined {
  if (image.cropFractions) {
    return {
      bottom: parseFloat(image.cropFractions.bottom),
      top: parseFloat(image.cropFractions.top),
      left: parseFloat(image.cropFractions.left),
      right: parseFloat(image.cropFractions.right),
    };
  }

  return undefined;
}

export async function playableVideoLayout({
  video,
  parentBounds,
  experimentalOptions,
}: {
  video: Video;
  parentBounds: BoundingBox;
  experimentalOptions?: ExperimentalOptions;
  overprints?: string[];
}): Promise<LayoutElement> {
  const boundingBox: BoundingBox = computeBoundsFromPosition({ position: video.position });
  const previewBox: BoundingBox = boundingBoxToPreviewBox({ boundingBox, position: video.position });

  const transform = buildTransform({ bounds: boundingBox, rotationAngle: video.rotationAngle, itemTransforms: video.transforms, translateToBounds: true });

  const renderingOperation: VideoLayout = {
    type: "drawVideo",
    transform,
    crop: getCrop(video),
    clip: await getClip(video, parentBounds),
    opacityMultiplier: video.opacityMultiplier ?? 1,
    startTimeMs: parseMS(video.startTime ?? "0s"),
    segments: video.segments?.map((segment) => ({
      startTimeMs: segment.startTime ? parseMS(segment.startTime) : undefined,
      endTimeMs: segment.endTime ? parseMS(segment.endTime) : undefined,
    })),
  };

  if (!experimentalOptions?.minimizeOutput) {
    const videoResponse = await fetch(video.sourceUrl);
    const videoBlob = await videoResponse.blob();

    renderingOperation.video = videoBlob;
  }

  return {
    id: video.id,
    status: { mode: "local" },
    measurementData: {
      boundingBox,
      previewBox,
      layoutBox: previewBox,
    },
    renderingOperation,
  };
}
