// @ts-nocheck
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const subpanelDocument: CimpressDocument = {
  documentId: "8fed33e3-a8b7-4ee3-acb1-a1d640d42d4d",
  version: "2",
  deleted: false,
  revision: 0,
  owner: "1Q68Z3MeC83IdfeGOPsIzST86kUgZSvK@clients",
  tenant: "account:g2Ez5VaoZWoqU22XqPjTLU",
  document: {
    panels: [
      {
        decorationTechnology: "offsetOrDigital",
        id: "e8e3e476-afcd-4536-8bc5-5734a61cbff9",
        name: "front",
        width: "91.89833611mm",
        height: "53.798611mm",
        subpanels: [
          {
            position: {
              x: "0mm",
              y: "0mm",
            },
            id: "deb3c88e-cf11-423d-83d2-e6d868d56f58",
            images: [],
            textAreas: [
              {
                id: "c4748f01-4084-4f9a-8125-f5d1df8cb392",
                position: {
                  x: "11.291077198935945mm",
                  y: "20.69838305056084mm",
                  width: "57.50277786666666mm",
                  height: "6.2454mm",
                },
                horizontalAlignment: "left",
                verticalAlignment: "top",
                curveAlignment: 0,
                blockFlowDirection: "horizontal-tb",
                textOrientation: "natural",
                zIndex: 2,
                content: [
                  {
                    content: "Hello, World!",
                    type: "inline",
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontSize: "13pt",
                    color: "rgb(#101820)",
                  },
                ],
              },
            ],
            shapes: [
              {
                type: "rectangle",
                stroke: {
                  color: "rgb(#101820)",
                  thickness: "0.683pt",
                  lineCap: "square",
                  lineJoin: "mitre",
                },
                zIndex: 1,
                position: {
                  x: "5.398196971048115mm",
                  y: "6.2431332746080415mm",
                  width: "79.79233248402062mm",
                  height: "41.661873656701026mm",
                },
                id: "7d6a0029-ebba-47c9-b349-ab5cfca7ba9a",
                color: "rgb(#9EA2A2)",
              },
              {
                id: "b8ca9afb-dbd6-4e42-b4d1-2b8e2941244a",
                type: "rectangle",
                zIndex: 3,
                position: {
                  x: "50.338942532386966mm",
                  y: "13.340855500000004mm",
                  width: "27.11692542921235mm",
                  height: "27.11692542921235mm",
                },
                color: "rgb(#C00000)",
              },
            ],
            scale: {
              x: 1.3,
              y: 1,
            },
            decorationTechnology: "offsetOrDigital",
          },
        ],
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
