import { fetchWithNetworkCache } from "../../cache/network";
import { fetchHTMLImageElement } from "./fetchImage";

export type ImageArgs = {
  url: string;
  formData?: FormData;
};

export type xRenderMetaData = {
  actual?: {
    width: string;
    height: string;
  };
  previewBox: {
    left: string;
    top: string;
    width: string;
    height: string;
  };
  snapBox?: {
    left: string;
    top: string;
    width: string;
    height: string;
  };
  pathInclusiveBox?: {
    left: string;
    top: string;
    width: string;
    height: string;
  };
  baselines?: string[];
  resizeFactor?: number;
};

export type BitmapData = {
  status: number;
  image: ImageBitmap;
  xRenderMetadataHeader?: xRenderMetaData;
  xItemReferenceMetadataHeader?: unknown;
  xItemReferenceUseSlotDimensionsHeader?: boolean;
  type: "bitmap";
};

export type HTMLImageElementData = {
  status: number;
  image: HTMLImageElement;
  xRenderMetadataHeader?: xRenderMetaData;
  xItemReferenceMetadataHeader?: unknown;
  xItemReferenceUseSlotDimensionsHeader?: boolean;
  type: "HTMLImageElement";
};

export type SvgData = {
  status: number;
  svg: string;
  xRenderMetadataHeader?: xRenderMetaData;
  xItemReferenceMetadataHeader?: unknown;
  xItemReferenceUseSlotDimensionsHeader?: boolean;
  type: "svg";
};

export type ImageData = HTMLImageElementData | BitmapData | SvgData;

const stringToBoolean = (value: string | undefined): boolean | undefined => {
  if (value === "true") {
    return true;
  }
  if (value === "false") {
    return false;
  }
  return undefined;
};

const responseResolver = async (r: Response | HTMLImageElement): Promise<ImageData> => {
  const response = r as Response;
  const blob: Blob = await response.clone().blob();
  const headers: Headers = response.headers;
  if (headers.get("content-type")?.includes("svg")) {
    const svg = await blob.text();

    return {
      status: 200,
      svg,
      xRenderMetadataHeader: JSON.parse(headers.get("x-render-metadata") as string),
      xItemReferenceMetadataHeader: JSON.parse(headers.get("x-itemreference-metadata") as string),
      xItemReferenceUseSlotDimensionsHeader: stringToBoolean(headers.get("x-itemreference-useslotdimensions") as string),
      type: "svg",
    };
  }

  // createImageBitmap is not supported in all browsers
  if (typeof createImageBitmap !== "undefined") {
    const image = await createImageBitmap(blob);

    return {
      status: 200,
      image,
      xRenderMetadataHeader: JSON.parse(headers.get("x-render-metadata") as string),
      xItemReferenceMetadataHeader: JSON.parse(headers.get("x-itemreference-metadata") as string),
      xItemReferenceUseSlotDimensionsHeader: stringToBoolean(headers.get("x-itemreference-useslotdimensions") as string),
      type: "bitmap",
    };
  }

  // create local url to make sure the same request url is not fetched again with different headers
  const objectURL = URL.createObjectURL(blob);
  const image = await fetchHTMLImageElement({ url: objectURL });
  URL.revokeObjectURL(objectURL);

  return {
    status: 200,
    image,
    xRenderMetadataHeader: JSON.parse(headers.get("x-render-metadata") as string),
    xItemReferenceMetadataHeader: JSON.parse(headers.get("x-itemreference-metadata") as string),
    xItemReferenceUseSlotDimensionsHeader: stringToBoolean(headers.get("x-itemreference-useslotdimensions") as string),
    type: "HTMLImageElement",
  };
};

export const fetchImage = async ({ url, formData }: ImageArgs): Promise<ImageData> => {
  // Local image processing (use native browser image layout)
  if (!url.startsWith("http")) {
    const img = await fetchWithNetworkCache<HTMLImageElement>({ url, fetchOptions: { fetchAsImage: true } });

    return {
      status: 200,
      image: img,
      type: "HTMLImageElement",
    };
  }

  return formData
    ? await fetchWithNetworkCache<ImageData>({ url, init: { method: "POST", body: formData }, responseResolver })
    : await fetchWithNetworkCache<ImageData>({ url, responseResolver });
};

export const loadSvgAsImage = async ({ svg }: { svg: string }): Promise<HTMLImageElement> => {
  const localUrl = URL.createObjectURL(new Blob([svg], { type: "image/svg+xml" }));
  const image = await fetchHTMLImageElement({ url: localUrl });
  URL.revokeObjectURL(localUrl);
  return image;
};
