import { Video, Image, ColorPalette } from "@mcp-artwork/cimdoc-types-v2";
import { ExperimentalOptions, LayoutElement, PreviewType } from "../../models/Layout";
import { BoundingBox } from "../../utils/boundingBox";
import { imageLayout } from "../image/Layout";

export async function staticVideoLayout({
  video,
  parentBounds,
  experimentalOptions,
  overprints,
  referrer,
  previewType,
  colorPalette,
}: {
  video: Video;
  parentBounds: BoundingBox;
  experimentalOptions?: ExperimentalOptions;
  overprints?: string[];
  referrer: string;
  previewType: PreviewType;
  colorPalette: ColorPalette | undefined;
}): Promise<LayoutElement> {
  const image = convertVideoToStaticImage(video);
  return await imageLayout({ image, parentBounds, experimentalOptions, overprints, referrer, previewType, colorPalette });
}

function convertVideoToStaticImage(video: Video): Image {
  return {
    id: video.id,
    position: video.position,
    printUrl: video.previewUrl,
    url: video.previewUrl,
    clipping: video.clipping,
    cropFractions: video.cropFractions,
    opacityMultiplier: video.opacityMultiplier,
    previewUrl: video.previewUrl,
    rotationAngle: video.rotationAngle,
    transforms: video.transforms,
  };
}
