import { FormEvent, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { fetchDocumentFromUrl } from "../../utils/API/document";
import { getDocumentPanels } from "../../selectors/document";
import { CimDocUpdater } from "../models";
import { useLocation, useNavigate } from "react-router-dom";
import { useCloseOnOutside } from "../hooks/useCloseOnOutside";
import { TextInput } from "../../TextInput";
import { cards, weddingInvitations } from "./templates";
import CSS from "./Templates.module.css";

type TemplatesProps = {
  setCimDoc: CimDocUpdater;
};

export function Templates({ setCimDoc }: TemplatesProps) {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const url = query.get("url");
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState<boolean>(!url);

  const onSelect = async (url: string) => {
    navigate(`/designer?url=${encodeURIComponent(url)}`);
  };

  useEffect(() => {
    if (url) {
      setCimDoc(undefined);
      fetchDocumentFromUrl(url).then((cimDoc) => {
        getDocumentPanels(cimDoc).forEach((panel) => {
          // Fill missing cimDoc element ids
          [...(panel.textAreas ?? []), ...(panel.itemReferences ?? []), ...(panel.images ?? []), ...(panel.shapes ?? [])].forEach((item) => {
            if (!item.id) {
              item.id = `${Math.random()}`;
            }
          });
        });

        setCimDoc(cimDoc);
        setShow(false);
      });
    }
  }, [url]);

  useCloseOnOutside({ getElement: () => containerRef.current, callback: () => setShow(false) });

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const url = formData.get("url");
    onSelect(url as string);
  };

  return (
    <div className={classNames(CSS.Templates, { [CSS.Hidden]: !show })} ref={containerRef}>
      {!show && (
        <button onClick={() => setShow(true)} className={CSS.Show}>
          🖼️
        </button>
      )}
      {show && (
        <>
          <h1 className={CSS.H1}>Templates</h1>
          <form onSubmit={onSubmit} className={CSS.Form}>
            <TextInput className={CSS.Input} name="url" type="text" placeholder="Rendering url" />
            <button type="submit">🎣</button>
          </form>
          <h2 className={CSS.H2}>Cards</h2>
          <ul className={CSS.Options}>
            {cards.map((card) => (
              <li onClick={() => onSelect(card)} className={CSS.Option} key={card}>
                <img className={CSS.Image} src={card} />
              </li>
            ))}
          </ul>
          <h2 className={CSS.H2}>Wedding Invitations</h2>
          <ul className={CSS.Options}>
            {weddingInvitations.map((invite) => (
              <li onClick={() => onSelect(invite)} className={CSS.Option} key={invite}>
                <img className={CSS.Image} src={invite} />
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}
