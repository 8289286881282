import { layout, LayoutInput, LayoutResult, TextOptions } from "@rendering/plasma";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { logLayoutDiagnostics } from "../utils/diagnostics";
import { GlobalOptionsContext } from "../Context/GlobalOptionsContext";

export type UseLayoutFusionInput = Omit<LayoutInput, "textOptions"> & { textOptions?: TextOptions };

type UseLayoutProps = { layoutInput: UseLayoutFusionInput };

export type UseLayoutState = {
  layoutResult?: LayoutResult;
  layoutError?: any;
};

const createLayoutRequestNumberGenerator = () => {
  let number = 0;
  let lastCompleted = 0;

  return {
    next() {
      number++;
      return number;
    },
    lastCompletedRequest() {
      return lastCompleted;
    },
    setLastCompletedRequest(request: number) {
      lastCompleted = request;
    },
  };
};

export const useLayout = ({ layoutInput }: UseLayoutProps): UseLayoutState => {
  const [layoutState, setLayoutState] = useState<UseLayoutState>({});
  const globalOptions = useContext(GlobalOptionsContext);
  const animationFrameRef = useRef<number>();
  const layoutRequestNumberGenerator = useMemo(() => createLayoutRequestNumberGenerator(), []);

  useEffect(() => {
    const input: LayoutInput = {
      ...layoutInput,
      textOptions: {
        ...globalOptions.options.textOptions,
        ...layoutInput.textOptions,
      },
      debugOptions: {
        timers: layoutInput.debugOptions?.timers ?? globalOptions.options.timers,
        log: globalOptions.options.log,
      },
      experimentalOptions: {
        ...globalOptions.options.experimentalOptions,
        ...layoutInput.experimentalOptions,
      },
    };

    window.cancelAnimationFrame(animationFrameRef.current!);

    const layoutRequest = layoutRequestNumberGenerator.next();
    animationFrameRef.current = window.requestAnimationFrame(() => {
      layout(input)
        .then((layoutResult) => {
          if (layoutRequest !== 0 && layoutRequest < layoutRequestNumberGenerator.lastCompletedRequest()) {
            // Prevent an old layout to paint over a newer layout
            return;
          } else {
            layoutRequestNumberGenerator.setLastCompletedRequest(layoutRequest);
            logLayoutDiagnostics(layoutResult);
            setLayoutState({ layoutError: undefined, layoutResult });
          }
        })
        .catch((error) => {
          console.error("Error in layout function", { error });
          setLayoutState({ layoutError: error, layoutResult: undefined });
        });
    });

    return () => {
      window.cancelAnimationFrame(animationFrameRef.current!);
    };
  }, [layoutInput, layoutRequestNumberGenerator, globalOptions]);

  return layoutState;
};
