// @ts-nocheck
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const rectangleLinearGradient: CimpressDocument = {
  documentId: "e054c782-9f5f-4f16-8a5e-e9b6069b0b96",
  version: "2",
  deleted: false,
  revision: 0,
  document: {
    definitions: {
      paints: {
        myGradient1: {
          type: "linearGradient",
          start: {
            x: "0%",
            y: "0%",
          },
          end: {
            x: "0%",
            y: "100%",
          },
          stops: [
            {
              offset: "0%",
              color: "rgb(#ff0000)",
            },
            {
              offset: "50%",
              color: "rgb(#008000)",
            },
            {
              offset: "100%",
              color: "rgb(#0000ff)",
            },
          ],
        },
      },
    },
    panels: [
      {
        id: "panel-1",
        name: "Front",
        width: "50mm",
        height: "50mm",
        shapes: [
          {
            type: "rectangle",
            zIndex: 1,
            position: {
              x: "0mm",
              y: "0mm",
              width: "50mm",
              height: "50mm",
            },
            color: "paint(myGradient1)",
            id: "01581b27-2ec2-420f-82db-28bd7d1920c3",
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
