// @ts-nocheck
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const verticalText: CimpressDocument = {
  version: "2",
  deleted: false,
  document: {
    panels: [
      {
        id: "e8e3e476-afcd-4536-8bc5-5734a61cbff9",
        name: "front",
        width: "91.898611mm",
        height: "53.798611mm",
        textAreas: [
          {
            id: "34b0a6b4-dbb3-418b-806a-d434a47bd38d",
            position: {
              x: "15.142137935429526mm",
              y: "28.09119924650726mm",
              width: "17.50277786666666mm",
              height: "10.0888mm",
            },
            horizontalAlignment: "left",
            verticalAlignment: "top",
            curveAlignment: 0,
            blockFlowDirection: "vertical-rl",
            textOrientation: "natural",
            content: [
              {
                content: "hel",
                type: "inline",
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                fontSize: "13pt",
                color: "rgb(#238bf3)",
                overprints: ["spot(RaisedFoilGold)"],
              },
              {
                content: "L",
                type: "inline",
                fontFamily: "Noto Sans",
                fontStyle: "bold",
                fontSize: "21pt",
                color: "rgb(#5ff257)",
              },
              {
                content: "O",
                type: "inline",
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                fontSize: "21pt",
                color: "rgb(#FFDDDD)",
                stroke: {
                  color: "rgb(#922541)",
                  thickness: "1pt",
                },
              },
              {
                content: "world",
                type: "inline",
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                fontSize: "13pt",
                color: "rgb(#101820)",
              },
            ],
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
