// @ts-nocheck
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const subpanelTransformedTextDocument: CimpressDocument = {
  version: "2",
  deleted: false,
  document: {
    panels: [
      {
        id: "e8e3e476-afcd-4536-8bc5-5734a61cbff9",
        name: "front",
        width: "91.898611mm",
        height: "53.798611mm",
        subpanels: [
          {
            position: {
              x: "0mm",
              y: "0mm",
            },
            id: "deb3c88e-cf11-423d-83d2-e6d868d56f58",
            textAreas: [
              {
                id: "34b0a6b4-dbb3-418b-806a-d434a47bd38d",
                position: {
                  x: "15.142137935429526mm",
                  y: "28.09119924650726mm",
                  width: "40mm",
                  height: "10mm",
                },
                rotationAngle: "30",
                zIndex: 1,
                content: [
                  {
                    content: "This is some text",
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontSize: "13pt",
                    color: "rgb(#238bf3)",
                  },
                ],
              },
            ],
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
