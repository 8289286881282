// Ported from https://gitlab.com/Cimpress-Technology/DocDesign/Document/doc.instructions/-/blob/master/src/Doc.Instructions.Domain/Text/TextAlongAPath/TextPathProcessor.cs

import { TextPath, TextArea, Position, TextPathSpecification, TextPathAlignment } from "@mcp-artwork/cimdoc-types-v2";
import { CalculationResult, Glyph, GlyphRun, Rectangle, ResultFont, TextAlignment, TextBlock } from "@mcp-artwork/rtext";
import memoize from "lodash.memoize";
import { Matrix } from "../../../utils/math/matrix";
import { parsePathData } from "../../../utils/parsePathData";
import { RtextCaretLineWithRotation, RtextCaretSpanWithRotation, RtextCaretWithRotation } from "../TextMeasurements";
import { PiecewisePath } from "./piecewisePath";
import { parseMM } from "../../../utils/unitHelper";
import { BoundingBox } from "../../../utils/boundingBox";
import { Point } from "../../../utils/math/geometry";
import { transformBoundingBox } from "../../..";

const getPiecewisePath = memoize(
  (svg: string, unit: string) => {
    const [svgPath] = parsePathData({
      pathData: svg,
      svgPathDataUnit: unit,
      pixelSize: 1,
    });
    const path = new PiecewisePath({ path: svgPath });
    return path;
  },
  (textPath, unit) => textPath + unit,
);

export const getTransformedPiecewisePath = (textArea: TextArea): PiecewisePath => {
  const textPath: TextPath | undefined = textArea.textPath;
  if (textPath === undefined) {
    throw Error("No text path found");
  }
  if (textPath.pathSpecification.type !== "svgPath") {
    throw Error("Only svg paths can be text paths");
  }

  // if viewbox is specified, scale and translate the path
  let dataUnit: string;
  [textPath.pathSpecification.svgPathData, dataUnit] = applyViewboxTransformAndGetUnit(textPath.pathSpecification, textArea.position);
  const path = getPiecewisePath(textPath.pathSpecification.svgPathData, dataUnit);
  return path;
};

export const applyPathToText = (
  textArea: TextArea,
  textBlocks: TextBlock[],
  path: PiecewisePath,
  textAreaResult: CalculationResult,
): {
  glyphRuns: GlyphRun[];
  visibleGlyphs: Set<number>;
} => {
  const outputRuns: GlyphRun[] = [];
  const startOffset = getStartOffset(textArea, path);
  const visibleGlyphs = new Set<number>();
  const cumulativeContentLength: number[] = [];
  let totalLength = 0;
  for (const block of textBlocks) {
    cumulativeContentLength.push(totalLength);
    totalLength += block.content?.length ?? 0;
  }
  for (const glyphRun of textAreaResult.glyphRuns ?? []) {
    for (const glyph of glyphRun.glyphs) {
      const midpoint = (glyph.xOffset ?? 0) + startOffset + ((glyph.xAdvance ?? 0) - (glyph.letterSpacing ?? 0)) / 2;
      const t = midpoint / path.length;

      // ignore out-of-path text
      if (t < 0 || t > 1) {
        continue;
      }
      visibleGlyphs.add(cumulativeContentLength[glyphRun.textBlockIndex ?? 0] + (glyph.cluster ?? 0));
      const pointOnPath = path.getPoint(t);

      // Since glyph rotation (via GlyphPivoting) starts at the beginning of the glyph (not at the center),
      // first assume that the glyph is positioned about the global coordinate (0,0).
      // 1. First, move the glyph so that the center is at (0, 0).
      // 2. Next, rotate the glyph
      // 3. Finally, move the glyph to the location on the path.
      const matrix = Matrix.multiply(
        Matrix.multiply(Matrix.translate(-((glyph.xAdvance ?? 0) - (glyph.letterSpacing ?? 0)) / 2, 0), Matrix.rotation(pointOnPath.theta)),
        Matrix.translate(pointOnPath.point.x, pointOnPath.point.y),
      );

      // Note (copied from .NET service): somewhere in the code, the offset (in this case, the text area position) gets added to the XOffset and
      // YOffset. We actually do want this for text along a path, so don't subtract the XOffset/YOffset by the position.
      const newGlyph: Glyph = {
        cluster: glyph.cluster,
        index: glyph.index,
        xAdvance: glyph.xAdvance,
        yAdvance: glyph.yAdvance,
        xOffset: matrix.x,
        yOffset: matrix.y,
      };

      const newGlyphRun: GlyphRun = {
        baseline: 0,
        orientation: "horizontal",
        glyphPivoting: (pointOnPath.theta * 180) / Math.PI,
        fontIndex: glyphRun.fontIndex,
        fontReference: glyphRun.fontReference,
        fontSize: glyphRun.fontSize,
        glyphs: [newGlyph],
        textBlockIndex: glyphRun.textBlockIndex,
      };

      outputRuns.push(newGlyphRun);
    }
  }

  return {
    glyphRuns: outputRuns,
    visibleGlyphs,
  };
};

export const applyPathToGlyphBounds = (textArea: TextArea, path: PiecewisePath, resultFonts: ResultFont[], glyphRuns: GlyphRun[]): BoundingBox[] => {
  const totalBounds: BoundingBox[] = [];

  const startOffset = getStartOffset(textArea, path);

  if (glyphRuns !== undefined) {
    glyphRuns.forEach((glyphRun) => {
      const engineFont: ResultFont = resultFonts[glyphRun.fontIndex as number];
      glyphRun.glyphs.forEach((glyph) => {
        const blackBoxBounds: Rectangle = engineFont.glyphs?.[glyph.index].blackBoxBounds as Rectangle;
        const glyphBounds: BoundingBox = {
          left: (glyph.xOffset ?? 0) + blackBoxBounds.x * glyphRun.fontSize,
          top: blackBoxBounds.y * glyphRun.fontSize,
          width: blackBoxBounds.width * glyphRun.fontSize,
          height: blackBoxBounds.height * glyphRun.fontSize,
        };

        const midpoint: number = glyphBounds.left + startOffset + glyphBounds.width / 2;
        const t: number = midpoint / path.length;

        // ignore out-of-path text
        if (t < 0 || t > 1) {
          return;
        }

        const point: { point: Point; theta: number } = path.getPoint(t);

        let matrix: Matrix = Matrix.scale(1, -1);
        matrix = Matrix.multiply(matrix, Matrix.translate(-(glyphBounds.left + glyphBounds.width / 2), 0));
        matrix = Matrix.multiply(matrix, Matrix.rotation(point.theta));
        matrix = Matrix.multiply(matrix, Matrix.translate(point.point.x, point.point.y));

        const transformedAbsoluteGlyphBounds: BoundingBox = transformBoundingBox(glyphBounds, matrix);
        totalBounds.push(transformedAbsoluteGlyphBounds);
      });
    });
  }

  return totalBounds;
};

export const applyPathToCarets = (
  textArea: TextArea,
  path: PiecewisePath,
  textAreaResult: CalculationResult,
  visibleGlyphs: Set<number>,
): RtextCaretLineWithRotation[] => {
  const outputCaretLine = {
    baseline: 0,
    spans: [] as RtextCaretSpanWithRotation[],
  };

  const startOffset = getStartOffset(textArea, path);
  let lastCursor: RtextCaretWithRotation | undefined;
  let lastSpan: RtextCaretSpanWithRotation | undefined;
  for (const caretLine of textAreaResult.lineInfos ?? []) {
    for (const span of caretLine.spans ?? []) {
      const newSpan = {
        ...span,
        caretPositions: [],
      } as RtextCaretSpanWithRotation;
      for (const caretPosition of span.caretPositions) {
        const caretX = caretPosition.x + startOffset;
        const t = caretX / path.length;
        const pointOnPath = path.getPoint(t < 0 ? 0 : t > 1 ? 1 : t);
        const cursor = {
          ...caretPosition,
          x: pointOnPath.point.x,
          y: pointOnPath.point.y,
          rotation: ((pointOnPath.theta * 180) / Math.PI + 270) % 360,
        };

        let isCursorVisible = false;
        // ignore out-of-path text
        if (caretPosition.glyphCluster !== undefined && visibleGlyphs.has(caretPosition.glyphCluster)) {
          isCursorVisible = true;
          lastCursor = undefined;
          lastSpan = undefined;
        } else if (!lastCursor && !lastSpan) {
          lastCursor = cursor;
          lastSpan = newSpan;
        }
        if (isCursorVisible) newSpan.caretPositions.push(cursor);
      }
      outputCaretLine.spans.push(newSpan);
    }
  }
  if (lastCursor && lastSpan) lastSpan.caretPositions.push(lastCursor);
  outputCaretLine.spans = outputCaretLine.spans.filter((span) => span.caretPositions.length);
  return [outputCaretLine];
};

export const getStartOffset = (textArea: TextArea, piecewisePath: PiecewisePath): number => {
  if (textArea.textPath?.startOffset !== undefined) {
    return textArea.textPath.startOffset * piecewisePath.length;
  }
  return 0;
};

export const convertTextAlignment = (textAlignment: TextPathAlignment): TextAlignment => {
  switch (textAlignment) {
    case "start":
      return "leading";
    case "center":
      return "center";
    case "end":
      return "trailing";
  }
};

/**
 *
 * @param viewBox The SVG viewbox in the format "x y width height"
 * @returns a Position
 */
const viewBoxToPosition = (viewBox: string, unit: string): Position => {
  const parts: string[] = viewBox.split(" ");

  return {
    x: `${parts[0]}${unit}`,
    y: `${parts[1]}${unit}`,
    width: `${parts[2]}${unit}`,
    height: `${parts[3]}${unit}`,
  };
};

/**
 * Transforms the SVG path by the viewbox transform. Note that we aren't using the SVG definition of
 * viewbox since there isn't any clipping. We are only interested in using a viewbox as a method of scaling.
 * This is also identical to the viewbox transform in curves, but they are similar.
 *
 * @param path the original svg path
 * @param viewBox Viewbox as a Position
 * @param textAreaPosition The TextArea Position
 *
 * @returns the transformed path
 */
export const applyViewboxTransformAndGetUnit = (pathSpec: TextPathSpecification, textAreaPosition: Position): string[] => {
  if ("viewBox" in pathSpec && "svgPathDataUnit" in pathSpec) {
    throw new Error("Must specify either svgPathDataUnit or viewBox, but not both");
  }
  if ("viewBox" in pathSpec) {
    // The unit is completely arbitrary (though it can't be zero). In this case the arbitrary unit chosen is millimeters.
    const dataUnit = "mm";
    let transform = Matrix.identity();
    const viewBox: Position = viewBoxToPosition(pathSpec.viewBox, dataUnit);
    const scaleX: number = parseMM(textAreaPosition.width) / parseMM(viewBox.width);
    const scaleY: number = parseMM(textAreaPosition.height) / parseMM(viewBox.height);

    transform = Matrix.multiply(transform, Matrix.translate(-parseMM(viewBox.x), -parseMM(viewBox.y)));
    // Scale the path
    transform = Matrix.multiply(transform, Matrix.scale(scaleX, scaleY));

    let [svgPath] = parsePathData({
      pathData: pathSpec.svgPathData,
      pixelSize: 1,
      svgPathDataUnit: dataUnit,
      closeBehavior: "explicit",
    });

    // Now translate with the textArea position x/y, but this is done in separate code so don't do it here.

    svgPath = svgPath.matrix([transform.a, transform.b, transform.c, transform.d, transform.x, transform.y]);

    return [svgPath.toString(), dataUnit];
  } else if ("svgPathDataUnit" in pathSpec) {
    return [pathSpec.svgPathData, pathSpec.svgPathDataUnit];
  } else {
    throw new Error("Must specify one of viewBox or svgPathDataUnit");
  }
};
