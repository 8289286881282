import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const ellipseRadialGradient: CimpressDocument = {
  documentId: "b58edb11-e828-4fe1-bf6a-d65857ff1311",
  version: "2",
  deleted: false,
  revision: 0,
  owner: "1Q68Z3MeC83IdfeGOPsIzST86kUgZSvK@clients",
  tenant: "account:g2Ez5VaoZWoqU22XqPjTLU",
  document: {
    definitions: {
      paints: {
        myGradient1: {
          type: "radialGradient",
          end: {
            x: "50%",
            y: "50%",
          },
          endRadius: "50%",
          stops: [
            {
              offset: "0%",
              color: "rgb(#ff0000)",
            },
            {
              offset: "50%",
              color: "rgb(#008000)",
            },
            {
              offset: "100%",
              color: "rgb(#0000ff)",
            },
          ],
          spreadMethod: "pad",
        },
      },
    },
    panels: [
      {
        id: "e8e3e476-afcd-4536-8bc5-5734a61cbff9",
        name: "front",
        width: "91.898611mm",
        height: "53.798611mm",
        shapes: [
          {
            type: "ellipse",
            zIndex: 1,
            position: {
              x: "20.038615091304052mm",
              y: "11.071055500000002mm",
              width: "46.23901964301075mm",
              height: "31.65651083700716mm",
            },
            id: "5db06065-bbeb-4d37-a0d2-dcdfbf5f9080",
            color: "paint(myGradient1)",
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/prod",
};
