import classNames from "classnames";
import CSS from "./FontStyleInput.module.css";

type FontStyleInputProps = {
  fontStyle: string;
  onChange: (value: string) => void;
  className?: string;
};

export function FontStyleInput({ fontStyle, onChange, className }: FontStyleInputProps) {
  const onToggle = (value: "bold" | "strikeout" | "italic" | "underline") => {
    let styles = fontStyle.split(",");

    if (styles.find((s) => s.trim() === value)) {
      styles = styles.filter((s) => s.trim() !== value);
    } else {
      styles.push(value);
    }

    const newValue = styles.join(",");
    onChange(newValue ? newValue : "normal");
  };

  return (
    <div className={classNames(className)}>
      <button className={classNames(CSS.Button, CSS.Bold, { [CSS.Active]: fontStyle.includes("bold") })} onClick={() => onToggle("bold")}>
        B
      </button>
      <button className={classNames(CSS.Button, CSS.Italic, { [CSS.Active]: fontStyle.includes("italic") })} onClick={() => onToggle("italic")}>
        I
      </button>
      <button className={classNames(CSS.Button, CSS.Underline, { [CSS.Active]: fontStyle.includes("underline") })} onClick={() => onToggle("underline")}>
        U
      </button>
      <button className={classNames(CSS.Button, CSS.Strikeout, { [CSS.Active]: fontStyle.includes("strikeout") })} onClick={() => onToggle("strikeout")}>
        S
      </button>
    </div>
  );
}
