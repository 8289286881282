import { ColorPalette, Stroke } from "@mcp-artwork/cimdoc-types-v2";
import { BoundingBox } from "../../utils/boundingBox";
import { parseMM } from "../../utils/unitHelper";
import { LayoutStroke, LineCap, LineJoin } from "../Models";
import { parseFill, parseOverprints } from "./Paint";
import CimDocDefinitionTreeNode from "../../utils/CimDocDefinitionTreeNode";

export async function getLayoutStroke(
  stroke: Stroke | undefined,
  options: { definitionTreeNode?: CimDocDefinitionTreeNode; itemBounds?: BoundingBox; colorPalette: ColorPalette | undefined },
): Promise<LayoutStroke | undefined> {
  if (stroke) {
    return {
      fill: await parseFill(stroke.color, options),
      overprints: parseOverprints(stroke.overprints, options.colorPalette),
      width: parseMM(stroke.thickness ?? "0mm"),
      lineJoin: parseLineJoin(stroke.lineJoin),
      lineCap: parseLineCap(stroke.lineCap),
      dashArray: stroke.dashPattern?.segments.map<number>((segment) => parseMM(segment.length)),
    };
  }

  return undefined;
}

function parseLineCap(lineCap?: string): LineCap {
  if (lineCap) {
    return lineCap.toLowerCase() as LineCap;
  }

  // Default
  return "round";
}

function parseLineJoin(lineJoin?: string): LineJoin {
  if (lineJoin) {
    return lineJoin.toLowerCase() as LineJoin;
  }

  // Default
  return "round";
}
