import CSS from "./ErrorMessage.module.css";

export function ErrorMessage({ error, title }: { error: Error; title: string }) {
  return (
    <div className={CSS.Error}>
      <strong>{title}</strong>
      <pre>{error.message}</pre>
    </div>
  );
}
