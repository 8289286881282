/**
 * Vector format is [a, b]
 */
export class Vector2 {
  public a: number;

  public b: number;

  constructor(a: number, b: number) {
    this.a = a;
    this.b = b;
  }

  public normalize(): Vector2 {
    const length = this.magnitude();

    return new Vector2(this.a / length, this.b / length);
  }

  public dotProduct(other: Vector2): number {
    return this.a * other.a + this.b * other.b;
  }

  public crossProduct(other: Vector2): number {
    return this.a * other.b - this.b * other.a;
  }

  public magnitude(): number {
    return Math.sqrt(this.a ** 2 + this.b ** 2);
  }
}
