import { CimpressDocument, DesignSurface, Image, ItemReference, TextArea } from "@mcp-artwork/cimdoc-types-v2";
import { useRef } from "react";
import { selectFirstSurface } from "../selectors/document";
import { createBodies, createGlobalBounds, step } from "./engine";
import { MassiveBody } from "./primitives";

type BodiesState = {
  images: MassiveBody[];
  textAreas: MassiveBody[];
  itemReferences: MassiveBody[];
};

type UpdateLookArgs = { timestamp: DOMHighResTimeStamp; document: CimpressDocument };

type UsePhysicsToDocumentReturn = {
  updateLoop: (args: UpdateLookArgs) => CimpressDocument;
  resetAnimation: () => void;
};

export function usePhysicsToDocument(): UsePhysicsToDocumentReturn {
  const lastUpdate = useRef<number>(0);
  const bodies = useRef<BodiesState>();

  function updateLoop({ document, timestamp }: UpdateLookArgs): CimpressDocument {
    const surface = selectFirstSurface(document);
    const globalBounds = createGlobalBounds(surface);

    if (!bodies.current) {
      bodies.current = {
        images: createBodies(surface.images?.map((image) => ({ position: image.position, rotationAngle: image.rotationAngle })) ?? []),
        textAreas: createBodies(surface.textAreas?.map((textArea) => ({ position: textArea.position, rotationAngle: textArea.rotationAngle })) ?? []),
        itemReferences: createBodies(
          surface.itemReferences?.map((itemReference) => ({ position: itemReference.position, rotationAngle: itemReference.rotationAngle })) ?? [],
        ),
      };
    }

    const deltaTime = Math.min(timestamp - lastUpdate.current, 60);
    lastUpdate.current = timestamp;

    // Update all the bodies
    const images = step(deltaTime, bodies.current.images, globalBounds);
    const textAreas = step(deltaTime, bodies.current.textAreas, globalBounds);
    const itemReferences = step(deltaTime, bodies.current.itemReferences, globalBounds);

    bodies.current = { images, textAreas, itemReferences };

    const clonedDocument = JSON.parse(JSON.stringify(document));
    const clonedSurface: DesignSurface = selectFirstSurface(clonedDocument);

    for (let i = 0; i < bodies.current.images.length; i++) {
      const body = bodies.current.images[i] as MassiveBody;
      const image = clonedSurface.images![i];

      image.position = {
        ...image.position,
        x: `${body.bounds.x}mm`,
        y: `${body.bounds.y}mm`,
      };
      image.rotationAngle = `${body.rotation}`;
    }

    for (let i = 0; i < bodies.current.textAreas.length; i++) {
      const body = bodies.current.textAreas[i] as MassiveBody;
      const textArea = clonedSurface.textAreas![i];

      textArea.position = {
        ...textArea.position,
        x: `${body.bounds.x}mm`,
        y: `${body.bounds.y}mm`,
      };

      textArea.rotationAngle = `${body.rotation}`;
    }

    for (let i = 0; i < bodies.current.itemReferences.length; i++) {
      const body = bodies.current.itemReferences[i] as MassiveBody;
      const itemReference = clonedSurface.itemReferences![i];

      itemReference.position = {
        ...itemReference.position,
        x: `${body.bounds.x}mm`,
        y: `${body.bounds.y}mm`,
      };

      itemReference.rotationAngle = `${body.rotation}`;
    }

    return clonedDocument;
  }

  return {
    updateLoop,
    resetAnimation: () => {
      bodies.current = undefined;
    },
  };
}
