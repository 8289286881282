import {
  HorizontalAlignment,
  MatrixTransformV2,
  ScaleTransformV2,
  SkewTransformV2,
  Transform,
  TransformOrigin,
  VerticalAlignment,
} from "@mcp-artwork/cimdoc-types-v2";
import { transformPoint, Matrix } from "../../utils/math/matrix";
import { mmToString, parseMM, toRadians } from "../../utils/unitHelper";
import { computeCenter, BoundingBox } from "../../utils/boundingBox";

export function buildTransform({
  bounds,
  skew,
  scale,
  rotationAngle,
  mirrorDirection,
  matrixTransform,
  itemTransforms,
  imageAlignment,
  translateToBounds,
}: {
  bounds: BoundingBox;
  skew?: SkewTransformV2;
  scale?: ScaleTransformV2;
  rotationAngle?: string | number;
  mirrorDirection?: "horizontal" | "vertical";
  matrixTransform?: MatrixTransformV2;
  itemTransforms?: Transform[];
  imageAlignment?: {
    horizontalAlignment?: HorizontalAlignment;
    verticalAlignment?: VerticalAlignment;
  };
  translateToBounds?: boolean;
}) {
  const boundsCenter = computeCenter(bounds);
  let transform = Matrix.identity();

  if (translateToBounds) {
    transform = Matrix.multiply(transform, Matrix.translate(bounds.left, bounds.top));
  }
  if (imageAlignment) {
    transform = Matrix.multiply(transform, getImageAlignmentTransform(bounds, imageAlignment.horizontalAlignment, imageAlignment.verticalAlignment));
  }
  if (skew) {
    transform = Matrix.multiply(transform, Matrix.skewAboutPoint(skew.x, skew.y, boundsCenter));
  }
  if (scale) {
    transform = Matrix.multiply(transform, Matrix.scaleAboutPoint(scale.x, scale.y, boundsCenter));
  }
  if (mirrorDirection) {
    transform = Matrix.multiply(transform, Matrix.mirrorAboutPoint(mirrorDirection, boundsCenter));
  }

  if (rotationAngle) {
    const rotation: number = toRadians(rotationAngle);

    transform = Matrix.multiply(transform, Matrix.rotateAboutCenter(rotation, bounds));
  }

  if (matrixTransform) {
    transform = Matrix.multiply(
      transform,
      new Matrix(matrixTransform.a, matrixTransform.b, matrixTransform.c, matrixTransform.d, parseMM(matrixTransform.x), parseMM(matrixTransform.y)),
    );
  }

  if (itemTransforms) {
    transform = Matrix.multiply(transform, buildItemTransform({ transforms: itemTransforms, bounds }));
  }

  return transform;
}

function applyTransformRelative(matrix: Matrix, relativeToX: number, relativeToY: number): Matrix {
  return Matrix.multiply(Matrix.multiply(Matrix.translate(-relativeToX, -relativeToY), matrix), Matrix.translate(relativeToX, relativeToY));
}

function findRelativePoint(origin1: TransformOrigin | undefined, bounds?: BoundingBox, container?: BoundingBox): { x: number; y: number } {
  const origin = origin1 ?? {
    base: "itemCenter",
    xOffset: undefined,
    yOffset: undefined,
  };
  let result = { x: parseMM(origin.xOffset ?? "0mm"), y: parseMM(origin.yOffset ?? "0mm") };
  switch (origin.base) {
    case "container":
      if (container === undefined) {
        throw Error("Container is needed for container bounds");
      }
      result = { x: result.x + container.left, y: result.y + container.top };
      break;
    case "itemCenter":
      if (bounds === undefined) {
        throw Error("Bounds are needed for itemCenter bounds");
      }
      result = { x: result.x + bounds.left + bounds.width / 2, y: result.y + bounds.top + bounds.height / 2 };
      break;
    default:
      throw Error("Invalid transform origin base");
  }
  return result;
}

function convertTransform(transform: Transform, bounds: BoundingBox | undefined, container: BoundingBox | undefined): Matrix {
  switch (transform.type) {
    case "matrix":
      return new Matrix(transform.scaleX, transform.skewX, transform.skewY, transform.scaleY, parseMM(transform.translateX), parseMM(transform.translateY));
    case "mirror":
      if (bounds === undefined) {
        throw Error("Bounds needed for mirror transform");
      }
      return applyTransformRelative(Matrix.mirror(transform.direction), bounds.left + bounds.width / 2, bounds.top + bounds.height / 2);
    case "rotate": {
      const relativePoint = findRelativePoint(transform.origin, bounds, container);
      return Matrix.rotateAboutPoint((transform.degreesClockwise * Math.PI) / 180, relativePoint.x, relativePoint.y);
    }
    case "skew": {
      const relativePoint = findRelativePoint(transform.origin, bounds, container);
      return applyTransformRelative(Matrix.skew((transform.angleDegrees * Math.PI) / 180, transform.axis), relativePoint.x, relativePoint.y);
    }
    case "translate":
      return Matrix.translate(parseMM(transform.translateX ?? "0mm"), parseMM(transform.translateY ?? "0mm"));
    case "scale": {
      const relativePoint = findRelativePoint(transform.origin, bounds, container);
      return applyTransformRelative(new Matrix(transform.scaleX ?? 1, 0, 0, transform.scaleY ?? 1, 0, 0), relativePoint.x, relativePoint.y);
    }
    default:
      throw Error("Invalid transform type");
  }
}

function getImageAlignmentTransform(boundingBox: BoundingBox, horizontalAlignment?: HorizontalAlignment, verticalAlignment?: VerticalAlignment): Matrix {
  let xTranslation = 0;
  let yTranslation = 0;

  if (horizontalAlignment === "center") {
    xTranslation = -boundingBox.width / 2;
  } else if (horizontalAlignment === "right") {
    xTranslation = -boundingBox.width;
  }

  if (verticalAlignment === "middle") {
    yTranslation = -boundingBox.height / 2;
  } else if (verticalAlignment === "bottom") {
    yTranslation = -boundingBox.height;
  }

  return Matrix.translate(xTranslation, yTranslation);
}

export function buildItemTransform({
  transforms = [],
  bounds,
  container,
}: {
  transforms?: Transform[];
  bounds?: BoundingBox;
  container?: BoundingBox;
}): Matrix {
  return transforms.reduce<Matrix>((previous, transform) => Matrix.multiply(previous, convertTransform(transform, bounds, container)), Matrix.identity());
}

export function transformBoundingBox(boundingBox: BoundingBox, matrix: Matrix): BoundingBox {
  const left = boundingBox.left;
  const top = boundingBox.top;
  const right = left + boundingBox.width;
  const bottom = top + boundingBox.height;
  const points = [
    { x: left, y: top },
    { x: right, y: top },
    { x: right, y: bottom },
    { x: left, y: bottom },
  ];

  const transformedPoints = points.map((point) => transformPoint(matrix, point));
  let xMin = transformedPoints[0].x;
  let xMax = transformedPoints[0].x;
  let yMin = transformedPoints[0].y;
  let yMax = transformedPoints[0].y;

  for (const point of transformedPoints) {
    xMin = Math.min(xMin, point.x);
    xMax = Math.max(xMax, point.x);
    yMin = Math.min(yMin, point.y);
    yMax = Math.max(yMax, point.y);
  }

  return {
    left: xMin,
    top: yMin,
    width: xMax - xMin,
    height: yMax - yMin,
  };
}

export function matrixToTransform(matrix: Matrix): Transform {
  return {
    type: "matrix",
    scaleX: matrix.a,
    skewX: matrix.b,
    skewY: matrix.c,
    scaleY: matrix.d,
    translateX: mmToString(matrix.x),
    translateY: mmToString(matrix.y),
  };
}
