import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const clippingWithTextDocument: CimpressDocument = {
  version: "2",
  deleted: false,
  document: {
    panels: [
      {
        id: "e8e3e476-afcd-4536-8bc5-5734a61cbff9",
        name: "front",
        width: "91.898611mm",
        height: "53.798611mm",
        images: [
          {
            id: "51baf726-1dde-4717-8dc7-53d30827d06d",
            printUrl: "https://uploads.documents.cimpress.io/v1/uploads/bad2c01d-21cf-4356-a410-de493d5beaef~110?tenant=doc-platform",
            previewUrl: "https://uploads.documents.cimpress.io/v1/uploads/bad2c01d-21cf-4356-a410-de493d5beaef~110?tenant=doc-platform",
            zIndex: 1,
            position: {
              x: "0mm",
              y: "-11.5860945mm",
              width: "76.85251843340683mm",
              height: "76.97075307715053mm",
            },
            horizontalAlignment: "left",
            verticalAlignment: "top",
            pageNumber: 1,
            clipping: {
              position: {
                x: "0mm",
                y: "0mm",
                scaleX: 1,
              },
              specification: {
                type: "textArea",
                textArea: {
                  id: "a3274828-28b3-4cc6-b941-eacb12129cf2",
                  position: {
                    x: "8.601871626211885mm",
                    y: "18.541914699359587mm",
                    width: "57.50277786666666mm",
                    height: "27.4402mm",
                  },
                  horizontalAlignment: "left",
                  verticalAlignment: "top",
                  zIndex: 0,
                  content: [
                    {
                      content: "Andrew",
                      fontFamily: "Satisfy",
                      fontStyle: "bold",
                      fontSize: "54pt",
                      color: "rgb(#101820)",
                    },
                  ],
                },
              },
            },
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
