// @ts-nocheck
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const embroideryText: CimpresDocument = {
  version: "2",
  deleted: false,
  document: {
    surfaces: [
      {
        name: "Item",
        width: "75mm",
        height: "0mm",
        textAreas: [
          {
            id: "a23e7564-5b15-4c35-9d1b-c995e032c341",
            position: {
              x: "0mm",
              y: "0mm",
              width: "75mm",
              height: "0mm",
            },
            horizontalAlignment: "left",
            verticalAlignment: "top",
            content: [
              {
                content: "Your text here!",
                type: "inline",
                fontFamily: "Shen Modern",
                fontStyle: "normal",
                fontSize: "30pt",
                color: "thread(mcp0015)",
              },
            ],
          },
        ],
        decorationTechnology: "embroideryFlat",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
