import { InstructionsRequest } from "./instructionsClient";

const RENDERING_SERVICE = "https://rendering.documents.cimpress.io";
const MAX_DOCUMENT_LENGTH = 1700;

export type RenderingRequest = {
  previewUri: string;
  instructionsPostData?: string;
};

export function buildRenderingRequest({
  instructionsRequest,
  tenant,
  pixelSize,
  quality,
  format,
  breakCache = false,
  overprint,
  referrer,
}: {
  instructionsRequest: InstructionsRequest;
  tenant?: string;
  pixelSize: string;
  quality?: number;
  format?: string;
  breakCache?: boolean;
  overprint?: string;
  referrer: string;
}): RenderingRequest {
  const { instructionsUri, documentUri } = instructionsRequest;
  let instructionsPostData: string | undefined = undefined;

  const params = new URLSearchParams();

  // If the document is small enough to fit on the query string add it as a parameter
  if (documentUri.length < MAX_DOCUMENT_LENGTH) {
    const instructions = `${instructionsUri}?documentUri=${encodeURIComponent(documentUri)}`;
    params.set("instructions_uri", instructions);
  } else {
    // Otherwise set the instructions endpoint and return POST data containing the document
    params.set("instructions_uri", instructionsUri);
    instructionsPostData = `{"documentUri":"${documentUri}"}`;
  }

  params.set("pixel", pixelSize);

  if (breakCache) {
    params.set("bc", `${Math.random()}`);
  }

  if (quality !== undefined) {
    if (quality > 0 && quality <= 100) {
      throw Error("Render quality must be between 0 and 100!");
    }
    params.set("quality", quality.toString());
  }

  if (format) {
    params.set("format", format);
  }

  if (overprint) {
    params.set("finish", overprint.replace("spot(", "").replace(")", ""));
    params.set("bgcolor", "00000000");
  }

  params.set("referrer", referrer);

  return {
    previewUri: `${RENDERING_SERVICE}/v1/${tenant}/preview?${params.toString()}`,
    instructionsPostData,
  };
}
