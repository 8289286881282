import { LayoutResult, LayoutElement, sanitizeOverprint } from "@rendering/plasma";
import memoize from "lodash.memoize";
import { applyClip } from "./paint/Clip";
import { computeDimensions, getLayoutBounds } from "./paint/dimensions/calculator";
import { runFilters } from "./paint/Filter";
import { paintImage, paintSSRImage } from "./paint/Image";
import { paintPaths } from "./paint/Path";
import { log } from "./utils/log";
import { timestamp } from "./utils/time";

export type PaintOverrides = {
  getAdditionalCanvas: (id: number) => CanvasRenderingContext2D;
};

export type PaintInput = {
  /** The context of the canvas that should be painted on */
  canvasContext: CanvasRenderingContext2D;
  /** If undefined, then we are painting the default channel (RGB). If set, this indicates which overprint channel we are painting */
  overprint?: string;
  /** The contents to be painted (the result of calling the layout function in plasma) */
  layoutResult: LayoutResult;
  /** The physical size in the source document that one pixel of output should represent. For example, if the document is 20mm wide and this is set to 2mm, then the resulting image will be 10 pixels wide */
  pixelSize: string;
  /** The number of pixels to be added on each of the four sides of the output */
  paddingPx?: number;
  debugOptions?: {
    timers?: boolean;
    ssrDimensions?: boolean;
    log?: boolean;
  };
  /**
   * Experimental option, subject to change.
   * Specify these when using nodejs so this engine doesn't need to create a second canvas */
  overrides?: PaintOverrides;
};

export type PaintResult = {
  /** Scaling factor from mm of the input document to pixels in the output image */
  scalar: number;
  debugInfo?: {
    timers?: { paint: number };
  };
};

// Offscreen canvas used for intermediate painting operations
// The ID parameter allows us to memoize more than one canvas, because
// we may need multiple canvases at a time.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getOffscreenCanvas = memoize((id: number): CanvasRenderingContext2D => {
  const paintingCanvas: HTMLCanvasElement = document.createElement("canvas");
  const paintingContext = paintingCanvas.getContext("2d") as CanvasRenderingContext2D;
  return paintingContext;
});

export function paint({ canvasContext, overprint, layoutResult, pixelSize, paddingPx, debugOptions, overrides }: PaintInput): PaintResult {
  log({ message: "calling paint function", enabled: !!debugOptions?.log, objects: { canvasContext, layoutResult, pixelSize, debugOptions, overprint } });

  const start = timestamp(!!debugOptions?.timers);

  const offscreenCanvasCreator = overrides ? overrides.getAdditionalCanvas : getOffscreenCanvas;
  const cacheContext = offscreenCanvasCreator(1);
  const paintingCanvas = canvasContext.canvas;
  const cacheCanvas = cacheContext.canvas;

  const { width, height, scalar } = computeDimensions({ pixelSize, layoutResult, ssr: !!debugOptions?.ssrDimensions });

  log({ message: "computed dimensions", enabled: !!debugOptions?.log, objects: { width, height, scalar } });

  // Set the input canvas to the computed dimensions
  canvasContext.canvas.width = width;
  canvasContext.canvas.height = height;
  canvasContext.save();

  // Adjust painting size to device pixel ratio
  paintingCanvas.width = width;
  paintingCanvas.height = height;

  if (paddingPx) {
    paintingCanvas.width += Math.ceil(paddingPx) * 2;
    paintingCanvas.height += Math.ceil(paddingPx) * 2;
  }

  // Clear the provided canvas
  canvasContext.clearRect(0, 0, paintingCanvas.width, paintingCanvas.height);

  const bounds = getLayoutBounds(layoutResult);

  canvasContext.save();

  try {
    log({ message: "bounds used", enabled: !!debugOptions?.log, objects: { bounds } });

    if (paddingPx) {
      canvasContext.transform(1, 0, 0, 1, Math.ceil(paddingPx), Math.ceil(paddingPx));
    }

    // For item previews of ssr fallback elements, bypass the standard rendering logic in order
    // to ensure the image is drawn at exactly its native size. Otherwise this will result in blurriness
    // due to rounding and transforming the htmlcanvas context.
    if (
      layoutResult.layoutType === "item" &&
      layoutResult.elements[0].status.mode === "server" &&
      layoutResult.elements[0].renderingOperation.type === "drawImage"
    ) {
      paintSSRImage({ context: canvasContext, layout: layoutResult.elements[0].renderingOperation, overprint: sanitizeOverprint(overprint) });
    } else {
      canvasContext.transform(scalar, 0, 0, scalar, 0, 0);
      canvasContext.transform(1, 0, 0, 1, -bounds.left, -bounds.top);

      paintElements(layoutResult.elements, canvasContext, 0, cacheCanvas, cacheContext, pixelSize, sanitizeOverprint(overprint));
    }
  } finally {
    canvasContext.restore();
  }

  // All overprints are composited using black + white in order to correctly apply overprint
  // knockout with overlaps. The final result to the caller should be an alpha + white mask
  // which matches renderings output and works directly with vortex
  if (overprint) {
    layoutResult.filters = [
      ...(layoutResult.filters ?? []),
      {
        type: "colorMatrix",
        matrix: [
          [0, 0, 0, 0],
          [0, 0, 0, 0],
          [0, 0, 0, 0],
          [0, 1, 0, 0],
          [1, 1, 1, 0],
        ],
      },
    ];
  }

  // Apply filters to the result before draw it
  if (layoutResult.filters && layoutResult.filters.length > 0) {
    const filteredResult: HTMLCanvasElement = runFilters(layoutResult.filters, paintingCanvas);
    // Before drawing the filtered result, clear the canvas
    canvasContext.clearRect(0, 0, paintingCanvas.width, paintingCanvas.height);
    canvasContext.drawImage(filteredResult, 0, 0, canvasContext.canvas.width, canvasContext.canvas.height);
  }

  canvasContext.restore();

  return {
    scalar,
    ...(debugOptions?.timers && start
      ? {
          debugInfo: {
            timers: {
              paint: timestamp(true) - start,
            },
          },
        }
      : {}),
  };
}

function paintElements(
  elements: LayoutElement[],
  context: CanvasRenderingContext2D,
  recursionDepth: number,
  cacheCanvas: HTMLCanvasElement,
  cacheContext: CanvasRenderingContext2D,
  pixelSize: string,
  overprint?: string,
) {
  if (recursionDepth > 99) {
    throw Error("Maximum recursion depth reached");
  }

  for (const element of elements) {
    if (element.renderingOperation.type === "drawImage") {
      const layout = element.renderingOperation;

      // Bounds may be bounds of the clip instead of the item. We want to draw the image in the rectangle
      // that is generated using the image bounds, not clip bounds.
      paintImage({ context, layout, bounds: layout.boundingBox, overprint, cacheCanvas, cacheContext });
    }

    if (element.renderingOperation.type === "drawPaths") {
      const layout = element.renderingOperation;
      paintPaths({ context, layout, overprint, pixelSize });
    }

    if (element.renderingOperation.type === "group") {
      const layout = element.renderingOperation;

      context.save();

      try {
        applyClip({ context, clip: layout.crop });
        applyClip({ context, clip: layout.clip });
        context.globalAlpha = context.globalAlpha * layout.opacityMultiplier;
        const transform = layout.transform;

        // Note that context.transform multiplies this transform by the current transform
        // (instead of replacing it, like context.setTransform does.)
        context.transform(transform.a, transform.b, transform.c, transform.d, transform.x, transform.y);

        paintElements(layout.contents, context, recursionDepth + 1, cacheCanvas, cacheContext, pixelSize, overprint);
      } finally {
        context.restore();
      }
    }
  }
}
