import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export const linearGradientText: CimpressDocument = {
  documentId: "0a4dff7f-d7eb-452a-bd4b-aefc1050e21f",
  version: "2",
  deleted: false,
  revision: 0,
  owner: "1Q68Z3MeC83IdfeGOPsIzST86kUgZSvK@clients",
  tenant: "account:g2Ez5VaoZWoqU22XqPjTLU",
  document: {
    definitions: {
      paints: {
        myGradient1: {
          type: "linearGradient",
          start: {
            x: "12.571954935560624mm",
            y: "13.084006782167512mm",
          },
          end: {
            x: "12.571954935560624mm",
            y: "19.32mm",
          },
          stops: [
            {
              offset: "0%",
              color: "rgb(#FF0000)",
            },
            {
              offset: "100%",
              color: "rgb(#00FFFF)",
            },
          ],
          spreadMethod: "pad",
        },
      },
    },
    panels: [
      {
        id: "e8e3e476-afcd-4536-8bc5-5734a61cbff9",
        name: "front",
        width: "91.898611mm",
        height: "53.798611mm",
        textAreas: [
          {
            id: "d949f610-bd0b-4ef9-8e75-bbb9bb2c0206",
            position: {
              x: "12.571954935560624mm",
              y: "13.084006782167512mm",
              width: "57.50277786666666mm",
              height: "6.2454mm",
            },
            horizontalAlignment: "left",
            verticalAlignment: "top",
            blockFlowDirection: "horizontal-tb",
            textOrientation: "natural",
            zIndex: 1,
            content: [
              {
                content: "Hello, World!",
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                fontSize: "13pt",
                color: "paint(myGradient1)",
              },
            ],
          },
        ],
        decorationTechnology: "offsetOrDigital",
      },
    ],
  },
  fontRepositoryUrl: "https://fonts.documents.cimpress.io/v1/repositories/aff15d65-e10f-492d-b8ea-cfd454c93c3f/published",
};
