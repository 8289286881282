import { ColorPalette, CurveItem, CurveItemSvg } from "@mcp-artwork/cimdoc-types-v2";
import { ClipPath } from "../Models";
import { BoundingBox, boundingBoxFromPath } from "../../utils/boundingBox";
import { parsePathData } from "../../utils/parsePathData";
import { parseMM } from "../../utils/unitHelper";
import { PathLayout } from "../Models";
import { getLayoutStroke } from "../helpers/Stroke";
import { buildTransform } from "../helpers/Transform";
import { parseFill, parseOverprints } from "../helpers/Paint";
import { getClip } from "../helpers/Clip";
import { curveLegacyToCurve } from "../../utils/curveLegacy";
import { PreviewType, LayoutElement } from "../../models/Layout";
import CimDocDefinitionTreeNode from "../../utils/CimDocDefinitionTreeNode";
import { getMeasurementData } from "../measurements/measurementData";

export async function curveLayout({
  curve: curveItem,
  parentBounds,
  options,
  previewType,
}: {
  curve: CurveItem | CurveItemSvg;
  parentBounds: BoundingBox;
  options: { definitionTreeNode?: CimDocDefinitionTreeNode; fontRepositoryUrl?: string; colorPalette: ColorPalette | undefined };
  previewType: PreviewType;
}): Promise<LayoutElement> {
  const curve = curveLegacyToCurve({ curve: curveItem });

  const { scaleX, scaleY, translateX, translateY } = calculateViewboxTransform(curve);

  // Use scale and translate to adjust position using the viewbox
  const [svgPath, path] = parsePathData({
    pathData: curve.svgPathData ?? "",
    pixelSize: 1,
    svgPathDataUnit: curve.svgPathDataUnit ?? "pt",
    closeBehavior: curve.closeBehavior,
    scaleX,
    scaleY,
    translateX,
    translateY,
  });

  const originalScaleTransform = curve.scale;
  const originalBoundingBox = boundingBoxFromPath({
    path: svgPath,
    stroke: curve.stroke,
  });

  const itemPreviewTransform = buildTransform({
    bounds: originalBoundingBox,
    skew: curve.skew,
    scale: curve.scale,
    rotationAngle: curve.rotationAngle,
    matrixTransform: curve.transform,
    itemTransforms: curve.transforms,
  });

  // Get bounding box from the scaled path data
  const boundingBox: BoundingBox = boundingBoxFromPath({
    path: svgPath,
    stroke: curve.stroke,
  });

  let transform = buildTransform({
    bounds: boundingBox,
    skew: curve.skew,
    scale: curve.scale,
    rotationAngle: curve.rotationAngle,
    matrixTransform: curve.transform,
    itemTransforms: curve.transforms,
  });

  const clipTransform = buildTransform({
    bounds: boundingBox,
    rotationAngle: curve.rotationAngle,
    itemTransforms: curve.transforms,
    translateToBounds: true,
  });

  const clipPath: ClipPath | undefined = await getClip(curve, parentBounds, clipTransform);

  const measurementDataResponse = getMeasurementData({
    boundingBox: boundingBox,
    tightBounds: boundingBox,
    transform: itemPreviewTransform,
    scaleTransform: originalScaleTransform,
    itemType: "shape",
  });

  if (previewType === "item") {
    transform = measurementDataResponse.itemPreviewTransform;
  }

  const renderingOperation: PathLayout = {
    type: "drawPaths",
    clip: clipPath,
    paths: [
      {
        path,
        transform,
        fill: await parseFill(curve.color, {
          definitionTreeNode: options.definitionTreeNode,
          itemBounds: boundingBox,
          colorPalette: options.colorPalette,
          fontRepositoryUrl: options.fontRepositoryUrl,
        }),
        fillRule: curve.windingType === "evenOdd" ? "evenodd" : "nonzero",
        overprints: parseOverprints(curve.overprints, options.colorPalette),
        stroke: await getLayoutStroke(curve.stroke, {
          definitionTreeNode: options.definitionTreeNode,
          itemBounds: boundingBox,
          colorPalette: options.colorPalette,
        }),
      },
    ],
    opacityMultiplier: curve.opacityMultiplier ?? 1,
  };

  return {
    id: curveItem.id,
    status: { mode: "local" },
    measurementData: {
      boundingBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.boundingBox,
      previewBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.previewBox,
      layoutBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.layoutBox,
    },
    renderingOperation,
  };
}

export function calculateViewboxTransform(curve: CurveItemSvg) {
  if (!(curve.viewBox && curve.position)) {
    return { scaleX: 1, scaleY: 1, translateX: 0, translateY: 0 };
  }

  const width = parseMM(curve.position.width);
  const viewBoxWidth = parseMM(curve.viewBox.width);
  const height = parseMM(curve.position.height);
  const viewBoxHeight = parseMM(curve.viewBox.height);

  const scaleX = width / viewBoxWidth;
  const scaleY = height / viewBoxHeight;
  const translateX = parseMM(curve.position.x) - parseMM(curve.viewBox.x) * scaleX;
  const translateY = parseMM(curve.position.y) - parseMM(curve.viewBox.y) * scaleY;
  return { scaleX, scaleY, translateX, translateY };
}
