import { fetchHTMLImageElement } from "../utils/api/fetchImage";

type CacheEntry<T> = {
  readFromCache: number;
  lastRead?: number;
  important: boolean;
  value: Promise<T>;
};

const networkCache = new Map<string, CacheEntry<unknown>>();

export const getNetworkCache = () => networkCache;

type FetchWithNetworkCacheArgs<T> = {
  url: string;
  init?: RequestInit;
  fetchOptions?: { fetchAsImage?: boolean };
  responseResolver?: (response: Response | HTMLImageElement) => Promise<T>; // Put in cache
  cacheOptions?: { important?: boolean };
};

const innerFetch = async ({
  url,
  fetchAsImage,
  init,
  remainingRetries,
}: {
  url: string;
  fetchAsImage: boolean;
  init?: RequestInit;
  remainingRetries: number;
}): Promise<Response | HTMLImageElement> => {
  const retry = async (): Promise<Response | HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        innerFetch({ url, fetchAsImage, init, remainingRetries: remainingRetries - 1 })
          .then(resolve)
          .catch(reject);
      }, 100);
    });
  };

  if (fetchAsImage) {
    try {
      return fetchHTMLImageElement({ url });
    } catch (e) {
      if (remainingRetries > 0) {
        return retry();
      }
      throw new Error(`Error while fetching image ${url}`);
    }
  }

  try {
    const response = await fetch(url, init);
    if (!response.ok) {
      let message = "";
      try {
        // Don't call .text on image url
        if (
          !response.url.startsWith("https://rendering.documents.cimpress.io/v1/fusion/images:process?imageUri=") &&
          !response.headers.get("Content-Type")?.startsWith("image/")
        ) {
          message = await response.text();
        }
      } catch (e) {
        // do nothing
      }
      throw new Error(`Error while fetching ${url}. Response status ${response.status}. ${message}`);
    }
    return response;
  } catch (e) {
    if (remainingRetries > 0) {
      return retry();
    }
    throw e;
  }
};

export async function fetchWithNetworkCache<T>({ url, init, fetchOptions, responseResolver, cacheOptions }: FetchWithNetworkCacheArgs<T>): Promise<T> {
  if (init?.method && init.method !== "GET" && !(init.body instanceof FormData)) {
    // The body is not formData. Skip caching for now.
    // TODO: when this happens fix it so it will be cached
    const value = innerFetch({ url, fetchAsImage: fetchOptions?.fetchAsImage ?? false, init, remainingRetries: 1 }).then(async (r) => {
      return responseResolver ? await responseResolver(r) : r;
    });
    return value as Promise<T>;
  }

  let cacheKey = url;
  if (init?.body instanceof FormData) {
    // Append the body of a non GET request to the cache key
    // For example: POST for larger documents
    for (const [key, value] of init.body.entries()) {
      cacheKey += ` ${key}-${value}`;
    }
  }

  if (!networkCache.has(cacheKey)) {
    const value = innerFetch({ url, fetchAsImage: fetchOptions?.fetchAsImage ?? false, init, remainingRetries: 1 }).then(async (r) => {
      return responseResolver ? await responseResolver(r) : r;
    });

    networkCache.set(cacheKey, { value, readFromCache: -1, important: cacheOptions?.important ?? false });

    // Remove failed Promises from cache
    value.catch(() => {
      networkCache.delete(cacheKey);
    });
  }

  const cache = networkCache.get(cacheKey) as CacheEntry<T>;
  networkCache.set(cacheKey, { ...cache, readFromCache: cache.readFromCache + 1, lastRead: Date.now() });
  return cache.value;
}
