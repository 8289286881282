import { ColorPalette, FilterSpecification, TextArea, TextDecoration, TextField } from "@mcp-artwork/cimdoc-types-v2";
import { CalculationResult, ResultFont, FontMetrics } from "@mcp-artwork/rtext";
import svgpath from "svgpath";
import { BoundingBox, boundingBoxFromPath, computeTextAreaBounds, maximum } from "../../utils/boundingBox";
import { Matrix } from "../../utils/math/matrix";
import { toRadians, parseMM } from "../../utils/unitHelper";
import { parseFill, parseOverprints } from "../helpers/Paint";
import { buildItemTransform, buildTransform } from "../helpers/Transform";
import { PathOperation, LayoutPaint } from "../Models";
import { processFilters } from "./filters/filterProcessor";
import { getDecorationBounds, getTextFieldByBlockIndex } from "./TextMeasurements";
import { translateLineJoin, translateLineCap, translateDashArray } from "./translates";
import { generateBackgroundShape } from "./textBackground/Generator";
import { TextPathData } from "../../utils/text/textUtil";
import CimDocDefinitionTreeNode from "../../utils/CimDocDefinitionTreeNode";
import { parsePathData } from "../../utils/parsePathData";

export interface BuildRenderingOperationsReturn {
  operations: PathOperation[];
  transform: Matrix;
  filterBounds: BoundingBox[];
  clipTransform: Matrix;
  backgroundPreviewBox?: BoundingBox;
}

export async function buildRenderingOperations({
  textArea,
  textFields,
  result,
  fonts,
  parentBounds,
  textPathData,
  options,
}: {
  textArea: TextArea;
  textFields: TextField[];
  result: CalculationResult;
  fonts: ResultFont[];
  parentBounds: BoundingBox;
  textPathData?: TextPathData;
  options: { definitionTreeNode?: CimDocDefinitionTreeNode; colorPalette?: ColorPalette };
}): Promise<BuildRenderingOperationsReturn> {
  let operations: PathOperation[] = [];
  const areaBounds: BoundingBox | undefined = computeTextAreaBounds({ textArea: textArea, engineResult: result });
  const extraBounds: BoundingBox[] = [];
  let backgroundPreviewBox: BoundingBox | undefined;
  let filterBounds: BoundingBox[] = [];
  const positionX: number = textArea.position.x === undefined ? 0 : parseMM(textArea.position.x);
  const positionY: number = textArea.position.y === undefined ? 0 : parseMM(textArea.position.y);

  if (areaBounds === undefined) {
    return { operations: [], transform: Matrix.identity(), filterBounds, clipTransform: Matrix.identity() };
  }

  let globalTransform = buildTransform({
    bounds: areaBounds,
    rotationAngle: textArea.rotationAngle,
    scale: textArea.scale,
    skew: textArea.skew,
    matrixTransform: textArea.transform,
    itemTransforms: textArea.transforms,
  });

  // TODO: We need to fix this for text path since that has different behavior. Transforms on text path transform each of the paths at each
  // step and then gets the bounding box, while transforms on normal text only operate on the 4 points of the position box.
  globalTransform = Matrix.multiply(globalTransform, buildItemTransform({ transforms: textArea.transforms, bounds: areaBounds, container: parentBounds }));

  const clipTransform = buildTransform({ bounds: areaBounds, rotationAngle: textArea.rotationAngle, itemTransforms: textArea.transforms });

  if (textArea.background !== undefined) {
    const backgroundOperation = generateBackgroundShape({
      lineInfos: result.lineInfos ?? [],
      position: textArea.position,
      cdifSpec: textArea.background,
      transform: globalTransform,
      colorPalette: options.colorPalette,
    });
    if (backgroundOperation) {
      operations.push(backgroundOperation);
      const [backgroundSvgPath] = parsePathData({ pathData: backgroundOperation.path, pixelSize: 1, svgPathDataUnit: "mm" });
      backgroundPreviewBox = boundingBoxFromPath({ path: backgroundSvgPath });
    }
  }

  const sourceGlyphRuns = textPathData === undefined ? result.glyphRuns : textPathData.glyphRuns;

  for (const glyphRun of sourceGlyphRuns ?? []) {
    const textField: TextField = getTextFieldByBlockIndex(textFields, glyphRun.textBlockIndex ?? 0);
    const font: ResultFont = fonts[glyphRun.fontIndex ?? 0];

    // Possible if requestOutlines = false
    if (font.glyphs === undefined || glyphRun.glyphs.length === 0 || font.unitsPerEm === undefined) {
      continue;
    }

    const { fontSize, baseline: baseLine } = glyphRun;

    const fill: LayoutPaint | undefined = await parseFill(textField.color, {
      definitionTreeNode: options.definitionTreeNode,
      itemBounds: areaBounds,
      colorPalette: options.colorPalette,
    });
    const strokeFill: LayoutPaint | undefined = textField.stroke
      ? await parseFill(textField.stroke.color, { definitionTreeNode: options.definitionTreeNode, itemBounds: areaBounds, colorPalette: options.colorPalette })
      : undefined;

    const overprints = parseOverprints(textField.overprints, options.colorPalette);

    // Only premultiply transforms for text fields with paints. Premultiplying is more expensive and unnecessary in most cases
    const premultiply: boolean = fill?.type !== "color" || (strokeFill !== undefined && strokeFill.type !== "color");

    for (const glyph of glyphRun.glyphs) {
      const { xOffset: offsetX, yOffset: offsetY } = glyph;
      const fontScalar: number = fontSize / font.unitsPerEm;
      let transform: Matrix;

      if (glyphRun.orientation === "vertical") {
        // Apply baseline to X instead of Y
        transform = new Matrix(fontScalar, 0, 0, -fontScalar, (offsetX ?? 0) + positionX + baseLine, (offsetY ?? 0) + positionY);
      } else {
        transform = new Matrix(fontScalar, 0, 0, -fontScalar, (offsetX ?? 0) + positionX, (offsetY ?? 0) + positionY + baseLine);
      }

      transform = Matrix.multiply(Matrix.rotation(toRadians(-glyphRun.glyphPivoting)), transform);

      let glyphPaths: string | undefined = font.glyphs[glyph.index].paths;

      if (premultiply && glyphPaths !== undefined) {
        glyphPaths = svgpath(glyphPaths).matrix([transform.a, transform.b, transform.c, transform.d, transform.x, transform.y]).toString();
      }

      if (glyphPaths) {
        const operation: PathOperation = {
          path: glyphPaths ?? "",
          // Invert y scale to switch coordinate spaces (top,left -> bottom,left)
          transform: premultiply ? globalTransform : Matrix.multiply(transform, globalTransform),
          fill,
          overprints,
        };

        // Add the stroke if it exists
        if (textField.stroke?.thickness) {
          operation.stroke = {
            fill: strokeFill,
            overprints: parseOverprints(textField.stroke.overprints, options.colorPalette),
            width: parseMM(textField.stroke.thickness) / (premultiply ? 1.0 : fontScalar),
            lineJoin: translateLineJoin(textField.stroke),
            lineCap: translateLineCap(textField.stroke),
            dashArray: translateDashArray(textField.stroke),
          };
        }

        operations.push(operation);
      }
    }

    if (font.fontMetrics) {
      const metrics: FontMetrics = font.fontMetrics;

      let decorations: TextDecoration[] = [];

      if (textField.decorations !== undefined && textField.decorations.length > 0) {
        decorations = textField.decorations.slice();
      }
      // Treat legacy underline/strikeout as new definition
      else {
        if (textField.fontStyle?.includes("strikeout") && metrics.strikeoutPosition !== undefined && metrics.strikeoutThickness !== undefined) {
          decorations.push({ type: "strikeout" });
        }
        if (textField.fontStyle?.includes("underline")) {
          decorations.push({ type: "underline" });
        }
      }

      // Newer method of specifying text decorations
      decorations.forEach(async (decoration: TextDecoration) => {
        const color: LayoutPaint | undefined =
          decoration.color === undefined ? fill : await parseFill(decoration.color, { colorPalette: options.colorPalette });

        const bounds: BoundingBox = getDecorationBounds({ type: decoration.type, metrics, glyphRun, textAreaPosition: textArea.position });

        operations.push(buildDecorationOperation(globalTransform, bounds, bounds.height, color));
      });
    }
  }

  if (textArea.filter != null) {
    const start = textArea.filter.indexOf("(");
    const end = textArea.filter.lastIndexOf(")");
    const filter: FilterSpecification | undefined = options.definitionTreeNode?.getFilterRecursive(textArea.filter.slice(start + 1, end));
    let blackBox: BoundingBox;

    if (textArea.textPath === undefined) {
      blackBox = {
        left: (result.blackBoxBounds?.x ?? 0) + positionX,
        top: (result.blackBoxBounds?.y ?? 0) + positionY,
        width: result.blackBoxBounds?.width ?? 0,
        height: result.blackBoxBounds?.height ?? 0,
      };
    } else {
      blackBox = { ...areaBounds, left: areaBounds.left + positionX, top: areaBounds.top + positionY };
    }

    if (filter !== undefined) {
      const filterResponse = processFilters({
        filterSpecification: filter,
        operations: operations,
        blackBox: textArea.textPath === undefined ? maximum([blackBox, ...extraBounds]) : areaBounds,
        originalPosition: areaBounds,
        colorPalette: options.colorPalette,
      });

      operations = filterResponse.operations;
      filterBounds = filterBounds.concat(filterResponse.extraBounds);
    }
  }

  return { operations, transform: globalTransform, filterBounds, clipTransform, backgroundPreviewBox };
}

function buildDecorationOperation(transform: Matrix, bounds: BoundingBox, thickness: number, color: LayoutPaint | undefined): PathOperation {
  return {
    path: `M${bounds.left + thickness / 2} ${bounds.top + bounds.height / 2}h${bounds.width - thickness}`,
    transform,
    stroke: {
      fill: color,
      width: thickness,
      lineJoin: "bevel",
      lineCap: "round",
      dashArray: [],
    },
  };
}
