import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { deflateSync } from "fflate";
import memoize from "lodash.memoize";
import { PreviewType } from "../../models/Layout";

const getEncoder = memoize((): TextEncoder => new TextEncoder());

const INSTRUCTIONS_SERVICE = "https://instructions.documents.cimpress.io";

export type InstructionsRequest = {
  instructionsUri: string;
  documentUri: string;
};

export function buildInstructionsRequest({ document, previewType }: { document: CimpressDocument; previewType: PreviewType }): InstructionsRequest {
  const documentUri: string = compressDocument({ document });

  if (previewType === "item") {
    return {
      instructionsUri: `${INSTRUCTIONS_SERVICE}/v3/instructions:item`,
      documentUri,
    };
  }

  return {
    instructionsUri: `${INSTRUCTIONS_SERVICE}/v3/instructions:preview`,
    documentUri,
  };
}

function compressDocument({ document }: { document: CimpressDocument }): string {
  const documentJson = JSON.stringify(document);

  const encodedValue = getEncoder().encode(documentJson);
  const valueDeflated = deflateSync(encodedValue);
  if (typeof btoa === "undefined") {
    return Buffer.from(valueDeflated).toString("base64");
  }

  return btoa(String.fromCharCode(...valueDeflated));
}
