import { useEffect, useState } from "react";
import { fetchDocument } from "../utils/API/document";
import { layout, Selector, LayoutInput, parseMM } from "@rendering/plasma";
import { getPageDimensions, selectFirstItemFromDocument } from "../selectors/document";

export function ItemPreviewMetadata() {
  const [error, setError] = useState<string>();
  const [layoutResult, setLayoutResult] = useState<string>();

  useEffect(() => {
    const fn = async () => {
      try {
        const params = new URLSearchParams(location.hash.substring("#/itemPreviewMetadata".length));

        const cimdocUrl: string | null = params.get("cimdocUrl");
        const pixel: string | null = params.get("pixel");
        const forceFallback: boolean = params.get("forceFallback") === "true" ? true : false;

        const width: string | null = params.get("width");
        const page: string | null = params.get("page");

        const useRtext: string | null = params.get("rtextEnabled");

        if (!cimdocUrl) {
          throw new Error("A cimdocUrl is required!");
        }

        if (!pixel && !width) {
          throw new Error("A pixel or width is required!");
        }

        if (cimdocUrl) {
          const document = await fetchDocument(cimdocUrl);

          const getSelectorAndPixelSize = async (): Promise<{ selector: Selector; pixelSize: string }> => {
            if (pixel) {
              return { selector: { id: selectFirstItemFromDocument({ document }).item.id, type: "item" }, pixelSize: `${parseMM(pixel)}mm` };
            } else if (width) {
              // Select the right panel by page number (default to 1)
              const selector: Selector = { type: "page", number: page ? parseInt(page) : 1 };
              const size = await getPageDimensions(selector.number, document);

              return { selector, pixelSize: `${size.width / parseInt(width)}mm` };
            } else {
              throw Error("Pixel or width must be specified");
            }
          };

          const { selector, pixelSize } = await getSelectorAndPixelSize();
          const input: LayoutInput = {
            document,
            selector,
            // Use rtext by default unless it's disabled manually
            textOptions: { rtextEnabled: useRtext ? useRtext === "true" : true },
            forceFallback: forceFallback
              ? {
                  breakCache: true,
                  reason: "forced fallback from #/itemPreviewMetadata via query parameter",
                }
              : undefined,
            experimentalOptions: { svgInImagesSupport: true },
            pixelSize,
            referrer: "fusion-demo",
          };

          const layoutOutput = await layout(input);
          if (!layoutOutput) {
            throw new Error("Layout output is empty!");
          }

          setLayoutResult(JSON.stringify(layoutOutput.elements[0].measurementData));
          // @ts-ignore
          window.completePaint = true;

          // Need to dispatch this event for the fusion capture service, which listens for this
          window.document.dispatchEvent(new Event("completePaint"));
        }
      } catch (error: any) {
        const message: string = (error as Error).message ?? "Unknown error!";
        const failEvent = new Event("fusionError");
        // @ts-ignore
        failEvent.errorMessage = message;

        // @ts-ignore
        window.fusionError = message;

        setError(message);
      }
    };
    fn();
  }, []);

  return (
    <div>
      <section id="layoutMetadata">{layoutResult}</section>
      <section id="layoutMetadataError">{error}</section>
    </div>
  );
}
