type FPSCounter = ({ canvasContext }: { canvasContext: CanvasRenderingContext2D }) => void;

type TimingSamples = [number, number, number, number, number, number, number, number, number, number];

const calculateFPS = (samples: TimingSamples): number => Math.round(1000 / (samples.reduce((a, b) => a + b, 0) / samples.length));

const drawFPS = ({ canvasContext, fps }: { canvasContext: CanvasRenderingContext2D; fps: number }): void => {
  canvasContext.save();
  // Draw a semitransparent box
  canvasContext.fillStyle = "rgba(0, 0, 0, 0.4)";
  canvasContext.fillRect(0, 0, 120, 35);

  // Draw the FPS
  canvasContext.font = "bold 25px Arial";
  canvasContext.fillStyle = "white";
  canvasContext.lineWidth = 2;
  canvasContext.fillText(`FPS: ${fps}`, 5, 25);

  canvasContext.restore();
};

export function createFPSCounter(): FPSCounter {
  // Use 10 timing samples
  const timingSamples: TimingSamples = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let timingSamplesIndex = 0;
  let lastTime: number;

  return ({ canvasContext }: { canvasContext: CanvasRenderingContext2D }) => {
    const time = performance.now();

    if (lastTime) {
      timingSamples[timingSamplesIndex] = time - lastTime;
      if (timingSamplesIndex === timingSamples.length - 1) {
        timingSamplesIndex = 0;
      } else {
        timingSamplesIndex = timingSamplesIndex + 1;
      }
    }

    lastTime = time;

    if (timingSamples[1]) {
      const fps = calculateFPS(timingSamples);
      drawFPS({ canvasContext, fps });
    }
  };
}
