import { fetchWithNetworkCache } from "./cache/network";
import { initRText } from "./layout/text/initRText";
import { preFetch as colorPreFetch, setFetchMethod } from "@mcp-artwork/color-converter";

export type PreFetchOptions = {
  skipRText: boolean;
};

export const preFetch = async (preFetchOptions?: PreFetchOptions): Promise<void> => {
  if (!preFetchOptions?.skipRText) {
    await initRText(false);
  }

  setColorConverterFetchMethod();

  await colorPreFetch();
};

export function setColorConverterFetchMethod() {
  setFetchMethod(({ url, init, responseResolver }) =>
    fetchWithNetworkCache({ url, init, responseResolver: (res) => responseResolver(res as Response), cacheOptions: { important: true } }),
  );
}
