import { DesignSurface, Position } from "@mcp-artwork/cimdoc-types-v2";
import { parseMM } from "@rendering/plasma";
import { Bounds, MassiveBody, Vector2 } from "./primitives";

const DAMPEN_FACTOR = 1.4;

export function random(min: number, max: number): number {
  return min + Math.random() * (max - min);
}

export function createGlobalBounds(surface: DesignSurface): Vector2 {
  return {
    x: parseMM(surface.width),
    y: parseMM(surface.height),
  };
}

export function createBodies(positions: { position: Position; rotationAngle?: string }[]): MassiveBody[] {
  return positions.map(({ position, rotationAngle }) => {
    return {
      bounds: {
        x: parseMM(position.x),
        y: parseMM(position.y),
        width: parseMM(position.width),
        height: parseMM(position.height),
      },
      mass: 0,
      rotation: Number(rotationAngle ?? 0),
      velocity: { x: 0.000001, y: 0.000001 },
      acceleration: {
        x: random(-0.000001, 0.000001),
        y: 0.000005,
      },
      angularVelocity: 0,
      angularAcceleration: random(-0.00002, 0.00002),
    };
  });
}

export function step(deltaTime: number, bodies: MassiveBody[], globalBounds: Vector2): MassiveBody[] {
  return bodies.map<MassiveBody>((body) => {
    // Integrate velocity
    const velocity: Vector2 = { ...body.velocity };
    velocity.x += body.acceleration.x * deltaTime;
    velocity.y += body.acceleration.y * deltaTime;

    const bounds: Bounds = { ...body.bounds };
    bounds.x += body.velocity.x * deltaTime;
    bounds.y += body.velocity.y * deltaTime;

    // Integrate rotation
    let angularVelocity: number = body.angularVelocity + body.angularAcceleration * deltaTime;
    const rotation: number = body.rotation + body.angularVelocity * deltaTime;
    let angularAcceleration: number = body.angularAcceleration;

    // Right bounds check
    if (bounds.x + bounds.width > globalBounds.x) {
      bounds.x = globalBounds.x - bounds.width;
      velocity.x = -velocity.x / DAMPEN_FACTOR;

      angularVelocity = -angularVelocity / DAMPEN_FACTOR;
      angularAcceleration = 0;
    }

    // Left bounds check
    if (bounds.x < 0) {
      bounds.x = 0;
      velocity.x = -velocity.x / DAMPEN_FACTOR;

      angularVelocity = -angularVelocity / DAMPEN_FACTOR;
      angularAcceleration = 0;
    }

    // Bottom bounds check
    if (bounds.y + bounds.height > globalBounds.y) {
      bounds.y = globalBounds.y - bounds.height;
      velocity.y = -velocity.y / DAMPEN_FACTOR;

      angularVelocity = -angularVelocity / DAMPEN_FACTOR;
      angularAcceleration = 0;
    }

    // Top bounds check
    if (bounds.y < 0) {
      bounds.y = 0;
      velocity.y = -velocity.y / DAMPEN_FACTOR;

      angularVelocity = -angularVelocity / DAMPEN_FACTOR;
      angularAcceleration = 0;
    }

    return {
      acceleration: body.acceleration,
      angularAcceleration,
      angularVelocity,
      bounds,
      mass: body.mass,
      rotation,
      velocity,
    };
  });
}
