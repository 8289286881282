import { ColorPalette, CropFractions, DesignSurface, ItemReference, Position, Subpanel } from "@mcp-artwork/cimdoc-types-v2";
import { LayoutElement, PreviewType } from "../../models/Layout";
import { fetchWithNetworkCache } from "../../cache/network";
import { subpanelLayout } from "../subpanel/Layout";
import { ClipPath } from "../Models";
import { Matrix } from "../../utils/math/matrix";
import { parseMM } from "../../utils/unitHelper";
import { replaceColors } from "./replaceColors";
import { boundingBoxFromPath, computeBoundsFromPosition, BoundingBox } from "../../utils/boundingBox";
import { buildTransform } from "../helpers/Transform";
import CimDocDefinitionTreeNode from "../../utils/CimDocDefinitionTreeNode";
import { getClip } from "../helpers/Clip";
import { getMeasurementData } from "../measurements/measurementData";
import { parsePathData } from "../../utils/parsePathData";

export type ItemReferenceType = "unknown" | "vector";

export function getItemReferenceType(itemReference: ItemReference): ItemReferenceType {
  if (itemReference.url.indexOf("items.documents.cimpress.io/v1/vector") > 0) return "vector";

  return "unknown";
}

type ColorOverrides = {
  [key: string]: string;
}[];

type ItemReferenceDataVector = {
  subpanelUrl: string;
  colorOverrides: ColorOverrides;
  cropFractions?: CropFractions;
};

type ItemReferenceLayoutArgs = {
  itemReference: ItemReference;
  validateAndLayout: (args: {
    surfaceOrSubpanel: DesignSurface | Subpanel;
    definitionTreeNodeOverride: CimDocDefinitionTreeNode | undefined;
    previewTypeOverride: PreviewType;
  }) => Promise<LayoutElement[]>;
  previewType: PreviewType;
  parentBounds: BoundingBox;
  colorPalette: ColorPalette | undefined;
  definitionTreeNode?: CimDocDefinitionTreeNode;
  fontRepositoryUrl?: string;
};

export async function itemReferenceLayout({
  itemReference,
  parentBounds,
  previewType,
  validateAndLayout,
  colorPalette,
  definitionTreeNode,
  fontRepositoryUrl,
}: ItemReferenceLayoutArgs): Promise<LayoutElement> {
  const itemRefType: ItemReferenceType = getItemReferenceType(itemReference);

  if (itemRefType === "vector") {
    const { subpanelUrl, colorOverrides, cropFractions } = itemReference.data as ItemReferenceDataVector;
    // Fetch subpanel
    const { cachedSubpanel, cachedLayoutElements } = await fetchWithNetworkCache<{ cachedSubpanel: Subpanel; cachedLayoutElements: LayoutElement[] }>({
      url: subpanelUrl,
      responseResolver: async (response) => {
        const subpanel = await (response as Response).json();
        try {
          if (subpanel.definitions !== undefined) {
            if (definitionTreeNode !== undefined) {
              const childNode = definitionTreeNode.createChildNode(subpanel.definitions);
              definitionTreeNode = childNode;
            } else {
              definitionTreeNode = new CimDocDefinitionTreeNode(subpanel.definitions, undefined);
            }
          }

          const layoutElements = await validateAndLayout({
            surfaceOrSubpanel: subpanel,
            definitionTreeNodeOverride: definitionTreeNode,
            previewTypeOverride: "document",
          });
          return { cachedSubpanel: subpanel, cachedLayoutElements: layoutElements };
        } catch (e) {
          // Something is not supported, return empty value to throw later but cache the result
          return { cachedLayoutElements: [], cachedSubpanel: {} };
        }
      },
    });

    if (cachedLayoutElements.length === 0) {
      throw new Error("Something not supported in returned subpabel");
    }

    const layoutElements = replaceColors(cachedLayoutElements, colorOverrides, colorPalette);

    // Layout as subpanel
    const layoutResultsSubpanel = await subpanelLayout({
      subpanel: cachedSubpanel,
      layoutElements,
      parentBounds,
      previewType,
      options: {
        definitionTreeNode,
      },
    });

    // Use bounding box of the itemreference position
    const boundingBox: BoundingBox = computeBoundsFromPosition({ position: itemReference.position });
    const originalScaleTransform = itemReference.scale;

    let positioningTransform = Matrix.identity();
    let transform = Matrix.identity();

    // Scale subpanel into itemreference position dimensions
    const scaleMatrix = Matrix.scale(
      parseMM(itemReference.position.width) / layoutResultsSubpanel.measurementData.boundingBox.width,
      parseMM(itemReference.position.height) / layoutResultsSubpanel.measurementData.boundingBox.height,
    );
    positioningTransform = Matrix.multiply(positioningTransform, scaleMatrix);

    if (cropFractions !== undefined) {
      positioningTransform = Matrix.multiply(positioningTransform, getCropFractionsTransform(cropFractions, itemReference.position));
    }

    positioningTransform = Matrix.multiply(positioningTransform, Matrix.translate(boundingBox.left, boundingBox.top));

    transform = Matrix.multiply(
      transform,
      buildTransform({
        bounds: boundingBox,
        scale: itemReference.scale,
        rotationAngle: itemReference.rotationAngle,
        itemTransforms: itemReference.transforms,
        matrixTransform: itemReference.transform,
      }),
    );

    const measurementData = getMeasurementData({
      itemType: "itemReference",
      boundingBox: boundingBox,
      tightBounds: boundingBox,
      transform,
      scaleTransform: originalScaleTransform,
    });

    if (previewType === "item") {
      transform = measurementData.itemPreviewTransform;
    }

    let clip: ClipPath | undefined;

    if (itemReference.clipping && itemReference.clipping.specification.type === "svgPathData") {
      // Remove the scale matrix from the clip, it's not needed
      const clipTransform = buildTransform({
        bounds: boundingBox,
        rotationAngle: itemReference.rotationAngle,
        itemTransforms: itemReference.transforms,
        translateToBounds: true,
      });

      const unit = itemReference.clipping.specification.unit ?? "mm";

      const [svgPath] = parsePathData({
        pathData: itemReference.clipping.specification.data ?? "",
        pixelSize: 1,
        svgPathDataUnit: unit,
      });

      const clipBounds = boundingBoxFromPath({
        path: svgPath,
      });

      const clipMeasurements = getMeasurementData({
        itemType: "itemReference",
        boundingBox: clipBounds,
        tightBounds: clipBounds,
        transform: clipTransform,
      });

      clip = await getClip(itemReference, parentBounds, previewType === "item" ? clipMeasurements.itemPreviewTransform : clipTransform, fontRepositoryUrl);
    }

    const totalTransform = Matrix.multiply(positioningTransform, transform);

    return {
      id: itemReference.id,
      measurementData: {
        boundingBox: clip?.boundingBox ?? measurementData.measurementData.boundingBox,
        previewBox: clip?.boundingBox ?? measurementData.measurementData.previewBox,
        layoutBox: clip?.boundingBox ?? measurementData.measurementData.layoutBox,
      },
      renderingOperation: {
        type: "group",
        contents: [layoutResultsSubpanel],
        transform: totalTransform,
        opacityMultiplier: itemReference.opacityMultiplier ?? 1,
        clip,
        crop: cropFractions !== undefined ? getCropFractionsClip(measurementData.measurementData.boundingBox, transform) : undefined,
      },
      status: {
        mode: "local",
      },
    };
  }

  throw new Error("Item reference not supported!");
}

function getCropFractionsTransform(cropFractions: CropFractions, position: Position): Matrix {
  const positionBoundingBox: BoundingBox = {
    left: parseMM(position.x),
    top: parseMM(position.y),
    width: parseMM(position.width),
    height: parseMM(position.height),
  };

  const cropLeft = parseFloat(cropFractions.left) * positionBoundingBox.width;
  const cropTop = parseFloat(cropFractions.top) * positionBoundingBox.height;
  const cropWidth = (1 - parseFloat(cropFractions.right) - parseFloat(cropFractions.left)) * positionBoundingBox.width;
  const cropHeight = (1 - parseFloat(cropFractions.bottom) - parseFloat(cropFractions.top)) * positionBoundingBox.height;

  const cropBoundingBox: BoundingBox = {
    left: cropLeft,
    top: cropTop,
    width: cropWidth,
    height: cropHeight,
  };

  let transform = Matrix.identity();

  // Remember we assume that the item ref is at (0, 0) and buildTransform() will translate the item to its position
  transform = Matrix.multiply(transform, Matrix.translate(-cropBoundingBox.left, -cropBoundingBox.top));
  transform = Matrix.multiply(transform, Matrix.scale(positionBoundingBox.width / cropBoundingBox.width, positionBoundingBox.height / cropBoundingBox.height));

  return transform;
}

function getCropFractionsClip(bounds: BoundingBox, transform: Matrix): ClipPath | undefined {
  return {
    path: `M${bounds.left} ${bounds.top} L${bounds.left + bounds.width} ${bounds.top} L${bounds.left + bounds.width} ${bounds.top + bounds.height} L${
      bounds.left
    } ${bounds.top + bounds.height}Z`,
    transform,
    boundingBox: bounds,
    isRelativeToItem: false,
  };
}
