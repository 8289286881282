import { Image } from "@mcp-artwork/cimdoc-types-v2";
import { LayoutElement } from "../../models/Layout";
import { SvgData } from "../../utils/api/image";
import { BoundingBox, boundingBoxToPreviewBox, computeBoundsFromPosition } from "../../utils/boundingBox";
import { SVG, Svg, Element, Polygon, Rect, NumberAlias } from "@svgdotjs/svg.js";
import { Matrix } from "../../utils/math/matrix";
import { parseMM } from "../../utils/unitHelper";
import { buildTransform } from "../helpers/Transform";
import { getClip } from "../helpers/Clip";
import { parseSvgColor } from "./svgColor";
import { LayoutColor } from "../Models";

export async function svgLayout({ image, parentBounds, svgData }: { image: Image; parentBounds: BoundingBox; svgData: SvgData }): Promise<LayoutElement> {
  const svg = SVG(svgData.svg) as Svg;

  const viewBox = svg.viewbox();

  const scaleX = parseMM(image.position.width) / viewBox.width;
  const scaleY = parseMM(image.position.height) / viewBox.height;

  const boundingBox: BoundingBox = computeBoundsFromPosition({ position: image.position });
  const previewBox: BoundingBox = boundingBoxToPreviewBox({ boundingBox, position: image.position });
  const baseTransform = new Matrix(scaleX, 0, 0, scaleY, parseMM(image.position.x) - viewBox.x / scaleX, parseMM(image.position.y) - viewBox.y / scaleY);
  const transform = Matrix.multiply(
    baseTransform,
    buildTransform({ bounds: boundingBox, rotationAngle: image.rotationAngle, mirrorDirection: image.mirrorDirection }),
  );

  const convertedElement = convertSvgElement(svg, { type: "color", display: "rgba(0, 0, 0, 1)", originalValue: "rgba(0, 0, 0, 1)" }, 1.0);
  const convertedElements = convertedElement ? [convertedElement] : [];

  return {
    id: image.id,
    status: { mode: "local" },
    measurementData: {
      boundingBox,
      previewBox,
      layoutBox: previewBox,
    },
    renderingOperation: {
      type: "group",
      contents: convertedElements,
      transform: transform,
      // filters: getFilters(image),
      clip: await getClip(image, parentBounds),
      opacityMultiplier: image.opacityMultiplier ?? 1,
    },
  };
}

// function parseMatrix(matrix: string): Matrix {
//   if (matrix === undefined || matrix === null) {
//     return new Matrix(1, 0, 0, 1, 0, 0);
//   } else {
//     const values = matrix.match(/-?\d+\.?\d*/g);
//     return new Matrix(
//       Number.parseFloat(values[0]),
//       Number.parseFloat(values[1]),
//       Number.parseFloat(values[2]),
//       Number.parseFloat(values[3]),
//       Number.parseFloat(values[4]),
//       Number.parseFloat(values[5])
//     );
//   }
// }

// interface GradientStop {
//   color: string;
//   offset: number;
// }

// interface RadialGradient {
//   stops: GradientStop[];
//   cx: number;
//   cy: number;
//   transform: Matrix;
//   r: number;
//   type: "radial";
// }

// interface LinearGradient {
//   stops: GradientStop[];
//   x1: number;
//   y1: number;
//   x2: number;
//   y2: number;
//   transform: Matrix;
//   type: "linear";
// }

// type Gradient = LinearGradient | RadialGradient;

// interface Gradients {
//   [id: string]: Gradient;
// }

// function findGradients(element: Element, gradients: Gradients) {
//   switch (element.type) {
//     case "linearGradient": {
//       const linearGradient = element as any as SVGGradient;
//       const stops = Array.from(linearGradient.node.children).map((stop) => ({
//         color: stop.getAttribute("stop-color"),
//         offset: Number.parseFloat(stop.getAttribute("offset")),
//       }));
//       gradients[`url(#${element.id()})`] = {
//         stops,
//         x1: Number.parseFloat(linearGradient.node.getAttribute("x1")),
//         y1: Number.parseFloat(linearGradient.node.getAttribute("y1")),
//         x2: Number.parseFloat(linearGradient.node.getAttribute("x2")),
//         y2: Number.parseFloat(linearGradient.node.getAttribute("y2")),
//         transform: parseMatrix(linearGradient.node.getAttribute("gradientTransform")),
//         type: "linear",
//       };
//       break;
//     }
//     case "radialGradient": {
//       const radialGradient = element as any as SVGGradient;
//       const stops = Array.from(radialGradient.node.children).map((stop) => ({
//         color: stop.getAttribute("stop-color"),
//         offset: Number.parseFloat(stop.getAttribute("offset")),
//       }));
//       gradients[`url(#${element.id()})`] = {
//         stops,
//         cx: Number.parseFloat(radialGradient.node.getAttribute("cx")),
//         cy: Number.parseFloat(radialGradient.node.getAttribute("cy")),
//         r: Number.parseFloat(radialGradient.node.getAttribute("r")),
//         transform: parseMatrix(radialGradient.node.getAttribute("gradientTransform")),
//         type: "radial",
//       };
//       break;
//     }
//   }

//   for (const child of element.children()) {
//     findGradients(child, gradients);
//   }
// }

function makeEllipseCurve(cx: number, cy: number, rx: number, ry: number): string {
  const kappa = 0.5522848;
  const ox = rx * kappa;
  const oy = ry * kappa;
  return `M${cx + rx} ${cy}C${cx + rx} ${cy + oy} ${cx + ox} ${cy + ry} ${cx} ${cy + ry}C${cx - ox} ${cy + ry} ${cx - rx} ${cy + oy} ${cx - rx} ${cy}C${
    cx - rx
  } ${cy - oy} ${cx - ox} ${cy - ry} ${cx} ${cy - ry}C${cx + ox} ${cy - ry} ${cx + rx} ${cy - oy} ${cx + rx} ${cy}Z`;
}

function convertNumberAlias(n: NumberAlias): number {
  if (typeof n === "string") {
    return Number.parseFloat(n);
  }
  return n.valueOf();
}

// This could probably be made more efficient by not making path
// strings for anything but 'path', and simply putting the path in context
// for fill or stroke commands to follow
function pathFromElement(element: Element): string {
  switch (element.type) {
    case "path":
      return element.attr().d;
    case "circle":
      return makeEllipseCurve(element.attr().cx, element.attr().cy, element.attr().r, element.attr().r);
    case "ellipse":
      return makeEllipseCurve(element.attr().cx, element.attr().cy, element.attr().rx, element.attr().ry);
    case "polygon": {
      const poly = element as unknown as Polygon;
      const points = poly.array();
      let path = `M${points[0][0]} ${points[0][1]}`;
      for (const point of points.slice(1)) {
        path = path + `L${points[0]} ${point[1]}`;
      }
      path = path + "Z";
      return path;
    }
    case "rect": {
      const rect = element as unknown as Rect;
      const left = convertNumberAlias(rect.x());
      const top = convertNumberAlias(rect.y());
      const right = left + convertNumberAlias(rect.width());
      const bottom = top + convertNumberAlias(rect.height());
      let rx = rect.attr().rx as number | undefined;
      let ry = rect.attr().ry as number | undefined;
      if (rx === undefined) {
        rx = ry;
      }
      if (ry === undefined) {
        ry = rx;
      }
      if (rx === undefined || ry === undefined || rx === 0 || ry === 0) {
        return `M${left} ${top}H${right}V${bottom}H${left}Z`;
      } else {
        const kappa = 0.5522848;
        const kp = 1.0 - kappa;
        return `M${left + rx} ${top}H${right - rx}C${right} ${top + ry} ${right - rx * kp} ${top} ${right} ${top + ry * kp}V${bottom - ry}C${
          right - rx
        } ${bottom} ${right} ${bottom - ry * kp} ${right - rx * kp} ${bottom}H${left + rx}C${left} ${bottom - ry} ${left + rx * kp} ${bottom} ${left} ${
          bottom - ry * kp
        }V${top + ry}C ${left + rx} ${top} ${left} ${top + ry * kp} ${left + rx * kp} ${top}Z`;
      }
    }
  }
  return "";
}

function convertSvgElement(element: Element, parentFill: LayoutColor, parentOpacity: number): LayoutElement | undefined {
  const fill = parseSvgColor(element.attr().fill) ?? parentFill;
  const opacity = (element.attr().opacity ?? 1) * parentOpacity;

  if (element.attr().transform !== undefined) {
    throw Error("Transforms are not supported");
  }

  switch (element.type) {
    case "title": {
      return undefined;
    }
    case "svg":
    case "g": {
      const subElements = element
        .children()
        .map((child) => convertSvgElement(child, fill, opacity))
        .filter((i) => i !== undefined);
      return {
        id: "x",
        measurementData: {
          boundingBox: { left: 0, top: 0, width: 0, height: 0 },
          previewBox: { left: 0, top: 0, width: 0, height: 0 },
          layoutBox: { left: 0, top: 0, width: 0, height: 0 },
        },
        renderingOperation: {
          contents: subElements.filter((e) => e !== undefined) as LayoutElement[],
          type: "group",
          transform: Matrix.identity(),
          opacityMultiplier: 1, // TODO: Read this from styles
        },
        status: {
          mode: "local",
        },
      };
    }
    case "path":
    case "circle":
    case "ellipse":
    case "polygon":
    case "rect": {
      // context.globalAlpha = opacity;
      const path = pathFromElement(element);

      // if (gradients[fill] !== undefined) {
      //   const gradient = gradients[fill];
      //   const canvasGradient: CanvasGradient = undefined;
      //   switch (gradient.type) {
      //     case "linear": {
      //       // const p0 = transformPoint(gradient.transform, { x: gradient.x1, y: gradient.y1 });
      //       // const p1 = transformPoint(gradient.transform, { x: gradient.x2, y: gradient.y2 });
      //       // canvasGradient = context.createLinearGradient(p0.x, p0.y, p1.x, p1.y);
      //       break;
      //     }
      //     case "radial": {
      //       // const center = transformPoint(gradient.transform, { x: gradient.cx, y: gradient.cy });
      //       // const extent = transformPoint(gradient.transform, { x: gradient.cx + gradient.r, y: gradient.cy });
      //       // canvasGradient = context.createRadialGradient(center.x, center.y, 0, center.x, center.y, Math.abs(extent.x - center.x));
      //       break;
      //     }
      //   }
      //   for (const stop of gradient.stops) {
      //     canvasGradient.addColorStop(stop.offset, stop.color);
      //   }
      //   throw Error("unsupported svg gradient fills");
      // }

      const stroke = element.attr().stroke;
      if (stroke !== undefined) {
        throw Error("unsupported svg attribute: stroke");
      }
      const strokeWidth = element.attr()["stroke-width"];
      if (strokeWidth !== undefined) {
        throw Error("unsupported svg attribute: strokeWidth");
      }

      return {
        id: "x",
        measurementData: {
          boundingBox: { left: 0, top: 0, width: 0, height: 0 },
          previewBox: { left: 0, top: 0, width: 0, height: 0 },
          layoutBox: { left: 0, top: 0, width: 0, height: 0 },
        },
        renderingOperation: {
          type: "drawPaths",
          paths: [
            {
              path,
              transform: Matrix.identity(),
              fill: fill,
            },
          ],
          opacityMultiplier: 1, // TODO: Read this from styles
        },
        status: {
          mode: "local",
        },
      };
    }
    default:
      throw Error("unsupported svg element");
  }
}
