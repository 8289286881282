import { LineInfo } from "@mcp-artwork/rtext";
import { PathOperation } from "../../Models";
import { ColorPalette, Position, TextBackground } from "@mcp-artwork/cimdoc-types-v2";
import { Matrix } from "../../../utils/math/matrix";
import { parseColor } from "../../../utils/paint/Color";
import { generateTextBoundsShape } from "./shapeGenerators/textBoundsShapeGenerator";
import { parseOverprints } from "../../helpers/Paint";

export function generateBackgroundShape({
  lineInfos,
  position,
  cdifSpec,
  transform,
  colorPalette,
}: {
  lineInfos: LineInfo[];
  position: Position;
  cdifSpec: TextBackground;
  transform?: Matrix;
  colorPalette: ColorPalette | undefined;
}): PathOperation | undefined {
  let backgroundShape: PathOperation | undefined;

  switch (cdifSpec.shape.type) {
    case "textBounds":
      backgroundShape = generateTextBoundsShape({ lineInfos, position, cdifSpec: cdifSpec.shape, transform });
      break;
    default:
      throw new Error(`shape type '${cdifSpec.shape.type}' not supported for text backgrounds`);
  }

  if (backgroundShape) {
    backgroundShape.fill = parseColor(cdifSpec.color, colorPalette);
    backgroundShape.overprints = parseOverprints(cdifSpec.overprints, colorPalette);
  }

  return backgroundShape;
}
