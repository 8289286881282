import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { Selector } from "./models/Layout";
import { selectSurface } from "./selectors/selectSurface";
import { parseMM } from "./utils/unitHelper";

type DeterminePixelSizeArgs = {
  selector: Selector;
  cimDoc: CimpressDocument;
};

export async function determinePixelSizeByDimension({ dimension, selector, cimDoc }: DeterminePixelSizeArgs & { dimension: number }): Promise<string> {
  const surface = await selectSurface({ selector, document: cimDoc });

  const surfaceWidth = parseMM(surface.width);
  const surfaceHeight = parseMM(surface.height);

  return `${Math.max(surfaceWidth, surfaceHeight) / dimension}mm`;
}

export async function determinePixelSizeByWidth({ width, selector, cimDoc }: DeterminePixelSizeArgs & { width: number }): Promise<string> {
  const surface = await selectSurface({ selector, document: cimDoc });

  const surfaceWidth = parseMM(surface.width);

  return `${surfaceWidth / width}mm`;
}

export async function determinePixelSizeByHeight({ height, selector, cimDoc }: DeterminePixelSizeArgs & { height: number }): Promise<string> {
  const surface = await selectSurface({ selector, document: cimDoc });

  const surfaceHeight = parseMM(surface.height);

  return `${surfaceHeight / height}mm`;
}
