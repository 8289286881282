import { PaintResult } from "@rendering/neon";
import { Filter, layout, LayoutElement, LayoutInput, LayoutResult, TextOptions } from "@rendering/plasma";
import memoize from "lodash.memoize";
import { useEffect, useRef, useState } from "react";
import { useLayout, UseLayoutFusionInput, UseLayoutState } from "../hooks/useLayout";
import { CanvasByOverprint, usePaint } from "../hooks/usePaint";
import CSS from "./FusionCanvas.module.css";

export type FusionCanvasProps = {
  input: UseLayoutFusionInput;
  filters?: Filter[];
  onLayout?: (result: UseLayoutState) => void;
  onPaint?: (args: { paintResults: PaintResult[]; canvas: HTMLCanvasElement; layoutResult: LayoutResult }) => void;
  className?: string;
};

// memoize the input so the <FusionCanvas input={{}} /> doesn't trigger a rerender all the time
export const memoizeInput = memoize(
  (input: UseLayoutFusionInput): UseLayoutFusionInput => input,
  (input: UseLayoutFusionInput) => JSON.stringify(input),
);

const findElementsRenderedOnServer = (layoutElements: LayoutElement[] | undefined): LayoutElement[] => {
  return (layoutElements ?? []).flatMap((layoutElement) => {
    if (layoutElement.status.mode === "server") {
      return [layoutElement];
    }
    if (layoutElement.renderingOperation.type === "group") {
      return findElementsRenderedOnServer(layoutElement.renderingOperation.contents);
    }
    return [];
  });
};

export const FusionCanvas = ({ input, filters, onLayout, className, onPaint }: FusionCanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const overprintCanvasesRef = useRef<HTMLDivElement>(null);
  const [serverReasonsOpen, setServerReasonsOpen] = useState<boolean>(false);

  const { layoutResult, layoutError } = useLayout({ layoutInput: memoizeInput({ ...input }) });

  const canvases: CanvasByOverprint[] = [{ canvas: canvasRef.current, overprint: undefined }];
  (input.overprints ?? []).forEach((overprint, i) => {
    canvases.push({ canvas: overprintCanvasesRef.current?.children[i] as HTMLCanvasElement, overprint });
  });

  useEffect(() => {
    onLayout?.({ layoutResult, layoutError });
  }, [layoutResult, layoutError]);

  usePaint({ canvases, pixelSize: input.pixelSize, layoutResult, filters, onPaint });

  const elementsRenderedOnServer = findElementsRenderedOnServer(layoutResult?.elements);

  return (
    <div>
      {!input.forceFallback && elementsRenderedOnServer.length > 0 && (
        <div onClick={() => setServerReasonsOpen(!serverReasonsOpen)}>
          🚨 {elementsRenderedOnServer.length} elements rendered on the server. Click for more info.
        </div>
      )}
      {serverReasonsOpen && elementsRenderedOnServer.length > 0 && (
        <div>
          {elementsRenderedOnServer.map((element) => (
            <div key={element.id}>
              {element.id}: {element.status.error}
            </div>
          ))}
        </div>
      )}
      <canvas width="0" height="0" ref={canvasRef} className={`${className} ${CSS.Canvas}`} />
      <div ref={overprintCanvasesRef}>
        {(input.overprints ?? []).map((overprint) => (
          <canvas width="0" height="0" className={`${className} ${CSS.Canvas}`} key={overprint} />
        ))}
      </div>
    </div>
  );
};
