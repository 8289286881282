import { ColorPalette, EllipseItem } from "@mcp-artwork/cimdoc-types-v2";
import svgpath from "svgpath";
import { ClipPath, PathLayout } from "../Models";
import { LayoutElement, PreviewType } from "../../models/Layout";
import { BoundingBox, computeBoundsFromPosition } from "../../utils/boundingBox";
import { parseMM } from "../../utils/unitHelper";
import { getClip } from "../helpers/Clip";
import { parseFill, parseOverprints } from "../helpers/Paint";
import { getLayoutStroke } from "../helpers/Stroke";
import { buildTransform } from "../helpers/Transform";
import CimDocDefinitionTreeNode from "../../utils/CimDocDefinitionTreeNode";
import { getMeasurementData } from "../measurements/measurementData";

const ellipseToPath = ({ x, y, width, height }: { x: number; y: number; width: number; height: number }): string => {
  const cx = width / 2;
  const cy = height / 2;
  const rx = width / 2;
  const ry = height / 2;

  const baseEllipse = `M${cx - rx},${cy}a${rx},${ry} 0 1,0 ${rx * 2},0a${rx},${ry} 0 1,0 -${rx * 2},0 Z`;
  const translatedEllipse = svgpath(baseEllipse).translate(x, y);

  return translatedEllipse.toString();
};

export async function ellipseLayout({
  ellipse,
  parentBounds,
  options,
  previewType,
}: {
  ellipse: EllipseItem;
  parentBounds: BoundingBox;
  options: { definitionTreeNode?: CimDocDefinitionTreeNode; fontRepositoryUrl?: string; colorPalette: ColorPalette | undefined };
  previewType: PreviewType;
}): Promise<LayoutElement> {
  const strokePadding = parseMM(ellipse.stroke?.thickness ?? "0mm") / 2;
  const originalScaleTransform = ellipse.scale;
  const originalBoundingBox = computeBoundsFromPosition({ position: ellipse.position, strokePadding });

  const itemPreviewTransform = buildTransform({
    bounds: originalBoundingBox,
    skew: ellipse.skew,
    scale: ellipse.scale,
    rotationAngle: ellipse.rotationAngle,
    matrixTransform: ellipse.transform,
    itemTransforms: ellipse.transforms,
  });

  const bounds: BoundingBox = computeBoundsFromPosition({ position: ellipse.position, strokePadding });
  let transform = buildTransform({
    bounds: bounds,
    skew: ellipse.skew,
    scale: ellipse.scale,
    rotationAngle: ellipse.rotationAngle,
    matrixTransform: ellipse.transform,
    itemTransforms: ellipse.transforms,
  });

  const clipTransform = buildTransform({ bounds, rotationAngle: ellipse.rotationAngle, itemTransforms: ellipse.transforms, translateToBounds: true });

  const clipPath: ClipPath | undefined = await getClip(ellipse, parentBounds, clipTransform);

  const measurementDataResponse = getMeasurementData({
    boundingBox: bounds,
    tightBounds: bounds,
    transform: itemPreviewTransform,
    scaleTransform: originalScaleTransform,
    itemType: "shape",
  });

  if (previewType === "item") {
    transform = measurementDataResponse.itemPreviewTransform;
  }

  const renderingOperation: PathLayout = {
    type: "drawPaths",
    clip: clipPath,
    paths: [
      {
        path: ellipseToPath({
          x: parseMM(ellipse.position.x),
          y: parseMM(ellipse.position.y),
          width: parseMM(ellipse.position.width),
          height: parseMM(ellipse.position.height),
        }),
        transform,
        fill: await parseFill(ellipse.color, {
          definitionTreeNode: options.definitionTreeNode,
          itemBounds: bounds,
          colorPalette: options.colorPalette,
          fontRepositoryUrl: options.fontRepositoryUrl,
        }),
        overprints: parseOverprints(ellipse.overprints, options.colorPalette),
        stroke: await getLayoutStroke(ellipse.stroke, {
          definitionTreeNode: options.definitionTreeNode,
          itemBounds: bounds,
          colorPalette: options.colorPalette,
        }),
      },
    ],
    opacityMultiplier: ellipse.opacityMultiplier ?? 1,
  };

  return {
    id: ellipse.id,
    status: { mode: "local" },
    measurementData: {
      boundingBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.boundingBox,
      previewBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.previewBox,
      layoutBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.layoutBox,
    },
    renderingOperation,
  };
}
