import { Rectangle } from "@mcp-artwork/rtext";

export function inflate(rect: Rectangle, dx: number, dy: number): Rectangle {
  return {
    x: rect.x - dx,
    y: rect.y - dy,
    width: rect.width + 2 * dx,
    height: rect.height + 2 * dy,
  };
}
