import { useEffect, useState } from "react";
import { Updater, useImmer } from "use-immer";
import { fetchDocumentFromUrl } from "../utils/API/document";
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { getProjectionSpecification, ProjectionSpecification } from "@rendering/plasma";

type UseDocumentProps = {
  url: string;
};

type UseDocumentReturn = {
  document?: CimpressDocument;
  error?: any;
  setDocument: Updater<CimpressDocument | undefined>;
  onResetDocument: () => void;
  projectionSpecification?: ProjectionSpecification;
};

export function useDocument({ url }: UseDocumentProps): UseDocumentReturn {
  const [initialDocument, setInitialDocument] = useState<CimpressDocument>();
  const [documentState, setDocument] = useImmer<CimpressDocument | undefined>(undefined);
  const [projectionSpecification, setProjectionSpecification] = useState<ProjectionSpecification>();
  const [error, setError] = useState<any>();

  useEffect(() => {
    if (url) {
      fetchDocumentFromUrl(url)
        .then((document) => {
          setDocument(document);
          setInitialDocument(document);
          if (document.projectionId?.startsWith("http")) {
            getProjectionSpecification(document.projectionId).then((result) => {
              setProjectionSpecification(result);
            });
          }
        })
        .catch((error) => setError(error));
    }
  }, [url]);

  return {
    document: documentState,
    error,
    setDocument,
    onResetDocument: () => {
      setDocument(initialDocument);
    },
    projectionSpecification,
  };
}
