import { useState } from "react";
import { demoEllipse } from "../data/shapes/ellipse";
import { simpleText } from "../data/text/simpleText";
import { list } from "../data/text/list";
import { ItemBlock } from "./ItemBlock";
import { resizedText } from "../data/text/resizedText";
import { imageDocument } from "../data/image";
import { calendarDocument } from "../data/calendar";
import { embroideryText } from "../data/text/embroidery";
import { clippingDocument } from "../data/clipping";
import { subpanelDocument } from "../data/subpanel";
import { curveDocument } from "../data/curve";
import { verticalText } from "../data/text/verticalText";
import { subpanelTransformedItemsDocument } from "../data/subpanelTransformedItems";
import { subpanelTransformedTextDocument } from "../data/subpanelTransformedText";
import { subpanelTransformedCurveDocument } from "../data/subpanelTransformedCurve";
import { lineDocument } from "../data/lineDocument";
import { subpanelTransformedLineDocument } from "../data/subpanelTransformedLine";
import { textAlongAPathDocument } from "../data/textAlongAPathDocument";
import { opacityDocument } from "../data/opacityDocument";
import { clippingWithTextDocument } from "../data/clippingWithTextDocument";
import { svgDocument } from "../data/svgDocument";
import { rectangleLinearGradient } from "../data/rectangleLinearGradient";
import { linearGradientText } from "../data/text/linearGradient";
import { textJustificationDocument } from "../data/text/textJustification";
import { ellipseRadialGradient } from "../data/ellipseRadialGradient";

export function ItemPreviews() {
  const [pixelSize, setPixelSize] = useState<number>(0.1 / Math.ceil(window.devicePixelRatio));
  const pixelSizeAsString = `${pixelSize}mm`;

  return (
    <div>
      <h3>MM per Pixel</h3>
      <input
        type="number"
        value={pixelSize}
        onInput={(e) => {
          const float = parseFloat(e.currentTarget.value);
          if (float > 0) {
            setPixelSize(float);
          }
        }}
        step={0.1}
      />
      <ItemBlock title="Text" initialDocument={simpleText} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Resized Text" initialDocument={resizedText} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Text Justification" initialDocument={textJustificationDocument} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Linear Gradient Text" initialDocument={linearGradientText} pixelSize={pixelSizeAsString} />
      <ItemBlock title="List" initialDocument={list} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Embroidery Text" initialDocument={embroideryText} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Image" initialDocument={imageDocument} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Ellipse" initialDocument={demoEllipse} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Calendar" initialDocument={calendarDocument} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Clipping" initialDocument={clippingDocument} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Subpanel" initialDocument={subpanelDocument} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Curve" initialDocument={curveDocument} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Vertical Text" initialDocument={verticalText} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Subpanel with Transformed Shape" initialDocument={subpanelTransformedItemsDocument} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Subpanel with Transformed Text" initialDocument={subpanelTransformedTextDocument} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Subpanel with Transformed Curve" initialDocument={subpanelTransformedCurveDocument} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Line" initialDocument={lineDocument} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Subpanel with Transformed Line" initialDocument={subpanelTransformedLineDocument} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Text along a path" initialDocument={textAlongAPathDocument} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Opacity" initialDocument={opacityDocument} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Clipping with Text" initialDocument={clippingWithTextDocument} pixelSize={pixelSizeAsString} />
      <ItemBlock title="SVG Image" initialDocument={svgDocument} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Rectangle with linear gradient" initialDocument={rectangleLinearGradient} pixelSize={pixelSizeAsString} />
      <ItemBlock title="Ellipse with radial gradient" initialDocument={ellipseRadialGradient} pixelSize={pixelSizeAsString} />
    </div>
  );
}
