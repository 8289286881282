import {
  RadialGradient,
  LinearGradient,
  ColorPalette,
  Paint,
  GradientPosition,
  GradientStop,
  Pattern,
  DesignSurface,
  CimpressDocument,
} from "@mcp-artwork/cimdoc-types-v2";
import { LayoutPaint, LayoutGradientStop } from "../../layout/Models";
import { BoundingBox } from "../boundingBox";
import { parseMM, parsePercentage, toRadians } from "../unitHelper";
import { parseColor } from "./Color";
import { Point } from "../math/geometry";
import { Matrix } from "../math/matrix";
import CimDocDefinitionTreeNode from "../CimDocDefinitionTreeNode";
import { layout, LayoutInput, LayoutResult } from "../..";

const PAINT_DEFINITION_REGEX = /paint\((.+)\)/;

function convertGradientPosition(position: GradientPosition, itemBounds?: BoundingBox): Point {
  const layoutGradientPosition: Point = { x: 0, y: 0 };

  if (position.x.endsWith("%")) {
    if (itemBounds === undefined) {
      throw Error("Cannot convert gradient percentage because there are no item bounds");
    }
    layoutGradientPosition.x = itemBounds.left + parsePercentage(position.x) * itemBounds.width;
  } else {
    layoutGradientPosition.x = parseMM(position.x);
  }
  if (position.y.endsWith("%")) {
    if (itemBounds === undefined) {
      throw Error("Cannot convert gradient percentage because there are no item bounds");
    }
    layoutGradientPosition.y = itemBounds.top + parsePercentage(position.y) * itemBounds.height;
  } else {
    layoutGradientPosition.y = parseMM(position.y);
  }

  return layoutGradientPosition;
}

function convertRadialGradientPosition(position: GradientPosition | undefined, itemBounds?: BoundingBox): Point {
  if (position === undefined) {
    return convertGradientPosition({ x: "50%", y: "50%" }, itemBounds);
  }

  return convertGradientPosition(position, itemBounds);
}

function convertGradientStop(stop: GradientStop, colorPalette: ColorPalette | undefined): LayoutGradientStop {
  return {
    offset: parsePercentage(stop.offset),
    color: parseColor(stop.color, colorPalette),
  };
}

function convertRadialGradientRadius(radius: string | undefined, itemBounds?: BoundingBox): number {
  if (itemBounds === undefined) {
    throw new Error("Item bounds was not defined");
  }

  const minDimension: number = Math.min(itemBounds.width, itemBounds.height);
  let percentage: number;

  if (radius === undefined) {
    percentage = 0.5;
  } else {
    percentage = parsePercentage(radius);
  }

  return percentage * minDimension;
}

export async function parsePaint(
  rawValue: string,
  options: { definitionTreeNode?: CimDocDefinitionTreeNode; itemBounds?: BoundingBox; colorPalette?: ColorPalette; fontRepositoryUrl?: string },
): Promise<LayoutPaint> {
  const result: RegExpExecArray | null = PAINT_DEFINITION_REGEX.exec(rawValue);

  if (!result || result.length < 2) {
    throw new Error("Unable to parse paint definition");
  }

  const paintAlias: string = result[1];
  const definitionPaint: Paint | undefined = options.definitionTreeNode?.getPaintRecursive(paintAlias);
  let transform: Matrix = Matrix.identity();

  if (definitionPaint === undefined) {
    throw new Error(`Unable to find '${paintAlias}' in paint definitions.`);
  }

  if (definitionPaint.type === "linearGradient") {
    const linearGradient: LinearGradient = definitionPaint;

    if (definitionPaint.transform) {
      const t = definitionPaint.transform;
      transform = Matrix.multiply(transform, new Matrix(t.a, t.b, t.c, t.d, parseMM(t.x), parseMM(t.y)));
    }

    return {
      type: "linearGradient",
      start: convertGradientPosition(linearGradient.start, options.itemBounds),
      end: convertGradientPosition(linearGradient.end, options.itemBounds),
      stops: linearGradient.stops.map((stop) => convertGradientStop(stop, options.colorPalette)),
      transform: transform,
    };
  }
  // Additional commentary on the behavior of radial gradients can be found in the documentation and
  // in the instructions service.
  else if (definitionPaint.type === "radialGradient") {
    if (options.itemBounds === undefined) {
      throw new Error("Expected item bounds for radial gradient");
    }

    const radialGradient: RadialGradient = definitionPaint;
    const end: Point = convertRadialGradientPosition(radialGradient.end, options.itemBounds);
    const endRadius: number = convertRadialGradientRadius(radialGradient.endRadius, options.itemBounds);

    const boundsWidth = options.itemBounds.width;
    const boundsHeight = options.itemBounds.height;

    if (boundsWidth > boundsHeight) {
      transform = Matrix.multiply(transform, Matrix.scaleAboutPoint(boundsWidth / boundsHeight, 1, end));
    } else if (boundsHeight > boundsWidth) {
      transform = Matrix.multiply(transform, Matrix.scaleAboutPoint(1, boundsHeight / boundsWidth, end));
    }

    if (definitionPaint.transform) {
      const t = definitionPaint.transform;
      transform = Matrix.multiply(transform, new Matrix(t.a, t.b, t.c, t.d, parseMM(t.x), parseMM(t.y)));
    }

    return {
      type: "radialGradient",
      start: end,
      end: end,
      startRadius: 0,
      endRadius: endRadius,
      stops: radialGradient.stops.map((stop) => convertGradientStop(stop, options.colorPalette)),
      transform: transform,
    };
  } else if (definitionPaint.type === "pattern") {
    const pattern = definitionPaint as Pattern;
    let transform: Matrix = Matrix.identity();

    if (pattern.transform !== undefined) {
      transform = Matrix.scale(pattern.transform.scaleX ?? 1, pattern.transform.scaleY ?? 1);
      transform = Matrix.multiply(transform, Matrix.rotation(toRadians(pattern.transform.rotationAngle ?? 0)));
      transform = Matrix.multiply(transform, Matrix.translate(parseMM(pattern.transform.translateX ?? "0mm"), parseMM(pattern.transform.translateY ?? "0mm")));
    }

    const panelName: string = pattern.definedPanelName;
    const patternPanel: DesignSurface | undefined = options.definitionTreeNode?.getPanelRecursive(panelName);

    if (patternPanel === undefined) {
      throw Error(`Unable to find '${panelName}' in definition panels.`);
    }

    const cimdoc: CimpressDocument = {
      document: { definitions: options.definitionTreeNode?.definition, panels: [patternPanel] },
      fontRepositoryUrl: options.fontRepositoryUrl,
      version: "3",
    };

    const layoutInput: LayoutInput = {
      document: cimdoc,
      selector: { type: "panel", id: patternPanel.id },
      // The pixelSize doesn't matter here. The real pixelSize is calculated in neon.
      pixelSize: "0.35277mm",
      referrer: "internal",
      textOptions: {
        rtextEnabled: true,
      },
    };

    const layoutResult: LayoutResult = await layout(layoutInput);

    return {
      type: "pattern",
      layout: layoutResult,
      transform,
      repetition: "repeat",
    };
  }

  throw Error("Unknown paint type");
}
