import * as svgpath from "svgpath";
import { EllipseItem, LineItem, Point, Position, RectangleItem, Stroke, TextArea } from "@mcp-artwork/cimdoc-types-v2";
import { minMaxCubic, minMaxQuadratic } from "./bezierCurveHelper";
import { parseMM, toRadians } from "./unitHelper";
import { Matrix } from "./math/matrix";
import { CalculationResult } from "@mcp-artwork/rtext";
import { Point as MMPoint } from "./math/geometry";
import { transformBoundingBox } from "../layout/helpers/Transform";

const MITRE_MULTIPLIER = 1.25;

export type BoundingBox = {
  left: number;
  top: number;
  width: number;
  height: number;
};

export type RotatedBoundingBox = BoundingBox & {
  rotation?: number;
};

type BoundingBoxFromPathArguments = {
  path: Omit<typeof svgpath, "default">;
  stroke?: Stroke;
  transform?: Matrix;
};

export function computeCenter(boundingBox: BoundingBox | RotatedBoundingBox): MMPoint {
  return { x: boundingBox.left + boundingBox.width / 2, y: boundingBox.top + boundingBox.height / 2 };
}

export const maximum = (boundingBoxes: BoundingBox[]): BoundingBox => {
  if (boundingBoxes.length === 0) throw Error("Cannot find the maximum of empty bounding boxes");

  // Determine the new global maximum for each remaining box
  return boundingBoxes.reduce((previous, current) => {
    const minX = Math.min(current.left, previous.left);
    const maxX = Math.max(current.left + current.width, previous.left + previous.width);
    const minY = Math.min(current.top, previous.top);
    const maxY = Math.max(current.top + current.height, previous.top + previous.height);
    return { left: minX, top: minY, width: maxX - minX, height: maxY - minY };
  }, boundingBoxes[0]);
};

export const computeTextAreaBounds = ({
  textArea,
  strokeThickness,
  engineResult,
}: {
  textArea: TextArea;
  strokeThickness?: number;
  engineResult?: CalculationResult;
}): BoundingBox => {
  strokeThickness = strokeThickness ?? 0;
  let positionBounds = computeBoundsFromPosition({ position: textArea.position });

  if ((textArea.blockFlowDirection === undefined || textArea.blockFlowDirection?.startsWith("horizontal")) && parseMM(textArea.position.height) === 0) {
    positionBounds.height = engineResult?.textBounds?.height as number;
  } else if (textArea.blockFlowDirection?.startsWith("vertical") == true && parseMM(textArea.position.width) === 0) {
    positionBounds.width = engineResult?.textBounds?.width as number;
  }

  positionBounds = expandBoundingBox({ boundingBox: positionBounds, amount: strokeThickness / 2 });

  return positionBounds;
};

export const computeBoundsFromPosition = ({ position, strokePadding }: { position: Position; strokePadding?: number }): BoundingBox => {
  const strokeMM = strokePadding ?? 0;

  return {
    left: parseMM(position.x) - strokeMM,
    top: parseMM(position.y) - strokeMM,
    width: parseMM(position.width) + strokeMM * 2,
    height: parseMM(position.height) + strokeMM * 2,
  };
};

export const boundingBoxFromPath = ({ path, stroke, transform }: BoundingBoxFromPathArguments): BoundingBox => {
  //@ts-expect-error Because the type of `path` is `Omit<typeof svgpath, "default">`, calling `svgpath.from()` will not
  // work on the type.
  // Cloning the svgpath object is required since the object methods modify the svgpath, which is an unintended side effect.
  const svgPath = svgpath.from(path);

  // If the path is empty string then the bouding box should have all attributes set to 0 or else they become infinity
  if (!svgPath.toString()) {
    return {
      left: 0,
      top: 0,
      width: 0,
      height: 0,
    };
  }

  let left = Infinity;
  let top = Infinity;
  let right = -Infinity;
  let bottom = -Infinity;

  if (transform !== undefined) {
    svgPath.matrix([transform.a, transform.b, transform.c, transform.d, transform.x, transform.y]);
  }

  svgPath
    .abs() // Replace all relative (lower case) path commands with absolute (upper case) path commands
    .unarc() // Replace all arch path commands (A) with curves (C or Q)
    .unshort() // Replace all shortcut command (T and S) with curves (C or Q)
    .iterate((segments, _, x, y) => {
      // https://github.com/mondeja/svg-path-bbox/blob/master/src/index.js
      switch (segments[0]) {
        case "M":
        case "L": {
          left = Math.min(left, segments[1]);
          top = Math.min(top, segments[2]);
          right = Math.max(right, segments[1]);
          bottom = Math.max(bottom, segments[2]);
          break;
        }
        case "V": {
          top = Math.min(top, segments[1]);
          bottom = Math.max(bottom, segments[1]);
          break;
        }
        case "H": {
          left = Math.min(left, segments[1]);
          right = Math.max(right, segments[1]);
          break;
        }
        case "C": {
          const xMinMax = minMaxCubic([x, segments[1], segments[3], segments[5]]);
          left = Math.min(left, xMinMax[0]);
          right = Math.max(right, xMinMax[1]);

          const yMinMax = minMaxCubic([y, segments[2], segments[4], segments[6]]);
          top = Math.min(top, yMinMax[0]);
          bottom = Math.max(bottom, yMinMax[1]);
          break;
        }
        case "Q": {
          const xMinMax = minMaxQuadratic([x, segments[1], segments[3]]);
          left = Math.min(left, xMinMax[0]);
          right = Math.max(right, xMinMax[1]);

          const yMinMax = minMaxQuadratic([y, segments[2], segments[4]]);
          top = Math.min(top, yMinMax[0]);
          bottom = Math.max(bottom, yMinMax[1]);
          break;
        }
      }
    });

  // Adjust for stroke thickness
  const strokeThickness = stroke?.thickness ? parseMM(stroke.thickness) : 0;
  const strokePadding = strokeThickness * getStrokeMultiplier(stroke, "curve");

  left = left - strokePadding;
  top = top - strokePadding;
  right = right + strokePadding;
  bottom = bottom + strokePadding;

  return {
    left: left,
    top: top,
    width: right - left,
    height: bottom - top,
  };
};

export const expandBoundingBox = ({ boundingBox, amount }): BoundingBox => {
  return {
    ...boundingBox,
    left: boundingBox.left - amount,
    top: boundingBox.top - amount,
    width: boundingBox.width + 2 * amount,
    height: boundingBox.height + 2 * amount,
  };
};

export const getTransformedRotatedBoundingBox = (rotatedBoundingBox: RotatedBoundingBox): BoundingBox => {
  return transformBoundingBox(rotatedBoundingBox, Matrix.rotateAboutCenter(toRadians(rotatedBoundingBox.rotation ?? 0), rotatedBoundingBox));
};

export const boundingBoxFromRectangleItem = ({ item }: { item: RectangleItem | EllipseItem }): BoundingBox => {
  const strokeThickness = item.stroke?.thickness ? parseMM(item.stroke.thickness) : 0;

  return computeBoundsFromPosition({
    position: item.position,
    strokePadding: strokeThickness * getStrokeMultiplier(item.stroke, item.type),
  });
};

export const getStrokeMultiplier = (stroke: Stroke | undefined, itemType: "curve" | "rectangle" | "ellipse" | "line"): number => {
  if (stroke?.lineJoin === "mitre" && (itemType === "curve" || itemType === "line")) {
    return MITRE_MULTIPLIER;
  }
  // For rectangle and ellipse items, the multiplier is always 0.5
  return 0.5;
};

export const boundingBoxFromEllipseItem = boundingBoxFromRectangleItem;

type BoundingBoxFromLineArguments = {
  start: { x: number; y: number };
  end: { x: number; y: number };
  strokeThickness: number;
};

export const boundingBoxFromLine = ({ start, end, strokeThickness }: BoundingBoxFromLineArguments): BoundingBox => {
  const maxX = Math.max(start.x, end.x);
  const minX = Math.min(start.x, end.x);
  const maxY = Math.max(start.y, end.y);
  const minY = Math.min(start.y, end.y);

  const halfStroke = strokeThickness / 2;

  const left = minX - halfStroke;
  const top = minY - halfStroke;
  const right = maxX + halfStroke;
  const bottom = maxY + halfStroke;

  return {
    left: left,
    top: top,
    width: right - left,
    height: bottom - top,
  };
};

export const boundingBoxFromLineItem = ({ line }: { line: LineItem }): BoundingBox => {
  const baseStrokeThickness = line.stroke?.thickness ? parseMM(line.stroke.thickness) : 0;
  const adjustedStrokeThickness = line.stroke?.lineCap === "square" ? baseStrokeThickness * 1.5 : baseStrokeThickness;

  return boundingBoxFromLine({
    start: {
      x: parseMM(line.start.x),
      y: parseMM(line.start.y),
    },
    end: {
      x: parseMM(line.end.x),
      y: parseMM(line.end.y),
    },
    strokeThickness: adjustedStrokeThickness,
  });
};

export const boundingBoxToPreviewBox = ({ boundingBox, position }: { boundingBox: BoundingBox; position: Point }): BoundingBox => {
  return { ...boundingBox, left: boundingBox.left - parseMM(position.x), top: boundingBox.top - parseMM(position.y) };
};
