import { DropShadowFilter, FilterSpecification, OffsetFilter, ScaleFilter, BlurFilter, MirrorFilter, ColorPalette } from "@mcp-artwork/cimdoc-types-v2";
import cloneDeep from "lodash.clonedeep";
import { BoundingBox } from "../../../utils/boundingBox";
import { Point } from "../../../utils/math/geometry";
import { Matrix } from "../../../utils/math/matrix";
import { parseColor } from "../../../utils/paint/Color";
import { parseMM, parsePercentage } from "../../../utils/unitHelper";
import { parseOverprints } from "../../helpers/Paint";
import { transformBoundingBox } from "../../helpers/Transform";
import { PathBlurEffect, PathOperation } from "../../Models";

export function processFilters({
  filterSpecification,
  operations,
  blackBox,
  originalPosition,
  colorPalette,
}: {
  filterSpecification: FilterSpecification;
  operations: PathOperation[];
  blackBox: BoundingBox;
  originalPosition: BoundingBox;
  colorPalette: ColorPalette | undefined;
}): { operations: PathOperation[]; extraBounds: BoundingBox[] } {
  const state = new Map<string, FilterPipelineData>();
  state["SourceGraphic"] = {
    operations: operations,
    originalPosition: originalPosition,
    currentBlackBox: blackBox,
    currentTransform: Matrix.identity(),
  };

  filterSpecification.definitions.forEach((filter) => {
    let result: FilterPipelineData;

    switch (filter.type) {
      case "blur":
        result = processBlur(filter, state);
        break;
      case "offset":
        result = processOffset(filter, state);
        break;
      case "dropShadow":
        result = processDropShadow(filter, state, colorPalette);
        break;
      case "scale":
        result = processScale(filter, state);
        break;
      case "mirror":
        result = processMirror(filter, state);
        break;
      default:
        throw new Error("Unsupported filter.");
    }

    state[filter.result] = result;
  });

  let totalOperations: PathOperation[] = [];
  let totalBounds: BoundingBox[] = [];

  filterSpecification.output.result.forEach((alias) => {
    const outputOperations: PathOperation[] = state[alias].operations;

    // the transform that already exists within each operation is the standard glyph scale transform, that is to be applied
    // after the filters have taken effect.
    outputOperations.forEach((op) => {
      op.transform = Matrix.multiply(op.transform, state[alias].currentTransform);
    });

    totalOperations = totalOperations.concat(outputOperations);
    totalBounds = totalBounds.concat(state[alias].currentBlackBox);
  });

  return { operations: totalOperations, extraBounds: totalBounds };
}

function processBlur(filter: BlurFilter, state: Map<string, FilterPipelineData>): FilterPipelineData {
  const output: FilterPipelineData = cloneDeep(state[filter.in]);
  const radius = parseMM(filter.radius ?? "0mm");

  output.operations.forEach((op) => {
    op.effects = [{ type: "blur", radius: radius }];
  });

  return output;
}

function processOffset(filter: OffsetFilter, state: Map<string, FilterPipelineData>): FilterPipelineData {
  const output: FilterPipelineData = cloneDeep(state[filter.in]);

  const dx = filter.dx === undefined ? 0 : parseMM(filter.dx);
  const dy = filter.dy === undefined ? 0 : parseMM(filter.dy);
  const translateMatrix: Matrix = Matrix.translate(dx, dy);

  output.currentTransform = Matrix.multiply(output.currentTransform, translateMatrix);
  output.currentBlackBox = transformBoundingBox(output.currentBlackBox, translateMatrix);

  return output;
}

function processDropShadow(filter: DropShadowFilter, state: Map<string, FilterPipelineData>, colorPalette: ColorPalette | undefined): FilterPipelineData {
  const output: FilterPipelineData = cloneDeep(state[filter.in]);

  const dx = filter.dx === undefined ? 0 : parseMM(filter.dx);
  const dy = filter.dy === undefined ? 0 : parseMM(filter.dy);
  const radius = parseMM(filter.radius ?? "0mm");
  const dropShadowFill = parseColor(filter.color ?? "", colorPalette);
  const overprints = parseOverprints(filter.overprints, colorPalette);

  const translateMatrix: Matrix = Matrix.translate(dx, dy);
  const blurEffect: PathBlurEffect = { type: "blur", radius: radius };

  output.operations.forEach((op) => {
    op.fill = dropShadowFill;
    op.overprints = overprints;
    op.stroke = undefined;
    op.effects = op.effects ? [...op.effects, blurEffect] : [blurEffect];
  });

  output.currentTransform = Matrix.multiply(output.currentTransform, translateMatrix);
  output.currentBlackBox = transformBoundingBox(output.currentBlackBox, translateMatrix);

  return output;
}

function processScale(filter: ScaleFilter, state: Map<string, FilterPipelineData>): FilterPipelineData {
  const output: FilterPipelineData = cloneDeep(state[filter.in]);

  let refPoint: Point = { x: 0, y: 0 };
  const xRelative: number = parsePercentage(filter.origin.x);
  const yRelative: number = parsePercentage(filter.origin.y);

  if (filter.origin.bounds === "blackbox") {
    refPoint = {
      x: output.currentBlackBox.left + output.currentBlackBox.width * xRelative,
      y: output.currentBlackBox.top + output.currentBlackBox.height * yRelative,
    };
  }

  const scaleMatrix: Matrix = Matrix.scaleAboutPoint(filter.scaleX ?? 1, filter.scaleY ?? 1, refPoint);
  output.currentTransform = Matrix.multiply(output.currentTransform, scaleMatrix);
  output.currentBlackBox = transformBoundingBox(output.currentBlackBox, scaleMatrix);

  return output;
}

function processMirror(filter: MirrorFilter, state: Map<string, FilterPipelineData>): FilterPipelineData {
  const output: FilterPipelineData = cloneDeep(state[filter.in]);

  let refPoint: Point;

  if (filter.direction === "vertical") {
    refPoint = {
      x: output.currentBlackBox.left + output.currentBlackBox.width / 2,
      y: output.currentBlackBox.top + output.currentBlackBox.height,
    };
  } else {
    refPoint = {
      x: output.currentBlackBox.left + output.currentBlackBox.width,
      y: output.currentBlackBox.top + output.currentBlackBox.height / 2,
    };
  }

  const transform = Matrix.mirrorAboutPoint(filter.direction, refPoint);

  output.currentTransform = Matrix.multiply(output.currentTransform, transform);
  output.currentBlackBox = transformBoundingBox(output.currentBlackBox, transform);

  return output;
}

interface FilterPipelineData {
  operations: PathOperation[];
  originalPosition: BoundingBox;
  currentBlackBox: BoundingBox;
  // We need the order of transforms to be in the order ABCDG where G is the globalTransform (rotationAngle and item transform)
  // and is applied last, and A-D are the transforms from the filters, in order of application.
  currentTransform: Matrix;
}
