import { HslFilter, TriangleBlurFilter } from "@rendering/plasma";
import { Updater } from "use-immer";

export function HslFilterControls({ filter, onChange }: { filter: HslFilter; onChange: Updater<[HslFilter, TriangleBlurFilter, TriangleBlurFilter]> }) {
  return (
    <>
      <label>Hue:</label>
      <input
        type="range"
        value={filter.hueOffset}
        min="0"
        max="360"
        step="0.05"
        onInput={(e) => {
          const value = e.currentTarget.value;
          onChange((draft) => {
            draft[0].hueOffset = Number(value);
          });
        }}
      />
      <label>Saturation:</label>
      <input
        type="range"
        value={filter.saturationOffset}
        min="0"
        max="1"
        step="0.05"
        onInput={(e) => {
          const value = e.currentTarget.value;
          onChange((draft) => {
            draft[0].saturationOffset = Number(value);
          });
        }}
      />
      <label>Lightness:</label>
      <input
        type="range"
        value={filter.lightnessOffset}
        min="0"
        max="1"
        step="0.05"
        onInput={(e) => {
          const value = e.currentTarget.value;
          onChange((draft) => {
            draft[0].lightnessOffset = Number(value);
          });
        }}
      />
    </>
  );
}
