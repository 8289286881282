import { CimpressDocument, Image } from "@mcp-artwork/cimdoc-types-v2";
import { buildImageProxyUrl, isWebPSupported, parseMM } from "@rendering/plasma";
import { ChangeEvent, memo, useEffect, useRef, useState } from "react";
import { InteractionEventEmitter } from "../../InteractionStateHandler";
import CSS from "./ImageInput.module.css";
import { Updater } from "use-immer";
import { getDocumentPanels } from "../../../selectors/document";

type ImageEditorProps = {
  image: Image;
  onSetCimDoc: Updater<CimpressDocument>;
  interactionEventEmitter: InteractionEventEmitter;
  moveIntoViewEnabled: boolean;
};

export const ImageEditor = memo(({ image, onSetCimDoc, interactionEventEmitter, moveIntoViewEnabled }: ImageEditorProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const initialValue = useRef<Image>(image);
  const [imageFormat, setImageFormat] = useState<"png" | "webp">();

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];
    if (file) {
      const tempPreviewUrl = URL.createObjectURL(file);
      onSetCimDoc((draft) => {
        getDocumentPanels(draft)
          .map((surface) => surface.images)
          .flat()
          .forEach((draftImage) => {
            if (draftImage?.id === image.id) {
              draftImage.previewUrl = tempPreviewUrl;
            }
          });
      });
      interactionEventEmitter.triggerEvent("focus", image.id);
    }

    e.currentTarget.value = "";
  };

  const onReset = () => {
    onSetCimDoc((draft) => {
      const surfaces = getDocumentPanels(draft);
      surfaces.forEach((surface) => {
        const index = surface.images?.findIndex((i) => i.id === image.id) ?? -1;
        if (index !== -1) {
          surface.images![index] = initialValue.current;
        }
      });
    });
  };

  useEffect(() => {
    const onClick = (id: string) => {
      if (image.id === id) {
        inputRef.current?.click();
      }
    };
    interactionEventEmitter.addEventListener("click", onClick);

    return () => {
      interactionEventEmitter.removeEventListener("click", onClick);
    };
  }, [interactionEventEmitter, image, inputRef]);

  useEffect(() => {
    isWebPSupported().then((webPsupported) => setImageFormat(webPsupported ? "webp" : "png"));
  }, []);

  return (
    <div className={CSS.Container}>
      <label className={CSS.ImageInput}>
        {image.previewUrl && (
          <img
            src={imageFormat && buildImageProxyUrl({ sourceUrl: image.previewUrl, format: imageFormat, referrer: "fusion-demo" })}
            style={{ aspectRatio: `${parseMM(image.position.width)} / ${parseMM(image.position.height)}` }}
            width={70}
            className={CSS.ImagePreview}
          />
        )}
        <input
          ref={inputRef}
          className={CSS.FileInput}
          type="file"
          multiple={false}
          onChange={onFileChange}
          onFocus={() => interactionEventEmitter.triggerEvent("focus", image.id)}
        />
        <div className={CSS.Text}>Change image</div>
      </label>
      {initialValue.current.previewUrl !== image.previewUrl && (
        <button className={CSS.Reset} onClick={onReset}>
          Reset
        </button>
      )}
      {moveIntoViewEnabled && (
        <button className={CSS.Eyes} title="Move into view" onClick={() => interactionEventEmitter.triggerEvent("focus", image.id)}>
          👀
        </button>
      )}
    </div>
  );
});
