import { DesignSurface, Subpanel, Ornament, ColorPalette } from "@mcp-artwork/cimdoc-types-v2";
import {
  OrderableItemInfo,
  TextOptions,
  LayoutElement,
  OrderableLayoutElement,
  ExperimentalOptions,
  ImageOptions,
  VideoOptions,
  PreviewType,
} from "../models/Layout";
import { BoundingBox } from "../utils/boundingBox";
import { log } from "../utils/log";
import { timestamp } from "../utils/time";
import { generateAnimationFunction } from "./helpers/Animation";
import { DecorationTechnology } from "./helpers/Technology";
import { imageLayout } from "./image/Layout";
import { GetAnimationFunction } from "./Models";
import { shapeLayout } from "./shapes/Layout";
import { subpanelLayout } from "./subpanel/Layout";
import { textAreaLayout } from "./text/Layout";
import { playableVideoLayout } from "./video/PlayableLayout";
import { staticVideoLayout } from "./video/StaticLayout";
import { ornamentLayout } from "./ornament/Layout";
import { Item } from "../models/Item";
import { itemReferenceLayout } from "./itemReference/Layout";
import CimDocDefinitionTreeNode from "../utils/CimDocDefinitionTreeNode";
import { MissingPantoneColorsError, MissingSpotColorsError, fetchPantoneColors, fetchSpotColors } from "@mcp-artwork/color-converter";
import { setColorConverterFetchMethod } from "../preFetch";

type LayoutItemArgs = {
  fontRepositoryUrl?: string;
  itemInfo: OrderableItemInfo;
  textOptions?: TextOptions;
  videoOptions?: VideoOptions;
  imageOptions?: ImageOptions;
  parentBounds: BoundingBox;
  pixelSize?: string;
  trackTime: boolean;
  enableLog: boolean;
  decoTech: DecorationTechnology;
  validateAndLayout: (args: {
    surfaceOrSubpanel: DesignSurface | Subpanel;
    definitionTreeNodeOverride?: CimDocDefinitionTreeNode;
    previewTypeOverride?: PreviewType;
  }) => Promise<LayoutElement[]>;
  experimentalOptions?: ExperimentalOptions;
  overprints?: string[];
  definitionTreeNode?: CimDocDefinitionTreeNode;
  referrer: string;
  previewType: PreviewType;
  colorPalette: ColorPalette | undefined;
};

export async function layoutItem(options: LayoutItemArgs): Promise<OrderableLayoutElement | undefined> {
  const {
    itemInfo,
    fontRepositoryUrl,
    textOptions,
    imageOptions,
    parentBounds,
    trackTime,
    enableLog,
    decoTech,
    validateAndLayout,
    definitionTreeNode,
    videoOptions,
    referrer,
    previewType,
    colorPalette,
  } = options;
  try {
    const { depth, itemType, item } = itemInfo;
    const itemId = (item as Exclude<Item, Ornament>).id ?? (item as Ornament).type;

    log({ message: `layout element ${itemType} ${itemId}`, enabled: enableLog, objects: { ...options, item, decoTech } });

    const start = timestamp(trackTime);
    let result: LayoutElement | undefined = undefined;

    if (itemType === "image") {
      result = await imageLayout({
        image: item,
        parentBounds,
        experimentalOptions: options.experimentalOptions,
        overprints: options.overprints,
        imageOptions,
        referrer,
        previewType,
        colorPalette,
        fontRepositoryUrl,
      });
    }
    if (itemType === "video") {
      if (videoOptions?.enableVideo) {
        switch (videoOptions.mode) {
          case "video":
            result = await playableVideoLayout({
              video: item,
              parentBounds,
              experimentalOptions: options.experimentalOptions,
              overprints: options.overprints,
            });
            break;
          default:
            result = await staticVideoLayout({
              video: item,
              parentBounds,
              experimentalOptions: options.experimentalOptions,
              overprints: options.overprints,
              referrer,
              previewType,
              colorPalette: options.colorPalette,
            });
            break;
        }
      } else {
        throw Error("Video is not enabled");
      }
    }
    if (itemType === "itemReference") {
      result = await itemReferenceLayout({
        itemReference: item,
        validateAndLayout,
        parentBounds,
        previewType,
        colorPalette,
        definitionTreeNode,
        fontRepositoryUrl,
      });
    }
    if (itemType === "shape") {
      result = await shapeLayout({
        shape: item,
        decoTech,
        parentBounds,
        options: { definitionTreeNode, fontRepositoryUrl, colorPalette },
        previewType,
      });
    }
    if (itemType === "textArea") {
      if (fontRepositoryUrl === undefined) {
        throw Error("Font repository is undefined");
      }
      result = await textAreaLayout({
        textArea: item,
        textOptions,
        fontRepositoryUrl,
        decorationTechnology: decoTech,
        parentBounds,
        trackTime,
        enableLog,
        options: { definitionTreeNode, colorPalette },
        previewType,
      });
    }
    if (itemType === "ornament") {
      result = await ornamentLayout({
        ornament: item,
        parentBounds,
        imageOptions,
        referrer,
        previewType,
        colorPalette,
      });
    }
    if (itemType === "subpanel") {
      let defTreeNode: CimDocDefinitionTreeNode | undefined = definitionTreeNode;

      if (item.definitions !== undefined) {
        if (defTreeNode !== undefined) {
          const childNode = defTreeNode.createChildNode(item.definitions);
          defTreeNode = childNode;
        } else {
          defTreeNode = new CimDocDefinitionTreeNode(item.definitions, undefined);
        }
      }

      const layoutElements = await validateAndLayout({ surfaceOrSubpanel: item, definitionTreeNodeOverride: defTreeNode, previewTypeOverride: "document" });

      defTreeNode = defTreeNode?.parent;

      result = await subpanelLayout({
        layoutElements,
        parentBounds,
        subpanel: item,
        options: { definitionTreeNode: defTreeNode },
        previewType,
      });
    }

    if (!result) {
      if (itemType === "ornament") {
        log({ message: `layout element for ornament ${itemId} skipped`, enabled: enableLog, objects: { item } });
        return undefined; // Skipped ornament type
      }

      throw Error("An item selector must have an id or type!");
    }

    let animationFunction: GetAnimationFunction | undefined = undefined;
    if (itemType !== "ornament" && videoOptions?.enableVideo && videoOptions?.mode === "video") {
      animationFunction = generateAnimationFunction(item, parentBounds, result);
    }

    log({ message: `layout element ${itemType} ${itemId} done`, enabled: enableLog, objects: result });

    return {
      depth,
      value: {
        ...result,
        renderingOperation: { ...result.renderingOperation, ...(animationFunction ? { getAnimation: animationFunction } : {}) },
        ...(start
          ? {
              debugInfo: {
                timers: {
                  total: timestamp(true) - start,
                },
              },
            }
          : {}),
      },
    };
  } catch (error) {
    if (error instanceof MissingPantoneColorsError) {
      log({ message: "missing pantone colors, fetching file...", enabled: enableLog, objects: { error } });
      setColorConverterFetchMethod();
      await fetchPantoneColors();
      log({ message: "done fetching pantone colors", enabled: enableLog });
      return layoutItem(options);
    }
    if (error instanceof MissingSpotColorsError) {
      log({ message: "missing spot colors, fetching file...", enabled: enableLog, objects: { error } });
      setColorConverterFetchMethod();
      await fetchSpotColors();
      log({ message: "done fetching spot colors", enabled: enableLog });
      return layoutItem(options);
    }

    throw error;
  }
}
