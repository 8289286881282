import { ReactNode, useState } from "react";
import { VortexDemoData, vortexDemoData } from "../data/vortexDemoData";
import { AddCustomDemoForm } from "./AddCustomDemoForm";
import CSS from "./DemoPicker.module.css";

type DemoPickerProps = {
  children: ({ demoData }: { demoData: VortexDemoData }) => ReactNode;
};

export function DemoPicker({ children }: DemoPickerProps) {
  const [demoData, setDemoData] = useState<VortexDemoData>(vortexDemoData[0]);
  const [showAddCustomForm, setShowAddCustomForm] = useState<boolean>(false);

  const onSubmit = ({ renderingInstructionsUrl, productConfig }: { renderingInstructionsUrl: string; productConfig: string }) => {
    setDemoData({
      ...vortexDemoData[0],
      name: "custom",
      image: "",
      productConfig,
      renderingInstructionsUrl,
    });
    setShowAddCustomForm(false);
  };

  return (
    <>
      <ul className={CSS.List}>
        {vortexDemoData.map((data) => (
          <li className={CSS.ListItem} key={data.name} onClick={() => setDemoData(data)}>
            <img alt={data.name} className={CSS.Image} src={data.image} draggable={false} />
            <div className={CSS.Name}>{data.name}</div>
          </li>
        ))}
        <li className={CSS.ListItem} onClick={() => setShowAddCustomForm(true)}>
          <div className={CSS.Image}>🤷</div>
          <div className={CSS.Name}>Add custom</div>
        </li>
      </ul>
      {children({ demoData })}
      {showAddCustomForm && <AddCustomDemoForm onSubmit={onSubmit} onClose={() => setShowAddCustomForm(false)} />}
    </>
  );
}
