import { PaintResult } from "@rendering/neon";
import { LayoutElement, LayoutResult } from "@rendering/plasma";

export function logLayoutDiagnostics(result: LayoutResult) {
  result.debugInfo?.timers && console.group(`Layout diagnostics`);
  Object.keys(result.debugInfo?.timers ?? {}).forEach((key) => {
    console.log(`Layout ${key}: ${result.debugInfo!.timers[key]} ms`);
  });

  for (let i = 0; i < result.elements.length; i++) {
    const element: LayoutElement = result.elements[i];

    Object.keys(element.debugInfo?.timers ?? {}).forEach((key) => {
      console.log(`Layout element ${i} ${key}: ${element.debugInfo!.timers[key]} ms`);
    });
  }
  result.debugInfo?.timers && console.groupEnd();
}

export function logPaintDiagnostics(results: PaintResult[]) {
  for (const result of results) {
    if (result.debugInfo?.timers) {
      console.log(`Paint: ${result.debugInfo.timers.paint} ms`);
    }
  }
}
