import { useRef } from "react";
import { OnPaintArgs } from "@rendering/fusion-react";
import { useCloseOnOutside } from "../hooks/useCloseOnOutside";
import { Item } from "../models";
import CSS from "./FusionDebug.module.css";

type FusionDebugProps = {
  fusionResults: OnPaintArgs | undefined;
  onClose: () => void;
  item: Item;
};

export function FusionDebug({ fusionResults, onClose, item }: FusionDebugProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  useCloseOnOutside({ getElement: () => containerRef.current, callback: onClose });

  return (
    <div className={CSS.Container} ref={containerRef}>
      <h2>CimDoc item</h2>
      <pre>{JSON.stringify(item, null, 4)}</pre>
      <h2>LayoutResult</h2>
      <pre>
        {JSON.stringify(
          (fusionResults ?? []).map((x) => x.layoutResult),
          null,
          4,
        )}
      </pre>
      <h2>PaintResult</h2>
      <pre>
        {JSON.stringify(
          (fusionResults ?? []).map((x) => x.paintResult),
          null,
          4,
        )}
      </pre>
    </div>
  );
}
