import { useCallback, useMemo, useState } from "react";
import { FusionPreview, FusionPreviewProps } from "@rendering/product-preview-react";
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";

export function ScenePreview({
  cimDoc,
  sceneUrl,
  page,
  width,
  rtextEnabled,
}: {
  cimDoc: CimpressDocument;
  sceneUrl: string;
  page: number;
  width: number;
  rtextEnabled: boolean;
}) {
  const [error, setError] = useState<string>();
  const view = useMemo<FusionPreviewProps["view"]>(
    () => ({
      type: "scene",
      name: "",
      purpose: "",
      subPurpose: "",
      scene: {
        id: `${Math.random()}`,
        url: sceneUrl,
        type: new URL(sceneUrl).pathname.endsWith("/scenes:generate") ? "dynamic" : "photographic",
        dimensions: {
          // fake
          height: 0,
          width: 0,
        },
        documents: [
          {
            blendingMode: "normal", // fake
            page,
          },
        ],
      },
    }),
    [page, sceneUrl],
  );

  const user = useMemo<FusionPreviewProps["user"]>(
    () => ({
      referer: "fusion-demo",
      tenant: {
        id: "doc-platform",
        type: "merchants",
      },
    }),
    [],
  );

  const fusionOptions = useMemo<FusionPreviewProps["fusionOptions"]>(
    () => ({
      textOptions: {
        rtextEnabled,
      },
      experimentalOptions: {
        svgInImagesSupport: true,
      },
    }),
    [rtextEnabled],
  );

  const onPaint = useCallback(() => {
    document.dispatchEvent(new Event("completePaint"));
    // @ts-ignore
    window.completePaint = true;
  }, []);

  const onError = useCallback((e: any) => {
    const message: string = (e as Error).message ?? "Unknown error!";
    const failEvent = new Event("fusionError");
    // @ts-ignore
    failEvent.errorMessage = message;
    document.dispatchEvent(failEvent);

    // @ts-ignore
    window.fusionError = message;

    setError(message);
  }, []);

  return (
    <div>
      {error && <div>Error: {error} </div>}
      <div style={{ width }}>
        <FusionPreview cimDoc={cimDoc} view={view} user={user} onPaint={onPaint} onError={onError} fusionOptions={fusionOptions}>
          {({ Canvas }) => <Canvas id="display" style={{ width: "100%" }} />}
        </FusionPreview>
      </div>
    </div>
  );
}
