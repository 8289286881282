import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { fetchData, resolveShortURL } from "../../utils/fetch";
import { decodeDocument } from "../../parsers/document";

function getSearchParam(documentUrlParams: URLSearchParams, paramName: string): string | null {
  return documentUrlParams.get(paramName) ?? documentUrlParams.get(paramName.toLowerCase());
}

async function parseDocumentFromQuery(url: string): Promise<CimpressDocument | undefined> {
  const documentUrlParams = new URLSearchParams(new URL(url).search);

  const instructionsUri = getSearchParam(documentUrlParams, "instructions_uri");
  if (instructionsUri) {
    return parseDocumentFromQuery(instructionsUri);
  }

  const docUri = getSearchParam(documentUrlParams, "documentUri") || getSearchParam(documentUrlParams, "document");
  if (docUri) {
    if (docUri.startsWith("http")) {
      // Force https
      const docURL = new URL(docUri);
      docURL.protocol = "https:";
      return fetchData<CimpressDocument>({ url: docURL.toString() });
    }
    return decodeDocument({ cimDoc: docUri });
  }

  return undefined;
}

export async function fetchDocument(data: string): Promise<CimpressDocument> {
  if (data.startsWith("http")) {
    return fetchDocumentFromUrl(data);
  }

  return decodeDocument({ cimDoc: data });
}

export async function fetchDocumentFromUrl(url: string): Promise<CimpressDocument> {
  try {
    new URL(url);
  } catch (error) {
    throw new Error("Provided document url is not a valid http url!");
  }
  if (new URL(url).host === "cim.press") {
    const response = await resolveShortURL({ shortURL: url });
    return fetchDocumentFromUrl(response.url);
  }

  const documentFromQuery = await parseDocumentFromQuery(url);

  if (documentFromQuery) {
    return documentFromQuery;
  }

  const documentResponse = await fetchData<CimpressDocument | { cimDocUrl: string }>({ url });

  if ((documentResponse as { cimDocUrl?: string }).cimDocUrl) {
    const document = await fetchData<CimpressDocument>({ url: (documentResponse as { cimDocUrl: string }).cimDocUrl });

    return document;
  }

  if ((documentResponse as CimpressDocument).document) {
    return documentResponse as CimpressDocument;
  }

  throw new Error("Unable to fetch document");
}
