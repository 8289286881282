import * as SvgPath from "svgpath";
import { toMM } from "./unitHelper";

type ParsePathDataArguments = {
  pixelSize: number;
  pathData: string;
  svgPathDataUnit: string;
  closeBehavior?: string; // "always" | "explicit" | "0" | "1"; // 0 = always 1 = explicit
  scaleX?: number;
  scaleY?: number;
  translateX?: number;
  translateY?: number;
};

// Remove ',' after command. Sometimes there might be a ',' after a command like : "M,100,10" should become: M100,10
const cleanPath = (path: string): string => path.replace(/(M|m|L|l|H|h|V|v|C|c|s|S|Q|q|T|t|A|a|Z|z),/g, (match) => match[0]);

export function parsePathData({
  pathData,
  pixelSize,
  svgPathDataUnit = "pt",
  closeBehavior = "always",
  scaleX = 1,
  scaleY = 1,
  translateX = 0,
  translateY = 0,
}: ParsePathDataArguments): [Omit<typeof SvgPath, "default">, string] {
  const svgPath = SvgPath.from(cleanPath(pathData))
    .iterate((segment) => {
      const [, ...coordinates] = segment;

      coordinates.forEach((coordinate, index) => {
        segment[index + 1] = toMM(coordinate, svgPathDataUnit) / pixelSize;
      });
    })
    .scale(scaleX, scaleY)
    .translate(translateX, translateY);

  let path = svgPath.toString();

  // TODO: This is not complete. We should add close instructions before every move command as well.
  if (!path.endsWith("Z") && ["always", 0, "0"].includes(closeBehavior)) {
    path = `${path}Z`;
  }

  return [svgPath, path];
}
