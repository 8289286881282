import { LineItem, Shape } from "@mcp-artwork/cimdoc-types-v2";
import { ColorPickerInput } from "../Inputs/ColorPickerInput";
import { CimDocUpdater } from "../../models";
import CSS from "./Shape.module.css";
import { useCallback } from "react";
import { findShape } from "../../utils";

type ShapeEditorProps = {
  shape: Shape;
  setCimDoc: CimDocUpdater;
};
export function ShapeEditor({ shape, setCimDoc }: ShapeEditorProps) {
  const color = (shape as Exclude<Shape, LineItem>).color;
  const hasStrokeColor = shape.stroke?.color && shape.stroke?.thickness !== "0mm";

  const onColorChange = useCallback((value: string) => {
    setCimDoc((draft) => {
      if (draft) {
        const draftShape = findShape(draft, shape.id);
        (draftShape as Exclude<Shape, LineItem>).color = value;
      }
    });
  }, []);

  const onStrokeColorChange = useCallback((value: string) => {
    setCimDoc((draft) => {
      if (draft) {
        const draftShape = findShape(draft, shape.id);
        draftShape.stroke!.color = value;
      }
    });
  }, []);

  return (
    <>
      {color && <ColorPickerInput className={CSS.Wrapper} color={color} onColorChange={onColorChange} />}
      {hasStrokeColor && <ColorPickerInput className={CSS.Wrapper} color={shape.stroke!.color!} onColorChange={onStrokeColorChange} />}
    </>
  );
}
